import { RecipeIngredientDto } from './recipe-ingredient-dto';

export class RecipeCostDto{
    constructor(recipeCost: RecipeCostDto){
        if(recipeCost!=null){
            this.id = recipeCost.id;
            this.recipeNumber = recipeCost.recipeNumber;
            this.name = recipeCost.name;
            this.cost = recipeCost.cost;
            this.recipeIngredients = recipeCost.recipeIngredients;
        }
    }

    id: string;
    recipeNumber: string;
    name: string;
    cost: number;
    recipeIngredients: RecipeIngredientDto[];

}