//import { EditorialDocumentsApiCallerService } from '../../shared/api-services/employees-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  addEditorialDocument,
  addEditorialDocumentSuccess,
  addEditorialDocumentFailure,
  deleteEditorialDocument,
  deleteEditorialDocumentSuccess,
  deleteEditorialDocumentFailure,
  setPageableEditorialDocumentsFilters,
  setPageableEditorialDocumentsFiltersSuccess,
  setPageableEditorialDocumentsFiltersFailure,
  loadPageableEditorialDocuments,
  loadPageableEditorialDocumentsSuccess,
  loadPageableEditorialDocumentsFailure,
  getEditorialDocument,
  updateEditorialDocument,
  updateEditorialDocumentSuccess,
  updateEditorialDocumentFailure,
  getEditorialDocumentSuccess,
  getEditorialDocumentFailure,
} from './editorial-documents.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { selectAllPageableEditorialDocumentsFilters } from './editorial-documents.selector';
import { EditorialDocumentsApiCallerService } from '../../shared/api-services/editorial-documents-api-caller.service';

@Injectable()
export class EditorialDocumentsEffects {

  private latestedEditorialDocumentsKey: string;

  deleteEditorialDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEditorialDocument),
      concatMap(({ id }) => {
        return this.editorialDocumentsApiCaller.deleteEditorialDocument(id).pipe(
          map((_) => deleteEditorialDocumentSuccess({ id: id })),
          tap(() => this.reloadEditorialDocuments(this.latestedEditorialDocumentsKey)),
          catchError((error) => of(deleteEditorialDocumentFailure({ error })))
        );
      })
    )
  );

  addEditorialDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addEditorialDocument),
      concatMap(({ data }) => {
        console.log("post", data);
        return this.editorialDocumentsApiCaller.addEditorialDocument(data).pipe(
          map((_) => addEditorialDocumentSuccess()),
          tap(() => this.navigateToCatalog()),
          catchError((error) => of(addEditorialDocumentFailure({ error })))
        );
      })
    )
  );

  updateEditorialDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEditorialDocument),
      concatMap(({ data }) => {
        return this.editorialDocumentsApiCaller.updateEditorialDocument(data).pipe(
          map((_) => updateEditorialDocumentSuccess()),
          tap(() => this.navigateToCatalog()),
          catchError((error) => of(updateEditorialDocumentFailure({ error })))
        );
      })
    )
  );

  loadPageableEditorialDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableEditorialDocuments),
      withLatestFrom(this.store$.select(selectAllPageableEditorialDocumentsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedEditorialDocumentsKey = key;

        return this.editorialDocumentsApiCaller.getPageableEditorialDocuments(filter.value).pipe(
          map((_) => loadPageableEditorialDocumentsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableEditorialDocumentsFailure({ error })))
        );
      })
    )
  );

  getEditorialDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEditorialDocument),
      concatMap(({id}) => {
        return this.editorialDocumentsApiCaller.getEditorialDocument(id).pipe(
          map((_) => getEditorialDocumentSuccess({ data: _ })),
          catchError((error) => of(getEditorialDocumentFailure({ error })))
        );
      })
    )
  );

  setPageableEditorialDocumentsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableEditorialDocumentsFilters),
      concatMap(({ data, key }) => {
        this.latestedEditorialDocumentsKey = key;
        return of(data).pipe(
          map((_) => setPageableEditorialDocumentsFiltersSuccess({ data, key })),
          finalize(() => this.reloadEditorialDocuments(key)),
          catchError((error) => of(setPageableEditorialDocumentsFiltersFailure({ error })))
        );
      })
    )
  );

  private reloadEditorialDocuments(key: string) {
    this.store$.dispatch(loadPageableEditorialDocuments({ key }));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private editorialDocumentsApiCaller: EditorialDocumentsApiCallerService,
    private router: Router,
  ) { }

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'editorial-documents', 'catalog']);
  }
}
