import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { MaterialModule } from '../material.module';
import { PipesModule } from '../pipes/pipes.module';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { AutocompleteWithButtonComponent } from './autocomplete-with-button/autocomplete-with-button.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { AddClientDialogComponent } from './add-client-dialog/add-client-dialog.component';
import { AddEmployeeDialogComponent } from './add-employee-dialog/add-employee-dialog.component';
import { AddDepartmentDialogComponent } from './add-department-dialog/add-department-dialog.component';
import { EditActionsComponent } from './edit-actions/edit-actions.component';
import { ErrorSendingAttachmentsComponent } from './error-message/error-sending-attachments.component';
import { SnackBarMessagesComponent } from './error-message/snack-bar-messages.component';
import { FilesManagerComponent } from './files-manager/files-manager.component';
import { FilesUploadComponent } from './files-upload/files-upload.component';
import { CustomFilesUploadComponent } from './custom-files-upload/custom-files-upload.component';
import { BussinesIndividualFormComponent } from './forms/bussines-individual-form/bussines-individual-form.component';
import { ContractorEmployeeGridComponent } from './forms/contractor-employee-grid/contractor-employee-grid.component';
import { InputComponent } from './input/input.component';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';
import { MatTableComponent } from './mat-table/mat-table.component';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SelectableTableComponent } from './selectable-table/selectable-table.component';
import { SpinnerButtonComponent } from './spinner-button/spinner-button.component';
import { StepperNavigationComponent } from './stepper-navigation/stepper-navigation.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { RangePickerComponent } from './range-picker/range-picker.component';
import { BaseDialogComponent } from './base-dialog/base-dialog.component';
import { ContactEntryComponent } from './contact-entry/contact-entry.component';
import { OptionsSelectComponent } from './options-select/options-select.component';
import { AddAuthorDialogComponent } from './add-author-dialog/add-author-dialog.component';
import { AutocompleteNullableComponent } from './autocomplete-nullable/autocomplete-nullable.component';
import { PhonesManagerComponent } from './phones-manager/phones-manager.component';
import { AutocompleteWithButtonArrayComponent } from './autocomplete-with-button-array/autocomplete-with-button-array.component';
import { DialogInfoComponent } from './dialog-info/dialog-info.component';
import { UploadFileDialogComponent } from './upload-file-dialog/upload-file-dialog.component';
import { NgHttpLoaderModule } from 'ng-http-loader';

@NgModule({
    declarations: [
        SelectSearchComponent,
        InputComponent,
        SpinnerButtonComponent,
        MenuListItemComponent,
        LoaderSpinnerComponent,
        AutocompleteComponent,
        AutocompleteNullableComponent,
        AutocompleteWithButtonComponent,
        BussinesIndividualFormComponent,
        EditActionsComponent,
        UnderConstructionComponent,
        FilesUploadComponent,
        CustomFilesUploadComponent,
        FilesManagerComponent,
        ErrorSendingAttachmentsComponent,
        DeleteDialogComponent,
        AddClientDialogComponent,
        AddAuthorDialogComponent,
        AddEmployeeDialogComponent,
        AddDepartmentDialogComponent,
        SnackBarMessagesComponent,
        ContractorEmployeeGridComponent,
        StepperNavigationComponent,
        MatTableComponent,
        SelectableTableComponent,
        RangePickerComponent,
        BaseDialogComponent,
        ContactEntryComponent,
        OptionsSelectComponent,
        PhonesManagerComponent,
        AutocompleteWithButtonArrayComponent,
        DialogInfoComponent,
        UploadFileDialogComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        FontAwesomeModule,
        NgSelectModule,
        MaterialModule,
        PipesModule,
        RouterModule,
        NgHttpLoaderModule.forRoot()
    ],
    exports: [
        SelectSearchComponent,
        InputComponent,
        SpinnerButtonComponent,
        MenuListItemComponent,
        LoaderSpinnerComponent,
        AutocompleteComponent,
        AutocompleteNullableComponent,
        AutocompleteWithButtonComponent,
        BussinesIndividualFormComponent,
        PipesModule,
        EditActionsComponent,
        UnderConstructionComponent,
        FilesUploadComponent,
        CustomFilesUploadComponent,
        FilesManagerComponent,
        ContractorEmployeeGridComponent,
        StepperNavigationComponent,
        MatTableComponent,
        SelectableTableComponent,
        RangePickerComponent,
        BaseDialogComponent,
        ContactEntryComponent,
        OptionsSelectComponent,
        PhonesManagerComponent,
        AutocompleteWithButtonArrayComponent,
        DialogInfoComponent,
        UploadFileDialogComponent
    ]
})
export class WidgetsModule {}
