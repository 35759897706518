import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './c-types.reducer';

const selectCTypesFeature = (state: StoreState) => state.cTypes;

export const selectCTypesForSelect = createSelector(selectCTypesFeature, (state: State, props: { key: string }) => {
  const result = state.cTypesForSelect;
  return result ? result : null;
});

export const selectPageableCTypes = createSelector(selectCTypesFeature, (state: State) => {
  const result = state.pageableCTypes;
  return result ? result : null;
});

export const selectCType = createSelector(selectCTypesFeature, (state: State) => {
  const result = state.cType;
  return result ? result : null;
});