import { AddressType } from '../enums/address-type.enum';

export class AddressDto {
  constructor(data: AddressDto) {
    if (data != null) {
      this.id = data.id;
      
      this.country = data.country;
      this.city = data.city;
      this.address = data.address;
      this.postalCode = data.postalCode;
      this.street = data.street;
      this.houseNumber = data.houseNumber;
      this.apartmentNumber = data.apartmentNumber;
      this.addressType = data.addressType;
    }
  }

  id: number;
  country: string;
  city: string;
  address: string;
  postalCode: string;
  street: string;
  houseNumber: number;
  apartmentNumber: number;
  addressType: AddressType;
}
