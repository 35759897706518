import { Injectable, InjectionToken, Inject, OnInit } from '@angular/core';
import { Store, select, on } from '@ngrx/store';
import { StoreState } from '../../root-store/store-state';
import { selectUserData, selectClaimsDictionary } from '../../root-store/user-data/user-data-selectors';
import { PermissionDictionaryElement } from '../models/permissionDictionaryElement';
import { ArrayHelper } from '../helpers/array-helper';
import { filter } from 'rxjs/operators';
import { UserDataModel } from '../models/userDataModel';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  userData: UserDataModel;
  allClaims: PermissionDictionaryElement[];

  constructor(private store$: Store<StoreState>) {
    this.store$
      .pipe(
        select(selectUserData),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        //dispatch for that is executed in authorized-layout component
        this.userData = data;
      });

    this.store$
      .pipe(
        select(selectClaimsDictionary),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        this.allClaims = data;
      });
  }

  hasClaim(claimType): boolean {
    const claimValue = this.allClaims.filter((obj) => {
      return obj.name === claimType;
    });
    const accessAll = this.allClaims.filter((obj) => {
      return obj.name === 'AccessAll';
    });
    if (
      (claimValue.length > 0 && this.userData.EnumPermission.includes(claimValue[0].value.toString())) ||
      this.userData.EnumPermission.includes(accessAll[0].value.toString())
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasClaims(claims: string[]): boolean {
    if (this.allClaims == null || this.userData == null) {
      return false;
    }
    const accessAll = this.allClaims.filter((obj) => {
      return obj.name === 'AccessAll';
    });
    if (this.userData.EnumPermission == undefined){
      return false;
    }
    if (this.userData.EnumPermission.includes(accessAll[0].value.toString())) {
      return true;
    }

    const claimsValues = this.allClaims.filter((obj) => {
      return claims.includes(obj.name);
    });
    
    return ArrayHelper.arraysIntersects(claimsValues.map((_) => _.value.toString()), this.userData.EnumPermission);
  }
}
