import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';

@Component({
  selector: 'app-authorised-layout',
  templateUrl: './authorised-layout.component.html',
  styleUrls: ['./authorised-layout.component.scss'],
  //animations: [
  //  trigger('toggleNav', [
  //    state('collapsed, void', style({ transform: 'translateX(-100%)' })),
  //    state('expanded', style({ transform: 'translateX(0)' })),
  //    transition('collapsed <=> expanded', [animate(200), animate(200)])
  //  ])
  //],
  encapsulation: ViewEncapsulation.None,
})
export class AuthorisedLayoutComponent implements OnInit, OnDestroy {
  @ViewChild("spinner", {static:true}) spinner;
  mobileQuery: MediaQueryList;
  public year = new Date().getFullYear();
  //public navState = 'expanded';
  public opened: boolean;

  isAuthenticated: boolean = false;
  
  constructor(
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private overlayService: OverlayWrapperService,
    public oidcSecurityService: OidcSecurityService,
  ) {
    this.opened = true;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe((auth) => {
      this.isAuthenticated = auth;
    });
    this.overlayService.spinner = this.spinner;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public showNav() {
    return this.router.url !== '/signin';
  }

  private _mobileQueryListener: () => void;
}
