import {
  addStructuralElement,
  addStructuralElementFailure,
  addStructuralElementSuccess,
  getStructuralElement,
  getStructuralElementFailure,
  getStructuralElementSuccess,
  loadPageableStructuralElements,
  loadPageableStructuralElementsFailure,
  loadPageableStructuralElementsSuccess,
  loadStructuralElement,
  loadStructuralElementFailure,
  loadStructuralElementSuccess,
  setPageableStructuralElementFilters,
  setPageableStructuralElementFiltersSuccess,
} from './structural-element.actions';
import { StructuralElementDto } from './../../shared/models/structural-element/structuralElement.Dto';
import { Action, createReducer, on } from '@ngrx/store';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableStructuralElementsQuery } from '../../shared/models/queries/get-pageable-structural-elements.query';

export const structuralElementFeatureKey = 'structuralElement';

export interface State {
  pageableStructuralElements?: DictionaryDto<string, PageableDto<StructuralElementDto>>;
  structuralElementsFilters?: DictionaryDto<string, GetPageableStructuralElementsQuery>;
  structuralElement?: StructuralElementDto;
  structuralElements?: StructuralElementDto[];
}

export const initialState: State = {
  structuralElementsFilters: new DictionaryDto<string, GetPageableStructuralElementsQuery>(),
};

const structuralElementReducer = createReducer(
  initialState,

  on(addStructuralElement, (state) => setAddStructuralElement(state, null)),
  on(addStructuralElementSuccess, (state) => setAddStructuralElement(state, null)),
  on(addStructuralElementFailure, (state) => setAddStructuralElement(state, null)),

  on(loadPageableStructuralElements, (state, { key }) => setPageableStructuralElements(state, null, key)),
  on(loadPageableStructuralElementsSuccess, (state, { data, key }) => setPageableStructuralElements(state, data, key)),
  on(loadPageableStructuralElementsFailure, (state, action) => state),

  on(getStructuralElement, (state) => setStructuralElement(state, null)),
  on(getStructuralElementSuccess, (state, { data }) => setStructuralElement(state, data)),
  on(getStructuralElementFailure, (state, action) => state),

  on(setPageableStructuralElementFilters, (state, { data, key }) =>
    setPageableStructuralElementFiltersFunc(state, null, key)
  ),
  on(setPageableStructuralElementFiltersSuccess, (state, { data, key }) =>
    setPageableStructuralElementFiltersFunc(state, data, key)
  ),

  on(loadStructuralElement, (state) => state),
  on(loadStructuralElementSuccess, (state, { data }) => setStructuralElements(state, data)),
  on(loadStructuralElementFailure, (state) => setStructuralElements(state, null))
);

function setPageableStructuralElements(state: State, data: PageableDto<StructuralElementDto>, key: string) {
  const pageableStructuralElements = Array.isArray(state.pageableStructuralElements)
    ? state.pageableStructuralElements
    : new DictionaryDto<string, PageableDto<StructuralElementDto>>();
  const orders = pageableStructuralElements.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableStructuralElements.push({ key, value: data });
  }

  return {
    ...state,
    pageableStructuralElements,
  };
}

function setStructuralElement(state: State, data: StructuralElementDto) {
  var structuralElement = data;
  return {
    ...state,
    structuralElement,
  };
}

function setAddStructuralElement(state: State, data: StructuralElementDto[]) {
  return {
    ...state,
    data,
  };
}

function setPageableStructuralElementFiltersFunc(state: State, data: GetPageableStructuralElementsQuery, key: string) {
  const structuralElementsFilters = Array.isArray(state.structuralElementsFilters)
    ? state.structuralElementsFilters
    : new DictionaryDto<string, GetPageableStructuralElementsQuery>();
  const filter = structuralElementsFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    structuralElementsFilters.push({ key, value: data });
  }

  return {
    ...state,
    structuralElementsFilters,
  };
}

function setStructuralElements(state: State, data: StructuralElementDto[]) {
  return {
    ...state,
    structuralElements: data,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return structuralElementReducer(state, action);
}
