import { Component, OnDestroy, OnInit } from '@angular/core';
import { InfoApiCallerService } from '../shared/api-services/info-api-caller.service';
import { version } from '../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  appVersion: string;
  apiVersion: string;

  constructor(private infoApiService: InfoApiCallerService) {}

  ngOnInit() {
    //this.infoApiService.getVersion().subscribe((data) => {
    //  this.apiVersion = data;
    //});
    this.appVersion = '1.5';
  }
  ngOnDestroy() {}
}
