import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './outgoing-messages.reducer';

const selectOutgoingMessagesFeature = (state: StoreState) => state.outgoingMessages;

export const selectPageableOutgoingMessages = createSelector(selectOutgoingMessagesFeature, (state: State, props: { key: string }) => {
  const result = state.pageableOutgoingMessages ? state.pageableOutgoingMessages.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectOutgoingMessage = createSelector(selectOutgoingMessagesFeature, (state: State) => {
  const result = state.outgoingMessage;
  return result ? result : null;
});

export const selectAllPageableOutgoingMessagesFilters = createSelector(selectOutgoingMessagesFeature, (state: State) => state.outgoingMessagesFilters);
export const selectPageableOutgoingMessagesFilters = createSelector(selectOutgoingMessagesFeature, (state: State, props: { key: string }) => {
  const result = state.outgoingMessagesFilters ? state.outgoingMessagesFilters.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});