import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ItemApiCallerService } from '../api-services/item/item-api-caller.service';

@Injectable()
export class ItemNameValidator {
  constructor(private itemsApiCaller: ItemApiCallerService) {}

  createValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value) {

        let nodeId = control.parent.value.nodeId;
        let customerId = control.parent.value.clientId;
        let addressId = control.parent.value.addressId;
        let name = control.value;

        return this.itemsApiCaller.getItemNameExist(name, nodeId, addressId, customerId).pipe(
          map((response: boolean) => {
            if (response) {
              return { itemNameExist: true };
            } else {
              return null;
            }
          }),
          catchError((err: any) => {
            return err.status === 404 ? of(null) : of({ itemNameExist: true });
          })
        );
      } else {
        return of(null);
      }
    };
  }
}
