import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { AdmixtureDto } from '../models/admixture/update-admixture-dto';

@Injectable({
  providedIn: 'root',
})
export class AdmixtureApiCallerService extends BaseApiCaller {
  protected controllerPath = 'admixture';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addAdmixture(admixture: AdmixtureDto) {
    return this.post('', admixture);
  }

  public updateAdmixture(admixture: AdmixtureDto) {
    return this.put('', admixture);
  }

  public deleteAdmixture(id: string) {
    return this.delete(id);
  }
}
