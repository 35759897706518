import { Action, createReducer, on } from "@ngrx/store";
import { OfferModel } from "../../shared/api-services/api-services";
import { DictionaryDto } from "../../shared/models/dictionary.dto";
import { PageableDto } from "../../shared/models/pageable.dto";
import { GetPageableOffersQuery } from "../../shared/models/queries/get-pageable-offers.query";
import { loadPageableOffers, loadPageableOffersSuccess, loadPageableOffersFailure, setPageableOffersFilters, setPageableOffersFiltersSuccess } from './offer.actions';

export interface State {
  pageableOffers?: PageableDto<OfferModel>;
  pageableFilters?: DictionaryDto<string, GetPageableOffersQuery>;
}

export const initialState: State = {};

const operatorsReducer = createReducer(
  initialState,

  on(loadPageableOffers, (state) => setPageableOffers(state, null)),
  on(loadPageableOffersSuccess, (state, { data }) => setPageableOffers(state, data)),
  on(loadPageableOffersFailure, (state, _) => setPageableOffers(state, null)),

  on(setPageableOffersFilters, (state, { data, key }) => setPageableOffersFiltersFunc(state, null, key)),
  on(setPageableOffersFiltersSuccess, (state, { data, key }) => setPageableOffersFiltersFunc(state, data, key)),
);

export function reducer(state: State | undefined, action: Action) {
  return operatorsReducer(state, action);
}

function setPageableOffers(state, pageableOffers: PageableDto<OfferModel>): State {
  return { ...state, pageableOffers };
}

function setPageableOffersFiltersFunc(state: State, data: GetPageableOffersQuery, key: string) {
  const pageableFilters = Array.isArray(state.pageableFilters)
    ? state.pageableFilters
    : new DictionaryDto<string, GetPageableOffersQuery>();
  const filter = pageableFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    pageableFilters.push({ key, value: data });
  }

  return {
    ...state,
    pageableFilters,
  };
}
