import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { CustomValidator } from '../../../validators/custom.validator';

export class EmployeePasswordForm extends UntypedFormGroup {
  constructor() {
    super(
      {
        email: new UntypedFormControl(null, [Validators.required]),
        password: new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(8),
          CustomValidator.uppercaseValidator,
          CustomValidator.lowercaseValidator,
          CustomValidator.numberValidator,
          CustomValidator.specialCharacterValidator,
        ]),
        passwordConfirm: new UntypedFormControl(null, [Validators.required]),
      },
      CustomValidator.passwordMatchValidator
    );
  }

  forAdd() {
    return this;
  }
}
