<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="form" multiple #matSelect>
    <button class="width-100p" mat-raised-button (click)="selectAllRows()">
      <span *ngIf="selectionStatus !== 'all'; else deselectAll">{{ 'Common.CheckAll' | translate }}</span>
    </button>
    <ng-template #deselectAll>
      <span>{{ 'Common.DecheckAll' | translate }}</span>
    </ng-template>
    <mat-option *ngFor="let status of options" [value]="status.id">
      {{ status.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
