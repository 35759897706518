import { Action, createReducer, on } from '@ngrx/store';
import { loadStatusesForSelect, loadStatusesForSelectSuccess, loadStatusesForSelectFailure, loadPageableStatuses, loadPageableStatusesSuccess, loadPageableStatusesFailure, getStatus, getStatusSuccess, getStatusFailure } from './statuses.actions';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';

export const formsDataFeatureKey = 'formsData';

export interface State {
  statusesForSelect?: SelectModel<number>[];
  pageableStatuses?: PageableDto<SelectModel<number>>;
  status?: SelectModel<number>;
}

export const initialState: State = {
  
};

const usersReducer = createReducer(
  initialState,
  
  on(loadStatusesForSelect, (state) => setStatusesForSelect(state, null)),
  on(loadStatusesForSelectSuccess, (state, { data }) => setStatusesForSelect(state, data)),
  on(loadStatusesForSelectFailure, (state, action) => state),

  on(loadPageableStatuses, (state) => setPageableStatuses(state, null)),
  on(loadPageableStatusesSuccess, (state, { data }) => setPageableStatuses(state, data)),
  on(loadPageableStatusesFailure, (state, action) => state),

  on(getStatus, (state) => setStatus(state, null)),
  on(getStatusSuccess, (state, { data}) => setStatus(state, data)),
  on(getStatusFailure, (state, action) => state),
);

function setStatusesForSelect(state: State, data: SelectModel<number>[]) {
  const statusesForSelect = data;
  return {
    ...state,
    statusesForSelect,
  };
}

function setPageableStatuses(state: State, data: PageableDto<SelectModel<number>>) {
  const pageableStatuses = data;
  return {
    ...state,
    pageableStatuses,
  };
}

function setStatus(state: State, data: SelectModel<number>) {
  var status = data;
  return {
    ...state,
    status,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
