import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '../../../validators/custom.validator';
import { ValidatorPatterns } from '../../../validators/validator-patterns';

export class SupplyForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(undefined),
      count: new UntypedFormControl(null, [Validators.required, Validators.pattern(ValidatorPatterns.positiveNumber)]),
      price: new UntypedFormControl(null),
      carId: new UntypedFormControl(null),
      carType: new UntypedFormControl(null),
      dateFrom: new UntypedFormControl(null),
      dateTo: new UntypedFormControl(null),
      orderItemsIds: new UntypedFormControl(null),
      addressId: new UntypedFormControl(null),
      addressName: new UntypedFormControl(null),
      supplyStatusId: new UntypedFormControl(null),
      supplyStatusName: new UntypedFormControl(null),
      employeeId: new UntypedFormControl(null),
      employeeName: new UntypedFormControl(null),
    });
  }

  forAdd(remainingCount: number) {
    this.controls["count"].setValidators([Validators.required, Validators.min(0), Validators.max(remainingCount)]);
    return this;
  }

  setHoursValidation(dateFromMaxHour: string, dateToMinHour: string){
    this.controls["dateFrom"].setValidators([Validators.required, CustomValidator.maxHour(dateFromMaxHour)]);
    this.controls["dateTo"].setValidators([Validators.required, CustomValidator.maxHour(dateToMinHour)]);
    return this;
  }
}
