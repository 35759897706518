import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiCaller } from './base-api-caller';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { SelectModel } from '../models/select-model';

@Injectable({
  providedIn: 'root',
})
export class SourcesApiCallerService extends BaseApiCaller {
  protected controllerPath = 'sources';

  sources: SelectModel<number>[] = [
    { id: 1, label: "Source 1" },
    { id: 2, label: "Source 2" },
    { id: 3, label: "Source 3" }
  ];

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addSource(message: SelectModel<number>) {
    this.sources.push(message);
    return of("ok");

  }

  public updateSource(message: SelectModel<number>): Observable<string>{
    this.deleteSource(message.id);
    this.addSource(message);
    return of("ok");
  }

  public deleteSource(id: number) {
    var filtered = this.sources.filter(function(value, index, arr){
      return value.id != id;
    });
    this.sources = filtered;
    return of("ok");
  }

  public getSource(id: number): Observable<SelectModel<number>>{
    var filtered = this.sources.filter(function(value, index, arr){
      return value.id == id;
    });
    return of(filtered[0]);
  }

  public getPageableSources() {
    var result = new PageableDto<SelectModel<number>>();
    result.total = this.sources.length;
    result.result = this.sources;
    return of(result);
  }

  public getSourcesForSelect(): Observable<SelectModel<number>[]> {
    return this.get<SelectModel<number>[]>('getForSelect');
  }
}
