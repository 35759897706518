import { StructuralElementDto } from '../../shared/models/structural-element/structuralElement.Dto';
import { createAction, props } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { SelectModel } from '../../shared/models/select-model';
import { GetPageableStructuralElementsQuery } from '../../shared/models/queries/get-pageable-structural-elements.query';

export const addStructuralElement = createAction(
  '[StructuralElement] Add StructuralElement',
  props<{ data: StructuralElementDto }>()
);
export const addStructuralElementSuccess = createAction(
  '[StructuralElement] Add StructuralElement Success',
  props<{ data: any }>()
);
export const addStructuralElementFailure = createAction(
  '[StructuralElement] Add StructuralElement Failure',
  props<{ error: any }>()
);

export const updateStructuralElement = createAction(
  '[StructuralElement] Update StructuralElement',
  props<{ data: StructuralElementDto }>()
);
export const updateStructuralElementSuccess = createAction('[StructuralElement] Update StructuralElement Success');
export const updateStructuralElementFailure = createAction(
  '[StructuralElement] Update StructuralElement Failure',
  props<{ error: any }>()
);

export const deleteStructuralElement = createAction('[StructuralElement] delete StructuralElement', props<{ id: string }>());
export const deleteStructuralElementSuccess = createAction('[StructuralElement] delete StructuralElement Success', props<{ id: string }>());
export const deleteStructuralElementFailure = createAction('[StructuralElement] delete StructuralElement Failure', props<{ error: any }>());

export const getStructuralElement = createAction('[StructuralElement] get StructuralElement', props<{ id: string }>());
export const getStructuralElementSuccess = createAction(
  '[StructuralElement] get StructuralElement Success',
  props<{ data: StructuralElementDto }>()
);
export const getStructuralElementFailure = createAction(
  '[StructuralElement] get StructuralElement Failure',
  props<{ error: any }>()
);

export const loadPageableStructuralElements = createAction(
  '[StructuralElement] Load Pageable StructuralElement',
  props<{ key: string }>()
);
export const loadPageableStructuralElementsSuccess = createAction(
  '[StructuralElement] Load Pageable StructuralElement Success',
  props<{ data: PageableDto<StructuralElementDto>; key: string }>()
);
export const loadPageableStructuralElementsFailure = createAction(
  '[StructuralElement] Load Pageable StructuralElement Failure',
  props<{ error: any }>()
);

export const setPageableStructuralElementFilters = createAction(
  '[StructuralElement] Set Pageable StructuralElement Filters',
  props<{ data?: GetPageableStructuralElementsQuery; key: string }>()
);
export const setPageableStructuralElementFiltersSuccess = createAction(
  '[StructuralElement] Set Pageable StructuralElement Filters Success',
  props<{ data: GetPageableStructuralElementsQuery; key: string }>()
);
export const setPageableStructuralElementFiltersFailure = createAction(
  '[StructuralElement] Set Pageable StructuralElement Filters Failure',
  props<{ error: any }>()
);

export const loadStructuralElement = createAction('[StructuralElement] Load StructuralElement');
export const loadStructuralElementSuccess = createAction(
  '[StructuralElement] Load StructuralElement Success',
  props<{ data: StructuralElementDto[] }>()
);
export const loadStructuralElementFailure = createAction(
  '[StructuralElement] Load StructuralElement Failure',
  props<{ error: any }>()
);
