
import { createReducer, on } from '@ngrx/store';
import { loadWater, loadWaterSuccess, loadWaterFailure } from './water.actions';
import { Action } from '@ngrx/store';
import { UpdateWaterDto } from '../../shared/models/water/update-water-dto';

export const cementFeatureKey = 'water';

export interface State {
    water?: UpdateWaterDto;
}

export const initialState: State = {};

const waterReducer = createReducer(
    initialState,
    on(loadWater, (state) => setWater(state, null)),
    on(loadWaterSuccess, (state, { data }) => setWater(state, data)),
    on(loadWaterFailure, (state) => setWater(state, null))
);

function setWater(state: State, data: UpdateWaterDto) {
    return {
        ...state,
        water: data,
    };
}

export function reducer(state: State | undefined, action: Action) {
    return waterReducer(state, action);
}
