import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { ValidatorPatterns } from '../../../validators/validator-patterns';

export class PositionForm extends UntypedFormGroup {
  constructor() {
    super({
      firstName: new UntypedFormControl(undefined, [Validators.required, Validators.pattern(ValidatorPatterns.onlyLetters)]),
      lastName: new UntypedFormControl(undefined, [Validators.required, Validators.pattern(ValidatorPatterns.onlyLetters)]),
    });
  }

  forAdd() {

    return this;
  }
}
