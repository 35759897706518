import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrderTypeApiCallerService } from '../../shared/api-services/order-type-api-caller.service';
import {
  loadOrderTypes,
  loadOrderTypesSuccess,
  loadOrderTypesFailure,
  loadOrderStatuses,
  loadOrderStatusesSuccess,
  loadOrderStatusesFailure,
} from './dictionaries.actions';
import { concatMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { OrderStatusApiCallerService } from '../../shared/api-services/order-status-api-caller.service';

@Injectable()
export class DictionariesEffects {
  loadOrderTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderTypes),
      concatMap(() => {
        return this.orderTypeApiCaller.getOrderTypes().pipe(
          map((_) => loadOrderTypesSuccess({ data: _ })),
          catchError((error) => of(loadOrderTypesFailure({ error })))
        );
      })
    )
  );

  loadOrderStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderStatuses),
      concatMap(() => {
        return this.orderStatusApiCaller.getOrderStatuses().pipe(
          map((_) => loadOrderStatusesSuccess({ data: _ })),
          catchError((error) => of(loadOrderStatusesFailure({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private orderTypeApiCaller: OrderTypeApiCallerService,
    private orderStatusApiCaller: OrderStatusApiCallerService
  ) {}
}
