import { Action, createReducer, on } from '@ngrx/store';
import { AttachmentProgressDto } from '../../shared/models/attachment/attachment-progress.dto';
import { DictionaryModel } from '../../shared/models/dictionary-model';
import { FileInfoDto } from '../../shared/models/file-info.dto';
import { HistoryDto } from '../../shared/models/history.dto';
import { OrderCompanyEmployeeDto } from '../../shared/models/order/order-company-employee.dto';
import { OrderDto } from '../../shared/models/order/order.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import {
  addOrderAtachmentProgressSuccess,
  addOrderAtachmentsFailure,
  addOrderAtachmentsSuccess,
  loadOperation,
  loadOperationFailure,
  loadOperationSuccess,
  loadOrderAttachmentsInfo,
  loadOrderAttachmentsInfoFailure,
  loadOrderAttachmentsInfoSuccess,
  loadOrderAvailableStatuses,
  loadOrderAvailableStatusesFailure,
  loadOrderAvailableStatusesSuccess,
  loadOrderCompanyEmployee,
  loadOrderCompanyEmployeeFailure,
  loadOrderCompanyEmployeeSuccess,
  loadOrderHistory,
  loadOrderHistoryFailure,
  loadOrderHistorySuccess,
  loadPageableOrders,
  loadPageableOrdersFailure,
  loadPageableOrdersSuccess,
  updateOrder,
  updateOrderFailure,
  updateOrderSuccess,
  loadPageableOrderTypes,
  loadPageableOrderTypesSuccess,
  loadPageableOrderTypesFailure,
  setPageableOrdersFiltersSuccess,
  setPageableOrdersFilters,
} from './operation.actions';

import { OrderTypeDto } from '../../shared/models/order-type.dto';
import { GetPageableOrdersQuery } from '../../shared/models/queries/get-pageable-orders.query';
import { DictionaryDto } from '../../shared/models/dictionary.dto';

export const operationFeatureKey = 'operation';
export interface State {
  operation?: OrderDto;
  ordersFilters?: DictionaryDto<string, GetPageableOrdersQuery>;
  pageableOrders?: DictionaryDto<string, PageableDto<OrderDto>>;
  orderAvailableStatuses?: DictionaryModel<string>[];
  updatedId?: string;
  attachmentsInfo?: FileInfoDto[];
  attachmentsProgress?: AttachmentProgressDto[];
  history?: HistoryDto;
  orderCompanyEmployee?: OrderCompanyEmployeeDto;
  orderTypes?: PageableDto<OrderTypeDto>;
}

export const initialState: State = {
  ordersFilters: new DictionaryDto<string, GetPageableOrdersQuery>(),
  pageableOrders: new DictionaryDto<string, PageableDto<OrderDto>>(),
};

const operationReducer = createReducer(
  initialState,

  on(loadOperation, (state) => setOperation(state, null)),
  on(loadOperationSuccess, (state, { data }) => setOperation(state, data)),
  on(loadOperationFailure, (state, action) => setOperation(state, null)),

  on(setPageableOrdersFilters, (state, { data, key }) => setPageableOrdersFiltersFunc(state, null, key)),
  on(setPageableOrdersFiltersSuccess, (state, { data, key }) => setPageableOrdersFiltersFunc(state, data, key)),

  on(loadPageableOrders, (state, { key }) => setPageableOrders(state, null, key)),
  on(loadPageableOrdersSuccess, (state, { data, key }) => setPageableOrders(state, data, key)),
  on(loadPageableOrdersFailure, (state, action) => state),

  on(loadOrderAvailableStatuses, (state) => setOrderAvailableStatuses(state, null)),
  on(loadOrderAvailableStatusesSuccess, (state, { data }) => setOrderAvailableStatuses(state, data)),
  on(loadOrderAvailableStatusesFailure, (state, action) => setOrderAvailableStatuses(state, null)),

  on(loadOrderAttachmentsInfo, (state) => setAttachmentsInfo(state, null)),
  on(loadOrderAttachmentsInfoSuccess, (state, { data }) => setAttachmentsInfo(state, data)),
  on(loadOrderAttachmentsInfoFailure, (state, action) => setAttachmentsInfo(state, null)),

  on(addOrderAtachmentProgressSuccess, (state, { data }) => setAttachmentsProgress(state, data)),
  on(addOrderAtachmentsSuccess, (state) => setAttachmentsProgress(state, null)),
  on(addOrderAtachmentsFailure, (state) => setAttachmentsProgress(state, null)),

  on(loadOrderHistory, (state) => setHistory(state, null)),
  on(loadOrderHistorySuccess, (state, { data }) => setHistory(state, data)),
  on(loadOrderHistoryFailure, (state, action) => setHistory(state, null)),

  on(loadPageableOrderTypes, (state) => setOrderTypes(state, null)),
  on(loadPageableOrderTypesSuccess, (state, { data }) => setOrderTypes(state, data)),
  on(loadPageableOrderTypesFailure, (state, action) => setOrderTypes(state, null)),

  on(loadOrderCompanyEmployee, (state) => setOrderCompanyEmployee(state, null)),
  on(loadOrderCompanyEmployeeSuccess, (state, { data }) => setOrderCompanyEmployee(state, data)),
  on(loadOrderCompanyEmployeeFailure, (state, action) => setOrderCompanyEmployee(state, null))
);

function setPageableOrders(state: State, data: PageableDto<OrderDto>, key: string) {
  const pageableOrders = Array.isArray(state.pageableOrders)
    ? state.pageableOrders
    : new DictionaryDto<string, PageableDto<OrderDto>>();
  const orders = pageableOrders.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableOrders.push({ key, value: data });
  }

  return {
    ...state,
    pageableOrders,
  };
}

function setPageableOrdersFiltersFunc(state: State, data: GetPageableOrdersQuery, key: string) {
  const ordersFilters = Array.isArray(state.ordersFilters)
    ? state.ordersFilters
    : new DictionaryDto<string, GetPageableOrdersQuery>();
  const filter = ordersFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    ordersFilters.push({ key, value: data });
  }

  return {
    ...state,
    ordersFilters,
  };
}

function setOperation(state: State, data: OrderDto) {
  return {
    ...state,
    operation: data,
  };
}

function setOrderCompanyEmployee(state: State, orderCompanyEmployee: OrderCompanyEmployeeDto) {
  return {
    ...state,
    orderCompanyEmployee,
  };
}

function setAttachmentsInfo(state: State, attachmentsInfo: FileInfoDto[]) {
  return {
    ...state,
    attachmentsInfo,
  };
}

function setAttachmentsProgress(state: State, attachmentsProgress: AttachmentProgressDto[]) {
  return {
    ...state,
    attachmentsProgress,
  };
}

function setOrderAvailableStatuses(state: State, data: DictionaryModel<string>[]) {
  return {
    ...state,
    orderAvailableStatuses: data,
  };
}

function setHistory(state: State, data: HistoryDto) {
  return {
    ...state,
    history: data,
  };
}

function setOrderTypes(state: State, data: PageableDto<OrderTypeDto>) {
  return {
    ...state,
    orderTypes: data,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return operationReducer(state, action);
}
