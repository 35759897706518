import { OrderStatusModel } from './../models/orders/orderStatus.model';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { DictionaryModel } from '../models/dictionary-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusApiCallerService extends BaseApiCaller {
  protected controllerPath = 'orderStatuses';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getOrderStatuses() {
    return this.get<DictionaryModel<string>[]>('');
  }

  public getAllOrderStatuses(): Observable<OrderStatusModel[]> {
    return this.get<OrderStatusModel[]>('');
  }
}
