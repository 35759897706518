import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

function init()
{
    return {file : new UntypedFormControl(), type: new UntypedFormControl(null, [Validators.required]), typeName: new UntypedFormControl()}
}
export class FileForm extends UntypedFormGroup{
    
    constructor(){
        super(init());
    }

}
