import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadPageableVersions,
  loadPageableVersionsSuccess,
  loadPageableVersionsFailure,
  createVersion,
  createVersionSuccess,
  createVersionFailure,
} from './version.actions';
import { VersionApiCallerService } from '../../shared/api-services/version-api-caller.service';
import { concatMap } from 'rxjs/internal/operators/concatMap';
import { map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class VersionEffects {
  loadPageableVersions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableVersions),
      concatMap(({ filters }) => {
        if (filters) {
          this.filters = filters;
        }
        return this.versionApiCaller.getPageableVersions(filters).pipe(
          map((_) => loadPageableVersionsSuccess({ data: _ })),
          catchError((error) => of(loadPageableVersionsFailure({ error })))
        );
      })
    )
  );

  createVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createVersion),
      concatMap(({ data }) => {
        return this.versionApiCaller.addVersion(data).pipe(
          map((_) => createVersionSuccess({ id: data.id })),
          tap(() => this.reloadPageable()),
          catchError((error) => {
            this.reloadPageable();
            return of(createVersionFailure({ error }));
          })
        );
      })
    )
  );

  private filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private versionApiCaller: VersionApiCallerService
  ) {}

  private reloadPageable() {
    this.store$.dispatch(loadPageableVersions({ filters: this.filters }));
  }
}
