<mat-dialog-content class="horizontalScrollbar">
  <mat-card-header>
    <mat-card-title>
      {{ label | translate }}
    </mat-card-title>
  </mat-card-header>
  <br>
  <mat-card-content>
      <div class="col-sm-12 display-grid">
        <mat-label>{{ text | translate }}</mat-label>
      </div>
      <mat-card-actions>
        <div class="d-flex width-100p space-between">
          <button type="button" color="warn" mat-raised-button (click)="cancel()">
            {{ 'Common.OK' | translate }}
          </button>
        </div>
      </mat-card-actions>
  </mat-card-content>
</mat-dialog-content>
