import {
  loadAdmixtureTypesSuccess,
  loadAdmixtureTypesFailure,
  loadAdmixtureTypes,
  loadCementClasses,
  loadCementClassSucces,
  loadCementClassFailure,
  loadCementTypes,
  loadCementTypesSucces,
  loadCementTypesFailure,
  loadCementNorms,
  loadCementNormsSucces,
  loadCementNormsFailure,
  loadCementSpecialProperties,
  loadCementSpecialPropertiesSucces,
  loadCementSpecialPropertiesFailure,
  loadAdditivesNorms,
  loadAdditivesNormsSuccess,
  loadAdditivesNormsFailure,
  loadWaterNorms,
  loadWaterNormsSuccess,
  loadWaterNormsFailure,
  loadEnduranceClasses,
  loadEnduranceClassesSuccess,
  loadEnduranceClassesFailure,
  loadEnduranceProgresses,
  loadEnduranceProgressesSuccess,
  loadEnduranceProgressesFailure,
  loadAggregateTypes,
  loadAggregateTypesSuccess,
  loadAggregateTypesFailure,
  loadVebeConsistencyClass,
  loadVebeConsistencyClassSuccess,
  loadVebeConsistencyClassFailure,
  loadConeConsistencyClass,
  loadConeConsistencyClassFailure,
  loadConeConsistencyClassSuccess,
  loadFlowConsistencyClass,
  loadFlowConsistencyClassSuccess,
  loadCompactibilityConsistencyClass,
  loadCompactibilityConsistencyClassSuccess,
  loadCompactibilityConsistencyClassFailure,
} from './recipe-dictionaries.actions';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
// import { create } from 'domain';
import { AdmixturesTypeApiCallerService } from '../../shared/api-services/admixtures-properties/admixtures-type-api-caller.service';
import { CementClassApiCallerService } from '../../shared/api-services/cement-properties/cement-class-api-caller.service';
import { CementNormApiCallerService } from '../../shared/api-services/cement-properties/cement-norm-api-caller.service';
import { CementTypeApiCallerService } from '../../shared/api-services/cement-properties/cement-type-api-caller.service';
import { SpecialProperiesApiCallerService } from '../../shared/api-services/cement-properties/special-properies-api-caller.service';
import { AdditiveNormApiCallerService } from '../../shared/api-services/additive-norm-api-caller.service';
import { WaterNormApiCallerService } from '../../shared/api-services/water-properties/water-norm-api-caller.service';
import { EnduranceClassApiCallerService } from '../../shared/api-services/mixture-properties/endurance-class-api-caller.service';
import { EnduranceProgressApiCallerService } from '../../shared/api-services/mixture-properties/endurance-progress-api-caller.service';
import { AggregateTypeApiCallerService } from '../../shared/api-services/aggregate-properties/aggregate-type-api-caller.service';
import { VebeConsistencyApiCallerService } from '../../shared/api-services/conistency-properties/vebe-consistency-api-caller.service';
import { FlowConsistencyApiCallerService } from '../../shared/api-services/conistency-properties/flow-consistency-api-caller.service';
import { ConeConsistencyApiCallerService } from '../../shared/api-services/conistency-properties/cone-consistency-api-caller.service';
import { CompactibilityConsistencyApiCallerService } from '../../shared/api-services/conistency-properties/compactibility-consistency-api-caller.service';

@Injectable()
export class RecipeDictionariesEffects {
  [x: string]: any;
  loadAdmixtureTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAdmixtureTypes),
      concatMap(() => {
        return this.admixtureTypesApiCaller.getAll().pipe(
          map((_) => loadAdmixtureTypesSuccess({ data: _ })),
          catchError((error) => of(loadAdmixtureTypesFailure({ error })))
        );
      })
    )
  );

  loadCementClass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCementClasses),
      concatMap(() => {
        return this.cementClassApiCaller.getAll().pipe(
          map((_) => loadCementClassSucces({ data: _ })),
          catchError((error) => of(loadCementClassFailure({ error })))
        );
      })
    )
  );

  loadCementTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCementTypes),
      concatMap(() => {
        return this.cementTypesApiCaller.getAll().pipe(
          map((_) => loadCementTypesSucces({ data: _ })),
          catchError((error) => of(loadCementTypesFailure({ error })))
        );
      })
    )
  );

  loadCementNorms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCementNorms),
      concatMap(() => {
        return this.cementNormsApiCaller.getAll().pipe(
          map((_) => loadCementNormsSucces({ data: _ })),
          catchError((error) => of(loadCementNormsFailure({ error })))
        );
      })
    )
  );

  loadCementSpecialProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCementSpecialProperties),
      concatMap(() => {
        return this.cementSpecialPropertiesApiCaller.getAll().pipe(
          map((_) => loadCementSpecialPropertiesSucces({ data: _ })),
          catchError((error) => of(loadCementSpecialPropertiesFailure({ error })))
        );
      })
    )
  );

  loadVebeConsistencyClass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVebeConsistencyClass),
      concatMap(() => {
        return this.vebeConsistencyApiCaller.getAll().pipe(
          map((_) => loadVebeConsistencyClassSuccess({ data: _ })),
          catchError((error) => of(loadVebeConsistencyClassFailure({ error })))
        );
      })
    )
  );

  loadConeConsistencyClass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConeConsistencyClass),
      concatMap(() => {
        return this.coneConsistencyApiCaller.getAll().pipe(
          map((_) => loadConeConsistencyClassSuccess({ data: _ })),
          catchError((error) => of(loadConeConsistencyClassFailure({ error })))
        );
      })
    )
  );

  loadFlowConsistencyClass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFlowConsistencyClass),
      concatMap(() => {
        return this.flowConsistencyApiCaller.getAll().pipe(
          map((_) => loadFlowConsistencyClassSuccess({ data: _ })),
          catchError((error) => of(loadVebeConsistencyClassFailure({ error })))
        );
      })
    )
  );

  loadCompactibilityConsistencyClass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompactibilityConsistencyClass),
      concatMap(() => {
        return this.compactibilityConsistencyApiCaller.getAll().pipe(
          map((_) => loadCompactibilityConsistencyClassSuccess({ data: _ })),
          catchError((error) => of(loadCompactibilityConsistencyClassFailure({ error })))
        );
      })
    )
  );

  loadAdditivesNorms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAdditivesNorms),
      concatMap(() => {
        return this.additivesNormsApiCaller.getAll().pipe(
          map((_) => loadAdditivesNormsSuccess({ data: _ })),
          catchError((error) => of(loadAdditivesNormsFailure({ error })))
        );
      })
    )
  );

  loadWaterNorms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWaterNorms),
      concatMap(() => {
        return this.waterNormsApiCaller.getAll().pipe(
          map((_) => loadWaterNormsSuccess({ data: _ })),
          catchError((error) => of(loadWaterNormsFailure({ error })))
        );
      })
    )
  );

  loadEnduranceClasses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnduranceClasses),
      concatMap(() => {
        return this.enduranceClassesApiCaller.getAll().pipe(
          map((_) => loadEnduranceClassesSuccess({ data: _ })),
          catchError((error) => of(loadEnduranceClassesFailure({ error })))
        );
      })
    )
  );

  loadEnduranceProgresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnduranceProgresses),
      concatMap(() => {
        return this.enduranceProgressesApiCaller.getAll().pipe(
          map((_) => loadEnduranceProgressesSuccess({ data: _ })),
          catchError((error) => of(loadEnduranceProgressesFailure({ error })))
        );
      })
    )
  );

  loadAggregateTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAggregateTypes),
      concatMap(() => {
        return this.aggregateTypeApiCaller.getAll().pipe(
          map((_) => loadAggregateTypesSuccess({ data: _ })),
          catchError((error) => of(loadAggregateTypesFailure({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private admixtureTypesApiCaller: AdmixturesTypeApiCallerService,
    private cementClassApiCaller: CementClassApiCallerService,
    private cementTypesApiCaller: CementTypeApiCallerService,
    private cementNormsApiCaller: CementNormApiCallerService,
    private cementSpecialPropertiesApiCaller: SpecialProperiesApiCallerService,
    private additivesNormsApiCaller: AdditiveNormApiCallerService,
    private waterNormsApiCaller: WaterNormApiCallerService,
    private enduranceClassesApiCaller: EnduranceClassApiCallerService,
    private enduranceProgressesApiCaller: EnduranceProgressApiCallerService,
    private aggregateTypeApiCaller: AggregateTypeApiCallerService,

    private vebeConsistencyApiCaller: VebeConsistencyApiCallerService,

    private coneConsistencyApiCaller: ConeConsistencyApiCallerService,
    private flowConsistencyApiCaller: FlowConsistencyApiCallerService,
    private compactibilityConsistencyApiCaller: CompactibilityConsistencyApiCallerService
  ) {}
}
