import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { CompanyDto } from '../models/company/company.dto';
import { UpdateCompanyDto } from '../models/company/update-company.dto';
import { BaseContractDto } from '../models/contract/base-contract.dto';
import { DictionaryModel } from '../models/dictionary-model';
import { CompanyType } from '../models/enums/company-type.enum';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableOrdersQuery } from '../models/queries/get-pageable-orders.query';
import { SearchModel } from '../models/search-model';
import { SelectModel } from '../models/select-model';
import { BaseApiCaller } from './base-api-caller';
import { map } from 'rxjs/operators';
import { GetPageableCompaniesQuery } from '../models/queries/get-pageable-companies.query';
import { CompanyNoteDto } from '../models/company/company-note.dto';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiCallerService extends BaseApiCaller {
  updateCompany(data: UpdateCompanyDto) {
    return this.put('', data);
  }
  protected controllerPath = 'company';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getCompany(id: string) {
    return this.get<CompanyDto>(id).pipe(map((_) => new CompanyDto(_)));
  }

  getClientSearchModel(filter: SearchModel) {
    return this.get<SelectModel<string>[]>('client/search-model', { params: { ...filter } });
  }

  getContractorSearchModel(filter: SearchModel) {
    return this.get<SelectModel<string>[]>('contractor/search-model', { params: { ...filter } });
  }

  getClientBusinessObjects(clientId: string) {
    return this.get<DictionaryModel<string>[]>(`client/${clientId}/business-objects`);
  }

  getPageableCompanies(filter: GetPageableCompaniesQuery) {
    const params = this.prepareParams(filter);

    return this.get<PageableDto<CompanyDto>>('', { params });
  }

  addCompany(dto: UpdateCompanyDto) {
    return this.post('', dto);
  }

  public getClientContracts(id: string) {
    return this.get<BaseContractDto[]>(`client/${id}/contracts`);
  }
}
