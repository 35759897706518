import { FileInfoDto } from '../file-info.dto';
import { UploadStatus } from '../enums/upload-status.enum';

export class AttachmentProgressDto extends FileInfoDto {
  constructor(id: string, fileName: string) {
    super({ id, fileName, canDelete: true });
    this.progress = 0;
    this.status = UploadStatus.Started;
  }
  progress: number;
  status: UploadStatus;
}
