import { createAction, props } from '@ngrx/store';
import { IngredientDto } from '../../shared/models/ingredients/ingredient-dto';
import { UpdateIngredientDto } from '../../shared/models/ingredients/update-ingredient-dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableIngredientsQuery } from '../../shared/models/queries/get-pageable-ingredients.query';


export const addIngredient = createAction('[Ingredients] Add Ingredient', props<{ data: UpdateIngredientDto }>());
export const addIngredientSuccess = createAction('[Ingredients] Add Ingredient Success', props<{ data: any }>());
export const addIngredientFailure = createAction('[Ingredients] Add Ingredient Failure', props<{ error: any }>());

export const updateIngredient = createAction('[Ingredients] Update Ingredient', props<{ data: UpdateIngredientDto }>());
export const updateIngredientSuccess = createAction('[Ingredients] Update Ingredient Success');
export const updateIngredientFailure = createAction('[Ingredients] Update Ingredient Failure', props<{ error: any }>());

export const deleteIngredient = createAction('[Ingredients] delete Ingredient', props<{ id: string }>());
export const deleteIngredientSuccess = createAction('[Ingredients] delete Ingredient Success', props<{ id: string }>());
export const deleteIngredientFailure = createAction('[Ingredients] delete Ingredient Failure', props<{ error: any }>());

export const getIngredient = createAction('[Ingredients] get Ingredient', props<{ id: string }>());
export const getIngredientSuccess = createAction('[Ingredients] get Ingredient Success', props<{ data: IngredientDto }>());
export const getIngredientFailure = createAction('[Ingredients] get Ingredient Failure', props<{ error: any }>());

export const loadPageableIngredients = createAction('[Ingredients] Load Pageable Ingredients', props<{ key: string }>());
export const loadPageableIngredientsSuccess = createAction('[Ingredients] Load Pageable Ingredients Success', props<{ data: PageableDto<IngredientDto>; key: string }>());
export const loadPageableIngredientsFailure = createAction('[Ingredients] Load Pageable Ingredients Failure', props<{ error: any }>());

export const setPageableIngredientsFilters = createAction('[Ingredients] Set Pageable Ingredients Filters', props<{ data?: GetPageableIngredientsQuery; key: string }>());
export const setPageableIngredientsFiltersSuccess = createAction('[Ingredients] Set Pageable Ingredients Filters Success', props<{ data: GetPageableIngredientsQuery; key: string }>());
export const setPageableIngredientsFiltersFailure = createAction('[Ingredients] Set Pageable Ingredients Filters Failure', props<{ error: any }>());