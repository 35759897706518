import { createAction, props } from '@ngrx/store';
import { ItemsModel } from '../../shared/models/items/ItemsModel';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableItemsQuery } from '../../shared/models/queries/get-pageable-items.query';
import { ItemDto } from '../../shared/models/items/itemDTO';
import { Guid } from 'guid-typescript';
import { SelectModel } from '../../shared/models/select-model';
import { ItemByNode } from '../../shared/models/items/itemByNodeModel';
import { ItemByCustomerId } from '../../shared/models/items/itemByCustomerModel';

export const loadItems = createAction('[Items] Load Items');

export const loadItemsService = createAction('[Items] Load ItemsService', props<{ customerId: any }>());

export const loadItemsSuccess = createAction('[Items] Load Items Success', props<{ data: ItemsModel[] }>());
export const loadItemsFailure = createAction('[Items] Load Items Failure', props<{ error: any }>());

export const loadItemsProduct = createAction('[Items] Load ItemsProduct');

export const loadItemsProductSuccess = createAction(
  '[Items] Load ItemsProduct Success',
  props<{ data: ItemsModel[] }>()
);
export const loadItemsProductFailure = createAction('[Items] Load ItemsProduct Failure', props<{ error: any }>());

export const loadItemsServiceSuccess = createAction(
  '[Items] Load ItemsService Success',
  props<{ data: ItemsModel[] }>()
);
export const loadItemsServiceFailure = createAction('[Items] Load ItemsService Failure', props<{ error: any }>());

export const addItem = createAction('[Items] Add Item', props<{ data: ItemDto }>());
export const addItemSuccess = createAction('[Items] Add Item Success', props<{ data: any }>());
export const addItemFailure = createAction('[Items] Add Item Failure', props<{ error: any }>());

export const addCustomerItem = createAction('[Items] Add CustomerItem', props<{ data: ItemDto }>());
export const addCustomerItemSuccess = createAction('[Items] Add CustomerItem Success', props<{ data: any }>());
export const addCustomerItemFailure = createAction('[Items] Add CustomerItem Failure', props<{ error: any }>());

export const updateCustomerItem = createAction('[Items] Update CustomerItem', props<{ data: ItemDto }>());
export const updateCustomerItemSuccess = createAction('[Items] AUpdatedd CustomerItem Success');
export const updateCustomerItemFailure = createAction('[Items] Update CustomerItem Failure', props<{ error: any }>());

export const updateItem = createAction('[Items] Update Item', props<{ data: ItemDto }>());
export const updateItemSuccess = createAction('[Items] Update Item Success');
export const updateItemFailure = createAction('[Items] Update Item Failure', props<{ error: any }>());

export const deleteItem = createAction('[Items] delete Item', props<{ id: string }>());
export const deleteItemSuccess = createAction('[Items] delete Item Success', props<{ id: string }>());
export const deleteItemFailure = createAction('[Items] delete Item Failure', props<{ error: any }>());

export const getItem = createAction('[Items] get Item', props<{ id: string }>());
export const getItemSuccess = createAction('[Items] get Item Success', props<{ data: ItemDto }>());
export const getItemFailure = createAction('[Items] get Item Failure', props<{ error: any }>());

export const loadPageableItems = createAction('[Items] Load Pageable Items', props<{ key: string }>());
export const loadPageableItemsSuccess = createAction(
  '[Items] Load Pageable Items Success',
  props<{ data: PageableDto<ItemDto>; key: string }>()
);
export const loadPageableItemsFailure = createAction('[Items] Load Pageable Items Failure', props<{ error: any }>());

export const setPageableItemsFilters = createAction(
  '[Items] Set Pageable Items Filters',
  props<{ data?: GetPageableItemsQuery; key: string }>()
);
export const setPageableItemsFiltersSuccess = createAction(
  '[Items] Set Pageable Items Filters Success',
  props<{ data: GetPageableItemsQuery; key: string }>()
);
export const setPageableItemsFiltersFailure = createAction(
  '[Items] Set Pageable Items Filters Failure',
  props<{ error: any }>()
);

export const loadItemTypesForSelect = createAction('[Items] Load ItemTypes For Select');
export const loadItemTypesForSelectSuccess = createAction(
  '[Items] Load ItemTypes For Select Success',
  props<{ data: SelectModel<string>[] }>()
);
export const loadItemTypesForSelectFailure = createAction(
  '[Items] Load ItemTypes For Select Failure',
  props<{ error: any }>()
);

export const getItemsByNodeId = createAction('[Items] get ItemsByNodeId', props<{ nodeId: string }>());
export const getItemsByNodeIdSuccess = createAction(
  '[Items] get ItemsByNodeId Success',
  props<{ data: ItemByNode[] }>()
);
export const getItemsByNodeIdFailure = createAction('[Items] get ItemsByNodeId Failure', props<{ error: any }>());

export const getItemsByCustomerId = createAction('[Items] get CustomerId ', props<{ customerId: string, nodeId: string }>());
export const getItemsByCustomerIdSuccess = createAction(
  '[Items] get CustomerId Success',
  props<{ data: ItemByCustomerId[] }>()
);
export const getItemsByCustomerIdFailure = createAction('[Items] get CustomerId Failure', props<{ error: any }>());

export const loadItemsForSelect = createAction('[FormsData] Load Items For Select', props<{ nodeId: string, isProduct: boolean|null, isService: boolean|null }>());
export const loadItemsForSelectSuccess = createAction(
  '[FormsData] Load Items For Select Success',
  props<{ data: SelectModel<string>[] }>()
);
export const loadItemsForSelectFailure = createAction(
  '[FormsData] Load Items For Select Failure',
  props<{ error: any }>()
);


