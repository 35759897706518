<div id="notificationPanel" class="panel">
  <div class='tmp'>
    <div class="note">
      <span class="arrow"></span>
      <div *ngIf="notifications">
        Liczba powiadomień: {{notifications.length}}
      </div>
      <div class="note" *ngFor="let note of notifications">
        {{note.message}}
      </div>
    </div>
  </div>
</div>
