<div class="table-container mat-elevation-z8">
  <div [ngStyle]="{ 'height.px': height }">
    <ng-content select="[mat-table]" *ngIf="!loading"></ng-content>
    <div *ngIf="loading" class="center">
      <mat-spinner [diameter]="240"> </mat-spinner>
    </div>
  </div>
</div>
<div class="display-flex">
  <ng-content select="[mat-actions]"></ng-content>
  <div class="flex-spacer"></div>
  <mat-paginator
    class="width-50p bg-color-transparent"
    [length]="total"
    [pageSize]="filters.pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageIndex]="filters.pageNumber"
    (page)="pageChange($event)"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>
