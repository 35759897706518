import { Action, createReducer, on } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { OtherDocumentDto } from '../../shared/models/other-documents/otherDocumentDTO';
import { addOtherDocument, addOtherDocumentSuccess, addOtherDocumentFailure, loadPageableOtherDocuments, loadPageableOtherDocumentsFailure, loadPageableOtherDocumentsSuccess, setPageableOtherDocumentsFilters, setPageableOtherDocumentsFiltersSuccess, getOtherDocument, getOtherDocumentSuccess, getOtherDocumentFailure } from './other-documents.actions';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { GetPageableOtherDocumentsQuery } from '../../shared/models/queries/get-pageable-other-documents.query';

export const otherDocumentsFeatureKey = 'otherDocuments';

export interface State {
  pageableOtherDocuments?: DictionaryDto<string, PageableDto<OtherDocumentDto>>;
  employeesFilters?: DictionaryDto<string, GetPageableOtherDocumentsQuery>;
  editorialDocument?: OtherDocumentDto;
}

export const initialState: State = {
  employeesFilters: new DictionaryDto<string, GetPageableOtherDocumentsQuery>(),
};

const usersReducer = createReducer(
  initialState,

  on(addOtherDocument, (state) => setAddOtherDocument(state, null)),
  on(addOtherDocumentSuccess, (state) => setAddOtherDocument(state, null)),
  on(addOtherDocumentFailure, (state) => setAddOtherDocument(state, null)),

  on(loadPageableOtherDocuments, (state, { key }) => setPageableOtherDocuments(state, null, key)),
  on(loadPageableOtherDocumentsSuccess, (state, { data, key }) => setPageableOtherDocuments(state, data, key)),
  on(loadPageableOtherDocumentsFailure, (state, action) => state),

  on(getOtherDocument, (state) => setOtherDocument(state, null)),
  on(getOtherDocumentSuccess, (state, { data}) => setOtherDocument(state, data)),
  on(getOtherDocumentFailure, (state, action) => state),

  on(setPageableOtherDocumentsFilters, (state, { data, key }) => setPageableOtherDocumentsFiltersFunc(state, null, key)),
  on(setPageableOtherDocumentsFiltersSuccess, (state, { data, key }) => setPageableOtherDocumentsFiltersFunc(state, data, key)),
);

function setPageableOtherDocuments(state: State, data: PageableDto<OtherDocumentDto>, key: string) {
  const pageableOtherDocuments = Array.isArray(state.pageableOtherDocuments)
    ? state.pageableOtherDocuments
    : new DictionaryDto<string, PageableDto<OtherDocumentDto>>();
  const orders = pageableOtherDocuments.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableOtherDocuments.push({ key, value: data });
  }

  return {
    ...state,
    pageableOtherDocuments,
  };
}

function setOtherDocument(state: State, data: OtherDocumentDto) {
  var editorialDocument = data;
  return {
    ...state,
    editorialDocument,
  };
}

function setAddOtherDocument(state: State, attachmentsProgress: OtherDocumentDto[]) {
  return {
    ...state,
    attachmentsProgress,
  };
}

function setPageableOtherDocumentsFiltersFunc(state: State, data: GetPageableOtherDocumentsQuery, key: string) {
  const employeesFilters = Array.isArray(state.employeesFilters)
    ? state.employeesFilters
    : new DictionaryDto<string, GetPageableOtherDocumentsQuery>();
  const filter = employeesFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    employeesFilters.push({ key, value: data });
  }

  return {
    ...state,
    employeesFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
