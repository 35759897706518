import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';

export const loadSourcesForSelect = createAction('[FormsData] Load Sources For Select');
export const loadSourcesForSelectSuccess = createAction('[FormsData] Load Sources For Select Success', props<{ data: SelectModel<number>[] }>());
export const loadSourcesForSelectFailure = createAction('[FormsData] Load Sources For Select Failure', props<{ error: any }>());

export const loadPageableSources = createAction('[FormsData] Load Pageable Sources');
export const loadPageableSourcesSuccess = createAction('[FormsData] Load Pageable Sources Success', props<{ data: PageableDto<SelectModel<number>> }>());
export const loadPageableSourcesFailure = createAction('[FormsData] Load Pageable Sources Failure', props<{ error: any }>());

export const deleteSource = createAction('[FormsData] delete Source', props<{ id: number }>());
export const deleteSourceSuccess = createAction('[FormsData] delete Source Success', props<{ id: number }>());
export const deleteSourceFailure = createAction('[FormsData] delete Source Failure', props<{ error: any }>());

export const addSource = createAction('[FormsData] Add Source', props<{ data: SelectModel<number> }>());
export const addSourceSuccess = createAction('[FormsData] Add Source Success');
export const addSourceFailure = createAction('[FormsData] Add Source Failure', props<{ error: any }>());

export const updateSource = createAction('[FormsData] Update Source', props<{ data: SelectModel<number> }>());
export const updateSourceSuccess = createAction('[FormsData] Update Source Success');
export const updateSourceFailure = createAction('[FormsData] Update Source Failure', props<{ error: any }>());

export const getSource = createAction('[FormsData] Get Source', props<{ id: number }>());
export const getSourceSuccess = createAction('[FormsData] Get Source Success', props<{ data: SelectModel<number> }>());
export const getSourceFailure = createAction('[FormsData] Get Source Failure', props<{ error: any }>());
