import { SpecialAdditivesEffects } from './special-additives/special-additives.effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers, MetaReducers } from './reducers-index';
import { EffectsModule } from '@ngrx/effects';
import { ContractorEffects } from './contractors/contractor.effects';
import { ClientsEffects } from './clients/clients.effects';
import { OperationEffects } from './operations/operation.effects';
import { ContractEffects } from './contracts/contract.effects';
import { DictionariesEffects } from './dictionaries/dictionaries.effects';
import { CompanyEmployeeEffects } from './company-employees/company-employee.effects';
import { VersionEffects } from './version/version.effects';
import { VersionLogEffects } from './version-log/version-log.effects';
import { NotificationsEffects } from './notifications/notifications.effects';
import { TablesEffects } from './tables/tables.effects';
import { UserDataEffects } from './user-data/user-data.effects';
import { EmployeesEffects } from './employees/employees.effects';
import { IncomingMessagesEffects } from './incoming-messages/incoming-messages.effects';
import { OutgoingMessagesEffects } from './outgoing-messages/outgoing-messages.effects';
import { DocumentsEffects } from './documents/documents.effects';
import { EditorialDocumentsEffects } from './editorial-documents/editorial-documents.effects';
import { OtherDocumentsEffects } from './other-documents/other-documents.effects';
import { AuthorsEffects } from './authors/authors.effects';
import { DepartmentsEffects } from './departments/departments.effects';
import { SourcesEffects } from './sources/sources.effects';
import { StatusesEffects } from './statuses/statuses.effects';
import { CTypesEffects } from './c-types/c-types.effects';
import { CustomerEffects } from './customer/customer.effects';
import { CarsEffects } from './cars/cars.effects';
import { NodesEffects } from './nodes/nodes.effects';
import { ItemsEffects } from './items/items.effects';
import { OrdersEffects } from './order/order.effects';
import { DeliveryCalendarEffects } from './delivery-calendar/delivery-calendar.effects';
import { AddressesEffects } from './addresses/addresses.effects';
import { SupplyStatusesEffects } from './supply-statuses/supply-statuses.effects';

import { RecipeEffects } from '../recipe/root-store/recipes/recipe.effects';
import { CementEffects } from '../recipe/root-store/cement/cement.effects';
import { AdmixtureEffects } from '../recipe/root-store/admixture/admixture.effects';
import { AggregateEffects } from '../recipe/root-store/aggregate/aggregate.effects';
import { AdditivesEffects } from '../recipe/root-store/additives/additives.effects';
import { WaterEffects } from '../recipe/root-store/water/water.effects';
import { MixturePropertiesEffects } from '../recipe/root-store/mixture-properties/mixture-properties.effects';
import { ConsistencyEffects } from '../recipe/root-store/consistency/consistency.effects';
import { RecipeDictionariesEffects } from '../recipe/root-store/recipe-dictionaries/recipe-dictionaries.effects';
import { StructuralElementEffects } from './structural-elements/structural-element.effects';
import { IngredientsEffects } from './ingredients/ingredients.effects';
import { RecipeCostsEffects } from './recipe-costs/recipe-costs.effects';
import { IngredientTypeEffects } from './ingredient-types/ingredient-types.effects';
import { PaymentTypeEffects } from './paymentType/paymentType.effects';
import { TransactionEffects } from './transactions/transactions.effects';
import { WZDocumentsEffects } from './wz-documents/wz-documents.effects';
import { SettlementsEffects } from './settlements/settlements.effects';
import { SettlementsHistoryEffects } from './settlements-history/settlements-history.effects';
import { OfferEffects } from './offers/offer.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers: MetaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forFeature([
      ContractorEffects,
      ClientsEffects,
      OperationEffects,
      ContractEffects,
      DictionariesEffects,
      CompanyEmployeeEffects,
      VersionEffects,
      VersionLogEffects,
      NotificationsEffects,
      TablesEffects,
      UserDataEffects,
      EmployeesEffects,
      IncomingMessagesEffects,
      OutgoingMessagesEffects,
      DocumentsEffects,
      EditorialDocumentsEffects,
      OtherDocumentsEffects,
      AuthorsEffects,
      DepartmentsEffects,
      SourcesEffects,
      StatusesEffects,
      CTypesEffects,
      CustomerEffects,
      CarsEffects,
      NodesEffects,
      ItemsEffects,
      OrdersEffects,
      RecipeDictionariesEffects,
      RecipeEffects,
      CementEffects,
      AdmixtureEffects,
      AggregateEffects,
      AdditivesEffects,
      WaterEffects,
      MixturePropertiesEffects,
      ConsistencyEffects,
      StructuralElementEffects,
      DeliveryCalendarEffects,
      AddressesEffects,
      SupplyStatusesEffects,
      IngredientsEffects,
      IngredientTypeEffects,
      RecipeCostsEffects,
      PaymentTypeEffects,
      SpecialAdditivesEffects,
      TransactionEffects,
      WZDocumentsEffects,
      SettlementsEffects,
      SettlementsHistoryEffects,
      OfferEffects
    ]),
  ],
})
export class RootStoreModule {}
