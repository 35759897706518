import { Action, createReducer, on } from '@ngrx/store';
import {
  loadClientsForSelect,
  loadClientsForSelectSuccess,
  loadClientsForSelectFailure,
  addClient,
  addClientSuccess,
  addClientFailure,
  loadPageableClientsSuccess,
  loadPageableClientsFailure,
  loadPageableClients,
  getClient,
  getClientSuccess,
  getClientFailure,
  setPageableClientsFilters,
  setPageableClientsFiltersSuccess,
} from './clients.actions';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { ClientDto } from '../../shared/models/dictionaries/clientDTO';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { GetPageableClientsQuery } from '../../shared/models/queries/get-pageable-clients.query';

export const formsDataFeatureKey = 'formsData';

export interface State {
  clientsForSelect?: SelectModel<number>[];
  pageableClients?: DictionaryDto<string, PageableDto<ClientDto>>;
  client?: ClientDto;
  clientsFilters?: DictionaryDto<string, GetPageableClientsQuery>;
  clientAdded?: { id: number; name: string };
}

export const initialState: State = {
  clientsFilters: new DictionaryDto<string, GetPageableClientsQuery>(),
};

const usersReducer = createReducer(
  initialState,

  on(addClient, (state) => setAddClient(state, null)),
  on(addClientSuccess, (state, data) => setAddClient(state, data)),
  on(addClientFailure, (state) => setAddClient(state, null)),

  on(loadClientsForSelect, (state) => setClientsForSelect(state, null)),
  on(loadClientsForSelectSuccess, (state, { data }) => setClientsForSelect(state, data)),
  on(loadClientsForSelectFailure, (state, action) => state),

  on(loadPageableClients, (state, { key }) => setPageableClients(state, null, key)),
  on(loadPageableClientsSuccess, (state, { data, key }) => setPageableClients(state, data, key)),
  on(loadPageableClientsFailure, (state, action) => state),

  on(getClient, (state) => setClient(state, null)),
  on(getClientSuccess, (state, { data }) => setClient(state, data)),
  on(getClientFailure, (state, action) => state),

  on(setPageableClientsFilters, (state, { data, key }) => setPageableClientsFiltersFunc(state, null, key)),
  on(setPageableClientsFiltersSuccess, (state, { data, key }) => setPageableClientsFiltersFunc(state, data, key))
);

function setClientsForSelect(state: State, data: SelectModel<number>[]) {
  const clientsForSelect = data;
  return {
    ...state,
    clientsForSelect,
  };
}

function setAddClient(state: State, clientAdded) {
  return {
    ...state,
    clientAdded,
  };
}

function setPageableClients(state: State, data: PageableDto<ClientDto>, key: string) {
  const pageableClients = Array.isArray(state.pageableClients)
    ? state.pageableClients
    : new DictionaryDto<string, PageableDto<ClientDto>>();
  const orders = pageableClients.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableClients.push({ key, value: data });
  }

  return {
    ...state,
    pageableClients,
  };
}

function setClient(state: State, data: ClientDto) {
  var client = data;
  return {
    ...state,
    client,
  };
}

function setPageableClientsFiltersFunc(state: State, data: GetPageableClientsQuery, key: string) {
  const clientsFilters = Array.isArray(state.clientsFilters)
    ? state.clientsFilters
    : new DictionaryDto<string, GetPageableClientsQuery>();
  const filter = clientsFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    clientsFilters.push({ key, value: data });
  }

  return {
    ...state,
    clientsFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
