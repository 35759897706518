//import { CarsApiCallerService } from '../../shared/api-services/Cars-api-caller.service';
import { Injectable, ViewChild } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  addCar,
  addCarSuccess,
  addCarFailure,
  deleteCar,
  deleteCarSuccess,
  deleteCarFailure,
  setPageableCarsFilters,
  setPageableCarsFiltersSuccess,
  setPageableCarsFiltersFailure,
  loadPageableCars,
  loadPageableCarsSuccess,
  loadPageableCarsFailure,
  getCar,
  getCarSuccess,
  getCarFailure,
  updateCar,
  updateCarSuccess,
  updateCarFailure, loadCarTypesForSelect, loadCarTypesForSelectFailure, loadCarTypesForSelectSuccess,
  loadCars, loadCarsFailure, loadCarsSuccess, loadCarTypesPump, loadCarTypesPumpSuccess, loadCarTypesPumpFailure,
  loadFilteredCars, loadFilteredCarsFailure, loadFilteredCarsSuccess, loadCarTypesMixer, loadCarTypesMixerSuccess, loadCarTypesMixerFailure
} from './cars.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { SnackBarWrapperService } from '../../shared/services/snack-bar-wrapper.service';
import { selectAllPageableCarsFilters } from './cars.selector';
import { CarsApiCallerService } from '../../shared/api-services/cars-api-caller.service';
import { DocumentsApiCallerService } from '../../shared/api-services/documents-api-caller.service';
import { AttachmentProgressDto } from '../../shared/models/attachment/attachment-progress.dto';
import { HttpEventType } from '@angular/common/http';
import { UploadStatus } from '../../shared/models/enums/upload-status.enum';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';

@Injectable()
export class CarsEffects {
  @ViewChild("spinner", {static:true}) spinner;

  private latestedCarsKey: string;

  deleteCars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCar),
      concatMap(({ id }) => {
        return this.carsApiCaller.deleteCar(id).pipe(
          map((_) => deleteCarSuccess({ id: id })),
          tap(() => this.reloadCars(this.latestedCarsKey)),
          catchError((error) => of(deleteCarFailure({ error })))
        );
      })
    )
  );

  addCar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCar),
      concatMap(({ data }) => {
        let lastValue;
        return this.carsApiCaller.addCar(data).pipe(
          map((_) => addCarSuccess({data: _})),
          tap((_) => { lastValue = _;}),
          finalize(() => {
            let carId = lastValue.data;
            this.navigateToCatalog();
            this.overlayService.closeOverlay("add-incoming-message");
          }),
          catchError((error) => of(addCarFailure({ error })))
        );
      })
    )
  );

  updateCar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCar),
      concatMap(({ data }) => {
        let lastValue;
        return this.carsApiCaller.updateCar(data).pipe(
          map((_) => updateCarSuccess()),
          tap((_) => lastValue = _),
          finalize(() => {
            let CarId = data.id;
            this.navigateToCatalog();
            this.overlayService.closeOverlay("add-incoming-message");
          }),
          catchError((error) => of(updateCarFailure({ error })))
        );
      })
    )
  );

  loadPageableCars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableCars),
      withLatestFrom(this.store$.select(selectAllPageableCarsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedCarsKey = key;

        return this.carsApiCaller.getPageableCars(filter.value).pipe(
          map((_) => loadPageableCarsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableCarsFailure({ error })))
        );
      })
    )
  );

  getCar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCar),
      concatMap(({id}) => {
        return this.carsApiCaller.getCar(id).pipe(
          map((_) => getCarSuccess({ data: _ })),
          catchError((error) => of(getCarFailure({ error })))
        );
      })
    )
  );

  setPageableCarsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableCarsFilters),
      concatMap(({ data, key }) => {
        this.latestedCarsKey = key;
        return of(data).pipe(
          map((_) => setPageableCarsFiltersSuccess({ data, key })),
          finalize(() => this.reloadCars(key)),
          catchError((error) => of(setPageableCarsFiltersFailure({ error })))
        );
      })
    )
  );

  loadCarTypesForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCarTypesForSelect),
      concatMap(() => {
        return this.carsApiCaller.getCarTypesForSelect().pipe(
          map((_) => loadCarTypesForSelectSuccess({ data: _ })),
          catchError((error) => of(loadCarTypesForSelectFailure({ error })))
        );
      })
    )
  );

  cars$ = createEffect(() =>
  this.actions$.pipe(
      ofType(loadCars),
      concatMap(() => {
          return this.carsApiCaller.getCars().pipe(
               map((_) => loadCarsSuccess({ data: _ })),
               catchError((error) => of(loadCarsFailure({ error })))
          );
      })
  ));

  loadCarPump$ = createEffect(() =>
  this.actions$.pipe(
    ofType(loadCarTypesMixer),
    concatMap(({typeId}) => {
      return this.carsApiCaller.getCarTypesPump([typeId]).pipe(
        map((_) => loadCarTypesMixerSuccess({ data: _ })),
        catchError((error) => of(loadCarTypesMixerFailure({ error })))
      );
    })
    )
  );

  loadCarMixer$ = createEffect(() =>
  this.actions$.pipe(
    ofType(loadCarTypesPump),
    concatMap(({typeIds}) => {
      return this.carsApiCaller.getCarTypesPump(typeIds).pipe(
        map((_) => loadCarTypesPumpSuccess({ data: _ })),
        catchError((error) => of(loadCarTypesPumpFailure({ error })))
      );
    })
    )
  );
  
  
  filteredCars$ = createEffect(() =>
  this.actions$.pipe(
      ofType(loadFilteredCars),
      concatMap(() => {
          return this.carsApiCaller.getCars().pipe(
               map((_) => loadFilteredCarsSuccess({ data: _ })),
               catchError((error) => of(loadFilteredCarsFailure({ error })))
          );
      })
  ));


  private reloadCars(key: string) {
    this.store$.dispatch(loadPageableCars({ key }));
  }
 
  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private carsApiCaller: CarsApiCallerService,
    private router: Router,
    private overlayService: OverlayWrapperService
  ) { }

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'cars', 'catalog']);
  }
}
