import { createAction, props } from '@ngrx/store';
import { AliasDto } from '../../shared/models/customers/alias.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { TransactionDto } from '../../shared/models/transactions/transaction.dto';

export const loadUnhandledTransactionsForAttachment = createAction(
  '[UnhandledTransactionsForAttachment] Load UnhandledTransactionsForAttachment',
  props<{ id: string }>()
);

export const loadUnhandledTransactionsForAttachmentSuccess = createAction(
  '[UnhandledTransactionsForAttachment] Load UnhandledTransactionsForAttachment Success',
  props<{ data: TransactionDto[] }>()
);

export const loadUnhandledTransactionsForAttachmentFailure = createAction(
  '[UnhandledTransactionsForAttachment] Load UnhandledTransactionsForAttachment Failure',
  props<{ error: any }>()
);

export const loadUnhandledTransactionsForClient = createAction(
  '[UnhandledTransactionsForClient] Load UnhandledTransactionsForClient',
  props<{ clientName: string }>()
);

export const loadUnhandledTransactionsForClientSuccess = createAction(
  '[UnhandledTransactionsForClient] Load UnhandledTransactionsForClient Success',
  props<{ data: TransactionDto[] }>()
);

export const loadUnhandledTransactionsForClientFailure = createAction(
  '[UnhandledTransactionsForClient] Load UnhandledTransactionsForClient Failure',
  props<{ error: any }>()
);

export const handleTransaction = createAction(
  '[transaction] Add transaction',
  props<{ data: any; clientName: string; attachmentId: string }>()
);

export const handleTransactionSuccess = createAction('[transaction] Add transaction Success', props<{}>());

export const handleTransactionFailure = createAction('[transaction] Add transaction Failure', props<{ error: any }>());

export const loadPageableTransactions = createAction(
  '[Transactions] Load Pageable Transactions',
  props<{ filters: GetPageableQuery }>()
);

export const loadPageableTransactionsSuccess = createAction(
  '[Transactions] Load Pageable Transactions Success',
  props<{ data: PageableDto<TransactionDto> }>()
);

export const loadPageableTransactionsFailure = createAction(
  '[Transactions] Load Pageable Transactions Failure',
  props<{ error: any }>()
);
