import { createSelector } from '@ngrx/store';
import { StoreState } from '../store-state';
import { State as State } from './wz-documents.reducer';

const selectWZDocumentsFeature = (state: StoreState) => state.wzDocuments;

export const selectPageableWZDocuments = createSelector(selectWZDocumentsFeature, (state: State, props: { key: string }) => {
    const result = state.pageableWZDocuments ? state.pageableWZDocuments.find((_) => _.key === props.key) : null;
  
    return result ? result.value : null;
});

export const selectAllPageableWZDocumentsFilters = createSelector(selectWZDocumentsFeature, (state: State) => state.WZDocumentsFilters);
export const selectPageableWZDocumentsFilters = createSelector(selectWZDocumentsFeature, (state: State, props: { key: string }) => {
  const result = state.WZDocumentsFilters ? state.WZDocumentsFilters.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});