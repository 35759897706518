import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './cars.reducer';

const selectCarFeature = (state: StoreState) => state.cars;

export const selectCars = createSelector(selectCarFeature, (state: State) => state.cars);

export const selectFilteredCars = createSelector(selectCarFeature, (state: State) => state.filteredCars);

export const selectPageableCars = createSelector(selectCarFeature, (state: State, props: { key: string }) => {
  const result = state.pageableCars ? state.pageableCars.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectCar = createSelector(selectCarFeature, (state: State) => {
  const result = state.car;
  return result ? result : null;
});

export const selectAllPageableCarsFilters = createSelector(selectCarFeature, (state: State) => state.carsFilters);
export const selectPageableCarsFilters = createSelector(selectCarFeature, (state: State, props: { key: string }) => {
  const result = state.carsFilters ? state.carsFilters.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectCarTypesForSelect = createSelector(selectCarFeature, (state: State, props: { key: string }) => {
  const result = state.carTypesForSelect;
  return result ? result : null;
});

export const selectCarPump = createSelector(selectCarFeature, (state: State, props: { key: string }) => {
  const result = state.carTypesPump;
  return result ? result : null;
});

export const selectCarMixer = createSelector(selectCarFeature, (state: State, props: { key: string }) => {
  const result = state.carTypesMixer;
  return result ? result : null;
});

