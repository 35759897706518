import { createSelector } from "@ngrx/store";
import { StoreState } from "../store-state";
import { State } from "./offer.reducer";

const selectFeature = (state: StoreState) => state.offers;

export const selectPageableOffers = createSelector(selectFeature, (state: State) => state.pageableOffers);

export const selectAllPageableOffersFilters = createSelector(selectFeature, (state: State) => state.pageableFilters);
export const selectPageableOffersFilters = createSelector(selectFeature, (state: State, props: { key: string }) => {
  const result = state.pageableFilters ? state.pageableFilters.find((_) => _.key === props.key) : null;
  return result ? result.value : null;
});

