import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { CustomValidator } from '../../../validators/custom.validator';

export class IngredientForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required]),
      select: new UntypedFormControl(null, [Validators.required]),
      value: new UntypedFormControl(null, [Validators.required]),
      nodeId: new UntypedFormControl(null, [Validators.required])
    });
  }

  forAdd() {
    return this;
  }
}