import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomerApiCallerService } from '../api-services/customer/customer-api-caller.service';

@Injectable()
export class CustomerExistValidator {
  constructor(private customerApiCaller: CustomerApiCallerService) {}

  createValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value) {

        let phoneNumber = control.value;

        return this.customerApiCaller.getPhoneNumberExist(phoneNumber).pipe(
          map((response: boolean) => {
            if (response) {
              return { phoneNumberExist: true };
            } else {
              return null;
            }
          }),
          catchError((err: any) => {
            return err.status === 404 ? of(null) : of({ phoneNumberExist: true });
          })
        );
      } else {
        return of(null);
      }
    };
  }
}
