import { createAction, props } from '@ngrx/store';
import { UpdateWaterDto } from '../../shared/models/water/update-water-dto';

export const loadWater = createAction('[Water] Load Water', props<{ recipeId: string }>());
export const addWater = createAction('[Water] Add Water', props<{ data: UpdateWaterDto }>());
export const updateWater = createAction('[Water] Update Water', props<{ data: UpdateWaterDto }>());

export const loadWaterSuccess = createAction('[Water] Load Water Success', props<{ data: UpdateWaterDto }>());
export const loadWaterFailure = createAction('[Water] Load Water Failure', props<{ error: any }>());

export const addWaterSuccess = createAction('[Water] Add Water Success', props<{ recipeId: string }>());
export const addWaterFailure = createAction('[Water] Add Water Failure', props<{ error: any }>());

export const updateWaterSuccess = createAction('[Water] Update Water Success', props<{ recipeId: string }>());
export const updateWaterFailure = createAction('[Water] Update Water Failure', props<{ error: any }>());
