export class EmployeeDto {
  constructor(data: EmployeeDto) {
    if (data != null) {
      this.id = data.id;
      this.userName = data.userName;
      this.normalizedUserName = data.normalizedUserName;
      this.email = data.email;
      this.normalizedEmail = data.normalizedEmail;
      this.emailConfirmed = data.emailConfirmed;
      this.phoneNumber = data.phoneNumber;
      this.phoneNumberConfirmed = data.phoneNumberConfirmed;
      this.twoFactorEnabled = data.twoFactorEnabled;
      this.lockoutEnd = data.lockoutEnd;
      this.lockoutEnabled = data.lockoutEnabled;
      this.accessFailedCount = data.accessFailedCount;
      this.name = data.name;
      this.surname = data.surname;
      this.nodeId = data.nodeId;
      this.absenceFrom = data.absenceFrom;
      this.absenceTo = data.absenceTo;
    }
  }

  id: string;
  userName: string;
  normalizedUserName: string;
  email: string;
  normalizedEmail: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  twoFactorEnabled: boolean;
  lockoutEnd: Date;
  lockoutEnabled: boolean;
  accessFailedCount: number;

  name: string;
  surname: string;

  nodeId: string;
  nodeName: string;
  
  absenceFrom?: Date;
  absenceTo?: Date;
}
