import { IndividualType } from '../enums/individual-type.enum';
import { CompanyType } from '../enums/company-type.enum';
import { PersonDto } from '../person.dto';
import { DictionaryModel } from '../dictionary-model';

export class CompanyDto {
  constructor(data?) {
    if (data != null) {
      Object.keys(data).forEach((key) => {
        this[key] = data[key];
      });

      this.createDate = new Date(data.createDate);
      if (this.companyEmployees == null) {
        this.companyEmployees = [];
      }
    }
  }

  id: string;
  individualType: IndividualType;
  type: CompanyType;
  companyEmployees: PersonDto[];
  createDate: Date;
  businessObjects: DictionaryModel<string>[];
  identityNumber: string;
  name: string;
}
