import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export class CustomValidator {
  public static createIsInEnumValidator(T) {
    return (control: UntypedFormControl) => {
      if (control.value != null && Object.values(T).indexOf(control.value) < 0) {
        return { isNotEnum: true };
      }

      return null;
    };
  }

  public static isRequiredForProduct(c: UntypedFormControl) {
    if (!c.parent) {
      return null;
    }
    if(c.parent.get('isProduct').value == true && !c.value){
      return {'required': true};
    }
    else{
      return null;
    }
  }

  public static isRequiredForCompany(c: UntypedFormControl) {
    if (!c.parent) {
      return null;
    }
    if(c.parent.get('isCompany').value == true && !c.value){
      return {'required': true};
    }
    else{
      return null;
    }
  }

  public static isRequiredForPerson(c: UntypedFormControl) {
    if (!c.parent) {
      return null;
    }
    if(c.parent.get('isCompany').value === false && !c.value && c.parent.get('bricklayer').value === false && !c.value){
      return {'required': true};
    }
    else{
      return null;
    }
  }

  public static isRequiredForConcrete(c: UntypedFormControl) {
    if (!c.parent) {
      return null;
    }
    if(c.parent.get('itemTypeId').value == '00000000-0001-0000-0000-000000000001' && c.value == null){
      return {'required': true};
    }
    else{
      return null;
    }
  }

  public static passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('passwordConfirm').value
       ? null : {'passwordMismatch': true};
  }

  public static uppercaseValidator(c: UntypedFormControl) {
    let regex = /[A-Z]/
    if (regex.test(c.value)) {
      return null;
    } else {
      return { requireUppercase: true }
    }
  }

  public static lowercaseValidator(c: UntypedFormControl) {
    let regex = /[a-z]/
    if (regex.test(c.value)) {
      return null;
    } else {
      return { requireLowercase: true }
    }
  }

  public static numberValidator(c: UntypedFormControl) {
    let regex = /[0-9]/
    if (regex.test(c.value)) {
      return null;
    } else {
      return { requireNumber: true }
    }
  }

  public static specialCharacterValidator(c: UntypedFormControl) {
    let regex = /[$@$!%*?&]/
    if (regex.test(c.value)) {
      return null;
    } else {
      return { requireSpecialCharacter: true }
    }
  }

  public static onlyDigits(c: UntypedFormControl) {
    let regex = /^[0-9]*$/
    if (regex.test(c.value)) {
      return null;
    } else {
      return { onlyDigits: true }
    }
  }

  public static isCommaSeparatedNumber(c: UntypedFormControl) {
    let regex = /^[0-9]+([\,.]{0,1}[0-9]{0,2})?$/
    if (regex.test(c.value)) {
      return null;
    } else {
      return { onlyDigits: true }
    }
  }

  public static maxHour(max: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors|null => {
      if (this.isEmptyInputValue(control.value) || this.isEmptyInputValue(max)) {
        return null;
      }
      console.log("validate")
      const value = parseFloat(control.value);
      let controlValue = moment(control.value)
      let timeStringArray = max.split(':');
      let maxHour = parseInt(timeStringArray[0])
      let maxMinute = parseInt(timeStringArray[1])
      let maxValue = moment(control.value);
      maxValue.hour(maxHour);
      maxValue.minute(maxMinute);

      return !isNaN(value) && controlValue > maxValue ?
      {'max': {'max': max, 'actual': control.value}} : null;
    };
  }

  public static minHour(min: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors|null => {
      if (this.isEmptyInputValue(control.value) || this.isEmptyInputValue(min)) {
        return null;
      }
      const value = parseFloat(control.value);
      let controlValue = moment(control.value)
      let timeStringArray = min.split(':');
      let minHour = parseInt(timeStringArray[0])
      let minMinute = parseInt(timeStringArray[1])
      let minValue = moment(control.value);
      minValue.hour(minHour);
      minValue.minute(minMinute);

      return !isNaN(value) && controlValue < minValue ?
      {'min': {'min': min, 'actual': control.value}} : null;
    };
  }

  public static moreThanZero(val:number, carType: string): ValidatorFn{
    return (control: AbstractControl): ValidationErrors|null =>{
      if(carType == "Pompa"){
        if(val>=0){
          return null;
        }
        else return {invalidNumber:true}
      }
      if(val>0){
        return null;
      }
      else return {invalidNumber:true}
    }
  }

  private static isEmptyInputValue(val: any){
    if(val == null || val == undefined){
      return true
    }
    else{
      return false
    }
  }
}
