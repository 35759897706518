import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { IdentityUsersApiCallerService } from '../../shared/api-services/identity-users-api-caller.service';
import {
  loadAllClaims,
  loadAllClaimsFailure,
  loadAllClaimsSuccess,
  storeUserData,
  storeUserDataFailure,
  storeUserDataSuccess,
} from './user-data.actions';
// import { ApiUsersApiService } from '../../shared/api-services/api-services';

@Injectable()
export class UserDataEffects {
  userData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storeUserData),
      concatMap(({ data }) => {
        return this.operation(data).pipe(
          map((_) => storeUserDataSuccess({ data: _ })),
          catchError((error) => of(storeUserDataFailure({ error })))
        );
      })
    )
  );

  // claims = [
  //   { value: 0, name: "NotSet" },
  //   { value: 10, name: "StockRead" },
  //   { value: 11, name: "ResourceChange" },
  //   { value: 13, name: "StockAddNew" },
  //   { value: 14, name: "StockRemove" },
  //   { value: 20, name: "SalesRead" },
  //   { value: 21, name: "SalesSell" },
  //   { value: 22, name: "SalesReturn" },
  //   { value: 30, name: "EmployeeRead" },
  //   { value: 40, name: "UserRead" },
  //   { value: 41, name: "UserChange" },
  //   { value: 50, name: "RoleRead" },
  //   { value: 51, name: "RoleChange" },
  //   { value: 60, name: "ClientRead" },
  //   { value: 61, name: "ClientChange" },
  //   { value: 70, name: "ResourceRead" },
  //   { value: 100, name: "OldPermissionNotUsed" },
  //   { value: 32767, name: "AccessAll" }
  // ]

  allClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllClaims),
      concatMap(() => {
        return this.client.getSystemPermissions().pipe(
          map((_) => loadAllClaimsSuccess({ data: _ })),
          catchError((error) => of(loadAllClaimsFailure({ error })))
        );
      })
    )
  );

  private operation(data) {
    return of(data);
  }

  constructor(private actions$: Actions, private client: IdentityUsersApiCallerService) {}
}
