import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ContractorDataForm } from '../contractor/contractor-data.form';
import { PersonListForm } from '../persons-list/person-list.form';
import { SelectModelForm } from '../select-model.form';
import { CompanyValidatorsApiCallerService } from '../../../api-services/company-validators-api-caller.service';

export class ClientForm extends UntypedFormGroup {
  constructor(companyValidatorsApiCaller: CompanyValidatorsApiCallerService) {
    super({
      id: new UntypedFormControl(null, [Validators.required]),
      name: new UntypedFormControl(null, [Validators.required]),
      contract: new UntypedFormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1)]),
      contractorData: new ContractorDataForm(companyValidatorsApiCaller),
      businessObjects: new UntypedFormControl(null),
    });
  }

  public forAddOperation() {
    this.removeControl('contractorData');

    return this;
  }

  public forFullView() {
    this.setControl('businessObjects', new SelectModelForm());
    this.setControl('companyEmployees', new PersonListForm());
    this.setControl('individualType', new UntypedFormControl());

    return this;
  }
}
