import { Action, createReducer, on } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { addOutgoingMessage, addOutgoingMessageSuccess, addOutgoingMessageFailure, loadPageableOutgoingMessages, loadPageableOutgoingMessagesFailure, loadPageableOutgoingMessagesSuccess, setPageableOutgoingMessagesFilters, setPageableOutgoingMessagesFiltersSuccess, getOutgoingMessage, getOutgoingMessageSuccess, getOutgoingMessageFailure } from './outgoing-messages.actions';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { GetPageableOutgoingMessagesQuery } from '../../shared/models/queries/get-pageable-outgoing-messages.query';
import { CorrespondenceDto } from '../../shared/models/correspondence/correspondenceDTO';

export const outgoingMessagesFeatureKey = 'outgoingMessages';

export interface State {
  pageableOutgoingMessages?: DictionaryDto<string, PageableDto<CorrespondenceDto>>;
  outgoingMessagesFilters?: DictionaryDto<string, GetPageableOutgoingMessagesQuery>;
  outgoingMessage?: CorrespondenceDto;
}

export const initialState: State = {
  outgoingMessagesFilters: new DictionaryDto<string, GetPageableOutgoingMessagesQuery>(),
};

const usersReducer = createReducer(
  initialState,

  on(addOutgoingMessage, (state) => setAddOutgoingMessage(state, null)),
  on(addOutgoingMessageSuccess, (state) => setAddOutgoingMessage(state, null)),
  on(addOutgoingMessageFailure, (state) => setAddOutgoingMessage(state, null)),

  on(loadPageableOutgoingMessages, (state, { key }) => setPageableOutgoingMessages(state, null, key)),
  on(loadPageableOutgoingMessagesSuccess, (state, { data, key }) => setPageableOutgoingMessages(state, data, key)),
  on(loadPageableOutgoingMessagesFailure, (state, action) => state),

  on(getOutgoingMessage, (state) => setOutgoingMessage(state, null)),
  on(getOutgoingMessageSuccess, (state, { data}) => setOutgoingMessage(state, data)),
  on(getOutgoingMessageFailure, (state, action) => state),

  on(setPageableOutgoingMessagesFilters, (state, { data, key }) => setPageableOutgoingMessagesFiltersFunc(state, null, key)),
  on(setPageableOutgoingMessagesFiltersSuccess, (state, { data, key }) => setPageableOutgoingMessagesFiltersFunc(state, data, key)),
);

function setPageableOutgoingMessages(state: State, data: PageableDto<CorrespondenceDto>, key: string) {
  const pageableOutgoingMessages = Array.isArray(state.pageableOutgoingMessages)
    ? state.pageableOutgoingMessages
    : new DictionaryDto<string, PageableDto<CorrespondenceDto>>();
  const orders = pageableOutgoingMessages.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableOutgoingMessages.push({ key, value: data });
  }

  return {
    ...state,
    pageableOutgoingMessages,
  };
}

function setOutgoingMessage(state: State, data: CorrespondenceDto) {
  var outgoingMessage = data;
  return {
    ...state,
    outgoingMessage,
  };
}

function setAddOutgoingMessage(state: State, attachmentsProgress: CorrespondenceDto[]) {
  return {
    ...state,
    attachmentsProgress,
  };
}

function setPageableOutgoingMessagesFiltersFunc(state: State, data: GetPageableOutgoingMessagesQuery, key: string) {
  const employeesFilters = Array.isArray(state.outgoingMessagesFilters)
    ? state.outgoingMessagesFilters
    : new DictionaryDto<string, GetPageableOutgoingMessagesQuery>();
  const filter = employeesFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    employeesFilters.push({ key, value: data });
  }

  return {
    ...state,
    employeesFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
