import { Action, createReducer, on } from '@ngrx/store';
import {
  loadSupplyStatusesForSelect,
  loadSupplyStatusesForSelectSuccess,
  loadSupplyStatusesForSelectFailure,
} from './supply-statuses.actions';
import { SelectModel } from '../../shared/models/select-model';

export const supplyStatusesFeatureKey = 'supplyStatuss';

export interface State {
  supplyStatusesForSelect?: SelectModel<string>[];
}

export const initialState: State = {
};

const supplyStatusesReducer = createReducer(
  initialState,

  on(loadSupplyStatusesForSelect, (state) => setSupplyStatusesForSelect(state, null)),
  on(loadSupplyStatusesForSelectSuccess, (state, { data }) => setSupplyStatusesForSelect(state, data)),
  on(loadSupplyStatusesForSelectFailure, (state, action) => state)
);

function setSupplyStatusesForSelect(state: State, data: SelectModel<string>[]) {
  const supplyStatusesForSelect = data;
  return {
    ...state,
    supplyStatusesForSelect,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return supplyStatusesReducer(state, action);
}
