import { Action, createReducer, on } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';

import { addDocument, addDocumentSuccess, addDocumentFailure, loadPageableDocuments, loadPageableDocumentsFailure, loadPageableDocumentsSuccess, setPageableDocumentsFilters, setPageableDocumentsFiltersSuccess, getDocument, getDocumentSuccess, getDocumentFailure } from './documents.actions';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { GetPageableDocumentsQuery } from '../../shared/models/queries/get-pageable-documents.query';
import { DocumentDto } from '../../shared/models/documents/documentDTO';

export const incomingMessagesFeatureKey = 'incomingMessages';

export interface State {
  pageableDocuments?: DictionaryDto<string, PageableDto<DocumentDto>>;
  documentsFilters?: DictionaryDto<string, GetPageableDocumentsQuery>;
  invoice?: DocumentDto;
}

export const initialState: State = {
  documentsFilters: new DictionaryDto<string, GetPageableDocumentsQuery>(),
};

const usersReducer = createReducer(
  initialState,

  on(addDocument, (state) => setAddDocument(state, null)),
  on(addDocumentSuccess, (state) => setAddDocument(state, null)),
  on(addDocumentFailure, (state) => setAddDocument(state, null)),

  on(loadPageableDocuments, (state, { key }) => setPageableDocument(state, null, key)),
  on(loadPageableDocumentsSuccess, (state, { data, key }) => setPageableDocument(state, data, key)),
  on(loadPageableDocumentsFailure, (state, action) => state),

  on(getDocument, (state) => setDocument(state, null)),
  on(getDocumentSuccess, (state, { data}) => setDocument(state, data)),
  on(getDocumentFailure, (state, action) => state),

  on(setPageableDocumentsFilters, (state, { data, key }) => setPageableDocumentsFiltersFunc(state, null, key)),
  on(setPageableDocumentsFiltersSuccess, (state, { data, key }) => setPageableDocumentsFiltersFunc(state, data, key)),
);

function setPageableDocument(state: State, data: PageableDto<DocumentDto>, key: string) {
  const pageableDocuments = Array.isArray(state.pageableDocuments)
    ? state.pageableDocuments
    : new DictionaryDto<string, PageableDto<DocumentDto>>();
  const orders = pageableDocuments.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableDocuments.push({ key, value: data });
  }

  return {
    ...state,
    pageableDocuments,
  };
}

function setDocument(state: State, data: DocumentDto) {
  var invoice = data;
  return {
    ...state,
    invoice,
  };
}

function setAddDocument(state: State, attachmentsProgress: DocumentDto[]) {
  return {
    ...state,
    attachmentsProgress,
  };
}

function setPageableDocumentsFiltersFunc(state: State, data: GetPageableDocumentsQuery, key: string) {
  const employeesFilters = Array.isArray(state.documentsFilters)
    ? state.documentsFilters
    : new DictionaryDto<string, GetPageableDocumentsQuery>();
  const filter = employeesFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    employeesFilters.push({ key, value: data });
  }

  return {
    ...state,
    employeesFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
