import { ConsistencyDto } from './../models/consistency/update-consistency-dto';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ConsistencyApiCallerService extends BaseApiCaller {
  protected controllerPath = 'consistency';

  // constructor(httpClient: HttpClient, private datePipe: DatePipe) {
  //   super(httpClient);
  //}
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addConsistency(consistency: ConsistencyDto) {
    return this.post('', consistency);
  }

  public updateConsistency(consistency: ConsistencyDto) {
    return this.put('', consistency);
  }
}
