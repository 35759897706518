import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { AddressType } from '../../../models/enums/address-type.enum';

export class ClientForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(null),
      firstName: new UntypedFormControl(null, [Validators.required]),
      lastName: new UntypedFormControl(null, [Validators.required]),
      name: new UntypedFormControl(null, [Validators.required]),
      clientType: new UntypedFormControl(),
      clientTypeValue: new UntypedFormControl(),
      nip: new UntypedFormControl(),
      pesel: new UntypedFormControl(),
      phoneNumber: new UntypedFormControl(),
      email: new UntypedFormControl(),

      inhabitantAddress: new UntypedFormGroup({
        id: new UntypedFormControl(),
        addressType: new UntypedFormControl(AddressType.Inhabitant),
        country: new UntypedFormControl(),
        city: new UntypedFormControl(),
        postCode: new UntypedFormControl(),
        street: new UntypedFormControl(),
        houseNumber: new UntypedFormControl(),
        apartmentNumber: new UntypedFormControl(),
      }),
      correspondenceAddress: new UntypedFormGroup({
        id: new UntypedFormControl(),
        addressType: new UntypedFormControl(AddressType.Correspondence),
        country: new UntypedFormControl(),
        city: new UntypedFormControl(),
        postCode: new UntypedFormControl(),
        street: new UntypedFormControl(),
        houseNumber: new UntypedFormControl(),
        apartmentNumber: new UntypedFormControl(),
      }),
    });
  }

  forAdd() {
    return this;
  }
}
