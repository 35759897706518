import { AddressDto } from "../dictionaries/addressDTO";

export class SpecialAdditivesDto {
  constructor(data: SpecialAdditivesDto) {
    if (data != null) {
      this.id = data.id;
      this.name = data.name;
      this.unit = data.unit;
      this.unitPrice = data.unitPrice;
      this.weight = data.weight;
      this.isForHigherClass = data.isForHigherClass;

      this.customerId = data.customerId;

      this.addressId = data.addressId;
      this.address = data.address;
    }
  }

  id: string;
  name: string;
  unit: string;
  unitPrice: number;
  weight: number;
  isForHigherClass: boolean;

  customerId?: string;

  addressId?: string;
  address?: AddressDto;
}
