import { createAction, props } from '@ngrx/store';
import { SettlementsHistoryDto } from '../../shared/models/orders/settlementsHistoryDto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableSettlementsHistory } from '../../shared/models/queries/get-pageable-settlements-history.query';

export const loadPageableSettlementsHistory = createAction(
  '[Operation] Load Settlements History',
  props<{ key: string }>()
);

export const loadPageableSettlementsHistorySuccess = createAction(
  '[Operation] Load Settlements History Success',
  props<{ data: PageableDto<SettlementsHistoryDto>; key: string }>()
);

export const loadPageableSettlementsHistoryFailure = createAction(
  '[Operation] Load Settlements History Failure',
  props<{ error: any }>()
);

export const setPageableSettlementsHistoryFilters = createAction(
  '[Operation] Set Pageable Settlements History Filters',
  props<{ data?: GetPageableSettlementsHistory; key: string }>()
);

export const setPageableSettlementsHistoryFiltersSuccess = createAction(
  '[Operation] Set Pageable Settlements History Filters Success',
  props<{ data?: GetPageableSettlementsHistory; key: string }>()
);

export const setPageableSettlementsHistoryFiltersFailure = createAction(
  '[Operation] Set Pageable Settlements History Filters Failure',
  props<{ error: any }>()
);
