import {
  addStructuralElement,
  addStructuralElementFailure,
  addStructuralElementSuccess,
  deleteStructuralElement,
  deleteStructuralElementFailure,
  deleteStructuralElementSuccess,
  getStructuralElement,
  getStructuralElementFailure,
  getStructuralElementSuccess,
  loadPageableStructuralElements,
  loadPageableStructuralElementsFailure,
  loadPageableStructuralElementsSuccess,
  loadStructuralElement,
  loadStructuralElementFailure,
  loadStructuralElementSuccess,
  setPageableStructuralElementFilters,
  setPageableStructuralElementFiltersFailure,
  setPageableStructuralElementFiltersSuccess,
  updateStructuralElement,
  updateStructuralElementFailure,
  updateStructuralElementSuccess,
} from './structural-element.actions';
import { Injectable, ViewChild } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import { StructuralElementApiCallerService } from '../../shared/api-services/structural-element-api-caller.service';
import { Router } from '@angular/router';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';
import { catchError, concatMap, finalize, map, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { selectAllPageableStructuralElementsFilters } from './structural-element.selectors';

@Injectable()
export class StructuralElementEffects {
  @ViewChild('spinner', { static: true }) spinner;

  private latestedStructuralElementsKey: string;

  addStructuralElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addStructuralElement),
      concatMap(({ data }) => {
        let lastValue;
        return this.structuralElementApiCaller.addStructuralElement(data).pipe(
          map((_) => addStructuralElementSuccess({ data: _ })),
          tap((_) => {
            lastValue = _;
          }),
          finalize(() => {
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(addStructuralElementFailure({ error })))
        );
      })
    )
  );

  updateStructuralElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStructuralElement),
      concatMap(({ data }) => {
        let lastValue;
        return this.structuralElementApiCaller.updateStructuralElement(data).pipe(
          map((_) => updateStructuralElementSuccess()),
          tap((_) => (lastValue = _)),
          finalize(() => {
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(updateStructuralElementFailure({ error })))
        );
      })
    )
  );

  deleteStructuralElement$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteStructuralElement),
    concatMap(({ id }) => {
      return this.structuralElementApiCaller.deleteStructuralElement(id).pipe(
        map((_) => deleteStructuralElementSuccess({ id })),
        tap(() => this.reloadStructuralElements(this.latestedStructuralElementsKey)),
        catchError((error) => of(deleteStructuralElementFailure({ error })))
      );
    })
  )
);

  loadPageableStructuralElements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableStructuralElements),
      withLatestFrom(this.store$.select(selectAllPageableStructuralElementsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedStructuralElementsKey = key;

        return this.structuralElementApiCaller.getPageableStructuralElements(filter.value).pipe(
          map((_) => loadPageableStructuralElementsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableStructuralElementsFailure({ error })))
        );
      })
    )
  );

  getStructuralElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStructuralElement),
      concatMap(({ id }) => {
        return this.structuralElementApiCaller.getStructuralElement(id).pipe(
          map((_) => getStructuralElementSuccess({ data: _ })),
          catchError((error) => of(getStructuralElementFailure({ error })))
        );
      })
    )
  );

  setPageableCarsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableStructuralElementFilters),
      concatMap(({ data, key }) => {
        this.latestedStructuralElementsKey = key;
        return of(data).pipe(
          map((_) => setPageableStructuralElementFiltersSuccess({ data, key })),
          finalize(() => this.reloadStructuralElements(key)),
          catchError((error) => of(setPageableStructuralElementFiltersFailure({ error })))
        );
      })
    )
  );
  structuralElements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStructuralElement),
      concatMap(() => {
        return this.structuralElementApiCaller.getStructuralElements().pipe(
          map((_) => loadStructuralElementSuccess({ data: _ })),
          catchError((error) => of(loadStructuralElementFailure({ error })))
        );
      })
    )
  );

  private reloadStructuralElements(key: string) {
    this.store$.dispatch(loadPageableStructuralElements({ key }));
  }

  filters: GetPageableQuery;
  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private structuralElementApiCaller: StructuralElementApiCallerService,
    private router: Router,
    private overlayService: OverlayWrapperService
  ) {}

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'structural-elements', 'catalog']);
  }
}
