import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { CustomValidator } from '../../../validators/custom.validator';

export class EmployeeForEditForm extends UntypedFormGroup {
  constructor() {
    super(
      {
        userId: new UntypedFormControl(null),
        userName: new UntypedFormControl(null, [Validators.required]),
        name: new UntypedFormControl(null, [Validators.required]),
        surname: new UntypedFormControl(null, [Validators.required]),
        email: new UntypedFormControl(null, [Validators.required]),
        phoneNumber: new UntypedFormControl(null),

        permissions: new UntypedFormControl(null),
        dataKey: new UntypedFormControl(null),
        roles: new UntypedFormControl(null),
      },
      CustomValidator.passwordMatchValidator
    );
  }

  forAdd() {
    return this;
  }
}
