import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree,
  CanLoad,
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SecurityService } from '../services/security.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private securityService: SecurityService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkUser();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let requiredClaims = route.data.requiredClaims as Array<string>;
    return this.checkUser().pipe( map((isLoggedIn) => {
      if(isLoggedIn){
        if(requiredClaims != undefined){
          if(this.hasClaims(requiredClaims)){
            return true;
          }
          else{
            this.router.navigate(['authorised/', 'access-denied']);
            //return false;
          }
        }
        else{
          return true;
        }
      }
      else{
        return false;
      }
    }))
  }

  canLoad(state: Route): Observable<boolean> {
    return this.checkUser();
  }

  private checkUser(): Observable<boolean> {
    return this.oidcSecurityService.checkAuth().pipe(
      tap((isAuthorized: boolean) => {
        if (!isAuthorized) {
          // this.router.navigate(['/', 'unauthorized']);
        }
      })
    );
  }

  hasClaims(claims) {
    return this.securityService.hasClaims(claims);
  }
}
