import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { concatMap, catchError, map } from 'rxjs/operators';
import { loadWater, addWaterSuccess, addWaterFailure, updateWaterSuccess,
        updateWaterFailure, addWater, updateWater, loadWaterSuccess, loadWaterFailure } from './water.actions';

import { of } from 'rxjs';
import { WaterApiCallerService } from '../../shared/api-services/water-api-caller.service';
import { RecipeApiCallerService } from '../../shared/api-services/recipe-api-caller.service';

@Injectable()
export class WaterEffects {
    cement$ = createEffect(() =>
    this.actions$.pipe(
        ofType(loadWater),
        concatMap(({ recipeId }) => {
            return this.recipeApiCaller.getWaterForRecipe(recipeId).pipe(
                 map((_) => loadWaterSuccess({ data: _ })),
                 catchError((error) => of(loadWaterFailure({ error })))
            );
        })
));

addCement$ = createEffect(() =>
 this.actions$.pipe(
     ofType(addWater),
     concatMap(({ data }) => {
         return this.waterApiCaller.addWater(data).pipe(
         map((_) => addWaterSuccess({ recipeId: data.id })),
         catchError((error) => of(addWaterFailure({ error })))
         );
     })
 ));

 updateCement$ = createEffect(() =>
 this.actions$.pipe(
     ofType(updateWater),
     concatMap(({ data }) => {
         return this.waterApiCaller.updateWater(data).pipe(
         map((_) => updateWaterSuccess({ recipeId: data.id })),
         catchError((error) => of(updateWaterFailure({ error })))
         );
     })
 ));

 constructor(
    private actions$: Actions,
    private waterApiCaller: WaterApiCallerService,
    private recipeApiCaller: RecipeApiCallerService
) {}
}
