//import { DocumentsApiCallerService } from '../../shared/api-services/employees-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  addDocument,
  addDocumentSuccess,
  addDocumentFailure,
  deleteDocument,
  deleteDocumentSuccess,
  deleteDocumentFailure,
  setPageableDocumentsFilters,
  setPageableDocumentsFiltersSuccess,
  setPageableDocumentsFiltersFailure,
  loadPageableDocuments,
  loadPageableDocumentsSuccess,
  loadPageableDocumentsFailure,
  updateDocumentSuccess,
  updateDocumentFailure,
  getDocument,
  getDocumentSuccess,
  getDocumentFailure,
  updateDocument,
} from './documents.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { SnackBarWrapperService } from '../../shared/services/snack-bar-wrapper.service';
import { selectAllPageableDocumentsFilters } from './documents.selector';
//import { IncomingMessageDto } from '../../shared/models/incoming-messages/incomingMessageDTO';
import { PageableDto } from '../../shared/models/pageable.dto';
import { DocumentDto } from '../../shared/models/documents/documentDTO';
import { DocumentsApiCallerService } from '../../shared/api-services/documents-api-caller.service';

@Injectable()
export class DocumentsEffects {

  private latestedDocumentsKey: string;

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDocument),
      concatMap(({ id }) => {
        return this.documentsApiCaller.deleteDocument(id).pipe(
          map((_) => deleteDocumentSuccess({ id: id })),
          tap(() => this.reloadDocuments(this.latestedDocumentsKey)),
          catchError((error) => of(deleteDocumentFailure({ error })))
        );
      })
    )
  );

  // addDocument$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(addDocument),
  //     concatMap(({ data }) => {
  //       return this.documentsApiCaller.addDocument(data).pipe(
  //         map((_) => addDocumentSuccess()),
  //         tap(() => this.navigateToCatalog()),
  //         catchError((error) => of(addDocumentFailure({ error })))
  //       );
  //     })
  //   )
  // );

  loadPageableDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableDocuments),
      withLatestFrom(this.store$.select(selectAllPageableDocumentsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedDocumentsKey = key;

        return this.documentsApiCaller.getPageableDocuments(filter.value).pipe(
          map((_) => loadPageableDocumentsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableDocumentsFailure({ error })))
        );
      })
    )
  );

  // getDocument$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(getDocument),
  //     concatMap(({id}) => {
  //       return this.documentsApiCaller.getDocument(id).pipe(
  //         map((_) => getDocumentSuccess({ data: _ })),
  //         catchError((error) => of(getDocumentFailure({ error })))
  //       );
  //     })
  //   )
  // );

  setPageableDocumentsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableDocumentsFilters),
      concatMap(({ data, key }) => {
        this.latestedDocumentsKey = key;
        return of(data).pipe(
          map((_) => setPageableDocumentsFiltersSuccess({ data, key })),
          finalize(() => this.reloadDocuments(key)),
          catchError((error) => of(setPageableDocumentsFiltersFailure({ error })))
        );
      })
    )
  );

  private reloadDocuments(key: string) {
    this.store$.dispatch(loadPageableDocuments({ key }));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private documentsApiCaller: DocumentsApiCallerService,
    private router: Router,
    private matSnackBar: SnackBarWrapperService
  ) { }

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'invoice-register', 'catalog']);
  }
}
