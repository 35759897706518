import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';

export const loadSupplyStatusesForSelect = createAction('[SupplyStatuses] Load SupplyStatuses For Select');
export const loadSupplyStatusesForSelectSuccess = createAction(
  '[SupplyStatuses] Load SupplyStatuses For Select Success',
  props<{ data: SelectModel<string>[] }>()
);
export const loadSupplyStatusesForSelectFailure = createAction(
  '[SupplyStatuses] Load SupplyStatuses For Select Failure',
  props<{ error: any }>()
);

