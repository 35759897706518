import { UntypedFormGroup } from '@angular/forms';

export function removeValidators(form: UntypedFormGroup) {
  for (const key in form.controls) {
    form.get(key).clearValidators();
  }
}
export function updateValidators(
  form: UntypedFormGroup,
  opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }
) {
  for (const key in form.controls) {
    form.get(key).updateValueAndValidity(opts);
  }
}

export function markAllAsTouched(form: UntypedFormGroup) {
  for (const key in form.controls) {
    form.get(key).markAsTouched();
  }
}
