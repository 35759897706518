import { Component, OnInit, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Store } from '@ngrx/store';
import { StoreState } from './root-store/store-state';
import { loadAllClaims, storeUserData } from './root-store/user-data/user-data.actions';
import { OfflineService } from './pwa/service/offline.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-maestro-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  authStatus = new Subject<boolean>();
  isAuthenticated = false;
  timer;


  constructor(
    private oidcSecurityService: OidcSecurityService,
    private offlineService: OfflineService,
    translate: TranslateService, 
    private store$: Store<StoreState>,
    private router: Router) {
    translate.use(environment.lang);
    translate.get('App.title').subscribe((_) => (document.title = _));
  }

  ngOnInit() {
    this.oidcSecurityService
      .checkAuth()
      .subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
        this.authStatus.next(isAuthenticated);

        // debugger;
        if (!isAuthenticated) {
          if ('#/autologin' !== window.location.hash) {
            this.write();
            this.router.navigate(['/autologin']);
          }
        }
        if (isAuthenticated) {
          this.navigateToStoredEndpoint();
        }
      });

      this.oidcSecurityService.userData$.subscribe((userData) => {
        if (userData != null) {
          this.store$.dispatch(storeUserData({ data: userData }));
          this.store$.dispatch(loadAllClaims());
        }
      });

      const intervalInMinutes = 15;
      this.startAuthSync(intervalInMinutes);
      this.startCacheSync(intervalInMinutes);
  }

  ngOnDestroy() {
    this.stopCacheSync();
  }

  @HostListener('window:beforeunload') beforeUnload() {
    if (this.isAuthenticated) {
      this.write();
    }
  }

  private navigateToStoredEndpoint() {
    let path = this.read('redirect') as string;
    if (path == null || path.length < 2) {
      return;
    }
    path = path.slice(1);
    if (path.toString().includes('/unauthorized')) {
      this.router.navigate(['/']);
    } else {
      this.router.navigateByUrl(path);
    }
  }

  private read(key: string): any {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }

    return;
  }

  private write(): void {
    // if (value === '#/unauthorized') {
    //   // return;
    // }
    localStorage.setItem('redirect', JSON.stringify(window.location.hash));
  }


  private startAuthSync(intervalInMinutes: number) {
    this.authStatus.asObservable().subscribe((isAuth) => {
      this.cacheSync(intervalInMinutes);
    });
  }

  private startCacheSync(intervalInMinutes: number) {
    this.timer = setInterval(() => {
      this.cacheSync(intervalInMinutes);
    }, 1000 * 60 * intervalInMinutes);
  }

  private stopCacheSync() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  private cacheSync(intervalInMinutes: number) {
    if (this.isAuthenticated) {
      this.offlineService.storeCustomers(intervalInMinutes);
    }
  }
}
