import { Action, createReducer, on } from '@ngrx/store';
import {
  loadAddressesForSelect,
  loadAddressesForSelectSuccess,
  loadAddressesForSelectFailure,
  getAddress,
  getAddressSuccess,
  getAddressFailure,
  addAddressSuccess,
} from './addresses.actions';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { omit } from '@ngrx/store/src/utils';
import { AddressDto } from '../../shared/models/addresses/addressDTO';
import { AddressSelectModel } from '../../shared/models/addressSelectModel';

export const addressesFeatureKey = 'addresss';

export interface State {
  addressesForSelect?: AddressSelectModel<any>[];
  address?: AddressDto;
}

export const initialState: State = {
};

const usersReducer = createReducer(
  initialState,

  on(loadAddressesForSelect, (state) => setAddressesForSelect(state, null)),
  on(loadAddressesForSelectSuccess, (state, { data }) => setAddressesForSelect(state, data)),
  on(loadAddressesForSelectFailure, (state, action) => state),

  on(getAddress, (state) => setAddress(state, null)),
  on(getAddressSuccess, (state, { data }) => setAddress(state, data)),
  on(getAddressFailure, (state, action) => state)
);

function setAddressesForSelect(state: State, data: AddressSelectModel<any>[]) {
  const addressesForSelect = data;
  return {
    ...state,
    addressesForSelect,
  };
}

function setAddress(state: State, data: AddressDto) {
  var address = data;
  return {
    ...state,
    address,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
