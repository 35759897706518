<div>
  <div class="col-sm-12 display-grid">
    <mat-list>
      <ng-container *ngFor="let item of items; let i = index">
        <mat-list-item>
          <span class="filename">{{ item }}</span>
          <div class="file-actions">
            <button type="button" class="action-button relative-bottom" mat-raised-button color="warn"
              (click)="deleteElement(item)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-list>
  </div>

  <div class="display-grid">
    <mat-form-field>
      <mat-label>{{inputLabel}}</mat-label>
      <input type="text" [placeholder]="placeholder" [(ngModel)]="inputValue" matInput  />
      <button mat-button *ngIf="inputValue" matSuffix mat-icon-button aria-label="Clear" (click)="addElement()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>