import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IndividualType } from '../../../models/enums/individual-type.enum';
import { updateValidators } from '../../../form-validators-helper';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-bussines-individual-form',
  templateUrl: './bussines-individual-form.component.html',
  styleUrls: ['./bussines-individual-form.component.scss'],
})
export class BussinesIndividualFormComponent implements OnInit, DoCheck {
  @Input() form: UntypedFormGroup;
  individualType = IndividualType;

  constructor() {}

  ngDoCheck() {
    this.validatorsRun();
  }

  ngOnInit() {}

  private validatorsRun() {
    updateValidators(this.form);
  }
}
