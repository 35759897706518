import { createAction, props } from '@ngrx/store';
import { DictionaryModel } from 'project/src/app/shared/models/dictionary-model';

export const loadAdmixtureTypes = createAction('[Dictionaries] Load AdmixtureTypes');
export const loadCementClasses = createAction('[Dictionaries] Load CementClasses');
export const loadCementTypes = createAction('[Dictionaries] Load CementTypes');
export const loadCementNorms = createAction('[Dictionaries] Load CementNorms');
export const loadCementSpecialProperties = createAction('[Dictionaries] Load CementSpecialProperties');
export const loadAdditivesNorms = createAction('[Dictionaries] Load AdditivesNorms');
export const loadWaterNorms = createAction('[Dictionaries] Load loadWaterNorms');
export const loadEnduranceClasses = createAction('[Dictionaries] Load EnduranceClasses');
export const loadEnduranceProgresses = createAction('[Dictionaries] Load EnduranceProgresses');
export const loadAggregateTypes = createAction('[Dictionaries] Load AggregateTypes');

// Słownik Typu Domieszek

export const loadAdmixtureTypesSuccess = createAction(
  '[Dictionaries] Load AdmixtureTypes Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadAdmixtureTypesFailure = createAction(
  '[Dictionaries] Load AdmixtureTypes Failure',
  props<{ error: any }>()
);

// Słownik Klasy Cementu
export const loadCementClassSucces = createAction(
  '[Dictionaries] Load CementClasses Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadCementClassFailure = createAction(
  '[Dictionaries] Load CementClasses Failure',
  props<{ error: any }>()
);

// Słownik Typu Cementu
export const loadCementTypesSucces = createAction(
  '[Dictionaries] Load CementTypes Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadCementTypesFailure = createAction('[Dictionaries] Load CementTypes Failure', props<{ error: any }>());

// Słownik Norm Cementu
export const loadCementNormsSucces = createAction(
  '[Dictionaries] Load CementNorms Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadCementNormsFailure = createAction('[Dictionaries] Load CementNorms Failure', props<{ error: any }>());

// Słownik Właściwości Specjalnych Cementu
export const loadCementSpecialPropertiesSucces = createAction(
  '[Dictionaries] Load CemenSpecialProperties Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadCementSpecialPropertiesFailure = createAction(
  '[Dictionaries] Load CemenSpecialProperties Failure',
  props<{ error: any }>()
);

// Słownik Norm Dodatków
export const loadAdditivesNormsSuccess = createAction(
  '[Dictionaries] Load AdditivesNorms Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadAdditivesNormsFailure = createAction(
  '[Dictionaries] Load AdditivesNorms Failure',
  props<{ error: any }>()
);

// Słownik Norm Wody

export const loadWaterNormsSuccess = createAction(
  '[Dictionaries] Load WaterNorms Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadWaterNormsFailure = createAction('[Dictionaries] Load WaterNorms Failure', props<{ error: any }>());

// Słownik Rozwoju Wytrzymałościowego Właściowości Betonu

export const loadEnduranceClassesSuccess = createAction(
  '[Dictionaries] Load EnduranceClasses Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadEnduranceClassesFailure = createAction(
  '[Dictionaries] Load EnduranceClasses Failure',
  props<{ error: any }>()
);

// Słownik Klasy Wytrzymałości Na Ściskanie Betonu

export const loadEnduranceProgressesSuccess = createAction(
  '[Dictionaries] Load EnduranceProgresses Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadEnduranceProgressesFailure = createAction(
  '[Dictionaries] Load EnduranceProgresses Failure',
  props<{ error: any }>()
);

// Słownik Typu Kruszyw
export const loadAggregateTypesSuccess = createAction(
  '[Dictionaries] Load AggregateTypes Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadAggregateTypesFailure = createAction(
  '[Dictionaries] Load AggregateTypes Failure',
  props<{ error: any }>()
);
// dictionaries for consistencies
export const loadVebeConsistencyClass = createAction('[Dictionaries] Load loadVebeConsistencyClass');
export const loadVebeConsistencyClassSuccess = createAction(
  '[Dictionaries] Load loadVebeConsistencyClass Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadVebeConsistencyClassFailure = createAction(
  '[Dictionaries] Load loadVebeConsistencyClass Failure',
  props<{ error: any }>()
);

export const loadConeConsistencyClass = createAction('[Dictionaries] Load loadConeConsistencyClass');
export const loadConeConsistencyClassSuccess = createAction(
  '[Dictionaries] Load loadConeConsistencyClass Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadConeConsistencyClassFailure = createAction(
  '[Dictionaries] Load loadConeConsistencyClass Failure',
  props<{ error: any }>()
);

export const loadFlowConsistencyClass = createAction('[Dictionaries] Load loadFlowConsistencyClass');
export const loadFlowConsistencyClassSuccess = createAction(
  '[Dictionaries] Load loadFlowConsistencyClass Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadFlowConsistencyClassFailure = createAction(
  '[Dictionaries] Load loadFlowConsistencyClass Failure',
  props<{ error: any }>()
);

export const loadCompactibilityConsistencyClass = createAction(
  '[Dictionaries] Load loadCompactibilityConsistencyClass'
);
export const loadCompactibilityConsistencyClassSuccess = createAction(
  '[Dictionaries] Load loadCompactibilityConsistencyClass Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadCompactibilityConsistencyClassFailure = createAction(
  '[Dictionaries] Load loadCompactibilityConsistencyClass Failure',
  props<{ error: any }>()
);
