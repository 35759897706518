import { OpenIdConfiguration, LogLevel } from 'angular-auth-oidc-client';
//localhost
// export const environment = {
//   production: false,
//   companyName: 'Hochtrans',
//   merchantLimit: 500,
//   apiRoot: 'https://localhost:44397',
//   recipeApiRoot: '',
//   mapRoutingServiceUrl: 'http://localhost:5000/route/v1',
//   lang: 'pl',
//   apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
//   displayDateFormat: 'DD-MM-YYYY',
//   reportsUrl: '',
//   identityConfig: {
//     stsServer: 'https://beton-identity.hhg.com.pl',
//     redirectUrl: 'https:/localhost:4200/#/authorised/dashboard',
//     clientId: 'hoch_beton_test',
//     responseType: 'code',
//     scope: 'openid profile offline_access IdentityServerApi HochtransTestApi',
//     postLogoutRedirectUri: 'https://localhost:4200/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     logLevel: LogLevel.Error,
//     useRefreshToken: true,
//   } as Ope nIdConfiguration,
// };


//test from devitm DEMO
export const environment = {
  production: true,
  companyName: 'Concrete',
  merchantLimit: 500,
  apiRoot: 'https://concrete.api.devitmcode.pl',
  recipeApiRoot: '',
  mapRoutingServiceUrl: 'https://localhost:5000/route/v1',
  lang: 'pl',
  apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
  displayDateFormat: 'DD-MM-YYYY',
  reportsUrl: '',
  identityConfig: {
    stsServer: 'https://concrete.identity.devitmcode.pl',
    redirectUrl: 'https://concrete.devitmcode.pl/#/authorised/dashboard',
    clientId: 'concrete_client',
    responseType: 'code',
    scope: 'openid profile offline_access IdentityServerApi ConcreteApi',
    postLogoutRedirectUri: 'https://concrete.devitmcode.pl/#/authorised/dashboard',
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
    renewTimeBeforeTokenExpiresInSeconds: 15,
    postLoginRoute: '/home',
    forbiddenRoute: '/forbidden',
    unauthorizedRoute: '/unauthorized',
    logLevel: LogLevel.Error,
    useRefreshToken: true,
  } as OpenIdConfiguration,
};

// export const environment = {
//   production: false,
//   companyName: 'Concrete',
//   merchantLimit: 500,
//   apiRoot: 'https://localhost:5001',
//   recipeApiRoot: '',
//   mapRoutingServiceUrl: 'https://localhost:5000/route/v1',
//   lang: 'pl',
//   apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
//   displayDateFormat: 'DD-MM-YYYY',
//   reportsUrl: '',
//   identityConfig: {
//     stsServer: 'https://concrete.identity.devitmcode.pl',
//     redirectUrl: 'http://localhost:4200/#/authorised/dashboard',
//     clientId: 'concrete_client',
//     responseType: 'code',
//     scope: 'openid profile offline_access IdentityServerApi ConcreteApi',
//     postLogoutRedirectUri: 'http://localhost:4200.pl/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     logLevel: LogLevel.Error,
//     useRefreshToken: true,
//   } as OpenIdConfiguration,
// };