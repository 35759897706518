import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from '../../models/select-model';
import { AuthorDto } from '../../models/dictionaries/authorDTO';
import { FormFactory } from '../forms/form-factory';
import { AddressDto } from '../../models/dictionaries/addressDTO';

@Component({
  selector: 'app-add-author-dialog',
  templateUrl: './add-author-dialog.component.html',
  styleUrls: ['./add-author-dialog.component.scss'],
})
export class AddAuthorDialogComponent implements OnInit {
  useTranslation: boolean;
  name: string;

  form: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<AddAuthorDialogComponent>,
    formFactory: FormFactory,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; useTranslation?: boolean }
  ) {
    this.name = data.name;
    this.useTranslation = data.useTranslation === true;
    this.form = formFactory.getAuthorForm().forAdd();
  }

  ngOnInit() {}

  save() {
    if (!this.form.valid) {
      return;
    }
    var formValues = this.form.getRawValue();
    var model = new AuthorDto(formValues);
    var inhabitantAddress = new AddressDto(formValues.inhabitantAddress);
    var correspondenceAddress = new AddressDto(formValues.correspondenceAddress);
    if (model.addresses == undefined) model.addresses = [];
    model.addresses.push(inhabitantAddress);
    model.addresses.push(correspondenceAddress);
    var result = model;
    this.dialogRef.close(result);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
