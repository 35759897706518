import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { StoreState } from './store-state';
import { reducer as contractors } from './contractors/contractor.reducer';
import { reducer as clients } from './clients/clients.reducer';
import { reducer as operations } from './operations/operation.reducer';
import { reducer as contracts } from './contracts/contract.reducer';
import { reducer as dictionaries } from './dictionaries/dictionaries.reducer';
import { reducer as companyEmployee } from './company-employees/company-employee.reducer';
import { reducer as versions } from './version/version.reducer';
import { reducer as versionLogs } from './version-log/version-log.reducer';
import { reducer as notifications } from './notifications/notification.reducer';
import { reducer as pageSize } from './tables/tables.reducer';
import { storageMetaReducer } from './storage-meta-reducer';
import { reducer as userData } from './user-data/user-data.reducer';
import { reducer as employees } from './employees/employees.reducer';
import { reducer as incomingMessages } from './incoming-messages/incoming-messages.reducer';
import { reducer as outgoingMessages } from './outgoing-messages/outgoing-messages.reducer';
import { reducer as documents } from './documents/documents.reducer';
import { reducer as editorialDocuments } from './editorial-documents/editorial-documents.reducer';
import { reducer as otherDocuments } from './other-documents/other-documents.reducer';
import { reducer as authors } from './authors/authors.reducer';
import { reducer as departments } from './departments/departments.reducer';
import { reducer as sources } from './sources/sources.reducer';
import { reducer as statuses } from './statuses/statuses.reducer';
import { reducer as cTypes } from './c-types/c-types.reducer';
import { reducer as customer } from './customer/customer.reducer';
import { reducer as cars } from './cars/cars.reducer';
import { reducer as nodes } from './nodes/nodes.reducer';
import { reducer as items } from './items/items.reducer';
import { reducer as orders } from './order/order.reducer';
import { reducer as structuralElements } from './structural-elements/structural-element.reducer';
import { reducer as deliveryCalendar } from './delivery-calendar/delivery-calendar.reducer';
import { reducer as addresses } from './addresses/addresses.reducer';
import { reducer as supplyStatuses } from './supply-statuses/supply-statuses.reducer';
import { reducer as paymentTypes } from './paymentType/paymentType.reducer';

import { reducer as recipeDictionaries } from '../recipe/root-store/recipe-dictionaries/recipe-dictionaries.reducer';
import { reducer as recipes } from '../recipe/root-store/recipes/recipe.reducer';
import { reducer as cements } from '../recipe/root-store/cement/cement.reducer';
import { reducer as additives } from '../recipe/root-store/additives/additives.reducer';
import { reducer as waters } from '../recipe/root-store/water/water.reducer';
import { reducer as admixtures } from '../recipe/root-store/admixture/admixture.reducer';
import { reducer as aggregates } from '../recipe/root-store/aggregate/aggregate.reducer';
import { reducer as mixtureProperties } from '../recipe/root-store/mixture-properties/mixture-properties.reducer';
import { reducer as consistencies } from '../recipe/root-store/consistency/consistency.reducer';
import { reducer as ingredients } from './ingredients/ingredients.reducer';
import { reducer as ingredientTypes } from './ingredient-types/ingredient-types.reducer';
import { reducer as recipeCosts } from './recipe-costs/recipe-costs.reducer';
import { reducer as specialAdditives } from './special-additives/special-additives.reducer';
import { reducer as transactions } from './transactions/transactions.reducer';
import { reducer as wzDocuments } from './wz-documents/wz-documents.reducer';
import { reducer as pageableSettlements } from './settlements/settlements.reducer';
import { reducer as historySettlements } from './settlements-history/settlements-history.reducer';
import { reducer as offers } from './offers/offer.reducer';

export const reducers: ActionReducerMap<StoreState> = {
  contractors,
  clients,
  operations,
  contracts,
  dictionaries,
  companyEmployee,
  versions,
  versionLogs,
  notifications,
  pageSize,
  userData,
  employees,
  incomingMessages,
  outgoingMessages,
  documents,
  editorialDocuments,
  otherDocuments,
  authors,
  departments,
  sources,
  statuses,
  cTypes,
  customer,
  cars,
  nodes,
  items,
  orders,
  recipeDictionaries,
  recipes,
  cements,
  additives,
  waters,
  admixtures,
  aggregates,
  mixtureProperties,
  consistencies,
  structuralElements,
  deliveryCalendar,
  addresses,
  supplyStatuses,
  ingredients,
  ingredientTypes,
  recipeCosts,
  paymentTypes,
  specialAdditives,
  transactions,
  wzDocuments,
  pageableSettlements,
  historySettlements,
  offers
};

export const MetaReducers: MetaReducer<StoreState>[] = [storageMetaReducer];
