import { createAction, props } from '@ngrx/store';
import { VersionLogDto } from '../../shared/models/version-log.dto';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { PageableDto } from '../../shared/models/pageable.dto';

export const loadPageableVersionLogs = createAction(
  '[VersionLog] Load Pageable VersionLogs',
  props<{ filters?: GetPageableQuery }>()
);

export const loadPageableVersionLogsSuccess = createAction(
  '[VersionLog] Load Pageable VersionLogs Success',
  props<{ data: PageableDto<VersionLogDto> }>()
);

export const loadPageableVersionLogsFailure = createAction(
  '[VersionLog] Load Pageable VersionLogs Failure',
  props<{ error: any }>()
);

export const createVersionLog = createAction('[VersionLog] Create VersionLog', props<{ versionLog: VersionLogDto }>());

export const createVersionLogSuccess = createAction('[VersionLog] Create VersionLog Success', props<{ id: string }>());

export const createVersionLogFailure = createAction('[VersionLog] Create VersionLog Failure', props<{ error: any }>());

export const loadVersionLogs = createAction('[VersionLog] Load VersionLog', props<{ id: string }>());

export const loadVersionLogsSuccess = createAction(
  '[VersionLog] Load VersionLog Success',
  props<{ data: VersionLogDto[] }>()
);

export const loadVersionLogsFailure = createAction('[VersionLog] Load VersionLog Failure', props<{ error: any }>());
