import { createAction, props } from '@ngrx/store';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableDocumentsQuery } from '../../shared/models/queries/get-pageable-documents.query';
import { DocumentDto } from '../../shared/models/documents/documentDTO';

export const addDocument = createAction('[DocumentRegister] Add Document', props<{ data: DocumentDto }>());
export const addDocumentSuccess = createAction('[DocumentRegister] Add Document Success');
export const addDocumentFailure = createAction('[DocumentRegister] Add Document Failure', props<{ error: any }>());

export const updateDocument = createAction('[DocumentRegister] Add DocumentRegister', props<{ data: DocumentDto }>());
export const updateDocumentSuccess = createAction('[DocumentRegister] Add DocumentRegister Success');
export const updateDocumentFailure = createAction('[DocumentRegister] Add DocumentRegister Failure', props<{ error: any }>());

export const deleteDocument = createAction('[DocumentRegister] delete Document', props<{ id: number }>());
export const deleteDocumentSuccess = createAction('[DocumentRegister] delete Document Success', props<{ id: number }>());
export const deleteDocumentFailure = createAction('[DocumentRegister] delete Document Failure', props<{ error: any }>());

export const getDocument = createAction('[IncomingMessages] get Document', props<{ id: number }>());
export const getDocumentSuccess = createAction('[IncomingMessages] get Document Success', props<{ data: DocumentDto }>());
export const getDocumentFailure = createAction('[IncomingMessages] get Document Failure', props<{ error: any }>());

export const loadPageableDocuments = createAction('[DocumentRegister] Load Pageable DocumentRegister', props<{ key: string }>());
export const loadPageableDocumentsSuccess = createAction(
  '[DocumentRegister] Load Pageable DocumentRegister Success',
  props<{ data: PageableDto<DocumentDto>; key: string }>()
);

export const loadPageableDocumentsFailure = createAction(
  '[DocumentRegister] Load Pageable DocumentRegister Failure',
  props<{ error: any }>()
);

export const setPageableDocumentsFilters = createAction(
  '[DocumentRegister] Set Pageable DocumentRegister Filters',
  props<{ data?: GetPageableDocumentsQuery; key: string }>()
);

export const setPageableDocumentsFiltersSuccess = createAction(
  '[DocumentRegister] Set Pageable DocumentRegister Filters Success',
  props<{ data: GetPageableDocumentsQuery; key: string }>()
);

export const setPageableDocumentsFiltersFailure = createAction(
  '[DocumentRegister] Set Pageable DocumentRegister Filters Failure',
  props<{ error: any }>()
);
