import { AddressDto } from '../dictionaries/addressDTO';

export class ItemDto {
  constructor(data: ItemDto) {
    if (data != null) {
      this.id = data.id;
      this.name = data.name;
      this.unit = data.unit;
      this.itemTypeId = data.itemTypeId;
      this.itemTypeName = data.itemTypeName;
      this.nodeId = data.nodeId;
      this.nodeName = data.nodeName;
      this.unitPrice = data.unitPrice;
      this.isProduct = data.isProduct;
      this.isService = data.isService;
      this.isWet = data.isWet;
      this.productOrService = data.productOrService;
      this.recipeCostId = data.recipeCostId;
      this.recipeNumber = data.recipeNumber;
      this.recipeCost = data.recipeCost;

      this.customerId = data.customerId;

      this.addressId = data.addressId;
      this.address = data.address;

      this.isActive = data.isActive;
    }
  }

  id: string;
  name?: string;
  recipeNumber?: string;
  unit?: string;
  itemTypeId?: string;
  recipeCostId?: string;
  itemTypeName: string;
  nodeId: string;
  nodeName: string;
  unitPrice: string;
  isProduct: boolean;
  isService: boolean;
  isWet: boolean;
  productOrService: string;
  recipeCost?: string;

  customerId?: string;

  addressId?: string;
  address?: AddressDto;

  isActive: boolean;
}
