import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { IngredientTypeApiCallerService } from '../../shared/api-services/ingredient-type-api-caller.service';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { loadIngredientTypes, loadIngredientTypesFailure, loadIngredientTypesForNode, loadIngredientTypesForNodeFailure, loadIngredientTypesForNodeSuccess, loadIngredientTypesSuccess } from './ingredient-types.actions';

@Injectable()
export class IngredientTypeEffects {


  ingredientTypes$ = createEffect(() =>
  this.actions$.pipe(
      ofType(loadIngredientTypes),
      concatMap((data) => {
          return this.ingredientTypeApiCallerService.getIngredientTypes().pipe(
               map((_) => loadIngredientTypesSuccess({ data: _ })),
               catchError((error) => of(loadIngredientTypesFailure({ error })))
          );
      })
  ));

  ingredientTypesForNode$ = createEffect(() =>
  this.actions$.pipe(
      ofType(loadIngredientTypesForNode),
      concatMap((data) => {
          return this.ingredientTypeApiCallerService.getIngredientTypesForNode( data.nodeId ).pipe(
               map((_) => loadIngredientTypesForNodeSuccess({ data: _ })),
               catchError((error) => of(loadIngredientTypesForNodeFailure({ error })))
          );
      })
  ));

  



 
  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private ingredientTypeApiCallerService: IngredientTypeApiCallerService,
  ) { }

}
