
import { loadCement, loadCementSuccess, loadCementFailure } from './cement.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { UpdateCementDto } from '../../shared/models/cement/update-cement-dto';

export const cementFeatureKey = 'cement';

export interface State {
    cement?: UpdateCementDto;
}

export const initialState: State = {};

const cementReducer = createReducer(
    initialState,
    on(loadCement, (state) => setCement(state, null)),
    on(loadCementSuccess, (state, { data }) => setCement(state, data)),
    on(loadCementFailure, (state) => setCement(state, null))
);

function setCement(state: State, data: UpdateCementDto) {
    return {
        ...state,
        cement: data,
    };
}

export function reducer(state: State | undefined, action: Action) {
    return cementReducer(state, action);
}
