import { DocumentDto } from './documentDto';
import { CorrespondenceType } from '../enums/correspondence-type.enum';
import * as moment from 'moment';
import { environment } from 'project/src/environments/environment';

export class CorrespondenceDto {
  constructor(data: CorrespondenceDto) {
    if (data != null) {
      this.id = data.id;
      this.positionNumber = data.positionNumber;
      this.clientId = data.clientId;
      this.clientName = data.clientName;
      this.receivedDate = moment(data.receivedDate).format(environment.apiDateFormat);
      this.releaseDate = moment(data.releaseDate).format(environment.apiDateFormat);

      this.statusId = data.statusId;
      this.statusName = data.statusName;
      this.sourceId = data.sourceId;
      this.sourceName = data.sourceName;
      this.departmentId = data.departmentId;
      this.departmentName = data.departmentName;
      this.description = data.description;
      this.employeeId = data.employeeId;
      this.employeeName = data.employeeName;
      this.authorId = data.authorId;
      this.authorName = data.authorName;
      this.title = data.title;
      this.documents = data.documents;
      this.type = data.type;
    }
  }

  id: number;
  positionNumber?: number;
  clientId?: number;
  clientName?: string;
  receivedDate?: string;
  releaseDate?: string;
  statusId?: number;
  statusName?: string;
  sourceId?: number;
  sourceName?: string;
  departmentId?: number;
  departmentName?: string;
  description?: string;
  employeeId?: number;
  employeeName?: string;
  authorId?: number;
  authorName?: string;
  title?: string;
  documents: DocumentDto[];
  type: CorrespondenceType;
}
