import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { map } from 'rxjs/operators';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableCarsQuery } from '../models/queries/get-pageable-cars.query';
import { CarDto } from '../models/cars/carDTO';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { SelectModel } from '../models/select-model';
import { CarSelectModel } from '../models/carSelectModel';
import { GetCarsQuery } from '../models/cars/get-cars.query';
import { Data } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CarsApiCallerService extends BaseApiCaller {
  protected controllerPath = 'Cars';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getCars() {
    return this.get<CarDto[]>('');
  }

  public getFilteredCars(query: GetCarsQuery) {
    const params = this.prepareParamsWithList(query);
    return this.get<CarDto[]>('', { params })
  }

  public addCar(dto: CarDto) {
    return this.post('', dto);
  }

  public updateCar(dto: CarDto) {
    return this.put('', dto);
  }

  public deleteCar(id: string) {
    return this.delete(id.toString());
  }

  public getCar(id: string) {
    return this.get<CarDto>(id.toString()).pipe(map((_) => new CarDto(_)));
  }

  public getPageableCars(query: GetPageableCarsQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<CarDto>>('pageable', { params });
  }

  public getCarTypesForSelect(): Observable<SelectModel<string>[]> {
    return this.get<SelectModel<string>[]>('getCarTypesForSelect');
  }

  public getCarTypesPump(typeIds: Array<string>): Observable<CarSelectModel<string>[]> {
    const params = this.prepareParamsWithList({typeIds});
    return this.get<CarSelectModel<string>[]>('getCarTypesPump', {params} );
  }

  public getCarsByNodeAndType(NodeId: string, TypeId: string) {
    return this.get<CarDto[]>('forNodeAndType?NodeId=' + NodeId + '&TypeId=' + TypeId);

    // this.getIdentityFullPath('email-user-unique?email=' + email + '&userId=' + userId)
  }

  public getFreeConcretemixer(NodeId: string, DateFrom: string, DateTo: string, OrderId: string = "") {
    let query = 'getConcreteMixer/' + NodeId + '/' + DateFrom + '/' + DateTo;
    
    if (OrderId) {
      query += '?OrderId=' + OrderId;
    }

    return this.get<CarDto[]>(query);
  }

  public getRegistrationNumbersForCustomerForSelect(customerId: string, word: string): Observable<string[]> {
    const params = this.prepareParams({customerId: customerId,word: word});
    return this.get<string[]>('getRegistrationNumbersForCustomerForSelect', {params});
  }
}
