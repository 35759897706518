import { Action, createReducer, on } from '@ngrx/store';
import { ItemsModel } from '../../shared/models/items/ItemsModel';
import {
  loadItems,
  loadItemsFailure,
  loadItemsProduct,
  loadItemsProductFailure,
  loadItemsProductSuccess,
  loadItemsService,
  loadItemsServiceFailure,
  loadItemsServiceSuccess,
  loadItemsSuccess,
  addItem,
  addItemSuccess,
  addItemFailure,
  loadPageableItems,
  loadPageableItemsFailure,
  loadPageableItemsSuccess,
  setPageableItemsFilters,
  setPageableItemsFiltersSuccess,
  getItem,
  getItemSuccess,
  getItemFailure,
  loadItemTypesForSelect,
  loadItemTypesForSelectSuccess,
  loadItemTypesForSelectFailure,
  getItemsByNodeId,
  getItemsByNodeIdSuccess,
  getItemsByNodeIdFailure,
  getItemsByCustomerId,
  getItemsByCustomerIdSuccess,
  getItemsByCustomerIdFailure,
  loadItemsForSelect,
  loadItemsForSelectSuccess,
  loadItemsForSelectFailure,
} from './items.actions';

export const customerFeatureKey = 'items';
export const ItemsFeatureKey = 'Items';

export interface State {
  items?: ItemsModel[];
  itemsProduct?: ItemsModel[];
  itemsService?: ItemsModel[];
}

import { PageableDto } from '../../shared/models/pageable.dto';
import {} from './items.actions';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { GetPageableItemsQuery } from '../../shared/models/queries/get-pageable-items.query';
import { SelectModel } from '../../shared/models/select-model';
import { ItemDto } from '../../shared/models/items/itemDTO';
import { ItemByNode } from '../../shared/models/items/itemByNodeModel';
import { ItemByCustomerId } from '../../shared/models/items/itemByCustomerModel';

export interface State {
  pageableItems?: DictionaryDto<string, PageableDto<ItemDto>>;
  itemsFilters?: DictionaryDto<string, GetPageableItemsQuery>;
  item?: ItemDto;
  carTypesForSelect?: SelectModel<string>[];
  itemsByNode?: ItemByNode[];
  itemsByCustomer?: ItemByCustomerId[];

  itemsForSelect?: SelectModel<string>[];
}

export const initialState: State = {
  itemsFilters: new DictionaryDto<string, GetPageableItemsQuery>(),
};

const carsReducer = createReducer(
  initialState,

  on(loadItems, (state) => state),
  on(loadItemsSuccess, (state, { data }) => setItems(state, data)),
  on(loadItemsFailure, (state) => setItems(state, null)),

  on(loadItemsProduct, (state) => state),
  on(loadItemsProductSuccess, (state, { data }) => setItemsProduct(state, data)),
  on(loadItemsProductFailure, (state) => setItemsProduct(state, null)),

  on(loadItemsService, (state) => state),
  on(loadItemsServiceSuccess, (state, { data }) => setItemsService(state, data)),
  on(loadItemsServiceFailure, (state) => setItemsService(state, null)),

  on(addItem, (state) => setAddItem(state, null)),
  on(addItemSuccess, (state) => setAddItem(state, null)),
  on(addItemFailure, (state) => setAddItem(state, null)),

  on(loadPageableItems, (state, { key }) => setPageableItems(state, null, key)),
  on(loadPageableItemsSuccess, (state, { data, key }) => setPageableItems(state, data, key)),
  on(loadPageableItemsFailure, (state, action) => state),

  on(getItem, (state) => setItem(state, null)),
  on(getItemSuccess, (state, { data }) => setItem(state, data)),
  on(getItemFailure, (state, action) => state),

  on(setPageableItemsFilters, (state, { data, key }) => setPageableItemsFiltersFunc(state, null, key)),
  on(setPageableItemsFiltersSuccess, (state, { data, key }) => setPageableItemsFiltersFunc(state, data, key)),

  on(loadItemTypesForSelect, (state) => setItemTypesForSelect(state, null)),
  on(loadItemTypesForSelectSuccess, (state, { data }) => setItemTypesForSelect(state, data)),
  on(loadItemTypesForSelectFailure, (state, action) => state),

  on(getItemsByNodeId, (state) => state),
  on(getItemsByNodeIdSuccess, (state, { data }) => setItemsByNode(state, data)),
  on(getItemsByNodeIdFailure, (state) => setItemsByNode(state, null)),

  on(getItemsByCustomerId, (state) => state),
  on(getItemsByCustomerIdSuccess, (state, { data }) => setItemsByCustomer(state, data)),
  on(getItemsByCustomerIdFailure, (state) => setItemsByCustomer(state, null)),

  on(loadItemsForSelect, (state) => setItemsForSelect(state, null)),
  on(loadItemsForSelectSuccess, (state, { data }) => setItemsForSelect(state, data)),
  on(loadItemsForSelectFailure, (state, action) => state)
  
);

function setItemsForSelect(state: State, data: SelectModel<string>[]) {
  const itemsForSelect = data;
  return {
    ...state,
    itemsForSelect,
  };
}

function setItems(state: State, data: ItemsModel[]) {
  return {
    ...state,
    items: data,
  };
}

function setItemsByNode(state: State, data: ItemByNode[]) {
  return {
    ...state,
    itemsByNode: data,
  };
}

function setItemsByCustomer(state: State, data: ItemByCustomerId[]) {
  return {
    ...state,
    itemsByCustomer: data,
  };
}

function setItemsProduct(state: State, data: ItemsModel[]) {
  return {
    ...state,
    itemsProduct: data,
  };
}

function setItemsService(state: State, data: ItemsModel[]) {
  return {
    ...state,
    itemsService: data,
  };
}

function setPageableItems(state: State, data: PageableDto<ItemDto>, key: string) {
  const pageableItems = Array.isArray(state.pageableItems)
    ? state.pageableItems
    : new DictionaryDto<string, PageableDto<ItemDto>>();
  const orders = pageableItems.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableItems.push({ key, value: data });
  }

  return {
    ...state,
    pageableItems,
  };
}

function setItem(state: State, data: ItemDto) {
  var item = data;
  return {
    ...state,
    item,
  };
}

function setAddItem(state: State, attachmentsProgress: ItemDto[]) {
  return {
    ...state,
    attachmentsProgress,
  };
}

function setPageableItemsFiltersFunc(state: State, data: GetPageableItemsQuery, key: string) {
  const carsFilters = Array.isArray(state.itemsFilters)
    ? state.itemsFilters
    : new DictionaryDto<string, GetPageableItemsQuery>();
  const filter = carsFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    carsFilters.push({ key, value: data });
  }

  return {
    ...state,
    carsFilters,
  };
}

function setItemTypesForSelect(state: State, data: SelectModel<string>[]) {
  const carTypesForSelect = data;
  return {
    ...state,
    carTypesForSelect,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return carsReducer(state, action);
}
