import { createAction, props } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableIncomingMessagesQuery } from '../../shared/models/queries/get-pageable-incoming-messages.query';
import { AttachmentProgressDto } from '../../shared/models/attachment/attachment-progress.dto';
import { FileUploadDto } from '../../shared/models/correspondence/FileUploadDto';
import { CorrespondenceDto } from '../../shared/models/correspondence/correspondenceDTO';
``

export const addIncomingMessage = createAction('[IncomingMessages] Add IncomingMessage', props<{ data: CorrespondenceDto, documents?: FileUploadDto[] }>());
export const addIncomingMessageSuccess = createAction('[IncomingMessages] Add IncomingMessage Success', props<{ data: any }>());
export const addIncomingMessageFailure = createAction('[IncomingMessages] Add IncomingMessage Failure', props<{ error: any }>());

export const updateIncomingMessage = createAction('[IncomingMessages] Add IncomingMessages', props<{ data: CorrespondenceDto, documents?: FileUploadDto[] }>());
export const updateIncomingMessageSuccess = createAction('[IncomingMessages] Add IncomingMessages Success');
export const updateIncomingMessageFailure = createAction('[IncomingMessages] Add IncomingMessages Failure', props<{ error: any }>());

export const deleteIncomingMessage = createAction('[IncomingMessages] delete IncomingMessage', props<{ id: number }>());
export const deleteIncomingMessageSuccess = createAction('[IncomingMessages] delete IncomingMessage Success', props<{ id: number }>());
export const deleteIncomingMessageFailure = createAction('[IncomingMessages] delete IncomingMessage Failure', props<{ error: any }>());

export const getIncomingMessage = createAction('[IncomingMessages] get IncomingMessage', props<{ id: number }>());
export const getIncomingMessageSuccess = createAction('[IncomingMessages] get IncomingMessage Success', props<{ data: CorrespondenceDto }>());
export const getIncomingMessageFailure = createAction('[IncomingMessages] get IncomingMessage Failure', props<{ error: any }>());

export const loadPageableIncomingMessages = createAction('[IncomingMessages] Load Pageable IncomingMessages', props<{ key: string }>());
export const loadPageableIncomingMessagesSuccess = createAction('[IncomingMessages] Load Pageable IncomingMessages Success', props<{ data: PageableDto<CorrespondenceDto>; key: string }>());
export const loadPageableIncomingMessagesFailure = createAction('[IncomingMessages] Load Pageable IncomingMessages Failure', props<{ error: any }>());

export const setPageableIncomingMessagesFilters = createAction('[IncomingMessages] Set Pageable IncomingMessages Filters', props<{ data?: GetPageableIncomingMessagesQuery; key: string }>());
export const setPageableIncomingMessagesFiltersSuccess = createAction('[IncomingMessages] Set Pageable IncomingMessages Filters Success', props<{ data: GetPageableIncomingMessagesQuery; key: string }>());
export const setPageableIncomingMessagesFiltersFailure = createAction('[IncomingMessages] Set Pageable IncomingMessages Filters Failure', props<{ error: any }>());

export const addIncomingMessageDocuments = createAction('[IncomingMessages] Add IncomingMessage Documents',  props<{ data: FileUploadDto[]; incomingMessageId: number }>());
export const addIncomingMessageDocumentProgress = createAction('[IncomingMessages] Add IncomingMessage Documents Progress', props<{ info: AttachmentProgressDto; incomingMessageId: number }>());
export const addIncomingMessageDocumentsScheduled = createAction('[IncomingMessages] Add IncomingMessage Documents Scheduled');
export const addIncomingMessageDocumentsFailure = createAction('[IncomingMessages] Add IncomingMessage Documents Failure', props<{ error: any }>());

export const deleteIncomingMessageDocument = createAction('[IncomingMessages] delete IncomingMessage Document', props<{ id: number }>());
export const deleteIncomingMessageDocumentSuccess = createAction('[IncomingMessages] delete IncomingMessage Document Success', props<{ id: number }>());
export const deleteIncomingMessageDocumentFailure = createAction('[IncomingMessages] delete IncomingMessage Document Failure', props<{ error: any }>());