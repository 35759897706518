import { Action, createReducer, on } from '@ngrx/store';
import { ConsistencyDto } from '../../shared/models/consistency/update-consistency-dto';
import { loadConsistency, loadConsistencySuccess, loadConsistencyFailure } from './consistency.actions';


export const consistencyFeatureKey = 'consistency';

export interface State {
  consistency?: ConsistencyDto;
}

export const initialState: State = {};

const consistencyReducer = createReducer(
  initialState,
  on(loadConsistency, (state) => setConsistency(state, null)),
  on(loadConsistencySuccess, (state, { data }) => setConsistency(state, data)),
  on(loadConsistencyFailure, (state) => setConsistency(state, null))
);

function setConsistency(state: State, data: ConsistencyDto) {
  return {
    ...state,
    consistency: data,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return consistencyReducer(state, action);
}
