import { createAction, props } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableSpecialAdditivesQuery } from '../../shared/models/queries/get-pageable-special-additives.query';
import { SpecialAdditivesDto } from '../../shared/models/special-additives/specialAdditives.Dto';
import { SpecialAdditivesSelectModel } from '../../shared/models/specialAdditivesSelectModel';

export const loadSpecialAdditivesForSelect = createAction(
  '[SpecialAdditives] Load SpecialAdditives For Select',
  props<{ isForHigherClass: boolean|null, customerId: string }>()
);
export const loadSpecialAdditivesForSelectSuccess = createAction(
  '[SpecialAdditives] Load SpecialAdditives For Select Success',
  props<{ data: SpecialAdditivesSelectModel<any>[] }>()
);
export const loadSpecialAdditivesForSelectFailure = createAction(
  '[SpecialAdditives] Load SpecialAdditives For Select Failure',
  props<{ error: any }>()
);

export const setPageableSpecialAdditivesFilters = createAction(
  '[SpecialAdditives] Set Pageable SpecialAdditives Filters',
  props<{ data?: GetPageableSpecialAdditivesQuery; key: string }>()
);
export const setPageableSpecialAdditivesFiltersSuccess = createAction(
  '[SpecialAdditives] Set Pageable SpecialAdditives Filters Success',
  props<{ data: GetPageableSpecialAdditivesQuery; key: string }>()
);
export const setPageableSpecialAdditivesFiltersFailure = createAction(
  '[SpecialAdditives] Set Pageable SpecialAdditives Filters Failure',
  props<{ error: any }>()
);

export const loadPageableSpecialAdditives = createAction(
  '[SpecialAdditives] Load Pageable SpecialAdditives',
  props<{ key: string }>()
);
export const loadPageableSpecialAdditivesSuccess = createAction(
  '[SpecialAdditives] Load Pageable SpecialAdditives Success',
  props<{ data: PageableDto<SpecialAdditivesSelectModel<any>>; key: string }>()
);
export const loadPageableSpecialAdditivesFailure = createAction(
  '[SpecialAdditives] Load Pageable SpecialAdditives Failure',
  props<{ error: any }>()
);

export const getSpecialAdditives = createAction('[SpecialAdditives] get SpecialAdditives', props<{ id: string }>());
export const getSpecialAdditivesSuccess = createAction(
  '[SpecialAdditives] get SpecialAdditives Success',
  props<{ data: SpecialAdditivesDto }>()
);
export const getSpecialAdditivesFailure = createAction(
  '[SpecialAdditives] get SpecialAdditives Failure',
  props<{ error: any }>()
);
