//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadDeliveryItemsForDate,
  loadDeliveryItemsForDateSuccess,
  loadDeliveryItemsForDateFailure,
  deleteDeliveryItem,
  deleteDeliveryItemSuccess,
  deleteDeliveryItemFailure,
  addDeliveryItem,
  addDeliveryItemSuccess,
  addDeliveryItemFailure,
  getDeliveryItem,
  getDeliveryItemSuccess,
  getDeliveryItemFailure,
  updateDeliveryItemSuccess,
  updateDeliveryItemFailure,
  updateDeliveryItem
} from './delivery-calendar.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
//import { DeliveryItemsApiCallerService } from '../../shared/api-services/employees-api-caller.service';
//import { selectAllPageableDeliveryItemsFilters } from './employees.selector';
import { IdentityUsersApiCallerService } from '../../shared/api-services/identity-users-api-caller.service';
import { IdentityRolesApiCallerService } from '../../shared/api-services/identity-roles-api-caller.service';
import { DeliveryCalendarApiCallerService } from '../../shared/api-services/delivery-calendar-api-caller.service';

@Injectable()
export class DeliveryCalendarEffects {

  private latestedDeliveryItemsKey: string;
 
  // addDeliveryItem$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(addDeliveryItem),
  //     concatMap(({ data }) => {
  //       return this.identityApiCaller.createOrUpdateUser(data).pipe(
  //         map((_) => addDeliveryItemSuccess()),
  //         tap(() => this.reloadDeliveryItems(this.latestedDeliveryItemsKey)),
  //         catchError((error) => of(addDeliveryItemFailure({ error })))
  //       );
  //     })
  //   )
  // );

  // getDeliveryItem$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(getDeliveryItem),
  //     concatMap(({ id }) => {
  //       return this.identityApiCaller.getUser(id).pipe(
  //         map((_) => getDeliveryItemSuccess({ data: _ })),
  //         catchError((error) => of(getDeliveryItemFailure({ error })))
  //       );
  //     })
  //   )
  // );

  // deleteDeliveryItem$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(deleteDeliveryItem),
  //     concatMap(({ id }) => {
  //       return this.identityApiCaller.deleteUser(id).pipe(
  //         map((_) => deleteDeliveryItemSuccess({ id: id })),
  //         tap(() => this.reloadDeliveryItems(this.latestedDeliveryItemsKey)),
  //         catchError((error) => of(deleteDeliveryItemFailure({ error })))
  //       );
  //     })
  //   )
  // );

  // setPageableDeliveryItemsFilters$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(setPageableDeliveryItemsFilters),
  //     concatMap(({ data, key }) => {
  //       this.latestedDeliveryItemsKey = key;
  //       return of(data).pipe(
  //         map((_) => setPageableDeliveryItemsFiltersSuccess({ data, key })),
  //         finalize(() => this.reloadDeliveryItems(key)),
  //         catchError((error) => of(setPageableDeliveryItemsFiltersFailure({ error })))
  //       );
  //     })
  //   )
  // );

  getDeliveryItemsForDate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(loadDeliveryItemsForDate),
    concatMap((data) => {
      return this.deliveryApiCaller.getDeliveryCalendarItemsForDate(data.date,data.nodes).pipe(
        map((_) => loadDeliveryItemsForDateSuccess({ data: _ })),
        catchError((error) => of(loadDeliveryItemsForDateFailure({ error })))
      );
    })
  )
);

  // private reloadDeliveryItems(key: string) {
  //   this.store$.dispatch(loadDeliveryItemsForSelect());
  // }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private deliveryApiCaller: DeliveryCalendarApiCallerService,
    private router: Router,
  ) { }

}
