import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';

export const loadCTypesForSelect = createAction('[FormsData] Load CTypes For Select');
export const loadCTypesForSelectSuccess = createAction('[FormsData] Load CTypes For Select Success', props<{ data: SelectModel<number>[] }>());
export const loadCTypesForSelectFailure = createAction('[FormsData] Load CTypes For Select Failure', props<{ error: any }>());

export const loadPageableCTypes = createAction('[FormsData] Load Pageable CTypes');
export const loadPageableCTypesSuccess = createAction('[FormsData] Load Pageable CTypes Success', props<{ data: PageableDto<SelectModel<number>> }>());
export const loadPageableCTypesFailure = createAction('[FormsData] Load Pageable CTypes Failure', props<{ error: any }>());

export const deleteCType = createAction('[FormsData] delete CType', props<{ id: number }>());
export const deleteCTypeSuccess = createAction('[FormsData] delete CType Success', props<{ id: number }>());
export const deleteCTypeFailure = createAction('[FormsData] delete CType Failure', props<{ error: any }>());

export const addCType = createAction('[FormsData] Add CType', props<{ data: SelectModel<number> }>());
export const addCTypeSuccess = createAction('[FormsData] Add CType Success');
export const addCTypeFailure = createAction('[FormsData] Add CType Failure', props<{ error: any }>());

export const updateCType = createAction('[FormsData] Update CType', props<{ data: SelectModel<number> }>());
export const updateCTypeSuccess = createAction('[FormsData] Update CType Success');
export const updateCTypeFailure = createAction('[FormsData] Update CType Failure', props<{ error: any }>());

export const getCType = createAction('[FormsData] Get CType', props<{ id: number }>());
export const getCTypeSuccess = createAction('[FormsData] Get CType Success', props<{ data: SelectModel<number> }>());
export const getCTypeFailure = createAction('[FormsData] Get CType Failure', props<{ error: any }>());

