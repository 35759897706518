//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadDepartmentsForSelect,
  loadDepartmentsForSelectSuccess,
  loadDepartmentsForSelectFailure,
  loadPageableDepartments,
  loadPageableDepartmentsSuccess,
  loadPageableDepartmentsFailure,
  deleteDepartment,
  deleteDepartmentFailure,
  deleteDepartmentSuccess,
  addDepartment,
  addDepartmentSuccess,
  addDepartmentFailure,
  getDepartment,
  getDepartmentSuccess,
  getDepartmentFailure,
  updateDepartment,
  updateDepartmentSuccess,
  updateDepartmentFailure,
  setPageableDepartmentsFiltersSuccess,
  setPageableDepartmentsFiltersFailure,
  setPageableDepartmentsFilters,
} from './departments.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { DepartmentsApiCallerService } from '../../shared/api-services/departments-api-caller.service';
import { selectAllPageableDepartmentsFilters } from './departments.selector';

@Injectable()
export class DepartmentsEffects {

  private latestedDepartmentsKey: string;

  addDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addDepartment),
      concatMap(({ data }) => {
        return this.departmentsApiCaller.addDepartment(data).pipe(
          map((_) => addDepartmentSuccess({ id: _, name: data.name })),
          tap(() => this.reloadDepartments(this.latestedDepartmentsKey)),
          catchError((error) => of(addDepartmentFailure({ error })))
        );
      })
    )
  );

  updateDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDepartment),
      concatMap(({ data }) => {
        return this.departmentsApiCaller.updateDepartment(data).pipe(
          map((_) => updateDepartmentSuccess()),
          catchError((error) => of(updateDepartmentFailure({ error })))
        );
      })
    )
  );

  loadDepartmentsForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDepartmentsForSelect),
      concatMap(() => {
        return this.departmentsApiCaller.getDepartmentsForSelect().pipe(
          map((_) => loadDepartmentsForSelectSuccess({ data: _ })),
          catchError((error) => of(loadDepartmentsForSelectFailure({ error })))
        );
      })
    )
  );

  loadPageableDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableDepartments),
      withLatestFrom(this.store$.select(selectAllPageableDepartmentsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedDepartmentsKey = key;
        return this.departmentsApiCaller.getPageableDepartments(filter.value).pipe(
          map((_) => loadPageableDepartmentsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableDepartmentsFailure({ error })))
        );
      })
    )
  );

  getDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDepartment),
      concatMap(({ id }) => {
        return this.departmentsApiCaller.getDepartment(id).pipe(
          map((_) => getDepartmentSuccess({ data: _ })),
          catchError((error) => of(getDepartmentFailure({ error })))
        );
      })
    )
  );

  deleteDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDepartment),
      concatMap(({ id }) => {
        return this.departmentsApiCaller.deleteDepartment(id).pipe(
          map((_) => deleteDepartmentSuccess({ id: id })),
          tap(() => this.reloadDepartments(this.latestedDepartmentsKey)),
          catchError((error) => of(deleteDepartmentFailure({ error })))
        );
      })
    )
  );

  setPageableDepartmentsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableDepartmentsFilters),
      concatMap(({ data, key }) => {
        this.latestedDepartmentsKey = key;
        return of(data).pipe(
          map((_) => setPageableDepartmentsFiltersSuccess({ data, key })),
          finalize(() => this.reloadDepartments(key)),
          catchError((error) => of(setPageableDepartmentsFiltersFailure({ error })))
        );
      })
    )
  );
 
  private reloadDepartments(key: string) {
    this.store$.dispatch(loadDepartmentsForSelect());
    this.store$.dispatch(loadPageableDepartments({key}));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private departmentsApiCaller: DepartmentsApiCallerService,
    private router: Router,
  ) { }

}
