import { createAction, props } from '@ngrx/store';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableOutgoingMessagesQuery } from '../../shared/models/queries/get-pageable-outgoing-messages.query';
import { CorrespondenceDto } from '../../shared/models/correspondence/correspondenceDTO';
import { FileUploadDto } from '../../shared/models/correspondence/FileUploadDto';

export const addOutgoingMessage = createAction(
  '[OutgoingMessages] Add OutgoingMessage',
  props<{ data: CorrespondenceDto; documents?: FileUploadDto[] }>()
);
export const addOutgoingMessageSuccess = createAction('[OutgoingMessages] Add OutgoingMessage Success');
export const addOutgoingMessageFailure = createAction(
  '[OutgoingMessages] Add OutgoingMessage Failure',
  props<{ error: any }>()
);

export const updateOutgoingMessage = createAction(
  '[OutgoingMessages] Add OutgoingMessages',
  props<{ data: CorrespondenceDto; documents?: FileUploadDto[] }>()
);
export const updateOutgoingMessageSuccess = createAction('[OutgoingMessages] Add OutgoingMessages Success');
export const updateOutgoingMessageFailure = createAction(
  '[OutgoingMessages] Add OutgoingMessages Failure',
  props<{ error: any }>()
);

export const deleteOutgoingMessage = createAction('[OutgoingMessages] delete OutgoingMessage', props<{ id: number }>());
export const deleteOutgoingMessageSuccess = createAction(
  '[OutgoingMessages] delete OutgoingMessage Success',
  props<{ id: number }>()
);
export const deleteOutgoingMessageFailure = createAction(
  '[OutgoingMessages] delete OutgoingMessage Failure',
  props<{ error: any }>()
);

export const sendOutgoingMessage = createAction(
  '[OutgoingMessages] send OutgoingMessage',
  props<{ id: number; key: string }>()
);
export const sendOutgoingMessageSuccess = createAction('[OutgoingMessages] send OutgoingMessageSuccess');
export const sendOutgoingMessageFailure = createAction(
  '[OutgoingMessages] send OutgoingMessageFailure',
  props<{ error: any }>()
);

export const getOutgoingMessage = createAction('[OutgoingMessages] get IncomingMessage', props<{ id: number }>());
export const getOutgoingMessageSuccess = createAction(
  '[OutgoingMessages] get IncomingMessage Success',
  props<{ data: CorrespondenceDto }>()
);
export const getOutgoingMessageFailure = createAction(
  '[OutgoingMessages] get IncomingMessage Failure',
  props<{ error: any }>()
);

export const loadPageableOutgoingMessages = createAction(
  '[OutgoingMessages] Load Pageable OutgoingMessages',
  props<{ key: string }>()
);

export const loadPageableOutgoingMessagesSuccess = createAction(
  '[OutgoingMessages] Load Pageable OutgoingMessages Success',
  props<{ data: PageableDto<CorrespondenceDto>; key: string }>()
);

export const loadPageableOutgoingMessagesFailure = createAction(
  '[OutgoingMessages] Load Pageable OutgoingMessages Failure',
  props<{ error: any }>()
);

export const setPageableOutgoingMessagesFilters = createAction(
  '[OutgoingMessages] Set Pageable OutgoingMessages Filters',
  props<{ data?: GetPageableOutgoingMessagesQuery; key: string }>()
);

export const setPageableOutgoingMessagesFiltersSuccess = createAction(
  '[OutgoingMessages] Set Pageable OutgoingMessages Filters Success',
  props<{ data: GetPageableOutgoingMessagesQuery; key: string }>()
);

export const setPageableOutgoingMessagesFiltersFailure = createAction(
  '[OutgoingMessages] Set Pageable OutgoingMessages Filters Failure',
  props<{ error: any }>()
);
