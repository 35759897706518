import { Action, createReducer, on } from '@ngrx/store';
import {
  loadAuthorsForSelect,
  loadAuthorsForSelectSuccess,
  loadAuthorsForSelectFailure,
  loadPageableAuthors,
  loadPageableAuthorsSuccess,
  loadPageableAuthorsFailure,
  getAuthor,
  getAuthorSuccess,
  getAuthorFailure,
  setPageableAuthorsFilters,
  setPageableAuthorsFiltersSuccess,
  addAuthorSuccess,
} from './authors.actions';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { AuthorDto } from '../../shared/models/dictionaries/authorDTO';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { GetPageableAuthorsQuery } from '../../shared/models/queries/get-pageable-authors.query';
import { omit } from '@ngrx/store/src/utils';

export const authorsFeatureKey = 'authors';

export interface State {
  authorsForSelect?: SelectModel<number>[];
  pageableAuthors?: DictionaryDto<string, PageableDto<AuthorDto>>;
  author?: AuthorDto;
  authorsFilters?: DictionaryDto<string, GetPageableAuthorsQuery>;
  authorAdded?: { id: number; name: string };
}

export const initialState: State = {
  authorsFilters: new DictionaryDto<string, GetPageableAuthorsQuery>(),
};

const usersReducer = createReducer(
  initialState,

  on(loadAuthorsForSelect, (state) => setAuthorsForSelect(state, null)),
  on(loadAuthorsForSelectSuccess, (state, { data }) => setAuthorsForSelect(state, data)),
  on(loadAuthorsForSelectFailure, (state, action) => state),

  on(loadPageableAuthors, (state, { key }) => setPageableAuthors(state, null, key)),
  on(loadPageableAuthorsSuccess, (state, { data, key }) => setPageableAuthors(state, data, key)),
  on(loadPageableAuthorsFailure, (state, action) => state),

  on(getAuthor, (state) => setAuthor(state, null)),
  on(getAuthorSuccess, (state, { data }) => setAuthor(state, data)),
  on(getAuthorFailure, (state, action) => state),

  on(setPageableAuthorsFilters, (state, { data, key }) => setPageableAuthorsFiltersFunc(state, null, key)),
  on(setPageableAuthorsFiltersSuccess, (state, { data, key }) => setPageableAuthorsFiltersFunc(state, data, key)),

  on(addAuthorSuccess, (state, data) => setAddAuthor(state, data))
);

function setAuthorsForSelect(state: State, data: SelectModel<number>[]) {
  const authorsForSelect = data;
  return {
    ...state,
    authorsForSelect,
  };
}

function setAddAuthor(state: State, authorAdded) {
  return {
    ...state,
    authorAdded,
  };
}

function setPageableAuthors(state: State, data: PageableDto<AuthorDto>, key: string) {
  const pageableAuthors = Array.isArray(state.pageableAuthors)
    ? state.pageableAuthors
    : new DictionaryDto<string, PageableDto<AuthorDto>>();
  const orders = pageableAuthors.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableAuthors.push({ key, value: data });
  }

  return {
    ...state,
    pageableAuthors,
  };
}

function setAuthor(state: State, data: AuthorDto) {
  var author = data;
  return {
    ...state,
    author,
  };
}

function setPageableAuthorsFiltersFunc(state: State, data: GetPageableAuthorsQuery, key: string) {
  const authorsFilters = Array.isArray(state.authorsFilters)
    ? state.authorsFilters
    : new DictionaryDto<string, GetPageableAuthorsQuery>();
  const filter = authorsFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    authorsFilters.push({ key, value: data });
  }

  return {
    ...state,
    authorsFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
