import * as moment from 'moment';
import { DateRanges } from '../models/enums/date-ranges.enum';
import { DateRange } from '../interfaces/i-date-range';
import { environment } from 'project/src/environments/environment';

export class DateHelper {
  public static greaterThan(a: Date, b: Date, translation = 0) {
    return moment(a).add(translation, 'day').isAfter(b);
  }

  public static today(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public static tomorrow(): Date {
    const momentDate = moment(this.today());
    const date = momentDate.add(1, 'days');

    return date.toDate();
  }

  public static lastDayOfWeek(): Date {
    const momentDate = moment(this.today());
    const date = momentDate.weekday(5);

    return date.toDate();
  }

  public static lastDayOfNextWeek(): Date {
    const momentDate = moment(this.today());
    const date = momentDate.weekday(12);

    return date.toDate();
  }

  public static firstDayOfMonth(): Date {
    const momentDate = moment(this.today());
    const date = momentDate.startOf('month');

    return date.toDate();
  }

  public static lastDayOfMonth(): Date {
    const momentDate = moment(this.today());
    const date = momentDate.endOf('month');

    return date.toDate();
  }

  public static firstDayOfWeek(): Date {
    const momentDate = moment(this.today());
    const date = momentDate.startOf('week');

    return date.toDate();
  }

  public static endOfDay(date: Date): Date {
    const momentDate = moment(date);

    return momentDate.endOf('day').toDate();
  }

  public static toDateString(date: Date | string): string {
    return moment(date).format(environment.displayDateFormat);
  }

  public static isEndOfWeek() {
    return moment(new Date()).weekday() >= 5;
  }

  public static getDefaultDateRange(): DateRange {
    return this.isEndOfWeek() ? this.getDateRange(DateRanges.ToNextWeekEnd) : this.getDateRange(DateRanges.ToWeekEnd);
  }

  public static getDateRange(range: DateRanges): DateRange {
    let result: DateRange;
    switch (range) {
      case DateRanges.Today:
        result = {
          dateFrom: moment(new Date()).add(1, 'hour').toDate(),
          dateTo: this.today(),
        };
        break;
      case DateRanges.ToTommorow:
        result = {
          dateFrom: this.tomorrow(),
          dateTo: this.tomorrow(),
        };
        break;

      case DateRanges.ToWeekEnd:
        const dateFrom = this.tomorrow();
        const dateTo = this.lastDayOfWeek();
        result = {
          dateFrom: dateFrom > dateTo ? moment(new Date()).add(1, 'hour').toDate() : dateFrom,
          dateTo: dateTo,
        };
        break;

      case DateRanges.ToMonthEnd:
        result = {
          dateFrom: this.tomorrow(),
          dateTo: this.lastDayOfMonth(),
        };
        break;

      case DateRanges.ToNextWeekEnd:
        result = {
          dateFrom: this.tomorrow(),
          dateTo: this.lastDayOfNextWeek(),
        };
        break;

      case DateRanges.ThisMonth:
        result = {
          dateFrom: this.firstDayOfMonth(),
          dateTo: this.lastDayOfMonth(),
        };
        break;

      case DateRanges.ThisWeek:
        result = {
          dateFrom: this.firstDayOfWeek(),
          dateTo: this.lastDayOfWeek(),
        };
        break;

      default:
        throw new Error(`Value ${range} not implemented.`);
    }
    return { ...result, dateTo: this.endOfDay(result.dateTo) };
  }
}
