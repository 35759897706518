import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { OrderApiCallerService } from '../shared/api-services/orders/order-api-caller.service';
import { OrderStatusesForHour } from '../shared/models/orders/ordersForHourRange';
import { OrderStatusModel } from '../shared/models/orders/orderStatus.model';
declare var $: any;
@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.scss'],
})
export class ChangeStatusComponent implements OnInit {
  content: string;
  title: string;
  statusesData: any;
  orderNumber: string;
  orderId: string;

  constructor(private orderApiService: OrderApiCallerService, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.statusesData = this.statusesData.filter((status) => status.id !== '00000000-0001-0000-0000-000000000005');
    $(document).ready(function () {
      let modalContent: any = $('.modal-content');
      modalContent.draggable({
        handle: '.modal-header',
        revert: false,
        //  revertDuration: 300,
      });
    });
  }

  onChangeStatus(option: OrderStatusModel) {
    console.log(option);
    const data = {
      id: this.orderId,
      orderStatusId: option.id.toString(),
    };
    this.orderApiService
      .updateOrderState(data)
      .pipe(
        finalize(() => {
          this.close();
        })
      )
      .subscribe();
  }

  close() {
    this.activeModal.close(this.content);
  }
}
