import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { IdentityUsersApiCallerService } from '../../../api-services/identity-users-api-caller.service';
import { CustomValidator } from '../../../validators/custom.validator';
import { ValidatorPatterns } from '../../../validators/validator-patterns';

export class ExternalEmployeeForm extends UntypedFormGroup {
  private accountApiCallerService: IdentityUsersApiCallerService;

  constructor() {
    super(
      {
        userId: new UntypedFormControl(null),
        userName: new UntypedFormControl(null, [Validators.required, Validators.pattern('[a-zA-Z0-9. ]*')]),
        name: new UntypedFormControl(null, [Validators.required, Validators.pattern(ValidatorPatterns.lastname)]),
        surname: new UntypedFormControl(null, [Validators.required, Validators.pattern(ValidatorPatterns.lastname)]),
        email: new UntypedFormControl(null, [Validators.required, Validators.pattern(ValidatorPatterns.email)]),
        password: new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(8),
          CustomValidator.uppercaseValidator,
          CustomValidator.lowercaseValidator,
          CustomValidator.numberValidator,
          CustomValidator.specialCharacterValidator,
        ]),
        phoneNumber: new UntypedFormControl(null, [Validators.required, Validators.pattern(ValidatorPatterns.phone)]),
        passwordConfirm: new UntypedFormControl(null, [Validators.required]),
        permissions: new UntypedFormControl([]),
        dataKey: new UntypedFormControl(null),
        roles: new UntypedFormControl(null),
        nodeId: new UntypedFormControl(null, [Validators.required]),
        customerId: new UntypedFormControl(null, [Validators.required]),
      },
      CustomValidator.passwordMatchValidator
    );
  }

  forAdd() {
    return this;
  }

  forAddEndEmailUnique(accountApiCallerService: IdentityUsersApiCallerService) {
    this.accountApiCallerService = accountApiCallerService;

    this.get('email').setAsyncValidators(this.createEmailUniqueValidator);

    this.get('userName').setAsyncValidators(this.noWhitespaceValidator);

    //  this.controls.noWhitespaceValidator();
    return this;
  }

  forAddEndEmailUniqueForExternalUsers(accountApiCallerService: IdentityUsersApiCallerService) {
    this.accountApiCallerService = accountApiCallerService;

    this.get('email').setAsyncValidators(this.createEmailUniqueValidator);

    this.get('userName').setAsyncValidators(this.noWhitespaceValidator);

    this.get('roles').setValue(['UzytkownikZewnetrzny']);
    this.get('roles').disable();

    //  this.controls.noWhitespaceValidator();
    return this;
  }

  private createEmailUniqueValidator: AsyncValidatorFn = (control: AbstractControl) => {
    if (control.value == null || control.value.length === 0) {
      return of(null);
    }
    return this.accountApiCallerService.emailByUserUnique(control.value, this.get('userId').value).pipe(
      map((_) => {
        const result = _ ? null : { isNotUnique: true };
        return result;
      })
    );
  };

  private noWhitespaceValidator: AsyncValidatorFn = (control: AbstractControl) => {
    if (control.value == null || control.value.length === 0) {
      return of(null);
    }
    const test = control.value;
    const isWhitespace = test.indexOf(' ') >= 0;
    const isValid = !isWhitespace;

    return of(isValid).pipe(
      map((_) => {
        const result = _ ? null : { whitespace: true };
        return result;
      })
    );
  };
}
