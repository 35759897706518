import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiCaller } from './base-api-caller';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { SelectModel } from '../models/select-model';
import { DepartmentDto } from '../models/dictionaries/departmentDTO';
import { GetPageableAuthorsQuery } from '../models/queries/get-pageable-authors.query';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsApiCallerService extends BaseApiCaller {
  protected controllerPath = 'organizationUnits';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addDepartment(dto: DepartmentDto) {
    return this.postWithReponseSimpleType<number>('', dto)
  }

  public updateDepartment(dto: DepartmentDto){
    return this.put('', dto);
  }

  public deleteDepartment(id: number) {
    return this.delete(id.toString());
  }

  public getDepartment(id: number): Observable<DepartmentDto>{
    return this.get<DepartmentDto>(id.toString()).pipe(map((_) => new DepartmentDto(_)));
  }

  public getPageableDepartments(query: GetPageableAuthorsQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<DepartmentDto>>('', { params });
  }

  public getDepartmentsForSelect(): Observable<SelectModel<number>[]> {
    return this.get<SelectModel<number>[]>('getForSelect');
  }
}
