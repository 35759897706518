import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableEmployeesQuery } from '../../shared/models/queries/get-pageable-employees.query';
import { IdentityPageableDto } from '../../shared/api-services/identity-users-api-caller.service';
import { EmployeeDto } from '../../shared/models/employees/employeeDTO';
import { UpdateEmployeeDto } from '../../shared/models/employees/updateEmployeeDTO';

export const loadEmployeesForSelect = createAction('[FormsData] Load Employees For Select');
export const loadEmployeesForSelectSuccess = createAction('[FormsData] Load Employees For Select Success', props<{ data: SelectModel<number>[] }>());
export const loadEmployeesForSelectFailure = createAction('[FormsData] Load Employees For Select Failure', props<{ error: any }>());

export const loadPageableEmployees = createAction('[FormsData] Load Pageable Employees', props<{ key: string }>());
export const loadPageableEmployeesSuccess = createAction('[FormsData] Load Pageable Employees Success', props<{ data: IdentityPageableDto<EmployeeDto>; key: string }>());
export const loadPageableEmployeesFailure = createAction('[FormsData] Load Pageable Employees Failure', props<{ error: any }>());

export const deleteEmployee = createAction('[FormsData] delete Employee', props<{ id: string }>());
export const deleteEmployeeSuccess = createAction('[FormsData] delete Employee Success', props<{ id: string }>());
export const deleteEmployeeFailure = createAction('[FormsData] delete Employee Failure', props<{ error: any }>());

export const addEmployee = createAction('[FormsData] Add Employee', props<{ data: UpdateEmployeeDto }>());
export const addEmployeeSuccess = createAction('[FormsData] Add Employee Success');
export const addEmployeeFailure = createAction('[FormsData] Add Employee Failure', props<{ error: any }>());

export const updateEmployee = createAction('[FormsData] Update Employee', props<{ data: UpdateEmployeeDto }>());
export const updateEmployeeSuccess = createAction('[FormsData] Update Emplouyee Success');
export const updateEmployeeFailure = createAction('[FormsData] Update Emplouyee Failure', props<{ error: any }>());

export const getEmployee = createAction('[FormsData] Get Employee', props<{ id: string }>());
export const getEmployeeSuccess = createAction('[FormsData] Get Employee Success', props<{ data: UpdateEmployeeDto }>());
export const getEmployeeFailure = createAction('[FormsData] Get Employee Failure', props<{ error: any }>());

export const setPageableEmployeesFilters = createAction('[Employees] Set Pageable Employees Filters', props<{ data?: GetPageableEmployeesQuery; key: string }>());
export const setPageableEmployeesFiltersSuccess = createAction('[Employees] Set Pageable Employees Filters Success', props<{ data: GetPageableEmployeesQuery; key: string }>());
export const setPageableEmployeesFiltersFailure = createAction('[Employees] Set Pageable Employees Filters Failure', props<{ error: any }>());

export const loadRolesForSelect = createAction('[UserData] Load Roles For Select');
export const loadRolesForSelectSuccess = createAction('[UserData] Load Roles For Select Success', props<{ data: any }>());
export const loadRolesForSelectFailure = createAction('[UserData] Load Roles For Select Failure', props<{ error: any }>());