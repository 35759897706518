<div class="container-fluid padding-zero">
  <div class="signin-form">
    <div style="width: 50%;">
      <div class="login-background"></div>
    </div>
    <div style="width: 50%;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
