import { FormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ITime } from '../interfaces/i-datetime-range';

export class DateRangeValidator {
  public static createDateToIsGreater() {
    return (control: UntypedFormGroup) => {
      if (control.value != null && this.dateFromIsGreater(control)) {
        return { dateFromIsGreater: true };
      }

      return null;
    };
  }

  public static createDateFromIsLowerThanNow() {
    return (control: UntypedFormGroup) => {
      if (control.value != null && this.dateFromIsLowerThanNow(control)) {
        return { dateFromIsLowerThanNow: true };
      }

      return null;
    };
  }

  private static compareTime(a: ITime, b: ITime) {
    if (a.hour > b.hour) {
      return 1;
    } else if (a.hour < b.hour) {
      return -1;
    } else if (a.minute > b.minute) {
      return 1;
    } else if (a.minute < b.minute) {
      return -1;
    } else if (a.second > b.second) {
      return 1;
    } else if (a.second < b.second) {
      return -1;
    }
    return 0;
  }

  public static dateFromIsLowerThanNow(control: UntypedFormGroup) {
    const date = new Date();
    if (moment(control.value.dateFrom).isSame(date, 'day')) {
      const nowTime = { hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() } as ITime;
      return this.compareTime(control.value.timeFrom, nowTime) < 0;
    }
    return moment(control.value.dateFrom).isBefore(date, 'day');
  }

  private static dateFromIsGreater(control: UntypedFormGroup) {
    if (moment(control.value.dateFrom).isSame(control.value.dateTo, 'day')) {
      return this.compareTime(control.value.timeFrom, control.value.timeTo) > 0;
    }
    return moment(control.value.dateFrom).isAfter(control.value.dateTo, 'day');
  }
}
