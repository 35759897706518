import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export class OperationNotesForm extends UntypedFormGroup {
  constructor() {
    super({
      note: new UntypedFormControl(null, [Validators.required]),
      contractorNote: new UntypedFormControl(null),
      notificationRequired: new UntypedFormControl(false),
    });
  }
}
