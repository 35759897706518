import { Action, createReducer, on } from '@ngrx/store';
import { CompanyEmployeeDto } from '../../shared/models/company-employee/company-employee.dto';
import {
  getPageableCompanyEmployee,
  getPageableCompanyEmployeeSuccess,
  getPageableCompanyEmployeeFailure,
} from './company-employee.actions';
import { PageableDto } from '../../shared/models/pageable.dto';

export const companyEmployeeFeatureKey = 'companyEmployee';

export interface State {
  pageableEmployees?: PageableDto<CompanyEmployeeDto>;
}

export const initialState: State = {};

const companyEmployeeReducer = createReducer(
  initialState,

  on(getPageableCompanyEmployee, (state) => setPageableEmployees(state, null)),
  on(getPageableCompanyEmployeeSuccess, (state, { data }) => setPageableEmployees(state, data)),
  on(getPageableCompanyEmployeeFailure, (state, action) => setPageableEmployees(state, null))
);

function setPageableEmployees(state: State, pageableEmployees: PageableDto<CompanyEmployeeDto>): State {
  return {
    ...state,
    pageableEmployees,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return companyEmployeeReducer(state, action);
}
