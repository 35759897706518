export class StructuralElementDto {
  constructor(data: StructuralElementDto) {
    if (data != null) {
      this.id = data.id;
      this.name = data.name;
      this.isActive = data.isActive;
    }
  }

  id: string;
  name: string;
  isActive: boolean;
}
