import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'project/src/environments/environment';
import { Observable } from 'rxjs';
import { ResponseDto } from '../models/response-dto';
import { map, tap } from 'rxjs/operators';
import { IPageableQuery } from '../interfaces/i-pageable-query';

export interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export abstract class BaseApiCaller {
  protected abstract controllerPath: string;

  constructor(protected httpClient: HttpClient) {}

  protected get<T>(path: string, options?: HttpOptions): Observable<T> {
    return this.httpClient.get<ResponseDto<T>>(this.getFullPath(path), options).pipe(
      tap((_) => this.checkError(_)),
      map((_) => _.result)
    );
  }

  protected postWithReponse<T>(path: string, body: any, options?: HttpOptions): Observable<T> {
    return this.httpClient.post<ResponseDto<T>>(this.getFullPath(path), body, options).pipe(
      tap((_) => this.checkError(_)),
      map((_) => _.result)
    );
  }

  protected postWithReponseSimpleType<T>(path: string, body: any, options?: HttpOptions): Observable<T> {
    return this.httpClient.post<T>(this.getFullPath(path), body, options).pipe();
  }

  protected post(path: string, body: any, options?: HttpOptions): Observable<unknown> {
    return this.httpClient.post(this.getFullPath(path), body, options);
  }

  protected put(path: string, body: any, options?: HttpOptions): Observable<unknown> {
    return this.httpClient.put(this.getFullPath(path), body, options);
  }

  protected delete(path: string, options?: HttpOptions): Observable<unknown> {
    return this.httpClient.delete(this.getFullPath(path), options);
  }

  protected getPageableParams(filter: IPageableQuery) {
    const result = {
      desc: filter.desc.toString(),
      orderBy: filter.orderBy,
      pageNumber: filter.pageNumber.toString(),
      pageSize: filter.pageSize.toString(),
      searchTerm: undefined,
    };
    if (filter.searchTerm != null && filter.searchTerm.length > 0) {
      result.searchTerm = filter.searchTerm;
    }

    return result;
  }

  protected prepareParams(data) {
    let result = new HttpParams();

    Object.keys(data).forEach((item) => {
      if (data[item] != null) {
        result = result.set(item, data[item]);
      }
    });

    return result;
  }

  protected prepareParamsWithList(data) {
    let result = new HttpParams();

    Object.keys(data).forEach((item) => {
      if (Array.isArray(data[item])) {
        for (var i = 0; i < data[item].length; i++) {
          result = result.set(item + '[' + i + ']', data[item][i]);
        }
      } else {
        if (data[item] != null) {
          result = result.set(item, data[item]);
        }
      }
    });
    return result;
  }

  protected getFullPath(path: string) {
    return environment.apiRoot + '/api/' + this.controllerPath + '/' + path;
  }

  protected getIdentityFullPath(path: string) {
    return environment.identityConfig.stsServer + '/' + path;
  }

  protected getClientData() {
    return environment.identityConfig.clientId + '_userData';
  }

  private checkError(response: ResponseDto<any>) {
    if (response.isError) {
      throw new Error('Error with request');
    }
  }

  public getDocumentWZ(id: string) {
    return this.httpClient.get(this.getFullPath(id), {  observe: 'response',
    responseType: 'blob' as 'json' });
  }

  public getDocumentPDF(id: string) {
    return this.httpClient.get(this.getFullPath(id), { responseType: 'blob' });
  }
}
