import { OrderSpecialAdditive } from './orderSpecialAdditive';
import { AddressDto } from '../addresses/addressDTO';
import { CustomerModel } from '../calendar/customer.model';
import { OrdetItemsDto } from '../orderItems/orderItemsDto';
import { SupplyDto } from '../supply/SupplyDto';
import { OrderStatusModel } from './orderStatus.model';

export class OrderDto {
    constructor(data: OrderDto) {
        if (data != null) {
            this.id = data.id.toString();
            this.type = data.type;
            this.customerId = data.customerId;
            this.dateFrom = data.dateFrom;
            this.dateTo = data.dateTo;
            this.paymentType = data.paymentType;
            this.netValue = data.netValue;
            this.grossValue = data.grossValue;
            this.discountValue = data.discountValue;
            this.isOffer = data.isOffer;
            this.hasIndividualPrice = data.hasIndividualPrice;
            this.orderStatusId = data.orderStatusId;
            this.orderStatus = data.orderStatus;
            this.orderItems = data.orderItems;
            this.supplies = data.supplies;
            this.customer = data.customer;
            this.nodeId = data.nodeId;
            this.orderNumber = data.orderNumber;
            this.addressId = data.addressId;
            this.address = data.address;
            this.distance = data.distance;
            this.transportFee = data.transportFee;
            this.distancePrice = data.distancePrice;
            this.personalPickup = data.personalPickup;
            this.registrationNumber = data.registrationNumber;
            this.comments = data.comments;
            this.vatRate = data.vatRate;
            this.calculatedGrossValue = data.calculatedGrossValue;
            this.structuralElementId = data.structuralElementId;
            this.specialAdditives = data.specialAdditives;
            this.wzCount = data.wzCount;
            this.isReceipt = data.isReceipt;
        }
    }

    id: string;
    type: number;
    customerId: string;
    dateFrom: Date;
    dateTo: Date;
    paymentType: any;
    isReceipt: boolean;
    netValue: number;
    grossValue: number;
    discountValue: number;
    isOffer: boolean;
    hasIndividualPrice: boolean;
    orderStatusId: string;
    orderStatus?: OrderStatusModel;
    orderItems: OrdetItemsDto[];
    supplies: SupplyDto[];
    customer?: CustomerModel;
    nodeId: string;
    orderNumber?: string;
    addressId?: string;
    address?: AddressDto;
    distance?: number;
    transportFee?: number;
    distancePrice?: number;
    personalPickup?: boolean;
    registrationNumber?: string;
    comments?: string;
    vatRate?: number;
    calculatedGrossValue?: number;
    structuralElementId?: string;
    specialAdditives?: OrderSpecialAdditive[];
    wzCount?: number;
}
