import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomerApiCallerService } from '../api-services/customer/customer-api-caller.service';

@Injectable()
export class UniqueNipValidator {
    constructor(
        private customerApiCaller: CustomerApiCallerService
    ) { }


    createValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            if (control.value) {
                let excludeId = control.parent.value.id;
                let nip = control.value;
                return this.customerApiCaller.doesCustomerWithNipExist(excludeId, nip)
                .pipe(
                    map(
                        (response: boolean) => {
                            if(response){
                                return { nipNotUnique: true }
                            } else{
                                return null;
                            }
                            },
                        ),
                        catchError(
                            (err: any) => {
                                return err.status === 404 ? of(null) : of({ nipNotUnique: true });
                            },
                        ),
                    );
            }
            else{
                return of(null)
            }
        };
    }
}