import { RecipeApiCallerService } from './../../shared/api-services/recipe-api-caller.service';
import { Injectable, ViewChild } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, catchError, concatMap, withLatestFrom, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  addRecipeFailure,
  addRecipe,
  addRecipeSuccess,
  updateRecipe,
  updateRecipeSuccess,
  updateRecipeFailure,
  loadRecipe,
  loadRecipeSuccess,
  loadRecipeFailure,
  deleteRecipe,
  deleteRecipeSuccess,
  deleteRecipeFailure,
  loadPageableRecipes,
  setPageableRecipesFilters,
  loadPageableRecipesSuccess,
  loadPageableRecipesFailure,
  setPageableRecipesFiltersSuccess,
  setPageableRecipesFiltersFailure,
} from './recipe.actions';
import { Store } from '@ngrx/store';
import { StoreState } from 'project/src/app/root-store/store-state';
import { selectAllPageableRecipesFilters } from './recipe.selectors';
@Injectable()
export class RecipeEffects {
  @ViewChild('spinner', { static: true }) spinner;

  private latestedRecipesKey: string;
  recipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecipe),
      concatMap(({ id }) => {
        return this.recipeApiCaller.getRecipe(id).pipe(
          map((_) => loadRecipeSuccess({ data: _ })),
          catchError((error) => of(loadRecipeFailure({ error })))
        );
      })
    )
  );

  aaddRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addRecipe),
      concatMap(({ data }) => {
        return this.recipeApiCaller.addRecipe(data).pipe(
          map((_) => addRecipeSuccess({ recipeId: data.id })),

          catchError((error) => of(addRecipeFailure({ error })))
        );
      })
    )
  );

  updateRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRecipe),
      concatMap(({ data }) => {
        return this.recipeApiCaller.updateRecipe(data).pipe(
          map((_) => updateRecipeSuccess({ recipeId: data.id })),

          catchError((error) => of(updateRecipeFailure({ error })))
        );
      })
    )
  );

  deleteRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRecipe),
      concatMap(({ id }) => {
        return this.recipeApiCaller.deleteRecipe(id).pipe(
          map((_) => deleteRecipeSuccess({ recipeId: id })),

          catchError((error) => of(deleteRecipeFailure({ error })))
        );
      })
    )
  );

  loadPageableCars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableRecipes),
      withLatestFrom(this.store$.select(selectAllPageableRecipesFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedRecipesKey = key;

        return this.recipeApiCaller.getPageableRecipes(filter.value).pipe(
          map((_) => loadPageableRecipesSuccess({ data: _, key })),
          catchError((error) => of(loadPageableRecipesFailure({ error })))
        );
      })
    )
  );

  setPageableCarsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableRecipesFilters),
      concatMap(({ data, key }) => {
        this.latestedRecipesKey = key;
        return of(data).pipe(
          map((_) => setPageableRecipesFiltersSuccess({ data, key })),
          finalize(() => this.reloadRecipes(key)),
          catchError((error) => of(setPageableRecipesFiltersFailure({ error })))
        );
      })
    )
  );

  private reloadRecipes(key: string) {
    this.store$.dispatch(loadPageableRecipes({ key }));
  }

  constructor(
    private actions$: Actions,
    private recipeApiCaller: RecipeApiCallerService,
    private store$: Store<StoreState>
  ) {}
}
