import { Action, createReducer, on } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { addIncomingMessage, addIncomingMessageSuccess, addIncomingMessageFailure, loadPageableIncomingMessages, loadPageableIncomingMessagesFailure, loadPageableIncomingMessagesSuccess, setPageableIncomingMessagesFilters, setPageableIncomingMessagesFiltersSuccess, getIncomingMessage, getIncomingMessageSuccess, getIncomingMessageFailure } from './incoming-messages.actions';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { GetPageableEmployeesQuery } from '../../shared/models/queries/get-pageable-users.query';
import { SelectModel } from '../../shared/models/select-model';
import { CorrespondenceDto } from '../../shared/models/correspondence/correspondenceDTO';

export const incomingMessagesFeatureKey = 'incomingMessages';

export interface State {
  pageableIncomingMessages?: DictionaryDto<string, PageableDto<CorrespondenceDto>>;
  incomingMessagesFilters?: DictionaryDto<string, GetPageableEmployeesQuery>;
  incomingMessage?: CorrespondenceDto;
}

export const initialState: State = {
  incomingMessagesFilters: new DictionaryDto<string, GetPageableEmployeesQuery>(),
  
};

const usersReducer = createReducer(
  initialState,

  on(addIncomingMessage, (state) => setAddEmployee(state, null)),
  on(addIncomingMessageSuccess, (state) => setAddEmployee(state, null)),
  on(addIncomingMessageFailure, (state) => setAddEmployee(state, null)),

  on(loadPageableIncomingMessages, (state, { key }) => setPageableIncomingMessages(state, null, key)),
  on(loadPageableIncomingMessagesSuccess, (state, { data, key }) => setPageableIncomingMessages(state, data, key)),
  on(loadPageableIncomingMessagesFailure, (state, action) => state),

  on(getIncomingMessage, (state) => setIncomingMessage(state, null)),
  on(getIncomingMessageSuccess, (state, { data}) => setIncomingMessage(state, data)),
  on(getIncomingMessageFailure, (state, action) => state),

  on(setPageableIncomingMessagesFilters, (state, { data, key }) => setPageableIncomingMessagesFiltersFunc(state, null, key)),
  on(setPageableIncomingMessagesFiltersSuccess, (state, { data, key }) => setPageableIncomingMessagesFiltersFunc(state, data, key)),
);

function setPageableIncomingMessages(state: State, data: PageableDto<CorrespondenceDto>, key: string) {
  const pageableIncomingMessages = Array.isArray(state.pageableIncomingMessages)
    ? state.pageableIncomingMessages
    : new DictionaryDto<string, PageableDto<CorrespondenceDto>>();
  const orders = pageableIncomingMessages.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableIncomingMessages.push({ key, value: data });
  }

  return {
    ...state,
    pageableIncomingMessages,
  };
}

function setIncomingMessage(state: State, data: CorrespondenceDto) {
  var incomingMessage = data;
  return {
    ...state,
    incomingMessage,
  };
}

function setAddEmployee(state: State, attachmentsProgress: CorrespondenceDto[]) {
  return {
    ...state,
    attachmentsProgress,
  };
}

function setPageableIncomingMessagesFiltersFunc(state: State, data: GetPageableEmployeesQuery, key: string) {
  const employeesFilters = Array.isArray(state.incomingMessagesFilters)
    ? state.incomingMessagesFilters
    : new DictionaryDto<string, GetPageableEmployeesQuery>();
  const filter = employeesFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    employeesFilters.push({ key, value: data });
  }

  return {
    ...state,
    employeesFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
