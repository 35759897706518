import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { CompanyApiCallerService } from '../../shared/api-services/company-api-caller.service';
import { ContractApiCallerService } from '../../shared/api-services/contract-api-caller.service';
import {
  loadClientContracts,
  loadClientContractsFailure,
  loadClientContractsSuccess,
  loadContract,
  loadContractFailure,
  loadContractSuccess,
} from './contract.actions';

@Injectable()
export class ContractEffects {
  contract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContract),
      concatMap(({ id }) => {
        return this.contractApiCaller.getContract(id).pipe(
          map((_) => loadContractSuccess({ data: _ })),
          catchError((error) => of(loadContractFailure({ error })))
        );
      })
    )
  );

  clientContracts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadClientContracts),
      concatMap(({ id }) => {
        return this.orderApiCaller.getClientContracts(id).pipe(
          map((_) => loadClientContractsSuccess({ data: _ })),
          catchError((error) => of(loadClientContractsFailure({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private orderApiCaller: CompanyApiCallerService,
    private contractApiCaller: ContractApiCallerService
  ) {}
}
