import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { updateAdditivesFailure, updateAdditivesSuccess,
    addAdditivesSuccess, addAdditivesFailure, loadAdditivesSuccess,
    loadAdditivesFailure,
    loadAdditives,
    addAdditives,
    updateAdditives,
    deleteAdditive,
    deleteAdditiveSuccess,
    deleteAdditiveFailure} from './additives.actions';
import { concatMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { AdditiveApiCallerService } from '../../shared/api-services/additive-api-caller.service';
import { RecipeApiCallerService } from '../../shared/api-services/recipe-api-caller.service';

@Injectable()
export class AdditivesEffects {

    additives$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAdditives),
            concatMap(({ recipeId }) => {
                return this.recipeApiCaller.getRecipeAdditives(recipeId).pipe(
                     map((_) => loadAdditivesSuccess({ data: _ })),
                     catchError((error) => of(loadAdditivesFailure({ error })))
                );
            })
    ));

    addAdditives$ = createEffect(() =>
     this.actions$.pipe(
         ofType(addAdditives),
         concatMap(({ data }) => {
             return this.additivesApiCaller.addAdditive(data).pipe(
             map((_) => addAdditivesSuccess({ recipeId: data.id })),
             catchError((error) => of(addAdditivesFailure({ error })))
             );
         })
     ));

     updateAdditives$ = createEffect(() =>
     this.actions$.pipe(
         ofType(updateAdditives),
         concatMap(({ data }) => {
             return this.additivesApiCaller.updateAdditive(data).pipe(
             map((_) => updateAdditivesSuccess({ recipeId: data.id })),
             catchError((error) => of(updateAdditivesFailure({ error })))
             );
         })
     ));

     deleteAdditive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteAdditive),
            concatMap(( { Id }) => {
                return this.additivesApiCaller.deleteAdditive(Id).pipe(
                    map((_) => deleteAdditiveSuccess({ Id })),
                    catchError((error) => of(deleteAdditiveFailure({ error })))
                );
            })
        ));

    constructor(
        private actions$: Actions,
        private additivesApiCaller: AdditiveApiCallerService,
        private recipeApiCaller: RecipeApiCallerService
    ) {}
}
