import { createAction, props } from '@ngrx/store';
import { DictionaryModel } from '../../shared/models/dictionary-model';

export const loadOrderTypes = createAction('[Dictionaries] Load OrderTypes');

export const loadOrderTypesSuccess = createAction(
  '[Dictionaries] Load OrderTypes Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadOrderTypesFailure = createAction('[Dictionaries] Load OrderTypes Failure', props<{ error: any }>());

export const loadOrderStatuses = createAction('[Dictionaries] LoadOrderStatuses OrderStatuses');

export const loadOrderStatusesSuccess = createAction(
  '[Dictionaries] Load OrderStatuses Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadOrderStatusesFailure = createAction(
  '[Dictionaries] Load OrderStatuses Failure',
  props<{ error: any }>()
);
