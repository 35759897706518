import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './departments.reducer';

const selectDepartmentsFeature = (state: StoreState) => state.departments;

export const selectDepartmentsForSelect = createSelector(selectDepartmentsFeature, (state: State, props: { key: string }) => {
  const result = state.departmentsForSelect;
  return result ? result : null;
});

export const selectPageableDepartments = createSelector(selectDepartmentsFeature, (state: State, props: {key: string}) => {
  const result = state.pageableDepartments ? state.pageableDepartments.find((_) => _.key === props.key) : null;
  return result ? result.value : null;
});

export const selectDepartment = createSelector(selectDepartmentsFeature, (state: State) => {
  const result = state.department;
  return result ? result : null;
});

export const selectAllPageableDepartmentsFilters = createSelector(selectDepartmentsFeature, (state: State) => state.departmentsFilters);
export const selectPageableDepartmentsFilters = createSelector(selectDepartmentsFeature, (state: State, props: { key: string }) => {
  const result = state.departmentsFilters ? state.departmentsFilters.find((_) => _.key === props.key) : null;
  return result ? result.value : null;
});

export const selectAddedDepartment = createSelector(selectDepartmentsFeature, (state: State) => state.departmentAdded);