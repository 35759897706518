import { createAction, props } from '@ngrx/store';
import { UpdateAdditiveDto } from '../../shared/models/additive/update-additive-dto';

export const loadAdditives = createAction('[Additives] Load Additives', props<{ recipeId: string }>());
export const addAdditives = createAction('[Additives] Add Additives', props<{ data: UpdateAdditiveDto }>());
export const updateAdditives = createAction('[Additives] Update Additives', props<{ data: UpdateAdditiveDto }>());
export const deleteAdditive = createAction('[Additives] Delete Additive', props<{ Id: string }>());

export const loadAdditivesSuccess = createAction('[Additives] Load Additives Success', props<{ data: UpdateAdditiveDto[] }>());
export const loadAdditivesFailure = createAction('[Additives] Load Additives Failure', props<{ error: any }>());

export const addAdditivesSuccess = createAction('[Additives] Add Additives Success', props<{ recipeId: string }>());
export const addAdditivesFailure = createAction('[Additives] Add Additives Failure', props<{ error: any }>());

export const updateAdditivesSuccess = createAction('[Additives] Update Additives Success', props<{ recipeId: string }>());
export const updateAdditivesFailure = createAction('[Additives] Update Additives Failure', props<{ error: any }>());

export const deleteAdditiveSuccess = createAction('[Additives] Delete Additive Success', props<{ Id: string }>());
export const deleteAdditiveFailure = createAction('[Additives] Delete Additive Failure', props<{ error: any }>());

 