import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './editorial-documents.reducer';

const selectEditorialDocumentsFeature = (state: StoreState) => state.editorialDocuments;

export const selectPageableEditorialDocuments = createSelector(selectEditorialDocumentsFeature, (state: State, props: { key: string }) => {
  const result = state.pageableEditorialDocuments ? state.pageableEditorialDocuments.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectEditorialDocument = createSelector(selectEditorialDocumentsFeature, (state: State) => {
  const result = state.editorialDocument;
  return result ? result : null;
});

export const selectAllPageableEditorialDocumentsFilters = createSelector(selectEditorialDocumentsFeature, (state: State) => state.employeesFilters);
export const selectPageableEditorialDocumentsFilters = createSelector(selectEditorialDocumentsFeature, (state: State, props: { key: string }) => {
  const result = state.employeesFilters ? state.employeesFilters.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});