import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableRecipeCostsQuery } from '../models/queries/get-pageable-recipe-costs-query';
import { RecipeCostDto } from '../models/recipe-costs/recipe-cost-dto';
import { UpdateRecipeCostDto } from '../models/recipe-costs/update-recipe-cost-dto';
import { BaseApiCaller } from './base-api-caller';

@Injectable({
    providedIn: 'root',
  })
export class RecipeCostsApiCallerService extends BaseApiCaller {
    protected controllerPath = 'RecipeCosts';
  
    constructor(httpClient: HttpClient) {
      super(httpClient);
    }
    
  
    public addRecipeCost(RecipeCost: UpdateRecipeCostDto) {
        console.log(RecipeCost);
      return this.post('', RecipeCost);
    }
  
    public updateRecipeCost(RecipeCost: UpdateRecipeCostDto) {
      return this.put('', RecipeCost);
    }
    public deleteRecipeCost(id: string) {
        return this.delete(id);
    }
    public getRecipeCost(id: string) {
        return this.get<RecipeCostDto>(id.toString()).pipe(map((_) => new RecipeCostDto(_)));
    }
  
    public getPageableItems(query: GetPageableRecipeCostsQuery) {
        const params = this.prepareParams(query);
        return this.get<PageableDto<RecipeCostDto>>('pageable', { params });
    }
}