import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { environment } from 'project/src/environments/environment';

export class CarDto {
  constructor(data: CarDto) {
    if (data != null) {
      this.id = data.id;
      this.name = data.name;
      this.typeId = data.typeId;
      this.typeName = data.typeName;
      this.registrationNumber = data.registrationNumber;
      this.pricePerKilometer = data.pricePerKilometer;
      this.nodeId = data.nodeId;
      this.nodeName = data.nodeName;
      this.volume = data.volume;
      this.maxVolume = data.maxVolume;
      this.volumeTon = data.volumeTon;
      this.maxVolumeTon = data.maxVolumeTon;
      this.carNodes = data.carNodes;
      this.mainNodeId = data.mainNodeId;
      this.employeeId = data.employeeId;
      this.employeeName = data.employeeName;
    }
  }

  id: string;
  name?: string;
  typeId?: string;
  typeName: string;
  registrationNumber: string;
  pricePerKilometer: number;
  nodeId: string;
  nodeName: string;
  volume: number;
  maxVolume: number;
  volumeTon: number;
  maxVolumeTon: number;
  mainNodeId: string;
  carNodes: string[];
  employeeId: string;

  employeeName: string;
}
