import { DeliveryItemForPump } from './../models/DeliveryItemForPump/DeliveyItemForPumpDto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { map } from 'rxjs/operators';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableItemsQuery } from '../models/queries/get-pageable-items.query';
import { ItemDto } from '../models/items/itemDTO';
import { Observable } from 'rxjs';
import { SelectModel } from '../models/select-model';
import { DeliveryItemDto } from '../models/deliveryCalendar/deliveryItemDTO';
import { ExistingDeliveryItemDto } from '../models/deliveryCalendar/ExistingDeliveryItemDto';
import { GetPageableUnassignedDeliveryItemsQuery } from '../models/queries/get-pageable-unassigned-delivery-items.query';
import { ExistingDeliveryCountDto } from '../models/deliveryCalendar/existingDeliveryCountDto';

@Injectable({
  providedIn: 'root',
})
export class DeliveryCalendarApiCallerService extends BaseApiCaller {
  protected controllerPath = 'DeliveryCalendar';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addItem(dto: ItemDto) {
    return this.post('', dto);
  }

  public updateItem(dto: ItemDto) {
    return this.put('', dto);
  }

  public deleteCalendarItem(id: string) {
    return this.delete(id.toString());
  }

  public getItem(id: string) {
    return this.get<ItemDto>(id.toString()).pipe(map((_) => new ItemDto(_)));
  }

  public getPageableItems(query: GetPageableItemsQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<ItemDto>>('pageable', { params });
  }

  public getItemTypesForSelect(): Observable<SelectModel<string>[]> {
    return this.get<SelectModel<string>[]>('getItemTypesForSelect');
  }

  public getDeliveryCalendarItemsForDate(date: Date, nodes: string[]): Observable<DeliveryItemDto[]> {
    return this.get<DeliveryItemDto[]>('GetUnassignedDeliveryItemsForDate', { 
      params: { 
        date: date.toISOString(), 
        nodes: nodes 
      } 
    });
  }

  public getPageableUnassignedDeliveryItemsForDate(query: GetPageableUnassignedDeliveryItemsQuery) { 
    return this.get<PageableDto<DeliveryItemDto>>('GetPageableUnassignedDeliveryItemsForDate', { params:{
      desc: query.desc,
      orderBy: query.orderBy,
      pageNumber: query.pageNumber,
      pageSize: query.pageSize,
      nodes: query.nodes ,
      date: query.date.toISOString()
    } });
  }

  public getExistingCalendarItemsForDate(date: Date): Observable<ExistingDeliveryItemDto[]> {
    return this.get<ExistingDeliveryItemDto[]>('GetExistingDeliveryItemsForDate', {
      params: { date: date.toISOString() },
    });
  }

  public getExistingCalendarItemsCountForDate(nodeId: string, date: Date|string): Observable<ExistingDeliveryCountDto> {
    return this.get<ExistingDeliveryCountDto>('GetExistingDeliveryItemsCountForDate', {
      params: { 
        nodeId: nodeId,
        date: typeof date === 'string' ? date : date.toISOString() 
      },
    });
  }

  public assignAutomatically(orderItemId: string) {
    return this.post('AssignDeliveriesByOrderItem', {id: orderItemId});
  }

  public getPompsOccupancy(date: Date, nodeId: string, carTypeId: string): Observable<DeliveryItemForPump[]> {
    return this.get<DeliveryItemForPump[]>('GetPumpOccupancyByNodeQuery', {
      params: { date: date.toISOString(), nodeId, carTypeId },
    });
  }
}
