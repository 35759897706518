import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';

@Injectable({
  providedIn: 'root',
})
export class IdentityRolesApiCallerService extends BaseApiCaller {
  protected controllerPath = 'ApiRoles';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getSystemRoles() {
    return this.httpClient.get<Array<string>>(this.getIdentityFullPath('GetRoles'));
  }
}