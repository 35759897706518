import { createSelector } from '@ngrx/store';
import { StoreState } from '../store-state';
import { State } from './settlements.reducer';

const selectSettlementsFeature = (state: StoreState) => state.pageableSettlements;

export const selectAllPageableSettlementsFilters = createSelector(
  selectSettlementsFeature,
  (state: State) => state.settlementsFilters
);

export const selectPageableSettlementsFilters = createSelector(
  selectSettlementsFeature,
  (state: State, props: { key: string }) => {
    const result = state.settlementsFilters ? state.settlementsFilters.find((_) => _.key === props.key) : null;

    return result ? result.value : null;
  }
);

export const selectPageableSettlements = createSelector(
  selectSettlementsFeature,
  (state: State, props: { key: string }) => {
    const result = state.pageableSettlements ? state.pageableSettlements.find((_) => _.key === props.key) : null;

    return result ? result.value : null;
  }
);
