import { createSelector } from '@ngrx/store';
import { StoreState } from '../store-state';
import { State } from './settlements-history.reducer';

const selectSettlementsHistoryFeature = (state: StoreState) => state.historySettlements;

export const selectPageableSettlementsHistory = createSelector(
  selectSettlementsHistoryFeature,
  (state: State, props: { key: string }) => {
    const result = state.pageableHistory ? state.pageableHistory.find((_) => _.key === props.key) : null;
    return result ? result.value : null;
  }
);

export const selectPageableSettlementsHistoryFilter = createSelector(
  selectSettlementsHistoryFeature,
  (state: State, props: { key: string }) => {
    const result = state.historyFilters ? state.historyFilters.find((_) => _.key === props.key) : null;
    return result ? result.value : null;
  }
);

export const selectAllPageableSettlementsHistoryFilter = createSelector(
  selectSettlementsHistoryFeature,
  (state: State) => state.historyFilters
);
