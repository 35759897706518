import { FormControl, UntypedFormGroup } from "@angular/forms";
import { Component, OnInit, Input } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "app-select-search",
  templateUrl: "./select-search.component.html",
  styleUrls: ["./select-search.component.css"]
})
export class SelectSearchComponent implements OnInit {
  @Input() data$: Observable<any[]>;
  dataLoading = false;
  @Input() dataInput$ = new Subject<string>();
  @Input() form: UntypedFormGroup;
  @Input() controlName: string;

  constructor() {}

  ngOnInit() {}
}
