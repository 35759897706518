import { createAction, props } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableEditorialDocumentsQuery } from '../../shared/models/queries/get-pageable-editorial-documents.query';
import { EditorialDocumentDto } from '../../shared/models/editorial-documents/editorialDocumentDTO';

export const addEditorialDocument = createAction('[EditorialDocuments] Add EditorialDocument', props<{ data: EditorialDocumentDto }>());
export const addEditorialDocumentSuccess = createAction('[EditorialDocuments] Add EditorialDocument Success');
export const addEditorialDocumentFailure = createAction('[EditorialDocuments] Add EditorialDocument Failure', props<{ error: any }>());

export const updateEditorialDocument = createAction('[EditorialDocuments] Add EditorialDocuments', props<{ data: EditorialDocumentDto }>());
export const updateEditorialDocumentSuccess = createAction('[EditorialDocuments] Add EditorialDocuments Success');
export const updateEditorialDocumentFailure = createAction('[EditorialDocuments] Add EditorialDocuments Failure', props<{ error: any }>());

export const deleteEditorialDocument = createAction('[EditorialDocuments] delete EditorialDocument', props<{ id: number }>());
export const deleteEditorialDocumentSuccess = createAction('[EditorialDocuments] delete EditorialDocument Success', props<{ id: number }>());
export const deleteEditorialDocumentFailure = createAction('[EditorialDocuments] delete EditorialDocument Failure', props<{ error: any }>());

export const getEditorialDocument = createAction('[EditorialDocuments] get EditorialDocument', props<{ id: number }>());
export const getEditorialDocumentSuccess = createAction('[EditorialDocuments] get EditorialDocument Success', props<{ data: EditorialDocumentDto }>());
export const getEditorialDocumentFailure = createAction('[EditorialDocuments] get EditorialDocument Failure', props<{ error: any }>());

export const loadPageableEditorialDocuments = createAction('[EditorialDocuments] Load Pageable EditorialDocuments', props<{ key: string }>());
export const loadPageableEditorialDocumentsSuccess = createAction(
  '[EditorialDocuments] Load Pageable EditorialDocuments Success',
  props<{ data: PageableDto<EditorialDocumentDto>; key: string }>()
);

export const loadPageableEditorialDocumentsFailure = createAction(
  '[EditorialDocuments] Load Pageable EditorialDocuments Failure',
  props<{ error: any }>()
);

export const setPageableEditorialDocumentsFilters = createAction(
  '[EditorialDocuments] Set Pageable EditorialDocuments Filters',
  props<{ data?: GetPageableEditorialDocumentsQuery; key: string }>()
);

export const setPageableEditorialDocumentsFiltersSuccess = createAction(
  '[EditorialDocuments] Set Pageable EditorialDocuments Filters Success',
  props<{ data: GetPageableEditorialDocumentsQuery; key: string }>()
);

export const setPageableEditorialDocumentsFiltersFailure = createAction(
  '[EditorialDocuments] Set Pageable EditorialDocuments Filters Failure',
  props<{ error: any }>()
);
