import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, withLatestFrom } from 'rxjs/operators';
import { OffersApiCallerService } from "../../shared/api-services/offers-api-caller.service";
import { StoreState } from '../store-state';
import { 
  loadPageableOffers, loadPageableOffersSuccess, loadPageableOffersFailure, 
  setPageableOffersFilters, setPageableOffersFiltersSuccess, setPageableOffersFiltersFailure 
} from './offer.actions';
import { selectAllPageableOffersFilters } from "./offer.selectors";

@Injectable()
export class OfferEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private offersApiCaller: OffersApiCallerService
  ) { }

  setPageableOffersFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableOffersFilters),
      concatMap(({ data, key }) => {
        return of(data).pipe(
          map((_) => setPageableOffersFiltersSuccess({ data, key })),
          finalize(() => this.reloadOffers(key)),
          catchError((error) => of(setPageableOffersFiltersFailure({ error })))
        );
      })
    )
  );

  loadPageableOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableOffers),
      withLatestFrom(this.store$.select(selectAllPageableOffersFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);

        return this.offersApiCaller.getPageable(filter.value).pipe(
          map((_) => loadPageableOffersSuccess({ data: _, key })),
          catchError((error) => of(loadPageableOffersFailure({ error })))
        );
      })
    )
  );

  private reloadOffers(key: string) {
    this.store$.dispatch(loadPageableOffers({ key }));
  }
}
