import { CommentsOfDayDto } from './../shared/models/orders/commentsOfDayDto';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Comments } from '../shared/models/comments/comments-model';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
export interface DialogData {
  content: string;
}

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
  comments: Comments;
  commentForm: UntypedFormGroup;

  content: string;

  commentPlaceholder = 'Komentarz';

  title: string;

  isSave = true;

  get comment() {
    return this.commentForm.get('content');
  }

  constructor(public activeModal: NgbActiveModal, private formBuilder: UntypedFormBuilder) {}
  ngOnInit() {
    $(document).ready(function () {
      let modalContent: any = $('.modal-content');
      modalContent.draggable({
        handle: '.modal-header',
        revert: false,
        //  revertDuration: 300,
      });
    });

    this.commentForm = new UntypedFormGroup({
      content: new UntypedFormControl(null, [Validators.required]),
    });

    if (this.content !== undefined && this.content.length > 0) {
      this.commentForm.get('content').setValue(this.content);
    }
  }

  save() {
    if (!this.commentForm.valid) {
      this.isSave = false;
      return;
    }
    this.content = this.commentForm.get('content').value;
    this.activeModal.close(this.content);
  }
}
