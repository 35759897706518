import { Component, OnInit, Input } from '@angular/core';
import { PersonListForm } from '../persons-list/person-list.form';

@Component({
  selector: 'app-contractor-employee-grid',
  templateUrl: './contractor-employee-grid.component.html',
  styleUrls: ['./contractor-employee-grid.component.scss'],
})
export class ContractorEmployeeGridComponent implements OnInit {
  @Input() form: PersonListForm;
  @Input() alwaysSuperEmployee: boolean;

  constructor() {}

  ngOnInit() {}
}
