
import { createReducer, on, Action } from '@ngrx/store';
import { UpdatePropertiesMixtureDto } from '../../shared/models/propertiesMixture/update-properties-mixture-dto';
import { loadMixtureProperties, loadMixturePropertiesSuccess, loadMixturePropertiesFailure } from './mixture-properties.actions';

export const mixturePropertiesFeatureKey = 'mixtureProperties';

export interface State {
    mixtureProperties?: UpdatePropertiesMixtureDto;
}

export const initialState: State = {};

const mixturePropertiesReducer = createReducer(
    initialState,
    on(loadMixtureProperties, (state) => setMixtureProperties(state, null)),
    on(loadMixturePropertiesSuccess, (state, { data }) => setMixtureProperties(state, data)),
    on(loadMixturePropertiesFailure, (state) => setMixtureProperties(state, null))
);

function setMixtureProperties(state: State, data: UpdatePropertiesMixtureDto) {
    return {
        ...state,
        mixtureProperties: data,
    };
}

export function reducer(state: State | undefined, action: Action) {
    return mixturePropertiesReducer(state, action);
}
