import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { concat, fromEvent, Observable, of } from 'rxjs';
import { map, mapTo, take } from 'rxjs/operators';
import { ApiService } from "../../shared/api-services/api-services";
import { SyncTask } from "../model/syncTask.model";

@Injectable()
export class OfflineService {
    _onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
    _offlineChanges$ = fromEvent(window, 'offline').pipe(mapTo(true));

    constructor(
        private http: HttpClient, 
        private api: ApiService,
        private readonly snackBar: MatSnackBar,
        private dbService: NgxIndexedDBService) {
    }


    public isOnline() : boolean {
        return window.navigator.onLine;
    }

    public setup() {
        this._offlineChanges$.subscribe((res) => {
            this.displayToast('Utracono połączenie z internetem');
            this._onlineChanges$.pipe(take(1)).subscribe((res) => {
                this.displayToast('Przywrócono połączenie z internetem');
                this.sync();
            });
        });
    }

    private displayToast(message: string) {
        this.snackBar.open(message, null, {
            duration: 2000
        });
    }

    private sync() {
        this.dbService.getAll('SyncTasks').subscribe(syncTasks => {
            const requests: Observable<any>[] = [];

            syncTasks.forEach((syncTask: any) => {
                const task = {
                    id: syncTask.id,
                    body: JSON.parse(syncTask.value)
                };

                const obs$ = this.http
                    .request(SyncTask.toHttpRequest(task.body))
                    .pipe(map(_ => task));

                requests.push(obs$);
            });

            const all$ = concat(...requests);

            all$.subscribe(x => {
                this.dbService.delete('SyncTasks', x.id).subscribe();
            });
        });
    }

    public storeCustomers(intervalInMinutes: number) {
        const lastSyncDateStr = window.localStorage.getItem('syncCustomersDate');
        const lastSyncDate = lastSyncDateStr !== undefined ? new Date(lastSyncDateStr) : null;

        this.dbService.count('Customers').subscribe(count => {
            if (count === 0 || 
                (lastSyncDate && moment().diff(moment(lastSyncDate), 'minutes')) > intervalInMinutes) {
                
                    this.api.customerGET().subscribe(resp => {
                    this.dbService.clear('Customers').subscribe(() => {
                        const data = resp.result.map(x => ({ value: JSON.stringify(x) }));
                        this.dbService.bulkAdd('Customers', data).subscribe();

                        window.localStorage.setItem('syncCustomersDate', new Date().toISOString());
                    });
                })
            }
        })
    }

    public getCustomers() : Observable<any> {
        return this.dbService.getAll('Customers')
            .pipe(
                map(data => data.map((x: any) => JSON.parse(x.value)))
            );
    }
}