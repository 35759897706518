//import { OtherDocumentsApiCallerService } from '../../shared/api-services/employees-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  addOtherDocument,
  addOtherDocumentSuccess,
  addOtherDocumentFailure,
  deleteOtherDocument,
  deleteOtherDocumentSuccess,
  deleteOtherDocumentFailure,
  setPageableOtherDocumentsFilters,
  setPageableOtherDocumentsFiltersSuccess,
  setPageableOtherDocumentsFiltersFailure,
  loadPageableOtherDocuments,
  loadPageableOtherDocumentsSuccess,
  loadPageableOtherDocumentsFailure,
  getOtherDocument,
  updateOtherDocument,
  updateOtherDocumentSuccess,
  updateOtherDocumentFailure,
  getOtherDocumentSuccess,
  getOtherDocumentFailure,
} from './other-documents.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { selectAllPageableOtherDocumentsFilters } from './other-documents.selector';
import { OtherDocumentsApiCallerService } from '../../shared/api-services/other-documents-api-caller.service';

@Injectable()
export class OtherDocumentsEffects {

  private latestedOtherDocumentsKey: string;

  deleteOtherDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteOtherDocument),
      concatMap(({ id }) => {
        return this.editorialDocumentsApiCaller.deleteOtherDocument(id).pipe(
          map((_) => deleteOtherDocumentSuccess({ id: id })),
          tap(() => this.reloadOtherDocuments(this.latestedOtherDocumentsKey)),
          catchError((error) => of(deleteOtherDocumentFailure({ error })))
        );
      })
    )
  );

  addOtherDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOtherDocument),
      concatMap(({ data }) => {
        console.log("post", data);
        return this.editorialDocumentsApiCaller.addOtherDocument(data).pipe(
          map((_) => addOtherDocumentSuccess()),
          tap(() => this.navigateToCatalog()),
          catchError((error) => of(addOtherDocumentFailure({ error })))
        );
      })
    )
  );

  updateOtherDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOtherDocument),
      concatMap(({ data }) => {
        return this.editorialDocumentsApiCaller.updateOtherDocument(data).pipe(
          map((_) => updateOtherDocumentSuccess()),
          tap(() => this.navigateToCatalog()),
          catchError((error) => of(updateOtherDocumentFailure({ error })))
        );
      })
    )
  );

  loadPageableOtherDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableOtherDocuments),
      withLatestFrom(this.store$.select(selectAllPageableOtherDocumentsFilters)),
      concatMap((data) => {
        console.log("filters", data[1]);
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedOtherDocumentsKey = key;

        return this.editorialDocumentsApiCaller.getPageableOtherDocuments(filter.value).pipe(
          map((_) => loadPageableOtherDocumentsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableOtherDocumentsFailure({ error })))
        );
      })
    )
  );

  getOtherDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOtherDocument),
      concatMap(({id}) => {
        return this.editorialDocumentsApiCaller.getOtherDocument(id).pipe(
          map((_) => getOtherDocumentSuccess({ data: _ })),
          catchError((error) => of(getOtherDocumentFailure({ error })))
        );
      })
    )
  );

  setPageableOtherDocumentsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableOtherDocumentsFilters),
      concatMap(({ data, key }) => {
        this.latestedOtherDocumentsKey = key;
        return of(data).pipe(
          map((_) => setPageableOtherDocumentsFiltersSuccess({ data, key })),
          finalize(() => this.reloadOtherDocuments(key)),
          catchError((error) => of(setPageableOtherDocumentsFiltersFailure({ error })))
        );
      })
    )
  );

  private reloadOtherDocuments(key: string) {
    this.store$.dispatch(loadPageableOtherDocuments({ key }));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private editorialDocumentsApiCaller: OtherDocumentsApiCallerService,
    private router: Router,
  ) { }

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'editorial-documents', 'catalog']);
  }
}
