import { Action, createReducer, on } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { addCar, addCarSuccess, addCarFailure, loadPageableCars, loadPageableCarsFailure,
   loadPageableCarsSuccess, setPageableCarsFilters, setPageableCarsFiltersSuccess, getCar,
    getCarSuccess, getCarFailure, loadCarTypesForSelect, loadCarTypesForSelectSuccess,
    loadCarTypesForSelectFailure, loadCarsSuccess, loadCars, loadCarsFailure, loadCarTypesPump,
    loadFilteredCars, loadFilteredCarsSuccess, loadFilteredCarsFailure, loadCarTypesPumpSuccess, loadCarTypesPumpFailure, loadCarTypesMixer, loadCarTypesMixerSuccess, loadCarTypesMixerFailure } from './cars.actions';

import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { GetPageableCarsQuery } from '../../shared/models/queries/get-pageable-cars.query';
import { SelectModel } from '../../shared/models/select-model';
import { CarDto } from '../../shared/models/cars/carDTO';
import { CarSelectModel } from '../../shared/models/carSelectModel';


export const CarsFeatureKey = 'Cars';

export interface State {
  pageableCars?: DictionaryDto<string, PageableDto<CarDto>>;
  carsFilters?: DictionaryDto<string, GetPageableCarsQuery>;
  car?: CarDto;
  carTypesForSelect?: SelectModel<string>[];
  carTypesPump?: CarSelectModel<string>[];
  carTypesMixer?: CarSelectModel<string>[];
  cars?: CarDto[];
  filteredCars?: CarDto[];
}

export const initialState: State = {
  carsFilters: new DictionaryDto<string, GetPageableCarsQuery>(),
  
};

const carsReducer = createReducer(
  initialState,

  on(addCar, (state) => setAddCar (state, null)),
  on(addCarSuccess, (state) => setAddCar(state, null)),
  on(addCarFailure, (state) => setAddCar(state, null)),

  on(loadPageableCars, (state, { key }) => setPageableCars(state, null, key)),
  on(loadPageableCarsSuccess, (state, { data, key }) => setPageableCars(state, data, key)),
  on(loadPageableCarsFailure, (state, action) => state),

  on(getCar, (state) => setCar(state, null)),
  on(getCarSuccess, (state, { data}) => setCar(state, data)),
  on(getCarFailure, (state, action) => state),

  on(setPageableCarsFilters, (state, { data, key }) => setPageableCarsFiltersFunc(state, null, key)),
  on(setPageableCarsFiltersSuccess, (state, { data, key }) => setPageableCarsFiltersFunc(state, data, key)),

  on(loadCarTypesForSelect, (state) => setCarTypesForSelect(state, null)),
  on(loadCarTypesForSelectSuccess, (state, { data }) => setCarTypesForSelect(state, data)),
  on(loadCarTypesForSelectFailure, (state, action) => state),

  on(loadCars, (state) => state),
  on(loadCarsSuccess, (state, { data }) => setCars(state, data)),
  on(loadCarsFailure, (state) => setCars(state, null)),

  on(loadCarTypesPump, (state) => setCarTypesPump(state, null)),
  on(loadCarTypesPumpSuccess, (state, { data }) => setCarTypesPump(state, data)),
  on(loadCarTypesPumpFailure, (state, action) => state),

  on(loadCarTypesMixer, (state) => setCarTypesMixer(state, null)),
  on(loadCarTypesMixerSuccess, (state, { data }) => setCarTypesMixer(state, data)),
  on(loadCarTypesMixerFailure, (state, action) => state),
  
  on(loadFilteredCars, (state) => state),
  on(loadFilteredCarsSuccess, (state, { data }) => setFilteredCars(state, data)),
  on(loadFilteredCarsFailure, (state) => setFilteredCars(state, null)),
);

function setPageableCars(state: State, data: PageableDto<CarDto>, key: string) {
  const pageableCars = Array.isArray(state.pageableCars)
    ? state.pageableCars
    : new DictionaryDto<string, PageableDto<CarDto>>();
  const orders = pageableCars.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableCars.push({ key, value: data });
  }

  return {
    ...state,
    pageableCars,
  };
}

function setCar(state: State, data: CarDto) {
  var car = data;
  return {
    ...state,
    car,
  };
}

function setAddCar(state: State, attachmentsProgress: CarDto[]) {
  return {
    ...state,
    attachmentsProgress,
  };
}

function setPageableCarsFiltersFunc(state: State, data: GetPageableCarsQuery, key: string) {
  const carsFilters = Array.isArray(state.carsFilters)
    ? state.carsFilters
    : new DictionaryDto<string, GetPageableCarsQuery>();
  const filter = carsFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    carsFilters.push({ key, value: data });
  }

  return {
    ...state,
    carsFilters,
  };
}

function setCarTypesForSelect(state: State, data: SelectModel<string>[]) {
  const carTypesForSelect = data;
  return {
    ...state,
    carTypesForSelect,
  };
}

function setCars(state: State, data: CarDto[]) {
  return {
    ...state,
    cars: data,
  };
}

function setCarTypesPump(state: State, data: CarSelectModel<string>[]) {
  const carTypesPump = data;
  return {
    ...state,
    carTypesPump,
  }
}

function setCarTypesMixer(state: State, data: CarSelectModel<string>[]) {
  const carTypesMixer = data;
  return {
    ...state,
    carTypesMixer,
  }
}

function setFilteredCars(state: State, data: CarDto[]) {
  return {
    ...state,
    filteredCars: data,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return carsReducer(state, action);
}
