//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadCTypesForSelect,
  loadCTypesForSelectSuccess,
  loadCTypesForSelectFailure,
  loadPageableCTypes,
  loadPageableCTypesSuccess,
  loadPageableCTypesFailure,
  deleteCType,
  deleteCTypeSuccess,
  deleteCTypeFailure,
  addCType,
  addCTypeFailure,
  addCTypeSuccess,
  getCType,
  getCTypeSuccess,
  getCTypeFailure,
  updateCType,
  updateCTypeSuccess,
  updateCTypeFailure,
} from './c-types.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { SnackBarWrapperService } from '../../shared/services/snack-bar-wrapper.service';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { ClientsApiCallerService } from '../../shared/api-services/clients-api-caller.service';
import { CTypesApiCallerService } from '../../shared/api-services/c-types-api-caller.service';

@Injectable()
export class CTypesEffects {

  addCType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCType),
      concatMap(({ data }) => {
        return this.cTypesApiCaller.addCType(data).pipe(
          map((_) => addCTypeSuccess()),
          tap(() => this.reloadCTypes()),
          catchError((error) => of(addCTypeFailure({ error })))
        );
      })
    )
  );

  updateCType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCType),
      concatMap(({ data }) => {
        return this.cTypesApiCaller.updateCType(data).pipe(
          map((_) => updateCTypeSuccess()),
          catchError((error) => of(updateCTypeFailure({ error })))
        );
      })
    )
  );

  loadCTypesForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCTypesForSelect),
      concatMap(() => {
        return this.cTypesApiCaller.getCTypesForSelect().pipe(
          map((_) => loadCTypesForSelectSuccess({ data: _ })),
          catchError((error) => of(loadCTypesForSelectFailure({ error })))
        );
      })
    )
  );

  loadPageableCTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableCTypes),
      concatMap((data) => {
        return this.cTypesApiCaller.getPageableCTypes().pipe(
          map((_) => loadPageableCTypesSuccess({ data: _, })),
          catchError((error) => of(loadPageableCTypesFailure({ error })))
        );
      })
    )
  );

  getCType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCType),
      concatMap(({ id }) => {
        return this.cTypesApiCaller.getCType(id).pipe(
          map((_) => getCTypeSuccess({ data: _ })),
          catchError((error) => of(getCTypeFailure({ error })))
        );
      })
    )
  );

  deleteCType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCType),
      concatMap(({ id }) => {
        return this.cTypesApiCaller.deleteCType(id).pipe(
          map((_) => deleteCTypeSuccess({ id: id })),
          tap(() => this.reloadCTypes()),
          catchError((error) => of(deleteCTypeFailure({ error })))
        );
      })
    )
  );

  private reloadCTypes() {
    this.store$.dispatch(loadCTypesForSelect());
    this.store$.dispatch(loadPageableCTypes());
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private clientsApiCaller: ClientsApiCallerService,
    private cTypesApiCaller: CTypesApiCallerService,
    private router: Router,
    private matSnackBar: SnackBarWrapperService
  ) { }

}
