import { DateRange } from '../../interfaces/i-date-range';
import { DatetimeRange, ITime } from '../../interfaces/i-datetime-range';

export class OrderScheduleDto implements DateRange {
  constructor(data?: DatetimeRange | OrderScheduleDto) {
    if (data != null) {
      const dto = data as DatetimeRange;
      if (dto.timeFrom != null && dto.dateTo != null) {
        this.dateFrom = this.setTime(dto.dateFrom, dto.timeFrom);
        this.dateTo = this.setTime(dto.dateTo, dto.timeTo);
      } else {
        this.dateFrom = new Date(data.dateFrom);
        this.dateTo = new Date(data.dateTo);
      }
    }
  }
  dateFrom: Date;
  dateTo: Date;

  private setTime(date: Date, time: ITime): Date {
    const result = new Date(date);
    result.setHours(time.hour);
    result.setMinutes(time.minute);
    result.setSeconds(time.second);

    return result;
  }
}
