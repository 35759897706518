import { Action, createReducer, on } from '@ngrx/store';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { IngredientTypeDto } from '../../shared/models/ingredient-types/ingredient-type-dto';
import { loadIngredientTypes, loadIngredientTypesFailure, loadIngredientTypesForNode, loadIngredientTypesForNodeFailure, loadIngredientTypesForNodeSuccess, loadIngredientTypesSuccess } from './ingredient-types.actions';

export const CarsFeatureKey = 'IngredientTypes';

export interface State {
  ingredients?: IngredientTypeDto[];
  ingredientsForNode?: IngredientTypeDto[];
}

export const initialState: State = {
};

const ingredientTypeReducer = createReducer(
  initialState,


  on(loadIngredientTypes, (state) => state),
  on(loadIngredientTypesSuccess, (state, { data }) => setIngredientTypes(state, data)),
  on(loadIngredientTypesFailure, (state) => setIngredientTypes(state, null)),

  on(loadIngredientTypesForNode, (state) => state),
  on(loadIngredientTypesForNodeSuccess, (state, { data }) => setIngredientTypesForNode(state, data)),
  on(loadIngredientTypesForNodeFailure, (state) => setIngredientTypesForNode(state, null)),

);


function setIngredientTypes(state: State, data: IngredientTypeDto[]) {
  return {
    ...state,
    ingredients: data,
  };
}

function setIngredientTypesForNode(state: State, data: IngredientTypeDto[]) {
  return {
    ...state,
    ingredientsForNode: data,
  };
}


export function reducer(state: State | undefined, action: Action) {
  return ingredientTypeReducer(state, action);
}