import { createSelector } from '@ngrx/store';
import { StoreState } from '../store-state';
import { State } from './recipe.reducer';

const selectFeature = (state: StoreState) => state.recipes;

export const selectRecipe = createSelector(selectFeature, (state: State) => state.recipe);

export const selectAllPageableRecipesFilters = createSelector(selectFeature, (state: State) => state.recipesFilters);
export const selectPageableRecipesFilters = createSelector(selectFeature, (state: State, props: { key: string }) => {
  const result = state.recipesFilters ? state.recipesFilters.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectPageableRecipes = createSelector(selectFeature, (state: State, props: { key: string }) => {
  const result = state.pageableRecipes ? state.pageableRecipes.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});
