import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiCaller } from './base-api-caller';
import { PageableDto } from '../models/pageable.dto';
import { SelectModel } from '../models/select-model';
import { ClientDto } from '../models/dictionaries/clientDTO';
import { GetPageableClientsQuery } from '../models/queries/get-pageable-clients.query';
import { AddressDto } from '../models/dictionaries/addressDTO';
import { map } from 'rxjs/operators';
import { ClientType } from '../models/enums/client-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ClientsApiCallerService extends BaseApiCaller {
  protected controllerPath = 'clients';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addClient(dto: ClientDto) {
    return this.postWithReponseSimpleType<number>('', dto);
  }

  public updateClient(dto: ClientDto) {
    return this.put('', dto);
  }

  public deleteClient(id: number) {
    return this.delete(id.toString());
  }

  public getClient(id: number): Observable<ClientDto> {
    return this.get<ClientDto>(id.toString()).pipe(map((_) => new ClientDto(_)));
  }

  public getPageableClients(query: GetPageableClientsQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<ClientDto>>('', { params });
  }

  public getClientsForSelect(): Observable<SelectModel<number>[]> {
    return this.get<SelectModel<number>[]>('getForSelect');
  }
}
