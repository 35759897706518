
<div class="modal-header cursor-pointer">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button"  class="close" data-dismiss="modal" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="commentForm" (ngSubmit)="save()">
<div class="modal-body">

  <div    class="col-sm-12">
   
    <mat-form-field appearance="fill" class="example-full-width aaa">
      <mat-label>{{commentPlaceholder}}</mat-label>
      <input matInput   formControlName="content" style="width:   90% !important;">
      <mat-error *ngIf="comment?.errors?.required ||
      commentForm.controls['content'].errors?.required">{{ 'ValidationMessages.required' | translate }}    </mat-error>
      

    </mat-form-field>

  </div>

</div>
 

<div class="modal-footer">

  <button  (click)="save()" color="primary" mat-raised-button class="button-submit .button-color" style="float: right;" >
    {{ 'Common.Save' | translate }}
  </button>
</div>
</form>
