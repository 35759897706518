import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-stepper-navigation',
  templateUrl: './stepper-navigation.component.html',
  styleUrls: ['./stepper-navigation.component.scss'],
})
export class StepperNavigationComponent implements OnInit {
  @Input() type: 'first' | 'last';
  @Input() form: AbstractControl;
  @Output() onSave = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  save() {
    this.onSave.emit();
  }
}
