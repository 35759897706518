import { GridState } from '../contracts/grid-state';
import { IPageableQuery } from '../../interfaces/i-pageable-query';
import { PageableQuery } from '../contracts/pageable-query';

export class GetPageableQuery {
  constructor(gridState: GridState | PageableQuery) {
    let query: IPageableQuery;
    if (gridState instanceof PageableQuery) {
      query = { ...gridState, pageNumber: gridState.pageNumber + 1 };
    } else {
      query = gridState.getPageableQuery();
    }

    this.desc = query.desc.toString();
    this.orderBy = query.orderBy;
    this.pageNumber = query.pageNumber.toString();
    this.pageSize = query.pageSize.toString();
    this.searchTerm = gridState.searchTerm != null ? gridState.searchTerm : undefined;
  }
  desc: string;
  orderBy: string;
  pageNumber: string;
  pageSize: string;
  searchTerm?: string;
}
