import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreState } from '../store-state';
import { State } from './structural-element.reducer';

const selectStructuralElementFeature = (state: StoreState) => state.structuralElements;

export const selectStructuralElements = createSelector(
  selectStructuralElementFeature,
  (state: State) => state.structuralElements
);

export const selectPageableStructuralElements = createSelector(
  selectStructuralElementFeature,
  (state: State, props: { key: string }) => {
    const result = state.pageableStructuralElements
      ? state.pageableStructuralElements.find((_) => _.key === props.key)
      : null;

    return result ? result.value : null;
  }
);

export const selectStructuralElement = createSelector(selectStructuralElementFeature, (state: State) => {
  const result = state.structuralElement;
  return result ? result : null;
});

export const selectAllPageableStructuralElementsFilters = createSelector(
  selectStructuralElementFeature,
  (state: State) => state.structuralElementsFilters
);

export const selectPageableStructuralElementsFilters = createSelector(
  selectStructuralElementFeature,
  (state: State, props: { key: string }) => {
    const result = state.structuralElementsFilters
      ? state.structuralElementsFilters.find((_) => _.key === props.key)
      : null;

    return result ? result.value : null;
  }
);
