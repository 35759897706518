import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { HistoryDto } from '../models/history.dto';

@Injectable({
  providedIn: 'root',
})
export class OrderHistoryApiCallerService extends BaseApiCaller {
  protected controllerPath = 'History';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getOrderHistory(id: string) {
    return this.get<HistoryDto>(id);
  }
}
