import { Guid } from 'guid-typescript';

export class FileStatus<TKey> {
  constructor(file: File, id?: TKey) {
    this.file = file;
    this.id = id;
    this.progress = 0;
    this.finished = false;
    this.error = false;
  }
  id: TKey;
  file: File;
  progress: number;
  finished: boolean;
  error: boolean;
  isDeleting: boolean;
}
