import { Action, createReducer, on } from '@ngrx/store';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableWZDocumentsQuery } from '../../shared/models/queries/get-pageable-wzdocuments';
import { WZDocumentModel } from '../../shared/models/wzDocuments/wzDocumentModel';
import { loadPageableWZDocuments, loadPageableWZDocumentsFailure,
         loadPageableWZDocumentsSuccess, setPageableWZDocumentsFilters,
         setPageableWZDocumentsFiltersSuccess } from './wz-documents.actions';

export const CarsFeatureKey = 'WZDocuments';

export interface State {
  pageableWZDocuments?: DictionaryDto<string, PageableDto<WZDocumentModel>>;
  WZDocumentsFilters?: DictionaryDto<string, GetPageableWZDocumentsQuery>;
//   filteredWZDocuments?: WZDocumentModel[];
}

export const initialState: State = {
    WZDocumentsFilters: new DictionaryDto<string, GetPageableWZDocumentsQuery>(),
};

const wzDocumentsReducer = createReducer(
    initialState,

    on(loadPageableWZDocuments, (state, { key }) => setPageableWZDocuments(state, null, key)),
    on(loadPageableWZDocumentsSuccess, (state, { data, key }) => setPageableWZDocuments(state, data, key)),
    on(loadPageableWZDocumentsFailure, (state, action) => state),

    on(setPageableWZDocumentsFilters, (state, { data, key }) => setPageableWZDocumentsFiltersFunc(state, null, key)),
    on(setPageableWZDocumentsFiltersSuccess, (state, { data, key }) => setPageableWZDocumentsFiltersFunc(state, data, key)),

    );

function setPageableWZDocuments(state: State, data: PageableDto<WZDocumentModel>, key: string) {
    const pageableWZDocuments = Array.isArray(state.pageableWZDocuments)
      ? state.pageableWZDocuments
      : new DictionaryDto<string, PageableDto<WZDocumentModel>>();
    const orders = pageableWZDocuments.find((_) => _.key === key);
    if (orders != null) {
      orders.value = data;
    } else {
        pageableWZDocuments.push({ key, value: data });
    }

    return {
      ...state,
      pageableWZDocuments,
    };
}

function setPageableWZDocumentsFiltersFunc(state: State, data: GetPageableWZDocumentsQuery, key: string) {
    const WZDocumentsFilters = Array.isArray(state.WZDocumentsFilters)
      ? state.WZDocumentsFilters
      : new DictionaryDto<string, GetPageableWZDocumentsQuery>();
    const filter = WZDocumentsFilters.find((_) => _.key === key);
    if (filter != null) {
      filter.value = data;
    } else {
        WZDocumentsFilters.push({ key, value: data });
    }

    return {
      ...state,
      WZDocumentsFilters,
    };
  }

  export function reducer(state: State | undefined, action: Action) {
    return wzDocumentsReducer(state, action);
  }