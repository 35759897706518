import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { CustomValidator } from '../../../validators/custom.validator';
import { ValidatorPatterns } from '../../../validators/validator-patterns';

export class CarForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required]),
      typeId: new UntypedFormControl(null, [Validators.required]),
      typeName: new UntypedFormControl(null),
      employeeId: new UntypedFormControl(null, [Validators.required]),
      employeeName: new UntypedFormControl(null),
      registrationNumber: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(ValidatorPatterns.onlyLettersAndDigits),
      ]),
      pricePerKilometer: new UntypedFormControl(null),
      nodeName: new UntypedFormControl(null),
      volume: new UntypedFormControl(null, [Validators.required, CustomValidator.isCommaSeparatedNumber]),
      maxVolume: new UntypedFormControl(null, [Validators.required, CustomValidator.isCommaSeparatedNumber]),
      volumeTon: new UntypedFormControl(null, [Validators.required, CustomValidator.isCommaSeparatedNumber]),
      maxVolumeTon: new UntypedFormControl(null, [Validators.required, CustomValidator.isCommaSeparatedNumber]),
      mainNodeId: new UntypedFormControl(null, [Validators.required]),
      carNodes: new UntypedFormControl(null),
    });
  }

  forAdd() {
    return this;
  }
}
