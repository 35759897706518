import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { NodeDto } from '../../shared/models/dictionaries/nodeDTO';
import { GetPageableNodesQuery } from '../../shared/models/queries/get-pageable-nodes.query';

export const loadNodesForSelect = createAction('[FormsData] Load Nodes For Select');
export const loadNodesForSelectSuccess = createAction('[FormsData] Load Nodes For Select Success', props<{ data: SelectModel<string>[] }>());
export const loadNodesForSelectFailure = createAction('[FormsData] Load Nodes For Select Failure', props<{ error: any }>());

export const loadPageableNodes = createAction('[FormsData] Load Pageable Nodes', props<{ key: string }>());
export const loadPageableNodesSuccess = createAction('[FormsData] Load Pageable Nodes Success', props<{ data: PageableDto<NodeDto>; key: string }>());
export const loadPageableNodesFailure = createAction('[FormsData] Load Pageable Nodes Failure', props<{ error: any }>());

export const deleteNode = createAction('[FormsData] delete Node', props<{ id: number }>());
export const deleteNodeSuccess = createAction('[FormsData] delete Node Success', props<{ id: number }>());
export const deleteNodeFailure = createAction('[FormsData] delete Node Failure', props<{ error: any }>());

export const addNode = createAction('[FormsData] Add Node', props<{ data: NodeDto }>());
export const addNodeSuccess = createAction('[FormsData] Add Node Success', props<{ id: number; name: string }>());
export const addNodeFailure = createAction('[FormsData] Add Node Failure', props<{ error: any }>());

export const updateNode = createAction('[FormsData] Update Node', props<{ data: NodeDto }>());
export const updateNodeSuccess = createAction('[FormsData] Update Node Success');
export const updateNodeFailure = createAction('[FormsData] Update Node Failure', props<{ error: any }>());

export const getNode = createAction('[FormsData] Get Node', props<{ id: number }>());
export const getNodeSuccess = createAction('[FormsData] Get Node Success', props<{ data: NodeDto }>());
export const getNodeFailure = createAction('[FormsData] Get Node Failure', props<{ error: any }>());

export const setPageableNodesFilters = createAction('[Authors] Set Pageable Nodes Filters', props<{ data?: GetPageableNodesQuery; key: string }>());
export const setPageableNodesFiltersSuccess = createAction('[Authors] Set Pageable Nodes Filters Success', props<{ data: GetPageableNodesQuery; key: string }>());
export const setPageableNodesFiltersFailure = createAction('[Authors] Set Pageable Nodes Filters Failure', props<{ error: any }>());