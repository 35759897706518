<div class="display-grid">
  <mat-card-subtitle>{{ 'PersonalData.Identifications' | translate }}</mat-card-subtitle>
  <mat-radio-group aria-label="Select an option" [formControl]="form.get('individualType')">
    <mat-radio-button class="mr-20" [value]="individualType.LegalEntity">{{
      'PersonalData.LegalEntity' | translate
    }}</mat-radio-button>
    <mat-radio-button class="mr-20" [value]="individualType.PrivateIndividual">{{
      'PersonalData.PrivateIndividual' | translate
    }}</mat-radio-button>
  </mat-radio-group>

  <ng-container [ngSwitch]="form.value.individualType">
    <form class="display-grid" *ngSwitchCase="individualType.PrivateIndividual"
      [formGroup]="form.get('privateIndividualData')">
      <app-input [label]="'PersonalData.FirstName' | translate"
        [form]="form.get(['privateIndividualData', 'firstName'])"></app-input>
      <app-input [label]="'PersonalData.LastName' | translate" [form]="form.get(['privateIndividualData', 'lastName'])">
      </app-input>

      <!-- <mat-form-field>
        <mat-label>{{ 'PersonalData.Post' | translate }}</mat-label>
        <input matInput formControlName="post" />
      </mat-form-field> -->
      <div class="height-30"></div>

      <app-input [label]="'PersonalData.IdentityDocument' | translate"
        [placeholder]="'PersonalData.EnterFormat' | translate: { format: 'YYYxxxxxx' }"
        [form]="form.get(['privateIndividualData', 'identityCardNumber'])"></app-input>
      <app-input [label]="'PersonalData.PersonalIdentityNumber' | translate"
        [placeholder]="'PersonalData.EnterFormat' | translate: { format: 'xxxxxxxxx' }"
        [form]="form.get(['privateIndividualData', 'pesel'])"></app-input>
    </form>

    <form class="display-grid" *ngSwitchCase="individualType.LegalEntity" [formGroup]="form.get('legalEntityData')">
      <app-input [label]="'Common.Name' | translate" [form]="form.get(['legalEntityData', 'name'])"></app-input>
      <div class="height-30"></div>
    </form>
  </ng-container>
</div>
