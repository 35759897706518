import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { AuthorDto } from '../../shared/models/dictionaries/authorDTO';
import { GetPageableAuthorsQuery } from '../../shared/models/queries/get-pageable-authors.query';

export const loadAuthorsForSelect = createAction('[Authors] Load Authors For Select');
export const loadAuthorsForSelectSuccess = createAction(
  '[Authors] Load Authors For Select Success',
  props<{ data: SelectModel<number>[] }>()
);
export const loadAuthorsForSelectFailure = createAction(
  '[Authors] Load Authors For Select Failure',
  props<{ error: any }>()
);

export const loadPageableAuthors = createAction('[Authors] Load Pageable Authors', props<{ key: string }>());
export const loadPageableAuthorsSuccess = createAction(
  '[Authors] Load Pageable Authors Success',
  props<{ data: PageableDto<AuthorDto>; key: string }>()
);
export const loadPageableAuthorsFailure = createAction(
  '[Authors] Load Pageable Authors Failure',
  props<{ error: any }>()
);

export const deleteAuthor = createAction('[Authors] delete Author', props<{ id: number }>());
export const deleteAuthorSuccess = createAction('[Authors] delete Author Success', props<{ id: number }>());
export const deleteAuthorFailure = createAction('[Authors] delete Author Failure', props<{ error: any }>());

export const addAuthor = createAction('[Authors] Add Client', props<{ data: AuthorDto }>());
export const addAuthorSuccess = createAction('[Authors] Add Author Success', props<{ id: number; name: string }>());
export const addAuthorFailure = createAction('[Authors] Add Author Failure', props<{ error: any }>());

export const updateAuthor = createAction('[Authors] Update Author', props<{ data: AuthorDto }>());
export const updateAuthorSuccess = createAction('[Authors] Update Author Success');
export const updateAuthorFailure = createAction('[Authors] Update Author Failure', props<{ error: any }>());

export const getAuthor = createAction('[Authors] Get Author', props<{ id: number }>());
export const getAuthorSuccess = createAction('[Authors] Get Author Success', props<{ data: AuthorDto }>());
export const getAuthorFailure = createAction('[Authors] Get Author Failure', props<{ error: any }>());

export const setPageableAuthorsFilters = createAction(
  '[Authors] Set Pageable Authors Filters',
  props<{ data?: GetPageableAuthorsQuery; key: string }>()
);
export const setPageableAuthorsFiltersSuccess = createAction(
  '[Authors] Set Pageable Authors Filters Success',
  props<{ data: GetPageableAuthorsQuery; key: string }>()
);
export const setPageableAuthorsFiltersFailure = createAction(
  '[Authors] Set Pageable Authors Filters Failure',
  props<{ error: any }>()
);
