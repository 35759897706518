import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackBarWrapperService } from '../services/snack-bar-wrapper.service';

import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService, private _snackBar: SnackBarWrapperService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.oidcSecurityService.forceRefreshSession();
        } else if (err.status === 403) {
          this._snackBar.openMessage('Brak uprawnienń', 'error');
        } else if (err.status >= 400) {
          this.convertError(err);
        }

        return Observable.throw(err);
      })
    );
  }

  convertError(err: any) {
    if (err.error instanceof Blob && err.error.type === 'application/json') {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const response: Response = JSON.parse(e.target.result as string) as Response;
        this.showErrors(response);
      };
      reader.readAsText(err.error);
    } else {
      this.showErrors(err.error);
    }
  }

  private showErrors(data) {
    let errors: string = "";
    if (data.errors != null) {
      if(typeof data.errors == 'object'){
        for (const [key, value] of Object.entries(data.errors)) {
          errors += `${key}: ${value}\n`
        }
      } else {
        errors = data.errors.join('<br />');
      }
    } else {
      if(typeof data.Errors == 'object'){
        for (const [key, value] of Object.entries(data.Errors)) {
          errors += `${key}: ${value}\n`
        }
      } else {
        errors = data.Errors.join('<br />');
      }
    }
    this._snackBar.openMessage(errors, 'error');
  }
}
