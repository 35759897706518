import { createAction, props } from '@ngrx/store';
import { UpdateCompanyEmployeeDto } from '../../shared/models/company-employee/update-company-employee.dto';
import { CompanyEmployeeDto } from '../../shared/models/company-employee/company-employee.dto';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableCompanyEmployeesQuery } from '../../shared/models/queries/get-pageable-company-employees.query';

export const createCompanyEmployee = createAction(
  '[CompanyEmployee] Create CompanyEmployee',
  props<{ data: UpdateCompanyEmployeeDto }>()
);

export const createCompanyEmployeeSuccess = createAction(
  '[CompanyEmployee] Create CompanyEmployee Success',
  props<{ id: string }>()
);

export const createCompanyEmployeeFailure = createAction(
  '[CompanyEmployee] Create CompanyEmployee Failure',
  props<{ error: any }>()
);

export const updateCompanyEmployee = createAction(
  '[CompanyEmployee] Update CompanyEmployee',
  props<{ data: UpdateCompanyEmployeeDto }>()
);

export const updateCompanyEmployeeSuccess = createAction(
  '[CompanyEmployee] Update CompanyEmployee Success',
  props<{ id: string }>()
);

export const updateCompanyEmployeeFailure = createAction(
  '[CompanyEmployee] Update CompanyEmployee Failure',
  props<{ error: any }>()
);

export const deleteCompanyEmployee = createAction('[CompanyEmployee] Delete CompanyEmployee', props<{ id: string }>());

export const deleteCompanyEmployeeSuccess = createAction(
  '[CompanyEmployee] Delete CompanyEmployee Success',
  props<{ id: string }>()
);

export const deleteCompanyEmployeeFailure = createAction(
  '[CompanyEmployee] Delete CompanyEmployee Failure',
  props<{ error: any }>()
);

export const getPageableCompanyEmployee = createAction(
  '[CompanyEmployee] Get Pageable CompanyEmployee',
  props<{ filters?: GetPageableCompanyEmployeesQuery }>()
);

export const getPageableCompanyEmployeeSuccess = createAction(
  '[CompanyEmployee] Get Pageable  CompanyEmployee Success',
  props<{ data: PageableDto<CompanyEmployeeDto> }>()
);

export const getPageableCompanyEmployeeFailure = createAction(
  '[CompanyEmployee] Get Pageable  CompanyEmployee Failure',
  props<{ error: any }>()
);
