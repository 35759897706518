import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { environment } from 'project/src/environments/environment';
import { da } from 'date-fns/locale';

@Injectable({
  providedIn: 'root',
})
export class SupplyHubService {
  connections: signalR.HubConnection;

  hubSupplyMessage: Subject<string>;
  stringSupplyConnect = '/signalr/supplyHub';

  connectionIsStarting = false;

  constructor(private oidcSecurityService: OidcSecurityService) {
    this.hubSupplyMessage = new Subject<string>();
  }

  public initiateSignalrSupplyConnection() {
    if (this.connections == null) {
      const token = this.oidcSecurityService.getToken();
      const url = environment.apiRoot + this.stringSupplyConnect;
      this.connections = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => token,
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();
    }

    if (this.connections.state !== signalR.HubConnectionState.Connected && !this.connectionIsStarting) {
      this.connectionIsStarting = true;
      this.connections
        .start()
        .then(() => console.log('Connection started'))
        .then(() => this.connections.invoke('send'))
        .then(() => (this.connectionIsStarting = false))
        .catch((err) => console.log('Error while starting connection: ' + err));
    }
    this.supplyNotification();
  }

  private supplyNotification() {
    this.connections.off('send-do-reload-supply');
    this.connections.on('send-do-reload-supply', (data) => {
      this.hubSupplyMessage.next(data);
    });
  }
}
