export const customerFeatureKey = 'RecipeCosts';
export const RecipeCostsFeatureKey = 'RecipeCosts';



export interface State {
    RecipeCosts?: RecipeCostDto[];
}

import { PageableDto } from '../../shared/models/pageable.dto';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { SelectModel } from '../../shared/models/select-model';
import { Action, createReducer, on } from '@ngrx/store';
import { RecipeCostDto } from '../../shared/models/recipe-costs/recipe-cost-dto';
import { addRecipeCost, addRecipeCostSuccess, addRecipeCostFailure, loadPageableRecipeCosts, loadPageableRecipeCostsSuccess, loadPageableRecipeCostsFailure, getRecipeCost, getRecipeCostSuccess, getRecipeCostFailure, setPageableRecipeCostsFilters, setPageableRecipeCostsFiltersSuccess } from './recipe-costs.actions';
import { GetPageableRecipeCostsQuery } from '../../shared/models/queries/get-pageable-recipe-costs-query';



export interface State {
  pageableRecipeCosts?: DictionaryDto<string, PageableDto<RecipeCostDto>>;
  RecipeCostsFilters?: DictionaryDto<string, GetPageableRecipeCostsQuery>;
  RecipeCost?: RecipeCostDto;
  carTypesForSelect?: SelectModel<string>[];
}

export const initialState: State = {
  RecipeCostsFilters: new DictionaryDto<string, GetPageableRecipeCostsQuery>(),
};

const RecipeCostsReducer = createReducer(
  initialState,

  on(addRecipeCost, (state) => setAddRecipeCost (state, null)),
  on(addRecipeCostSuccess, (state) => setAddRecipeCost(state, null)),
  on(addRecipeCostFailure, (state) => setAddRecipeCost(state, null)),

  on(loadPageableRecipeCosts, (state, { key }) => setPageableRecipeCosts(state, null, key)),
  on(loadPageableRecipeCostsSuccess, (state, { data, key }) => setPageableRecipeCosts(state, data, key)),
  on(loadPageableRecipeCostsFailure, (state, action) => state),

  on(getRecipeCost, (state) => setRecipeCost(state, null)),
  on(getRecipeCostSuccess, (state, { data}) => setRecipeCost(state, data)),
  on(getRecipeCostFailure, (state, action) => state),

  on(setPageableRecipeCostsFilters, (state, { data, key }) => setPageableRecipeCostsFiltersFunc(state, null, key)),
  on(setPageableRecipeCostsFiltersSuccess, (state, { data, key }) => setPageableRecipeCostsFiltersFunc(state, data, key)),

);

function setPageableRecipeCosts(state: State, data: PageableDto<RecipeCostDto>, key: string) {
  const pageableRecipeCosts = Array.isArray(state.pageableRecipeCosts)
    ? state.pageableRecipeCosts
    : new DictionaryDto<string, PageableDto<RecipeCostDto>>();
  const orders = pageableRecipeCosts.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableRecipeCosts.push({ key, value: data });
  }

  return {
    ...state,
    pageableRecipeCosts,
  };
}

function setRecipeCost(state: State, data: RecipeCostDto) {
  var RecipeCost = data;
  return {
    ...state,
    RecipeCost,
  };
}

function setAddRecipeCost(state: State, attachmentsProgress: RecipeCostDto[]) {
  return {
    ...state,
    attachmentsProgress,
  };
}

function setPageableRecipeCostsFiltersFunc(state: State, data: GetPageableRecipeCostsQuery, key: string) {
  const carsFilters = Array.isArray(state.RecipeCostsFilters)
    ? state.RecipeCostsFilters
    : new DictionaryDto<string, GetPageableRecipeCostsQuery>();
  const filter = carsFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    carsFilters.push({ key, value: data });
  }

  return {
    ...state,
    carsFilters,
  };
}


export function reducer(state: State | undefined, action: Action) {
  return RecipeCostsReducer(state, action);
}
