import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ContractorDataForm } from './contractor-data.form';
import { CompanyDto } from '../../../models/company/company.dto';
import { IndividualType } from '../../../models/enums/individual-type.enum';
import { PersonListForm } from '../persons-list/person-list.form';
import { IPatchValue } from '../../../interfaces/i-patch-value';
import { CompanyValidatorsApiCallerService } from '../../../api-services/company-validators-api-caller.service';
import { SuperUserAccountForm } from './super-user-account.form';
import { AccountApiCallerService } from '../../../api-services/account-api-caller.service';
import { IdentityUsersApiCallerService } from '../../../api-services/identity-users-api-caller.service';

export class ContractorForm extends UntypedFormGroup {
  constructor(
    companyValidatorsApiCaller: CompanyValidatorsApiCallerService,
    private accountApiCallerService?: IdentityUsersApiCallerService
  ) {
    super({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      contractorData: new ContractorDataForm(companyValidatorsApiCaller),
      persons: new UntypedFormArray([]),
    });
  }

  forAddContractor() {
    this.removeControl('persons');
    this.addControl('superUserAccount', new SuperUserAccountForm(this.accountApiCallerService));

    return this;
  }

  forAddOperation() {
    this.removeControl('contractorData');
    this.get('id').setValue(null);
    this.get('id').setValidators(Validators.required);

    return this;
  }

  patchValue(
    value: IPatchValue | CompanyDto,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    if (value instanceof CompanyDto) {
      this.get('id').patchValue(value.id);
      this.get('contractorData').patchValue(value, options);
      this.patchPerson(value);
    } else {
      super.patchValue(value, options);
    }
  }

  private patchPerson(company: CompanyDto) {
    const formArray = this.get('persons') as UntypedFormArray;
    formArray.clear();

    company.companyEmployees.forEach((item) => {
      formArray.push(new PersonListForm(item));
    });
  }
}
