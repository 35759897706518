import { createAction, props } from '@ngrx/store';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { VersionDto } from '../../shared/models/version.dto';
import { PageableDto } from '../../shared/models/pageable.dto';

export const loadPageableVersions = createAction(
  '[Version] Load Pageable Versions',
  props<{ filters?: GetPageableQuery }>()
);

export const loadPageableVersionsSuccess = createAction(
  '[Version] Load Pageable Versions Success',
  props<{ data: PageableDto<VersionDto> }>()
);

export const loadPageableVersionsFailure = createAction(
  '[Version] Load Pageable Versions Failure',
  props<{ error: any }>()
);

export const createVersion = createAction('[Version] Create Version', props<{ data: VersionDto }>());

export const createVersionSuccess = createAction('[Version] Create Version Success', props<{ id: string }>());

export const createVersionFailure = createAction('[Version] Create Version Failure', props<{ error: any }>());
