import { Injectable } from '@angular/core';
import { BaseApiCaller } from '../base-api-caller';
import { HttpClient } from '@angular/common/http';
import { DictionaryModel } from '../../models/dictionary-model';

@Injectable({
  providedIn: 'root',
})
export class CementTypeApiCallerService extends BaseApiCaller {
  protected controllerPath = 'cementType';

  // constructor(httpClient: HttpClient, private datePipe: DatePipe) {
  //   super(httpClient);
  //}
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAll() {
    return this.get<DictionaryModel<string>[]>('');
  }
}
