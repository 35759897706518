import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { PersonListForm } from '../persons-list/person-list.form';
import { AccountApiCallerService } from '../../../api-services/account-api-caller.service';
import { IdentityUsersApiCallerService } from '../../../api-services/identity-users-api-caller.service';

function init() {
  return { isRequired: new UntypedFormControl(false) };
}

export class SuperUserAccountForm extends UntypedFormGroup {
  constructor(accountApiCallerService: IdentityUsersApiCallerService) {
    super(init());

    this.get('isRequired').valueChanges.subscribe((value) => {
      if (value) {
        this.addControl('account', new PersonListForm().forAdd(accountApiCallerService));
      } else {
        this.removeControl('account');
      }
    });
  }
}
