import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './employees.reducer';

const selectEmployeesFeature = (state: StoreState) => state.employees;

export const selectEmployeesForSelect = createSelector(selectEmployeesFeature, (state: State, props: { key: string }) => {
  const result = state.employeesForSelect;
  return result ? result : null;
});

export const selectPageableEmployees = createSelector(selectEmployeesFeature, (state: State, props: {key: string}) => {
  const result = state.pageableEmployees ? state.pageableEmployees.find((_) => _.key === props.key) : null;
  return result ? result.value : null;
});

export const selectEmployee = createSelector(selectEmployeesFeature, (state: State) => {
  const result = state.employee;
  return result ? result : null;
});

export const selectAllPageableEmployeesFilters = createSelector(selectEmployeesFeature, (state: State) => state.employeesFilters);
export const selectPageableEmployeesFilters = createSelector(selectEmployeesFeature, (state: State, props: { key: string }) => {
  const result = state.employeesFilters ? state.employeesFilters.find((_) => _.key === props.key) : null;
  return result ? result.value : null;
});

export const selectAddedEmployee = createSelector(selectEmployeesFeature, (state: State) => state.employeeAdded);