import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ClientForm } from '../client/client.form';
import { SelectModelForm } from '../select-model.form';
import { CompanyValidatorsApiCallerService } from '../../../api-services/company-validators-api-caller.service';

export class ContractForm extends UntypedFormGroup {
  constructor(companyValidatorsApiCaller: CompanyValidatorsApiCallerService) {
    super({
      id: new UntypedFormControl(Guid.create(), Validators.required),
      name: new UntypedFormControl(null, Validators.required),
      // businessUnit: new SelectModelForm(),
      client: new ClientForm(companyValidatorsApiCaller).forFullView(),
    });
  }

  patchValue(data) {}
}
