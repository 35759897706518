import { CustomerDto } from './../../shared/models/customers/customer-dto';
import { createAction, props } from '@ngrx/store';
import { CustomerModel } from '../../shared/models/calendar/customer.model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableCustomersQuery } from '../../shared/models/queries/get-pageable-customers-query';
import { GusCustomerDto } from '../../shared/models/customers/gus-customer-dto';
import { AliasDto } from '../../shared/models/customers/alias.dto';

export const loadCustomer = createAction('[Customer] Load Customer');

export const loadCustomerSuccess = createAction('[Customer] Load Customer Success', props<{ data: CustomerModel[] }>());
export const loadCustomerFailure = createAction('[Customer] Load Customer Failure', props<{ error: any }>());

export const loadPageableCustomers = createAction('[Customers] Load Pageable Customers', props<{ key: string }>());
export const loadPageableCustomersSuccess = createAction(
  '[Customers] Load Pageable Customers Success',
  props<{ data: PageableDto<CustomerDto>; key: string }>()
);
export const loadPageableCustomersFailure = createAction(
  '[Customers] Load Pageable Customers Failure',
  props<{ error: any }>()
);

export const setPageableCustomersFilters = createAction(
  '[Customers] Set Pageable Customers Filters',
  props<{ data?: GetPageableCustomersQuery; key: string }>()
);
export const setPageableCustomersFiltersSuccess = createAction(
  '[Customers] Set Pageable Customers Filters Success',
  props<{ data: GetPageableCustomersQuery; key: string }>()
);
export const setPageableCustomersFiltersFailure = createAction(
  '[Customers] Set Pageable Customers Filters Failure',
  props<{ error: any }>()
);

export const getCustomer = createAction('[Customer] get Customer', props<{ id: string }>());
export const getCustomerSuccess = createAction('[Customer] get Customer Success', props<{ data: CustomerDto }>());
export const getCustomerFailure = createAction('[Customer] get Customer Failure', props<{ error: any }>());

export const getCustomerDataByNip = createAction('[Customer] get CustomerDataByNip', props<{ nip: string }>());
export const getCustomerDataByNipSuccess = createAction('[Customer] get CustomerDataByNip Success', props<{ data: GusCustomerDto }>());
export const getCustomerDataByNipFailure = createAction('[Customer] get CustomerDataByNip Failure', props<{ error: any }>());

export const addCustomer = createAction('[Customer] Add Customer', props<{ data: CustomerDto }>());
export const addCustomerSuccess = createAction('[Customer] Add Customer Success', props<{ data: any }>());
export const addCustomerFailure = createAction('[Customer] Add Customer Failure', props<{ error: any }>());

export const updateCustomer = createAction('[Customer] Update Customer', props<{ data: CustomerDto }>());
export const updateCustomerSuccess = createAction('[Customer] Update Customer Success');
export const updateCustomerFailure = createAction('[Customer] Update Customer Failure', props<{ error: any }>());

export const addCustomerAlias = createAction('[CustomerAlias] Add CustomerAlias', props<{ data: AliasDto}>());

export const addCustomerAliasSuccess = createAction('[CustomerAlias] Add CustomerAlias Success', props<{ id: string }>());

export const addCustomerAliasFailure = createAction('[CustomerAlias] Add CustomerAlias Failure', props<{ error: any }>());
