import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State } from './clients.reducer';

const selectClientsFeature = (state: StoreState) => state.clients;

export const selectClientsForSelect = createSelector(selectClientsFeature, (state: State, props: { key: string }) => {
  const result = state.clientsForSelect;
  return result ? result : null;
});

export const selectPageableClients = createSelector(selectClientsFeature, (state: State, props: { key: string }) => {
  const result = state.pageableClients ? state.pageableClients.find((_) => _.key === props.key) : null;
  console.log(result?.value);
  return result ? result.value : null;
});

export const selectClient = createSelector(selectClientsFeature, (state: State) => {
  const result = state.client;
  return result ? result : null;
});

export const selectAllPageableClientsFilters = createSelector(
  selectClientsFeature,
  (state: State) => state.clientsFilters
);
export const selectPageableClientsFilters = createSelector(
  selectClientsFeature,
  (state: State, props: { key: string }) => {
    const result = state.clientsFilters ? state.clientsFilters.find((_) => _.key === props.key) : null;
    return result ? result.value : null;
  }
);

export const selectAddedClient = createSelector(selectClientsFeature, (state: State) => state.clientAdded);
