import { Action, createReducer, on } from '@ngrx/store';
import { loadNodesForSelect, loadNodesForSelectSuccess, loadNodesForSelectFailure, loadPageableNodes, loadPageableNodesSuccess, loadPageableNodesFailure, getNode, getNodeSuccess, getNodeFailure, setPageableNodesFilters, setPageableNodesFiltersSuccess, addNodeSuccess } from './nodes.actions';
import { SelectModel } from '../../shared/models/select-model';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { NodeDto } from '../../shared/models/dictionaries/nodeDTO';
import { GetPageableNodesQuery } from '../../shared/models/queries/get-pageable-nodes.query';

export const nodesFeatureKey = 'nodes';

export interface State {
  nodesForSelect?: SelectModel<string>[];
  pageableNodes?: DictionaryDto<string,PageableDto<NodeDto>>;
  node?: NodeDto;
  nodesFilters?: DictionaryDto<string, GetPageableNodesQuery>;
  nodeAdded?: { id: number; name: string };
}

export const initialState: State = {
  nodesFilters: new DictionaryDto<string, GetPageableNodesQuery>(),
};

const usersReducer = createReducer(
  initialState,
  
  on(loadNodesForSelect, (state) => setNodesForSelect(state, null)),
  on(loadNodesForSelectSuccess, (state, { data }) => setNodesForSelect(state, data)),
  on(loadNodesForSelectFailure, (state, action) => state),

  on(loadPageableNodes, (state, {key}) => setPageableNodes(state, null, key)),
  on(loadPageableNodesSuccess, (state, { data, key }) => setPageableNodes(state, data, key)),
  on(loadPageableNodesFailure, (state, action) => state),

  on(getNode, (state) => setNode(state, null)),
  on(getNodeSuccess, (state, { data}) => setNode(state, data)),
  on(getNodeFailure, (state, action) => state),

  on(setPageableNodesFilters, (state, { data, key }) => setPageableNodesFiltersFunc(state, null, key)),
  on(setPageableNodesFiltersSuccess, (state, { data, key }) => setPageableNodesFiltersFunc(state, data, key)),

  on(addNodeSuccess, (state, data) => setAddNode(state, data))
);

function setNodesForSelect(state: State, data: SelectModel<string>[]) {
  const nodesForSelect = data;
  return {
    ...state,
    nodesForSelect,
  };
}

function setAddNode(state: State, nodeAdded) {
  return {
    ...state,
    nodeAdded,
  };
}

function setPageableNodes(state: State, data: PageableDto<NodeDto>, key : string) {
  const pageableNodes = Array.isArray(state.pageableNodes)
    ? state.pageableNodes
    : new DictionaryDto<string, PageableDto<NodeDto>>();
  const orders = pageableNodes.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableNodes.push({ key, value: data });
  }

  return {
    ...state,
    pageableNodes,
  };
}

function setNode(state: State, data: NodeDto) {
  var node = data;
  return {
    ...state,
    node,
  };
}

function setPageableNodesFiltersFunc(state: State, data: GetPageableNodesQuery, key: string) {
  const nodesFilters = Array.isArray(state.nodesFilters)
    ? state.nodesFilters
    : new DictionaryDto<string, GetPageableNodesQuery>();
  const filter = nodesFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    nodesFilters.push({ key, value: data });
  }

  return {
    ...state,
    nodesFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
