import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export class SpecialAdditivesForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, Validators.required),
      unit: new UntypedFormControl(null, Validators.required),
      unitPrice: new UntypedFormControl(null, Validators.required),
      weight: new UntypedFormControl(null, Validators.required),
      isForHigherClass: new UntypedFormControl(null, Validators.required),
      customerId: new UntypedFormControl(null),
      specialAdditiveId: new UntypedFormControl(null),
      specialAdditiveName: new UntypedFormControl(null),
      addressId: new UntypedFormControl(null),
      addressName: new UntypedFormControl(null)
    });
  }

  forAdd() {
    return this;
  }
}
