import { Component, OnInit, Input } from '@angular/core';
import { ContactType } from '../../models/enums/contact-type.enum';

@Component({
  selector: 'app-contact-entry',
  templateUrl: './contact-entry.component.html',
  styleUrls: ['./contact-entry.component.scss'],
})
export class ContactEntryComponent implements OnInit {
  @Input() contact: ContactType;
  @Input() showIcon = true;
  @Input() showLabel = true;
  contactType = ContactType;
  constructor() {}

  ngOnInit(): void {}
}
