import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './other-documents.reducer';

const selectOtherDocumentsFeature = (state: StoreState) => state.otherDocuments;

export const selectPageableOtherDocuments = createSelector(selectOtherDocumentsFeature, (state: State, props: { key: string }) => {
  const result = state.pageableOtherDocuments ? state.pageableOtherDocuments.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectOtherDocument = createSelector(selectOtherDocumentsFeature, (state: State) => {
  const result = state.editorialDocument;
  return result ? result : null;
});

export const selectAllPageableOtherDocumentsFilters = createSelector(selectOtherDocumentsFeature, (state: State) => state.employeesFilters);
export const selectPageableOtherDocumentsFilters = createSelector(selectOtherDocumentsFeature, (state: State, props: { key: string }) => {
  const result = state.employeesFilters ? state.employeesFilters.find((_) => _.key === props.key) : null;
  return result ? result.value : null;
});