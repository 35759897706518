import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UpdateRecipe } from '../models/recipe/update-recipe.dto';
import { RecipeDto } from '../models/recipe-dto';
import { PageableDto } from '../models/pageable-dto';
import { UpdateCementDto } from '../models/cement/update-cement-dto';
import { UpdatePropertiesMixtureDto } from '../models/propertiesMixture/update-properties-mixture-dto';
import { ConsistencyDto } from '../models/consistency/update-consistency-dto';
import { UpdateAdditiveDto } from '../models/additive/update-additive-dto';
import { AdmixtureDto } from '../models/admixture/update-admixture-dto';
import { AggregateDto } from '../models/aggregate/update-aggregate-dto';
import { UpdateWaterDto } from '../models/water/update-water-dto';
import { GetPageableRecipesQuery } from 'project/src/app/shared/models/queries/get-pageable-recipes-query';

@Injectable({
  providedIn: 'root',
})
export class RecipeApiCallerService extends BaseApiCaller {
  protected controllerPath = 'recipe';

  // constructor(httpClient: HttpClient, private datePipe: DatePipe) {
  //   super(httpClient);
  //}
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addRecipe(recipe: UpdateRecipe) {
    return this.post('', recipe);
  }

  public updateRecipe(recipe: UpdateRecipe) {
    return this.put('', recipe);
  }

  public getRecipeById(id: string) {
    return this.get<UpdateRecipe>(id);
  }

  public deleteRecipe(id: string) {
    return this.delete(id);
  }

  public getPageableRecipes(filters: GetPageableRecipesQuery) {
    // if (filters.dateFrom) {
    //   dateFrom = moment(filters.dateFrom).format(environment.apiDateFormat);
    // }
    // if (filters.dateTo) {
    //   dateTo = moment(filters.dateTo).format(environment.apiDateFormat);
    // }
    const transform = {
      ...filters,
    };

    const tmpParams = this.prepareParams(transform);
    const params = this.modifyFormat(tmpParams);

    const headers = { 'accept-language': 'pl-PL' };

    return this.get<PageableDto<RecipeDto>>('', { params, headers });
  }

  public getRecipe(id: string) {
    return this.get<RecipeDto>(id);
  }
  private modifyFormat(params: HttpParams): HttpParams {
    return params;
  }

  public getCementForRecipe(id: string) {
    return this.get<UpdateCementDto>(id + '/cement');
  }

  public getWaterForRecipe(id: string) {
    return this.get<UpdateWaterDto>(id + '/water');
  }

  public getPropertiesMixtureForRecipe(id: string) {
    return this.get<UpdatePropertiesMixtureDto>(id + '/propertiesMixture');
  }

  public getConsistencyForRecipe(id: string) {
    return this.get<ConsistencyDto>(id + '/consistency');
  }

  public getRecipeAdditives(id: string) {
    return this.get<UpdateAdditiveDto[]>(id + '/additives');
  }

  public getRecipeAdmixtures(id: string) {
    return this.get<AdmixtureDto[]>(id + '/admixtures');
  }

  public getRecipeAggregates(id: string) {
    return this.get<AggregateDto[]>(id + '/aggregates');
  }
}
