import { OrderCancellationReason } from './../../models/orders/orderCancellationReason.model';
import { GetPageableOrdersCatalog } from './../../models/queries/get-pageable-orders-catalog.query';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CustomerOrderHistory } from '../../models/customerOrderHistoryModel';
import { CommentsOfDayDto } from '../../models/orders/commentsOfDayDto';
import { OrderDto } from '../../models/orders/order.dto';
import { OrderDateModel } from '../../models/orders/orderDate.model';
import { OrderForDeliveryStatusesDto } from '../../models/orders/orderForDeliveryStatuses.Dto';
import { OrderStatusesForHour } from '../../models/orders/ordersForHourRange';
import { OrderGroupedByHourHour } from '../../models/orders/ordersGroupedByHour';
import { OrderStatus } from '../../models/orders/orderState.model';
import { BaseApiCaller } from '../base-api-caller';
import { PageableDto } from '../../models/pageable.dto';
import { ActiveOrderModel } from '../../models/activeOrderModel';
import { OrderForNodes } from '../../models/orders/orderforNodes';
import { Observable } from 'rxjs';
import { SettlementsDto } from '../../models/orders/settlementsDto';
import { OrderEditHistoryModel } from '../api-services';
import { GetPageableOrderEditHistoryQuery } from '../../models/queries/get-pageable-order-edit-history.query';

@Injectable({
  providedIn: 'root',
})
export class OrderApiCallerService extends BaseApiCaller {
  protected controllerPath = 'orders';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getOrder() {
    return this.get<OrderDto[]>('');
  }

  public getOrderById(id: string) {
    return this.get<OrderDto>(id.toString()).pipe(map((_) => new OrderDto(_)));
  }

  public postOrder(order: OrderDto) {
    return this.post('', order);
  }

  public getOrderByDate(date: any) {
    return this.get<OrderDto[]>(date.payload.nodeId + '/' + date.payload.dateFrom + '/' + date.payload.dateTo);
  }

  public updateOrderDate(date: OrderDateModel) {
    return this.put('UpdateDate', date);
  }

  public addCopyOrder(order: OrderDto) {
    return this.post('AddCopyOrder', order);
  }

  public updateOrder(date: OrderDto) {
    return this.put('', date);
  }

  public updateOrderState(date: OrderStatus) {
    return this.put('UpdateState', date);
  }

  public updateOrderSettlement(order: SettlementsDto) {
    return this.put('UpdateSettlement', order);
  }

  public updateCancellationReason(date: OrderCancellationReason) {
    return this.put('UpdateCancellationReason', date);
  }

  public getInProggressOrderByDate(date: any) {
    return this.get<OrderDto[]>(
      'InProggress' + '/' + date.payload.nodeId + '/' + date.payload.dateFrom + '/' + date.payload.dateTo
    );
  }

  public getAllOrderByDate(date: any) {
    return this.get<OrderForDeliveryStatusesDto[]>(
      'GetAll' + '/' + date.payload.nodeId + '/' + date.payload.dateFrom + '/' + date.payload.dateTo
    );
  }

  public getAllOrderFourHourRange(date: any) {
    const params = this.prepareParams(date.payload);
    return this.get<OrderGroupedByHourHour[]>('GetForHoursRanges?' + params);
  }

  public getAllCommentsOfDay(date: any) {
    const params = this.prepareParams(date.payload);
    return this.get<CommentsOfDayDto[]>('GetCommentsOfDay?' + params);
  }

  public addCommentOfDay(comment: CommentsOfDayDto) {
    return this.post('AddCommentOfDay', comment);
  }

  public getCustomerOrderHistory(customerId: string) {
    return this.get<CustomerOrderHistory[]>('GetCustomerOrdersHistory/' + customerId);
  }

  public getPageableOrdersCatalog(query: GetPageableOrdersCatalog) {
    const params = query ? this.prepareParams(query) : null;
    return this.get<PageableDto<OrderStatusesForHour>>('orders-catalog', { params });
  }

  public getOrdersCatalog() {
    return this.get<OrderStatusesForHour[]>('');
  }

  public getOrderEditHistoryPageable(query: GetPageableOrderEditHistoryQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<OrderEditHistoryModel>>('GetEditHistory/Pageable', { params });
  }

  public activateOrder(data: ActiveOrderModel) {
    return this.post('OrderActivation', data);
  }

  public getOrdersNodes(date: Date): Observable<OrderForNodes[]> {
    return this.get<OrderForNodes[]>('nodes-callendar', {
      params: { date: date.toISOString() },
    });
  }
}
// GetCommentsOfDay?DateOfComments=2020-12-04
