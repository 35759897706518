<mat-dialog-content class="horizontalScrollbar">
  <mat-card-header>
    <mat-card-title>{{ 'IncomingMessages.NewEmployee' | translate }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="save()">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'Dictionaries.EmployeeData' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="col-sm-12 display-grid">
            <mat-form-field class="example-full-width">
              <mat-label>{{ 'Common.LastName' | translate }}</mat-label>
              <input matInput formControlName="lastName" />
            </mat-form-field>
          </div>
          <div class="col-sm-12 display-grid">
            <mat-form-field class="example-full-width">
              <mat-label>{{ 'Common.FirstName' | translate }}</mat-label>
              <input matInput formControlName="firstName" />
            </mat-form-field>
          </div>
          <!-- 
          <div class="col-sm-12 display-grid">
            <mat-form-field class="example-full-width">
              <mat-label>{{ 'Common.EvidentionNumber' | translate }}</mat-label>
              <input matInput formControlName="evidentionNumber" />
            </mat-form-field>
          </div> -->

          <div class="col-sm-12 display-grid">
            <app-autocomplete
              [label]="'IncomingMessages.Department' | translate"
              [searchLabel]="'IncomingMessages.Department' | translate"
              [placeholder]="'Order.EnterClient' | translate"
              [form]="form"
              [data]="filteredDepartments | async"
              [idField]="'departmentId'"
              [labelField]="'departmentName'"
              [filterFunc]="filterDepartments"
            >
            </app-autocomplete>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-card-actions>
        <div class="d-flex width-100p ml-20">
          <button type="submit" color="primary" mat-raised-button>
            {{ 'Common.Save' | translate }}
          </button>
          <button color="warn" mat-raised-button (click)="cancel()">
            {{ 'Common.Cancel' | translate }}
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-dialog-content>
