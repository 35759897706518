
import {
  loadAggregates,
  loadAggregatesSuccess,
  loadAggregatesFailure,
  addAggregate,
  addAggregateSuccess,
  addAggregateFailure,
  updateAggregate,
  updateAggregateFailure,
  updateAggregateSuccess,
  deleteAggregate,
  deleteAggregateSuccess,
  deleteAggregateFailure,
} from './aggregate.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AggregateApiCallerService } from '../../shared/api-services/aggregate-api-caller.service';
import { RecipeApiCallerService } from '../../shared/api-services/recipe-api-caller.service';

@Injectable()
export class AggregateEffects {
  aggregates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAggregates),
      concatMap(({ recipeId }) => {
        return this.recipeApiCaller.getRecipeAggregates(recipeId).pipe(
          map((_) => loadAggregatesSuccess({ data: _ })),
          catchError((error) => of(loadAggregatesFailure({ error })))
        );
      })
    )
  );

  addAggregate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAggregate),
      concatMap(({ data }) => {
        return this.aggregateApiCaller.addAggregate(data).pipe(
          map((_) => addAggregateSuccess({ id: data.id })),

          catchError((error) => of(addAggregateFailure({ error })))
        );
      })
    )
  );
  updateAggregate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAggregate),
      concatMap(({ data }) => {
        return this.aggregateApiCaller.updateAggregate(data).pipe(
          map((_) => updateAggregateSuccess({ id: data.id })),

          catchError((error) => of(updateAggregateFailure({ error })))
        );
      })
    )
  );

  deleteAggregate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAggregate),
      concatMap(({ id }) => {
        return this.aggregateApiCaller.deleteAggregate(id).pipe(
          map((_) => deleteAggregateSuccess({ id })),

          catchError((error) => of(deleteAggregateFailure({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private aggregateApiCaller: AggregateApiCallerService,
    private recipeApiCaller: RecipeApiCallerService
  ) {}
}
