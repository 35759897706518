import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiCaller } from './base-api-caller';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { SelectModel } from '../models/select-model';
import { AuthorDto } from '../models/dictionaries/authorDTO';
import { GetPageableAuthorsQuery } from '../models/queries/get-pageable-authors.query';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthorsApiCallerService extends BaseApiCaller {
  protected controllerPath = 'authors';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addAuthor(dto: AuthorDto) {
    return this.postWithReponseSimpleType<number>('', dto);
  }

  public updateAuthor(dto: AuthorDto) {
    return this.put('', dto);
  }

  public deleteAuthor(id: number) {
    return this.delete(id.toString());
  }

  public getAuthor(id: number): Observable<AuthorDto> {
    return this.get<AuthorDto>(id.toString()).pipe(map((_) => new AuthorDto(_)));
  }

  public getPageableAuthors(query: GetPageableAuthorsQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<AuthorDto>>('', { params });
  }

  public getAuthorsForSelect(): Observable<SelectModel<number>[]> {
    return this.get<SelectModel<number>[]>('getForSelect');
  }
}
