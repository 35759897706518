import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { ContractDto } from '../models/contract/contract.dto';

@Injectable({
  providedIn: 'root',
})
export class ContractApiCallerService extends BaseApiCaller {
  protected controllerPath = 'contract';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getContract(id: string) {
    return this.get<ContractDto>(id);
  }
}
