import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageableQuery } from '../models/contracts/pageable-query';
import { EmployeeDto } from '../models/employees/employeeDTO';
import { UpdateEmployeeDto } from '../models/employees/updateEmployeeDTO';
import { UpdateEmployeePasswordDto } from '../models/employees/updateEmployeePasswordDTO';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableEmployeesQuery } from '../models/queries/get-pageable-employees.query';
import { SelectModel } from '../models/select-model';
import { BaseApiCaller, HttpOptions } from './base-api-caller';

@Injectable({
  providedIn: 'root',
})
export class IdentityUsersApiCallerService extends BaseApiCaller {
  protected controllerPath = 'ApiUsers';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public emailUnique(email: string) {
    return this.httpClient.get<boolean>(this.getIdentityFullPath('email-unique?email=' + email));
  }

  public emailByUserUnique(email: string, userId: string) {
    return this.httpClient.get<boolean>(
      this.getIdentityFullPath('email-user-unique?email=' + email + '&userId=' + userId)
    );
  }

  public getPageableEmployees(query: GetPageableEmployeesQuery) {
    var request = this.prepareIdentityPageableParams(query);

    return this.httpClient.post<IdentityPageableDto<EmployeeDto>>(
      this.getIdentityFullPath('GetUsersForDataTables'),
      request
    );
  }

  public createOrUpdateUser(dto: UpdateEmployeeDto) {
    return this.httpClient.post<IdentityPageableDto<EmployeeDto>>(this.getIdentityFullPath('CreateOrUpdateUser'), dto);
  }

  public changePasswordByEmail(dto: UpdateEmployeePasswordDto) {
    return this.httpClient.post<IdentityPageableDto<UpdateEmployeeDto>>(
      this.getIdentityFullPath('ChangePasswordByEmail'),
      dto
    );
  }

  public addUserAbsence(dto: UpdateEmployeeDto) {
    return this.httpClient.post<IdentityPageableDto<EmployeeDto>>(this.getIdentityFullPath('AddUserAbsene'), dto);
  }
  public getUser(userId: string) {
    return this.httpClient.get<UpdateEmployeeDto>(this.getIdentityFullPath('GetUser?'), { params: { userId: userId } });
  }

  public getUserBySessionStorage() {
    const loggedUser = JSON.parse(sessionStorage.getItem(this.getClientData()));
    return this.httpClient.get<UpdateEmployeeDto>(this.getIdentityFullPath('GetUser?'), {
      params: { userId: loggedUser.sub },
    });
  }

  public deleteUser(userId: string) {
    return this.httpClient.get<string>(this.getIdentityFullPath('DeleteUser'), { params: { userId: userId } });
  }

  public getSystemPermissions() {
    return this.httpClient.get<boolean>(this.getIdentityFullPath('GetPermissionsDictionary'));
  }

  public getUserNode(userId: string) {
    return this.httpClient.get<string>(this.getIdentityFullPath('GetUserNode'), { params: { userId } });
  }

  public getAvailableUsersForSelect(date: Date, roles: string[]) {
    var x = {
      date: date != null ? date.toJSON() : null,
      roles: roles,
    };
    var params = this.prepareParamsWithList(x);
    return this.httpClient.get<SelectModel<string>[]>(this.getIdentityFullPath('GetAvailableUsersForSelect'), {
      params,
    });
  }

  protected prepareIdentityPageableParams(data: any) {
    let result: IdentityPageableQuery = {
      pageNumber: parseInt(data.pageNumber) - 1,
      pageSize: parseInt(data.pageSize),
      sortColumn: data.orderBy,
      sortDirection: data.desc == 'true' ? 'desc' : 'asc',
      filterWords: [],
      roles: [],
      nodeIds: []
    };
    if (data.searchTerm != undefined) result.filterWords = [data.searchTerm];
    if (data.roles != undefined) result.roles = data.roles;
    if (data.nodeIds != undefined) result.nodeIds = data.nodeIds;
    return result;
  }
}

export class IdentityPageableQuery {
  pageNumber: number;
  pageSize: number;
  sortDirection: string;
  sortColumn: string;
  filterWords: Array<string>;
  roles: Array<string>;
  nodeIds: Array<string>;
}

export class IdentityPageableDto<T> {
  data: T[];
  totalPages: number;
}

export class IdentityDto<T> {
  data: T;
}
