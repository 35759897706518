import {
  loadConsistency,
  loadConsistencySuccess,
  loadConsistencyFailure,
  addConsistency,
  addConsistencySuccess,
  addConsistencyFailure,
  updateConsistencySuccess,
  updateConsistency,
  updateConsistencyFailure,
} from './consistency.actions';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConsistencyApiCallerService } from '../../shared/api-services/consistency-api-caller.service';
import { RecipeApiCallerService } from '../../shared/api-services/recipe-api-caller.service';

@Injectable()
export class ConsistencyEffects {
  consistency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConsistency),
      concatMap(({ recipeId }) => {
        return this.recipeApiCaller.getConsistencyForRecipe(recipeId).pipe(
          map((_) => loadConsistencySuccess({ data: _ })),
          catchError((error) => of(loadConsistencyFailure({ error })))
        );
      })
    )
  );

  addConsistency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addConsistency),
      concatMap(({ data }) => {
        return this.consistencyApiCaller.addConsistency(data).pipe(
          map((_) => addConsistencySuccess({ id: data.id })),
          catchError((error) => of(addConsistencyFailure({ error })))
        );
      })
    )
  );

  updateConsistency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConsistency),
      concatMap(({ data }) => {
        return this.consistencyApiCaller.updateConsistency(data).pipe(
          map((_) => updateConsistencySuccess({ id: data.id })),
          catchError((error) => of(updateConsistencyFailure({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private consistencyApiCaller: ConsistencyApiCallerService,
    private recipeApiCaller: RecipeApiCallerService
  ) {}
}
