import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { UpdateAdditiveDto } from '../models/additive/update-additive-dto';

@Injectable({
  providedIn: 'root',
})
export class AdditiveApiCallerService extends BaseApiCaller {
  protected controllerPath = 'additive';

  // constructor(httpClient: HttpClient, private datePipe: DatePipe) {
  //   super(httpClient);
  //}
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addAdditive(additive: UpdateAdditiveDto) {
    return this.post('', additive);
  }

  public updateAdditive(additive: UpdateAdditiveDto) {
    return this.put('', additive);
  }

  public deleteAdditive(id: string) {
    return this.delete(id);
  }
}
