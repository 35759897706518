//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadAddressesForSelect,
  loadAddressesForSelectSuccess,
  loadAddressesForSelectFailure,
  addAddressSuccess,
  addAddressFailure,
  deleteAddress,
  deleteAddressSuccess,
  deleteAddressFailure,
  getAddress,
  getAddressSuccess,
  getAddressFailure,
  updateAddress,
  updateAddressSuccess,
  updateAddressFailure,
  addAddress,
} from './addresses.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { AddressesApiCallerService } from '../../shared/api-services/addresses-api-caller.service';


@Injectable()
export class AddressesEffects {
  private latestedAddresssKey: string;
  private latestCustomerKey:string;

  addAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAddress),
      concatMap(({ data }) => {
        return this.addressesApiCaller.addAddress(data).pipe(
          map((_) => addAddressSuccess()),
          tap(() => this.reloadAddresses(this.latestCustomerKey)),
          catchError((error) => of(addAddressFailure({ error })))
        );
      })
    )
  );

  updateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAddress),
      concatMap(({ data }) => {
        return this.addressesApiCaller.updateAddress(data).pipe(
          map((_) => updateAddressSuccess()),
          catchError((error) => of(updateAddressFailure({ error })))
        );
      })
    )
  );

  loadAddressesForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAddressesForSelect),
      concatMap(({customerId}) => {
        this.latestCustomerKey = customerId;
        return this.addressesApiCaller.getAddressesForSelect(customerId).pipe(
          map((_) => loadAddressesForSelectSuccess({ data: _ })),
          catchError((error) => of(loadAddressesForSelectFailure({ error })))
        );
      })
    )
  );

  getAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddress),
      concatMap(({ id }) => {
        return this.addressesApiCaller.getAddress(id).pipe(
          map((_) => getAddressSuccess({ data: _ })),
          catchError((error) => of(getAddressFailure({ error })))
        );
      })
    )
  );

  deleteAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAddress),
      concatMap(({ id }) => {
        return this.addressesApiCaller.deleteAddress(id).pipe(
          map((_) => deleteAddressSuccess({ id: id })),
          tap(() => this.reloadAddresses(this.latestCustomerKey)),
          catchError((error) => of(deleteAddressFailure({ error })))
        );
      })
    )
  );

  private reloadAddresses(key: string) {
    this.store$.dispatch(loadAddressesForSelect({customerId: this.latestCustomerKey}));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private addressesApiCaller: AddressesApiCallerService,
    private router: Router
  ) {}
}
