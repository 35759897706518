import { Injectable } from '@angular/core';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { OrderHubService } from './order-hub.service';
import { SupplyHubService } from './supply-hub.service';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private isAuthorized: boolean;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private supplyHubService: SupplyHubService,
    private orderHubService: OrderHubService
  ) {}
  public startConnection = () => {
    this.init();
  };

  private init() {
    this.oidcSecurityService.isAuthenticated$.subscribe((isAuthorized: boolean) => {
      this.isAuthorized = isAuthorized;
      if (isAuthorized) {
        this.orderHubService.initiateSignalrConnection();
        this.supplyHubService.initiateSignalrSupplyConnection();
      }
    });

    console.log('IsAuthorized:' + this.isAuthorized);
  }
}
