import {
  setPageSize,
  setPageSizeSuccess,
  getPageSize,
  getPageSizeSuccess,
  getPageSizeFailure,
  setPageSizeFailure,
  setOrderBy,
  getOrderBy,
  getOrderBySuccess,
  getOrderByFailure,
  setOrderByFailure,
  setOrderBySuccess,
  setSortType,
  setSortTypeSuccess,
  setSortTypeFailure,
  getSortType,
  getSortTypeSuccess,
  getSortTypeFailure,
} from './tables.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, catchError, tap, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import { selectPageSize, selectOrderBy, selectSortType } from './tables.selectors';
import { PageSizeMapDto } from '../../shared/models/page-size-map.dto';
import { OrderByMapDto } from '../../shared/models/order-by-map.dto';
import { SortTypeMapDto } from '../../shared/models/sort-type-map.dto';

@Injectable()
export class TablesEffects {
  setPageSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageSize),
      withLatestFrom(this.store$.select(selectPageSize)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) => setPageSizeSuccess({ data: this.getPageSizeMapFromData(data) })),
          catchError((error) => of(setPageSizeFailure({ error })))
        );
      })
    )
  );

  getPageSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPageSize),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getPageSizeSuccess({ pageSize: Number.parseInt(_) })),
          catchError((error) => of(getPageSizeFailure({ error })))
        );
      })
    )
  );

  setOrderBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setOrderBy),
      withLatestFrom(this.store$.select(selectOrderBy)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) => setOrderBySuccess({ data: this.getOrderByMapFromData(data) })),
          catchError((error) => of(setOrderByFailure({ error })))
        );
      })
    )
  );

  getOrderBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrderBy),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getOrderBySuccess({ orderBy: _ })),
          catchError((error) => of(getOrderByFailure({ error })))
        );
      })
    )
  );

  setSortType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSortType),
      withLatestFrom(this.store$.select(selectSortType)),
      concatMap((data) => {
        return of(data).pipe(
          map((data) => setSortTypeSuccess({ data: this.getSortTypeFromData(data) })),
          catchError((error) => of(setSortTypeFailure({ error })))
        );
      })
    )
  );

  getSortType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSortType),
      concatMap(({ tableKey }) => {
        return of(tableKey).pipe(
          map((_) => getSortTypeSuccess({ sortType: _ })),
          catchError((error) => of(getSortTypeFailure({ error })))
        );
      })
    )
  );

  getPageSizeMapFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as PageSizeMapDto[]) : [];
    const pageSize = data[0].data;
    var existingValue = state.find((_) => _.key == pageSize.key);
    if (existingValue) {
      existingValue.pageSize = pageSize.pageSize;
    } else {
      var entity: PageSizeMapDto = new PageSizeMapDto(pageSize.key, pageSize.pageSize);
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  getOrderByMapFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as OrderByMapDto[]) : [];
    const sort = data[0].data;
    var existingValue = state.find((_) => _.key == sort.key);
    if (existingValue) {
      existingValue.orderBy = sort.orderBy;
    } else {
      var entity: OrderByMapDto = new OrderByMapDto(sort.key, sort.orderBy);
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  getSortTypeFromData(data: any) {
    const state = Array.isArray(data[1]) ? (data[1] as SortTypeMapDto[]) : [];
    const sortType = data[0].data;
    var existingValue = state.find((_) => _.key == sortType.key);
    if (existingValue) {
      existingValue.desc = sortType.desc;
    } else {
      var entity: SortTypeMapDto = new SortTypeMapDto(sortType.key, sortType.orderBy);
      state.push(entity);
    }
    return state.map((_) => Object.assign({}, _));
  }

  constructor(private actions$: Actions, private store$: Store<StoreState>) {}
}
