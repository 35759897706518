import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DateHelper } from '../../../helpers/date.helper';
import { DateRanges } from '../../../models/enums/date-ranges.enum';
import { DateRangeValidator } from '../../../validators/date-range.validator';
import { DateRange } from '../../../interfaces/i-date-range';

export class OperationSchedulerForm extends UntypedFormGroup {
  constructor() {
    super({
      dateFrom: new UntypedFormControl(null, [Validators.required]),
      dateTo: new UntypedFormControl(null, [Validators.required]),
      timeFrom: new UntypedFormControl(null, [Validators.required]),
      timeTo: new UntypedFormControl(null, [Validators.required]),
    });

    this.setValidators([DateRangeValidator.createDateToIsGreater(), DateRangeValidator.createDateFromIsLowerThanNow()]);
  }

  patchValue(range: DateRange) {
    if (range == null) {
      return;
    }

    super.patchValue({
      ...range,
      timeFrom: {
        hour: range.dateFrom.getHours(),
        minute: range.dateFrom.getMinutes(),
        second: range.dateFrom.getSeconds(),
      },
      timeTo: {
        hour: range.dateTo.getHours(),
        minute: range.dateTo.getMinutes(),
        second: range.dateTo.getSeconds(),
      },
    });
  }

  public forEditOperation() {
    this.setValidators(DateRangeValidator.createDateToIsGreater());
  }

  public forAddOperation() {
    const dateRange = DateHelper.getDefaultDateRange();
    this.patchValue(dateRange);

    return this;
  }
}
