import { createSelector } from '@ngrx/store';
import { StoreState } from '../store-state';
import { State } from './order.reducer';

const selectOrderFeature = (state: StoreState) => state.orders;

export const selectOrders = createSelector(selectOrderFeature, (state: State) => state.orders);

export const selectOrdersByDate = createSelector(selectOrderFeature, (state: State) => state.ordersByDate);

export const selectOrder = createSelector(selectOrderFeature, (state: State) => state.Getorder);

export const selectInProggressOrdersByDate = createSelector(
  selectOrderFeature,
  (state: State) => state.inProggrssOrdersByDate
);

export const selectAllOrdersByDate = createSelector(selectOrderFeature, (state: State) => state.allOrdersByDate);

export const selectAllOrdersByHourRange = createSelector(
  selectOrderFeature,
  (state: State) => state.allOrdersByHourRange
);

export const selectAllCommentsOfDay = createSelector(selectOrderFeature, (state: State) => state.allComentsOfDay);

export const selectAddComnmentOfDay = createSelector(selectOrderFeature, (state: State) => state.addCommentOfDay);

export const selectCustoemrOrderHistory = createSelector(
  selectOrderFeature,
  (state: State) => state.customerOrderHistory
);

// export const selectOrdersCatalog = createSelector(selectOrderFeature, (state: State) => state.ordersCatalog);

export const selectPageableOrdersCatalog = createSelector(
  selectOrderFeature,
  (state: State, props: { key: string }) => {
    const result = state.pageableOrdersCatalog ? state.pageableOrdersCatalog.find((_) => _.key === props.key) : null;

    return result ? result.value : null;
  }
);

export const selectOrdersCatalog = createSelector(selectOrderFeature, (state: State) => {
  const result = state.ordersCatalog;
  return result ? result : null;
});

export const selectAllPageableOrdersCatalogFilters = createSelector(
  selectOrderFeature,
  (state: State) => state.ordersCatalogFilters
);

export const selectPageableOrdersCatalogFilters = createSelector(
  selectOrderFeature,
  (state: State, props: { key: string }) => {
    const result = state.ordersCatalogFilters ? state.ordersCatalogFilters.find((_) => _.key === props.key) : null;

    return result ? result.value : null;
  }
);

export const selectPageableOrderEditHistory = createSelector(selectOrderFeature, (state: State) => state.pageableEditHistory);

export const selectAllPageableOrderEditHistoryFilters = createSelector(selectOrderFeature, (state: State) => state.pageableEditHistoryFilters);
export const selectPageableOrderEditHistoryFilters = createSelector(selectOrderFeature, (state: State, props: { key: string }) => {
  const result = state.pageableEditHistoryFilters ? state.pageableEditHistoryFilters.find((_) => _.key === props.key) : null;
  return result ? result.value : null;
});