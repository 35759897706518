<app-files-upload [form]="attachmentForm" [disabled]="disabled"></app-files-upload>
<button style="float: right;" class="action-button" id="cancel" mat-raised-button [disabled]="!attachmentForm.valid || disabled"
  (click)="close(false)">
  {{ 'Common.Cancel' | translate }}
</button> &nbsp; &nbsp;
<button style="float: right;margin-right: 10px;" class="action-button" *ngIf="this.attachmentForm.value?.length>0" id="save"
  color="primary" mat-raised-button [disabled]="!attachmentForm.valid || disabled" (click)="onConfirmAction()">
  {{ 'Common.Save' | translate }}
</button>
<ng-http-loader
    [backdrop]="false"
    [backgroundColor]="'#0378bb'"
    [debounceDelay]="100"
    [opacity]="1"
    [spinner]="spinnerStyle.skThreeBounce"
  ></ng-http-loader>