import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom, finalize } from 'rxjs/operators';
import { DocumentsApiCallerService } from '../../shared/api-services/documents-api-caller.service';
import { OrderApiCallerService } from '../../shared/api-services/order-api-caller.service';
import { OrderHistoryApiCallerService } from '../../shared/api-services/order-history-api-caller.service';
import { AttachmentProgressDto } from '../../shared/models/attachment/attachment-progress.dto';
import { UploadStatus } from '../../shared/models/enums/upload-status.enum';
import { errorCreatingOrderWhileSendingFile } from '../../shared/services/snack-bar-messaged';
import { SnackBarWrapperService } from '../../shared/services/snack-bar-wrapper.service';
import { StoreState } from '../store-state';
import {
  addOrder,
  addOrderAtachmentProgress,
  addOrderAtachmentProgressSuccess,
  addOrderAtachments,
  addOrderAtachmentsFailure,
  addOrderAtachmentsScheduled,
  addOrderAtachmentsSuccess,
  addOrderFailure,
  addOrderSuccess,
  loadOperation,
  loadOperationFailure,
  loadOperationSuccess,
  loadOrderAttachmentsInfo,
  loadOrderAttachmentsInfoFailure,
  loadOrderAttachmentsInfoSuccess,
  loadOrderAvailableStatuses,
  loadOrderAvailableStatusesFailure,
  loadOrderAvailableStatusesSuccess,
  loadPageableOrders,
  loadPageableOrdersFailure,
  loadPageableOrdersSuccess,
  setOrderStatus,
  setOrderStatusFailure,
  setOrderStatusSuccess,
  updateOrder,
  updateOrderFailure,
  updateOrderSuccess,
  loadOrderHistory,
  loadOrderHistorySuccess,
  loadOrderHistoryFailure,
  updateOrderCompanyEmployee,
  updateOrderCompanyEmployeeSuccess,
  updateOrderCompanyEmployeeFailure,
  loadOrderCompanyEmployee,
  loadOrderCompanyEmployeeSuccess,
  loadOrderCompanyEmployeeFailure,
  unassignOrderCompanyEmployee,
  unassignOrderCompanyEmployeeSuccess,
  unassignOrderCompanyEmployeeFailure,
  loadPageableOrderTypes,
  loadPageableOrderTypesFailure,
  loadPageableOrderTypesSuccess,
  createOrderType,
  createOrderTypeSuccess,
  createOrderTypeFailure,
  deleteOrderType,
  deleteOrderTypeFailure,
  deleteOrderTypeSuccess,
  updateOrderType,
  updateOrderTypeFailure,
  updateOrderTypeSuccess,
  setPageableOrdersFilters,
  setPageableOrdersFiltersSuccess,
  setPageableOrdersFiltersFailure,
} from './operation.actions';
import {
  selectOrderAttachmentsProgress,
  selectPageableOrderFilters,
  selectAllPageableOrderFilters,
} from './operations-selectors';
import { OrderTypeApiCallerService } from '../../shared/api-services/order-type-api-caller.service';
import { deleteCompanyEmployeeFailure } from '../company-employees/company-employee.actions';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { GetPageableOrdersQuery } from '../../shared/models/queries/get-pageable-orders.query';

@Injectable()
export class OperationEffects {
  private filters: GetPageableQuery;
  private latestedOrdersKey: string;

  operation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOperation),
      concatMap(({ id }) => {
        return this.orderApiCaller.getOrder(id).pipe(
          map((_) => loadOperationSuccess({ data: _ })),
          catchError((error) => of(loadOperationFailure({ error })))
        );
      })
    )
  );

  orderAttachmentsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderAttachmentsInfo),
      concatMap(({ id }) => {
        return this.orderApiCaller.getAttachmentsInfo(id).pipe(
          map((_) => loadOrderAttachmentsInfoSuccess({ data: _ })),
          catchError((error) => of(loadOrderAttachmentsInfoFailure({ error })))
        );
      })
    )
  );

  orderAvailableStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderAvailableStatuses),
      concatMap(({ id }) => {
        return this.orderApiCaller.getAvailableStatuses(id).pipe(
          map((_) => loadOrderAvailableStatusesSuccess({ data: _ })),
          catchError((error) => of(loadOrderAvailableStatusesFailure({ error })))
        );
      })
    )
  );

  addOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOrder),
      concatMap(({ data, attachments, isClone }) => {
        return this.orderApiCaller.addOrder(data).pipe(
          map((_) => addOrderSuccess({ orderId: data.id })),
          tap((_) => {
            if (attachments != null) {
              this.store$.dispatch(addOrderAtachments({ data: attachments, orderId: data.id }));
            } else if (isClone) {
              this.reloadOrders(this.latestedOrdersKey);
            } else {
              this.navigateToOrder(data.id);
            }
          }),
          catchError((error) => of(addOrderFailure({ error })))
        );
      })
    )
  );

  addOrderAtachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOrderAtachments),
      concatMap(({ data, orderId }) => {
        data.forEach((element) => {
          const id = Guid.create().toString();

          // this.attachmentApiCaller.addAttachment(id, orderId, element).subscribe(
          //   (event) => {
          //     const progressInfo = new AttachmentProgressDto(id, element.name);
          //     if (event.type === HttpEventType.UploadProgress) {
          //       progressInfo.progress = Math.round((100 * event.loaded) / event.total);
          //       progressInfo.status = UploadStatus.InProgress;
          //     } else if (event.type === HttpEventType.Response) {
          //       progressInfo.status = UploadStatus.Finished;
          //       progressInfo.progress = 100;
          //     }
          //     this.store$.dispatch(addOrderAtachmentProgress({ info: progressInfo, orderId }));
          //   },
          //   (error) => {
          //     this.store$.dispatch(addOrderAtachmentsFailure({ error }));
          //     this.matSnackBar.openMessage(errorCreatingOrderWhileSendingFile, 'error');
          //     this.navigateToOrder(orderId);
          //   }
          // );
        });
        return of(addOrderAtachmentsScheduled());
      })
    )
  );

  addOrderAtachmentProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOrderAtachmentProgress),
      withLatestFrom(this.store$.pipe(select(selectOrderAttachmentsProgress))),
      concatMap((actionAndStoreState) => {
        const progress = { ...actionAndStoreState[0].info };
        const orderId = actionAndStoreState[0].orderId;
        const state = actionAndStoreState[1] ? actionAndStoreState[1].map((data) => ({ ...data })) : [];
        const current = state.find((_) => _.id === progress.id);
        if (current != null) {
          current.progress = progress.progress;
          current.status = progress.status;
        } else {
          state.push(progress);
        }

        if (state.every((_) => _.status === UploadStatus.Finished)) {
          this.navigateToOrder(orderId);
          return of(addOrderAtachmentsSuccess());
        } else {
          return of(addOrderAtachmentProgressSuccess({ data: state, orderId }));
        }
      })
    )
  );

  updateOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrder),
      concatMap(({ data }) => {
        return this.orderApiCaller.updateOrder(data).pipe(
          map((_) => updateOrderSuccess({ orderId: data.id })),
          tap((_) => {
            this.navigateToOrder(data.id);
            this.store$.dispatch(loadOperation({ id: data.id }));
            this.store$.dispatch(loadOrderHistory({ orderId: data.id }));
          }),
          catchError((error) => of(updateOrderFailure({ error })))
        );
      })
    )
  );

  updateOrderCompanyEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrderCompanyEmployee),
      concatMap(({ data }) => {
        return this.orderApiCaller.updateOrderCompanyEmployee(data).pipe(
          map((_) => updateOrderCompanyEmployeeSuccess({ orderId: data.id })),
          tap((_) => this.store$.dispatch(loadOrderCompanyEmployee({ id: _.orderId }))),
          catchError((error) => of(updateOrderCompanyEmployeeFailure({ error })))
        );
      })
    )
  );

  loadOrderCompanyEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderCompanyEmployee),
      concatMap(({ id }) => {
        return this.orderApiCaller.getOrderCompanyEmployee(id).pipe(
          map((_) => loadOrderCompanyEmployeeSuccess({ data: _ })),
          catchError((error) => of(loadOrderCompanyEmployeeFailure({ error })))
        );
      })
    )
  );

  unassignOrderCompanyEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unassignOrderCompanyEmployee),
      concatMap(({ id }) => {
        return this.orderApiCaller.unassignOrderCompanyEmployee(id).pipe(
          map((_) => unassignOrderCompanyEmployeeSuccess({ id })),
          tap((_) => this.store$.dispatch(loadOrderCompanyEmployee({ id: _.id }))),
          catchError((error) => of(unassignOrderCompanyEmployeeFailure({ error })))
        );
      })
    )
  );

  setOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setOrderStatus),
      concatMap(({ orderId, orderStatusId }) => {
        return this.orderApiCaller.setStatus(orderId, orderStatusId).pipe(
          map((_) => setOrderStatusSuccess({ orderId })),
          tap((_) => this.store$.dispatch(loadOperation({ id: orderId }))),
          catchError((error) => {
            this.store$.dispatch(loadOperation({ id: orderId }));
            return of(setOrderStatusFailure({ error }));
          }),
          finalize(() => this.store$.dispatch(loadOrderHistory({ orderId })))
        );
      })
    )
  );

  loadPageableOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableOrders),
      withLatestFrom(this.store$.select(selectAllPageableOrderFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedOrdersKey = key;

        return this.orderApiCaller.getPageableOrders(filter.value).pipe(
          map((_) => loadPageableOrdersSuccess({ data: _, key })),
          catchError((error) => of(loadPageableOrdersFailure({ error })))
        );
      })
    )
  );

  setPageableOrdersFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableOrdersFilters),
      concatMap(({ data, key }) => {
        this.latestedOrdersKey = key;
        return of(data).pipe(
          map((_) => setPageableOrdersFiltersSuccess({ data, key })),
          finalize(() => this.reloadOrders(key)),
          catchError((error) => of(setPageableOrdersFiltersFailure({ error })))
        );
      })
    )
  );

  loadPageableOrderTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableOrderTypes),
      concatMap(({ filters }) => {
        if (filters) {
          this.filters = filters;
        }
        return this.orderTypeApiCaller.getPageableOrderTypes(filters).pipe(
          map((_) => loadPageableOrderTypesSuccess({ data: _ })),
          catchError((error) => of(loadPageableOrderTypesFailure({ error })))
        );
      })
    )
  );

  loadOrderHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderHistory),
      concatMap(({ orderId }) => {
        return this.orderHistoryApiCaller.getOrderHistory(orderId).pipe(
          map((_) => loadOrderHistorySuccess({ data: _ })),
          catchError((error) => of(loadOrderHistoryFailure({ error })))
        );
      })
    )
  );

  createOrderType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOrderType),
      concatMap(({ data }) => {
        return this.orderTypeApiCaller.addOrderType(data).pipe(
          map((_) => createOrderTypeSuccess({ id: data.id })),
          tap(() => this.reloadOrderTypes()),
          catchError((error) => {
            this.reloadOrderTypes();
            return of(createOrderTypeFailure({ error }));
          })
        );
      })
    )
  );

  deleteOrderType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteOrderType),
      concatMap(({ id }) => {
        return this.orderTypeApiCaller.deleteOrderType(id).pipe(
          catchError((error) => {
            this.reloadOrderTypes();
            return of(deleteOrderTypeFailure({ error }));
          }),
          map((_) => deleteOrderTypeSuccess({ id: id })),
          tap(() => this.reloadOrderTypes())
        );
      })
    )
  );

  updateOrderType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrderType),
      concatMap(({ data }) => {
        return this.orderTypeApiCaller.updateOrderType(data).pipe(
          map((_) => updateOrderTypeSuccess({ Id: data.id })),
          tap(() => this.reloadOrderTypes()),
          catchError((error) => of(updateOrderTypeFailure({ error }))),
          tap(() => this.reloadOrderTypes())
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private orderApiCaller: OrderApiCallerService,
    private attachmentApiCaller: DocumentsApiCallerService,
    private router: Router,
    private store$: Store<StoreState>,
    private orderHistoryApiCaller: OrderHistoryApiCallerService,
    private matSnackBar: SnackBarWrapperService,
    private orderTypeApiCaller: OrderTypeApiCallerService
  ) {}

  private navigateToOrder(orderId) {
    this.router.navigate(['/', 'authorised', 'operations', 'operation', orderId]);
  }

  private reloadOrderTypes() {
    this.store$.dispatch(loadPageableOrderTypes({ filters: this.filters }));
  }

  private reloadOrders(key: string) {
    this.store$.dispatch(loadPageableOrders({ key }));
  }
}
