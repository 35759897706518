import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { OrderDto } from '../models/orders/order.dto';
import { environment } from 'project/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderHubService {
  stringOrderConnect = '/signalr/orderHub';
  connection: signalR.HubConnection;

  hubHelloMessage: Subject<string>;
  hubUpdateOrder: Subject<string | OrderDto>;

  connectionIsStarting = false;
  constructor(private oidcSecurityService: OidcSecurityService) {
    this.hubHelloMessage = new Subject<string>();
    this.hubUpdateOrder = new Subject<string | OrderDto>();
  }

  public initiateSignalrConnection() {
    if (this.connection == null) {
      const token = this.oidcSecurityService.getToken();
      const url = environment.apiRoot + this.stringOrderConnect;
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => token,
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();
    }

    if (this.connection.state !== signalR.HubConnectionState.Connected && !this.connectionIsStarting) {
      this.connectionIsStarting = true;
      this.connection
        .start()
        .then(() => console.log('Connection started'))
        .then(() => this.connection.invoke('send'))
        .then(() => (this.connectionIsStarting = false))
        .catch((err) => console.log('Error while starting connection: ' + err));
    }

    this.orderNotification();
  }

  private orderNotification() {
    this.connection.off('send-add-order');
    this.connection.on('send-add-order', (data) => {
      const result = data;
      this.hubHelloMessage.next(result);
    });

    this.connection.off('send-edit-order');
    this.connection.on('send-edit-order', (data) => {
      this.hubUpdateOrder.next(data);
    });

    this.connection.off('send-do-reload-order');
    this.connection.on('send-do-reload-order', (data) => {
      this.hubUpdateOrder.next(data);
    });
  }
}
