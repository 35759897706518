import { createAction, props } from '@ngrx/store';
import { CompanyDto } from '../../shared/models/company/company.dto';
import { UpdateCompanyDto } from '../../shared/models/company/update-company.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableOrdersQuery } from '../../shared/models/queries/get-pageable-orders.query';
import { SearchModel } from '../../shared/models/search-model';
import { SelectModel } from '../../shared/models/select-model';
import { GetPageableCompaniesQuery } from '../../shared/models/queries/get-pageable-companies.query';

export const loadContractor = createAction('[Contractor] Load Contractor', props<{ id: string }>());

export const loadContractorSuccess = createAction(
  '[Contractor] Load Contractor Success',
  props<{ data: CompanyDto }>()
);

export const loadContractorFailure = createAction('[Contractor] Load Contractor Failure', props<{ error: any }>());

export const loadContractorsSearchModel = createAction(
  '[Client] Load Contractors SearchModel',
  props<{ data: SearchModel }>()
);

export const loadContractorsSearchModelSuccess = createAction(
  '[Client] Load Contractors SearchModel Success',
  props<{ data: SelectModel<string>[] }>()
);

export const loadContractorsSearchModelFailure = createAction(
  '[Client] Load Contractors SearchModel Failure',
  props<{ error: any }>()
);

export const loadPageableContractors = createAction(
  '[Client] Load Pageable Contractors',
  props<{ filter: GetPageableCompaniesQuery }>()
);

export const loadPageableContractorsSuccess = createAction(
  '[Client] Load Pageable Contractors Success',
  props<{ data: PageableDto<CompanyDto> }>()
);

export const loadPageableContractorsFailure = createAction(
  '[Client] Load Pageable Contractors Failure',
  props<{ error: any }>()
);

export const addContractor = createAction('[Contractor] Add Contractor', props<{ data: UpdateCompanyDto }>());

export const addContractorSuccess = createAction('[Contractor] Add Contractor Success', props<{ id: string }>());

export const addContractorFailure = createAction('[Contractor] Add Contractor Failure', props<{ error: any }>());

export const updateContractor = createAction('[Contractor] Update Contractor', props<{ data: UpdateCompanyDto }>());

export const updateContractorSuccess = createAction('[Contractor] Update Contractor Success', props<{ id: string }>());

export const updateContractorFailure = createAction('[Contractor] Update Contractor Failure', props<{ error: any }>());
