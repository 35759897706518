import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { UpdateWaterDto } from '../models/water/update-water-dto';

@Injectable({
  providedIn: 'root',
})
export class WaterApiCallerService extends BaseApiCaller {
  protected controllerPath = 'water';

  // constructor(httpClient: HttpClient, private datePipe: DatePipe) {
  //   super(httpClient);
  //}
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addWater(water: UpdateWaterDto) {
    return this.post('', water);
  }

  public updateWater(water: UpdateWaterDto) {
    return this.put('', water);
  }
}
