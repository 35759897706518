import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './incoming-messages.reducer';

const selectIncomingMessageFeature = (state: StoreState) => state.incomingMessages;

export const selectPageableIncomingMessages = createSelector(selectIncomingMessageFeature, (state: State, props: { key: string }) => {
  const result = state.pageableIncomingMessages ? state.pageableIncomingMessages.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectIncomingMessage = createSelector(selectIncomingMessageFeature, (state: State) => {
  const result = state.incomingMessage;
  return result ? result : null;
});

export const selectAllPageableIncomingMessagesFilters = createSelector(selectIncomingMessageFeature, (state: State) => state.incomingMessagesFilters);
export const selectPageableIncomingMessagesFilters = createSelector(selectIncomingMessageFeature, (state: State, props: { key: string }) => {
  const result = state.incomingMessagesFilters ? state.incomingMessagesFilters.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});
