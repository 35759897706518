import { loadAdmixtures, loadAdmixturesSuccess, loadAdmixturesFailure } from './admixture.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { AdmixtureDto } from '../../shared/models/admixture/update-admixture-dto';

export const admixtureFeatureKey = 'admixture';

export interface State {
  admixtures?: AdmixtureDto[];
}

export const initialState: State = {};

const admixtureReducer = createReducer(
  initialState,
  on(loadAdmixtures, (state) => setAdmixture(state, null)),
  on(loadAdmixturesSuccess, (state, { data }) => setAdmixture(state, data)),
  on(loadAdmixturesFailure, (state, action) => setAdmixture(state, null))
);

function setAdmixture(state: State, data: AdmixtureDto[]) {
  return {
    ...state,
    admixtures: data,
  };
}
export function reducer(state: State | undefined, action: Action) {
  return admixtureReducer(state, action);
}
