import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';

@Component({
  selector: 'app-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.css'],
})
export class SpinnerButtonComponent implements OnInit {
  @Output() onClick = new EventEmitter<any>(null);
  @Input() set disabled(value: boolean) {
    this.btnOpts.disabled = value;
    this.btnOpts.buttonColor = value ? 'warn' : 'primary';
  }
  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: '',
    spinnerSize: 20,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };
  _isLoading: boolean = false;
  constructor() {}

  ngOnInit() {}

  @Input() set label(val: string) {
    this.btnOpts.text = val;
  }
  @Input() set isLoading(val: boolean) {
    this._isLoading = val;
    this.btnOpts.active = this._isLoading;
  }

  public btOnClick() {
    this.onClick.next();
  }
}
