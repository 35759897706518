import { createAction, props } from '@ngrx/store';
import { PageableDto } from 'project/src/app/shared/models/pageable.dto';
import { GetPageableRecipesQuery } from 'project/src/app/shared/models/queries/get-pageable-recipes-query';

import { RecipeDto } from '../../shared/models/recipe-dto';
import { UpdateRecipe } from '../../shared/models/recipe/update-recipe.dto';

export const loadRecipe = createAction('[Recipe] Load Recipes', props<{ id: string }>());

export const loadRecipeSuccess = createAction('[Recipe] Load Recipes Success', props<{ data: RecipeDto }>());

export const loadRecipeFailure = createAction('[Recipe] Load Recipes Failure', props<{ error: any }>());

export const addRecipe = createAction('[Recipe] Add Recipe', props<{ data: UpdateRecipe }>());

export const addRecipeSuccess = createAction('[Recipe] Add Recipe Success', props<{ recipeId: string }>());

export const addRecipeFailure = createAction('[Recipe] Add Recipe Failure', props<{ error: any }>());

export const updateRecipe = createAction('[Recipe] Update Recipe', props<{ data: UpdateRecipe }>());

export const updateRecipeSuccess = createAction('[Recipe] Update Recipe Success', props<{ recipeId: string }>());

export const updateRecipeFailure = createAction('[Recipe] Update Recipe Failure', props<{ error: any }>());

export const deleteRecipe = createAction('[Recipe] Delete Recipe', props<{ id: string }>());

export const deleteRecipeSuccess = createAction('[Recipe] Delete Recipe Success', props<{ recipeId: string }>());

export const deleteRecipeFailure = createAction('[Recipe] Delete Recipe Failure', props<{ error: any }>());

export const loadPageableRecipes = createAction('[Recipes] Load Pageable Recipes', props<{ key: string }>());
export const loadPageableRecipesSuccess = createAction(
  '[Recipes] Load Pageable Recipes Success',
  props<{ data: PageableDto<RecipeDto>; key: string }>()
);
export const loadPageableRecipesFailure = createAction(
  '[Recipes] Load Pageable Recipes Failure',
  props<{ error: any }>()
);

export const setPageableRecipesFilters = createAction(
  '[Recipes] Set Pageable Recipes Filters',
  props<{ data?: GetPageableRecipesQuery; key: string }>()
);
export const setPageableRecipesFiltersSuccess = createAction(
  '[Recipes] Set Pageable Recipes Filters Success',
  props<{ data: GetPageableRecipesQuery; key: string }>()
);
export const setPageableRecipesFiltersFailure = createAction(
  '[Recipes] Set Pageable Recipes Filters Failure',
  props<{ error: any }>()
);
