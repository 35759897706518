import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { CustomValidator } from '../../../validators/custom.validator';

export class OrderStatusesForm extends UntypedFormGroup {
  constructor() {
    super({
      dayToDisplay: new UntypedFormControl(null),
      comments: new UntypedFormControl(null),
    });
  }

  forAdd() {
    return this;
  }
}
