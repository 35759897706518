import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { VersionDto } from '../models/version.dto';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { ValidationResultDto } from '../models/validation/validation-result.dto';
import { VersionLogDto } from '../models/version-log.dto';

@Injectable({
  providedIn: 'root',
})
export class VersionApiCallerService extends BaseApiCaller {
  protected controllerPath = 'version';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getPageableVersions(filter: GetPageableQuery) {
    const params = this.prepareParams(filter);
    return this.get<PageableDto<VersionDto>>('pageable', { params });
  }

  public addVersion(data: VersionDto) {
    return this.post('', data);
  }

  public versionNumberUnique(versionName: string) {
    return this.httpClient.get<ValidationResultDto>(this.getFullPath('version-number-unique/' + versionName));
  }

  public addVersionLog(data: VersionLogDto) {
    return this.post('log', data);
  }

  public getVersionLogs(id: string) {
    return this.get<VersionLogDto[]>(this.getFullPath('logs/' + id));
  }
}
