//import { OutgoingMessagesApiCallerService } from '../../shared/api-services/employees-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  addOutgoingMessage,
  addOutgoingMessageSuccess,
  addOutgoingMessageFailure,
  deleteOutgoingMessage,
  deleteOutgoingMessageSuccess,
  deleteOutgoingMessageFailure,
  setPageableOutgoingMessagesFilters,
  setPageableOutgoingMessagesFiltersSuccess,
  setPageableOutgoingMessagesFiltersFailure,
  loadPageableOutgoingMessages,
  loadPageableOutgoingMessagesSuccess,
  loadPageableOutgoingMessagesFailure,
  getOutgoingMessage,
  getOutgoingMessageSuccess,
  getOutgoingMessageFailure,
  updateOutgoingMessage,
  updateOutgoingMessageSuccess,
  updateOutgoingMessageFailure,
  sendOutgoingMessage,
  sendOutgoingMessageSuccess,
  sendOutgoingMessageFailure,
} from './outgoing-messages.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { selectAllPageableOutgoingMessagesFilters } from './outgoing-messages.selector';
import { PageableDto } from '../../shared/models/pageable.dto';
import { OutgoingMessagesApiCallerService } from '../../shared/api-services/outgoing-messages-api-caller.service';
import { addIncomingMessageDocuments, addIncomingMessageSuccess } from '../incoming-messages/incoming-messages.actions';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';

@Injectable()
export class OutgoingMessagesEffects {
  private latestedOutgoingMessagesKey: string;

  deleteOutgoingMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteOutgoingMessage),
      concatMap(({ id }) => {
        return this.outgoingMessagesApiCaller.deleteOutgoingMessage(id).pipe(
          map((_) => deleteOutgoingMessageSuccess({ id: id })),
          tap(() => this.reloadOutgoingMessages(this.latestedOutgoingMessagesKey)),
          catchError((error) => of(deleteOutgoingMessageFailure({ error })))
        );
      })
    )
  );

  addOutgoingMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOutgoingMessage),
      concatMap(({ data, documents }) => {
        let lastValue;
        return this.outgoingMessagesApiCaller.addOutgoingMessage(data).pipe(
          map((_) => addIncomingMessageSuccess({ data: _ })),
          tap((_) => (lastValue = _)),
          finalize(() => {
            let incomingMessageId = lastValue.data;
            if (documents != null) {
              this.store$.dispatch(
                addIncomingMessageDocuments({ data: documents, incomingMessageId: incomingMessageId })
              );
            }
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-outgoing-message');
          }),
          catchError((error) => of(addOutgoingMessageFailure({ error })))
        );
      })
    )
  );

  updateOutgoingMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOutgoingMessage),
      concatMap(({ data, documents }) => {
        let lastValue;
        return this.outgoingMessagesApiCaller.updateOutgoingMessage(data).pipe(
          map((_) => updateOutgoingMessageSuccess()),
          tap((_) => (lastValue = _)),
          finalize(() => {
            let incomingMessageId = data.id;
            if (documents != null) {
              this.store$.dispatch(
                addIncomingMessageDocuments({ data: documents, incomingMessageId: incomingMessageId })
              );
            }
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-outgoing-message');
          }),
          catchError((error) => of(updateOutgoingMessageFailure({ error })))
        );
      })
    )
  );

  loadPageableOutgoingMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableOutgoingMessages),
      withLatestFrom(this.store$.select(selectAllPageableOutgoingMessagesFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedOutgoingMessagesKey = key;

        return this.outgoingMessagesApiCaller.getPageableOutgoingMessages(filter.value).pipe(
          map((_) => loadPageableOutgoingMessagesSuccess({ data: _, key })),
          catchError((error) => of(loadPageableOutgoingMessagesFailure({ error })))
        );
      })
    )
  );

  sendOutgoingMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendOutgoingMessage),
      concatMap(({ id, key }) => {
        return this.outgoingMessagesApiCaller.sendOutgoingMessage(id).pipe(
          map((_) => sendOutgoingMessageSuccess()),
          finalize(() => this.reloadOutgoingMessages(key)),
          catchError((error) => of(sendOutgoingMessageFailure({ error })))
        );
      })
    )
  );

  getOutgoingMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOutgoingMessage),
      concatMap(({ id }) => {
        return this.outgoingMessagesApiCaller.getOutgoingMessage(id).pipe(
          map((_) => getOutgoingMessageSuccess({ data: _ })),
          catchError((error) => of(getOutgoingMessageFailure({ error })))
        );
      })
    )
  );

  setPageableOutgoingMessagesFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableOutgoingMessagesFilters),
      concatMap(({ data, key }) => {
        this.latestedOutgoingMessagesKey = key;
        return of(data).pipe(
          map((_) => setPageableOutgoingMessagesFiltersSuccess({ data, key })),
          finalize(() => this.reloadOutgoingMessages(key)),
          catchError((error) => of(setPageableOutgoingMessagesFiltersFailure({ error })))
        );
      })
    )
  );

  private reloadOutgoingMessages(key: string) {
    this.store$.dispatch(loadPageableOutgoingMessages({ key }));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private outgoingMessagesApiCaller: OutgoingMessagesApiCallerService,
    private router: Router,
    private overlayService: OverlayWrapperService
  ) {}

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'outgoing-messages', 'catalog']);
  }
}
