import { HttpResponse } from '@angular/common/http';

export class HttpResponseHelper {
  public static getFileName(response: HttpResponse<Blob>) {
    const contentDisposition = response.headers.get('content-disposition');
    const start = contentDisposition.indexOf('filename') + 9;
    const end = contentDisposition.lastIndexOf('filename') - 2;

    return contentDisposition.slice(start, end);
  }
}
