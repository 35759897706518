import { PageableDto } from '../../shared/models/pageable.dto';
import { VersionDto } from '../../shared/models/version.dto';
import { Action, createReducer, on } from '@ngrx/store';
import { loadPageableVersions, loadPageableVersionsSuccess, loadPageableVersionsFailure } from './version.actions';

export const profileFeatureKey = 'versions';
export const initialState: State = {};

export interface State {
  versions?: PageableDto<VersionDto>;
  version?: VersionDto;
}

const versionReducer = createReducer(
  initialState,
  on(loadPageableVersions, (state) => setVersions(state, null)),
  on(loadPageableVersionsSuccess, (state, { data }) => setVersions(state, data)),
  on(loadPageableVersionsFailure, (state, action) => setVersions(state, null))
);

function setVersions(state: State, data: PageableDto<VersionDto>) {
  return {
    ...state,
    versions: data,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return versionReducer(state, action);
}
