<mat-form-field *ngIf="form.enabled" class="width-100p">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select [formControl]="form.get(idField)" #singleSelect>
    <mat-option>
      <ngx-mat-select-search [noEntriesFoundLabel]="'Common.RecordsNotFound' | translate"
        [placeholderLabel]="searchLabel" [formControl]="searchForm"></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let option of data" [value]="option.id"
      (onSelectionChange)="selectionChanged(option.id, option.label)">
      {{ option.label }}
    </mat-option>
    <mat-option *ngIf="showSelectedItem(data)" [value]="selectedItem[idField]">
      {{ selectedItem[labelField] }}
    </mat-option>
  </mat-select>

  <button *ngIf="form.get(idField).value" matSuffix mat-icon-button type="button" aria-label="Clear"
    (click)="$event.stopPropagation(); clear();">
    <mat-icon>close</mat-icon>
  </button>

  <mat-error *ngIf="form.get(idField).hasError('required')">
    {{ 'ValidationErrors.FieldRequired' | translate }}
  </mat-error>
</mat-form-field>

<mat-form-field *ngIf="!form.enabled" class="width-100p">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input matInput type="text" [formControl]="form.get(labelField)" readonly />
  <a *ngIf="itemLink && form.disabled" mat-button matSuffix [routerLink]="itemLink">
    <mat-icon class="color-primary">visibility </mat-icon>
  </a>
</mat-form-field>