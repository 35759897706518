import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from '../../models/select-model';
import { FormFactory } from '../forms/form-factory';
import { ClientDto } from '../../models/dictionaries/clientDTO';
import { MatRadioChange } from '@angular/material/radio';
import { AddressDto } from '../../models/dictionaries/addressDTO';

@Component({
  selector: 'app-add-client-dialog',
  templateUrl: './add-client-dialog.component.html',
  styleUrls: ['./add-client-dialog.component.scss'],
})
export class AddClientDialogComponent implements OnInit {
  useTranslation: boolean;
  name: string;

  form: UntypedFormGroup;

  public get type(): string {
    return this.form.get('clientTypeValue').value;
  }

  constructor(
    private dialogRef: MatDialogRef<AddClientDialogComponent>,
    formFactory: FormFactory,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; useTranslation?: boolean }
  ) {
    this.name = data.name;
    this.useTranslation = data.useTranslation === true;
    this.form = formFactory.getClientForm().forAdd();
  }

  ngOnInit() {
    this.setInitialCheckboxValue();
  }

  setInitialCheckboxValue() {
    this.form.controls['clientTypeValue'].setValue('2');
    var type = parseInt('2');
    this.form.controls['clientType'].setValue(type);
  }

  radioChange(event: MatRadioChange) {
    console.log(event.value);
    var type = parseInt(event.value);
    this.form.controls['clientType'].setValue(type);
  }

  confirm() {
    if (!this.form.valid) {
      if (this.form.controls.clientTypeValue.value == 2) {
        if (!this.form.controls.firstName.valid || !this.form.controls.lastName.valid) {
          return;
        }
      } else if (!this.form.controls.name.valid) {
        return;
      }
    }
    var formValues = this.form.getRawValue();
    var model = new ClientDto(formValues);
    var inhabitantAddress = new AddressDto(formValues.inhabitantAddress);
    var correspondenceAddress = new AddressDto(formValues.correspondenceAddress);
    if (model.addresses == undefined) model.addresses = [];
    model.addresses.push(inhabitantAddress);
    model.addresses.push(correspondenceAddress);
    var result = model;
    this.dialogRef.close(result);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
