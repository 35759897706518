//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadNodesForSelect,
  loadNodesForSelectSuccess,
  loadNodesForSelectFailure,
  loadPageableNodes,
  loadPageableNodesSuccess,
  loadPageableNodesFailure,
  deleteNode,
  deleteNodeFailure,
  deleteNodeSuccess,
  addNode,
  addNodeSuccess,
  addNodeFailure,
  getNode,
  getNodeSuccess,
  getNodeFailure,
  updateNode,
  updateNodeSuccess,
  updateNodeFailure,
  setPageableNodesFiltersSuccess,
  setPageableNodesFiltersFailure,
  setPageableNodesFilters,
} from './nodes.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { NodesApiCallerService } from '../../shared/api-services/nodes-api-caller.service';
import { selectAllPageableNodesFilters } from './nodes.selector';

@Injectable()
export class NodesEffects {

  private latestedNodesKey: string;

  addNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNode),
      concatMap(({ data }) => {
        return this.nodesApiCaller.addNode(data).pipe(
          map((_) => addNodeSuccess({ id: _, name: data.name })),
          tap(() => this.reloadNodes(this.latestedNodesKey)),
          catchError((error) => of(addNodeFailure({ error })))
        );
      })
    )
  );

  updateNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNode),
      concatMap(({ data }) => {
        return this.nodesApiCaller.updateNode(data).pipe(
          map((_) => updateNodeSuccess()),
          catchError((error) => of(updateNodeFailure({ error })))
        );
      })
    )
  );

  loadNodesForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNodesForSelect),
      concatMap(() => {
        return this.nodesApiCaller.getNodesForSelect().pipe(
          map((_) => loadNodesForSelectSuccess({ data: _ })),
          catchError((error) => of(loadNodesForSelectFailure({ error })))
        );
      })
    )
  );

  loadPageableNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableNodes),
      withLatestFrom(this.store$.select(selectAllPageableNodesFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedNodesKey = key;
        return this.nodesApiCaller.getPageableNodes(filter.value).pipe(
          map((_) => loadPageableNodesSuccess({ data: _, key })),
          catchError((error) => of(loadPageableNodesFailure({ error })))
        );
      })
    )
  );

  getNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNode),
      concatMap(({ id }) => {
        return this.nodesApiCaller.getNode(id).pipe(
          map((_) => getNodeSuccess({ data: _ })),
          catchError((error) => of(getNodeFailure({ error })))
        );
      })
    )
  );

  deleteNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteNode),
      concatMap(({ id }) => {
        return this.nodesApiCaller.deleteNode(id).pipe(
          map((_) => deleteNodeSuccess({ id: id })),
          tap(() => this.reloadNodes(this.latestedNodesKey)),
          catchError((error) => of(deleteNodeFailure({ error })))
        );
      })
    )
  );

  setPageableNodesFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableNodesFilters),
      concatMap(({ data, key }) => {
        this.latestedNodesKey = key;
        return of(data).pipe(
          map((_) => setPageableNodesFiltersSuccess({ data, key })),
          finalize(() => this.reloadNodes(key)),
          catchError((error) => of(setPageableNodesFiltersFailure({ error })))
        );
      })
    )
  );
 
  private reloadNodes(key: string) {
    this.store$.dispatch(loadNodesForSelect());
    this.store$.dispatch(loadPageableNodes({key}));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private nodesApiCaller: NodesApiCallerService,
    private router: Router,
  ) { }

}
