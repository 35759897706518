import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State } from './user-data.reducer';

const selectFeature = (state: StoreState) => state.userData;

export const selectUserData = createSelector(selectFeature, (state: State) => state.userData);

export const selectClaimsDictionary = createSelector(selectFeature, (state: State) => {
    var jsonClaims = localStorage.getItem('claimsDictionary');
    var localClaims = JSON.parse(jsonClaims);
    if(localClaims != null){
        return localClaims;
    }
    else{
        return state.claimsDictionary;
    }
});