import { createAction, props } from '@ngrx/store';
import { UpdateCementDto } from '../../shared/models/cement/update-cement-dto';


export const loadCement = createAction('[Cement] Load Cement', props<{ recipeId: string }>());
export const addCement = createAction('[Cement] Add Cement', props<{ data: UpdateCementDto }>());
export const updateCement = createAction('[Cement] Update Cement', props<{ data: UpdateCementDto }>());

export const loadCementSuccess = createAction('[Cement] Load Cement Success', props<{ data: UpdateCementDto }>());
export const loadCementFailure = createAction('[Cement] Load Cement Failure', props<{ error: any }>());

export const addCementSuccess = createAction('[Cement] Add Cement Success', props<{ recipeId: string }>());
export const addCementFailure = createAction('[Cement] Add Cement Failure', props<{ error: any }>());

export const updateCementSuccess = createAction('[Cement] Update Cement Success', props<{ recipeId: string }>());
export const updateCementFailure = createAction('[Cement] Update Cement Failure', props<{ error: any }>());
