import { AddressType } from '../enums/address-type.enum';

export class AddressDto {
  constructor(data: AddressDto) {
    if (data != null) {
      this.id = data.id;
      this.address = data.address;
      this.city = data.city;
      this.postalCode = data.postalCode;
      this.phoneNumber = data.phoneNumber;
      this.isMainAddress = data.isMainAddress;
      this.customerId = data.customerId;
      this.managerName = data.managerName;
    }
  }

  id: string;
  address: string;
  city: string;
  postalCode: string;
  managerName: string;
  phoneNumber: string;
  isMainAddress: boolean;
  customerId: string;
}
