import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { concatMap } from "rxjs/internal/operators/concatMap";
import { catchError, map, tap } from "rxjs/operators";
import { TransactionApiCallerService } from "../../shared/api-services/transacion-api-caller.service";
import { PageableQuery } from "../../shared/models/contracts/pageable-query";
import { GetPageableQuery } from "../../shared/models/queries/get-pageable.query";
import { StoreState } from "../store-state";
import { handleTransaction, handleTransactionFailure, handleTransactionSuccess, loadPageableTransactions, loadPageableTransactionsFailure, loadPageableTransactionsSuccess, loadUnhandledTransactionsForAttachment, loadUnhandledTransactionsForAttachmentFailure, loadUnhandledTransactionsForAttachmentSuccess, loadUnhandledTransactionsForClient, loadUnhandledTransactionsForClientFailure, loadUnhandledTransactionsForClientSuccess } from "./transactions.actions";

@Injectable()
export class TransactionEffects {
  unhandledTransactionsForAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUnhandledTransactionsForAttachment),
      concatMap(({ id }) => {
        return this.transactionApiCaller.loadUnhandledTransactionsForAttachment(id).pipe(
          map((_) => loadUnhandledTransactionsForAttachmentSuccess({ data: _ })),
          catchError((error) => of(loadUnhandledTransactionsForAttachmentFailure({ error })))
        );
      })
    )
  );

  unhandledTransactionsForClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUnhandledTransactionsForClient),
      concatMap(({ clientName}) => {
        return this.transactionApiCaller.loadUnhandledTransactionsForClient(clientName).pipe(
          map((_) => loadUnhandledTransactionsForClientSuccess({ data: _ })),
          catchError((error) => of(loadUnhandledTransactionsForClientFailure({ error })))
        );
      })
    )
  );

  handleTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleTransaction),
      concatMap(({ data, attachmentId, clientName }) => {
        return this.transactionApiCaller.handleTransaction(data).pipe(
          map((_) => handleTransactionSuccess({})),
          tap(() => {
            this.reloadTransations();
            this.store$.dispatch(loadUnhandledTransactionsForAttachment({ id: attachmentId }));
            this.store$.dispatch(loadUnhandledTransactionsForClient({ clientName: clientName}));
          }),
          catchError((error) => {
            return of(handleTransactionFailure({ error }));
          })
        );
      })
    )
  );

  loadpageableTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableTransactions),
      concatMap(({ filters }) => {
        return this.transactionApiCaller.getPageableTransactions(filters).pipe(
          map((_) => loadPageableTransactionsSuccess({ data: _ })),
          catchError((error) => of(loadPageableTransactionsFailure({ error })))
        );
      })
    )
  );

  reloadTransations() {
    this.store$.dispatch(loadPageableTransactions({ filters: new GetPageableQuery(new PageableQuery()) }));
  }
  constructor(
    private actions$: Actions,
    private transactionApiCaller: TransactionApiCallerService,
    private store$: Store<StoreState>
  ) {}
}