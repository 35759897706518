import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './items.reducer';

const selectItemFeature = (state: StoreState) => state.items;

export const selectItems = createSelector(selectItemFeature, (state: State) => state.items);
export const selectItemsProduct = createSelector(selectItemFeature, (state: State) => state.itemsProduct);
export const selectItemsService = createSelector(selectItemFeature, (state: State) => state.itemsService);
export const selectItemsByNodeId = createSelector(selectItemFeature, (state: State) => state.itemsByNode);
export const selectItemsByCustomerId = createSelector(selectItemFeature, (state: State) => state.itemsByCustomer);

export const selectPageableItems = createSelector(selectItemFeature, (state: State, props: { key: string }) => {
  const result = state.pageableItems ? state.pageableItems.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectItem = createSelector(selectItemFeature, (state: State) => {
  const result = state.item;
  return result ? result : null;
});

export const selectAllPageableItemsFilters = createSelector(selectItemFeature, (state: State) => state.itemsFilters);
export const selectPageableItemsFilters = createSelector(selectItemFeature, (state: State, props: { key: string }) => {
  const result = state.itemsFilters ? state.itemsFilters.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectItemTypesForSelect = createSelector(selectItemFeature, (state: State, props: { key: string }) => {
  const result = state.carTypesForSelect;
  return result ? result : null;
});
export const selectItemsForSelect = createSelector(selectItemFeature, (state: State, props: { key: string }) => {
  const result = state.itemsForSelect;
  return result ? result : null;
});
