import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { AggregateDto } from '../models/aggregate/update-aggregate-dto';

@Injectable({
  providedIn: 'root',
})
export class AggregateApiCallerService extends BaseApiCaller {
  protected controllerPath = 'aggregate';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addAggregate(aggregate: AggregateDto) {
    return this.post('', aggregate);
  }

  public updateAggregate(aggregate: AggregateDto) {
    return this.put('', aggregate);
  }

  public deleteAggregate(id: string) {
    return this.delete(id);
  }
}
