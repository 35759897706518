//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable, ViewChild } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  addIncomingMessage,
  addIncomingMessageSuccess,
  addIncomingMessageFailure,
  deleteIncomingMessage,
  deleteIncomingMessageSuccess,
  deleteIncomingMessageFailure,
  setPageableIncomingMessagesFilters,
  setPageableIncomingMessagesFiltersSuccess,
  setPageableIncomingMessagesFiltersFailure,
  loadPageableIncomingMessages,
  loadPageableIncomingMessagesSuccess,
  loadPageableIncomingMessagesFailure,
  getIncomingMessage,
  getIncomingMessageSuccess,
  getIncomingMessageFailure,
  updateIncomingMessage,
  updateIncomingMessageSuccess,
  updateIncomingMessageFailure,
  addIncomingMessageDocuments,
  addIncomingMessageDocumentProgress,
  addIncomingMessageDocumentsScheduled,
  addIncomingMessageDocumentsFailure,
  deleteIncomingMessageDocument
} from './incoming-messages.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { SnackBarWrapperService } from '../../shared/services/snack-bar-wrapper.service';
import { selectAllPageableIncomingMessagesFilters } from './incoming-messages.selector';
import { IncomingMessagesApiCallerService } from '../../shared/api-services/incoming-messages-api-caller.service';
import { DocumentsApiCallerService } from '../../shared/api-services/documents-api-caller.service';
import { AttachmentProgressDto } from '../../shared/models/attachment/attachment-progress.dto';
import { HttpEventType } from '@angular/common/http';
import { UploadStatus } from '../../shared/models/enums/upload-status.enum';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';

@Injectable()
export class IncomingMessagesEffects {
  @ViewChild("spinner", {static:true}) spinner;

  private latestedIncomingMessagesKey: string;

  deleteIncomingMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteIncomingMessage),
      concatMap(({ id }) => {
        return this.incomingMessagesApiCaller.deleteIncomingMessage(id).pipe(
          map((_) => deleteIncomingMessageSuccess({ id: id })),
          tap(() => this.reloadIncomingMessages(this.latestedIncomingMessagesKey)),
          catchError((error) => of(deleteIncomingMessageFailure({ error })))
        );
      })
    )
  );

  addIncomingMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addIncomingMessage),
      concatMap(({ data, documents }) => {
        let lastValue;
        return this.incomingMessagesApiCaller.addIncomingMessage(data).pipe(
          map((_) => addIncomingMessageSuccess({data: _})),
          tap((_) => { lastValue = _;}),
          finalize(() => {
            let incomingMessageId = lastValue.data;
            if (documents != null) {
              this.store$.dispatch(addIncomingMessageDocuments({ data: documents, incomingMessageId: incomingMessageId }));
            }
            this.navigateToCatalog();
            this.overlayService.closeOverlay("add-incoming-message");
          }),
          catchError((error) => of(addIncomingMessageFailure({ error })))
        );
      })
    )
  );

  addOrderAtachments$ = createEffect(() =>
  this.actions$.pipe(
    ofType(addIncomingMessageDocuments),
    concatMap(({ data, incomingMessageId }) => {
      console.log("send documents")
      data.forEach((element) => {

        this.documentsApiCaller.addDocument(incomingMessageId, element).subscribe(
          (event) => {
            const progressInfo = new AttachmentProgressDto("id", element.file.name);
            if (event.type === HttpEventType.UploadProgress) {
              progressInfo.progress = Math.round((100 * event.loaded) / event.total);
              progressInfo.status = UploadStatus.InProgress;
            } else if (event.type === HttpEventType.Response) {
              progressInfo.status = UploadStatus.Finished;
              progressInfo.progress = 100;
            }
            this.store$.dispatch(addIncomingMessageDocumentProgress({ info: progressInfo, incomingMessageId }));
          },
          (error) => {
            this.store$.dispatch(addIncomingMessageDocumentsFailure({ error }));
            this.navigateToCatalog();
          }
        );
      });
      return of(addIncomingMessageDocumentsScheduled());
    })
  )
);

  updateIncomingMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateIncomingMessage),
      concatMap(({ data, documents }) => {
        let lastValue;
        return this.incomingMessagesApiCaller.updateIncomingMessage(data).pipe(
          map((_) => updateIncomingMessageSuccess()),
          tap((_) => lastValue = _),
          finalize(() => {
            let incomingMessageId = data.id;
            if (documents != null) {
              this.store$.dispatch(addIncomingMessageDocuments({ data: documents, incomingMessageId: incomingMessageId }));
            }
            this.navigateToCatalog();
            this.overlayService.closeOverlay("add-incoming-message");
          }),
          catchError((error) => of(updateIncomingMessageFailure({ error })))
        );
      })
    )
  );

  loadPageableIncomingMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableIncomingMessages),
      withLatestFrom(this.store$.select(selectAllPageableIncomingMessagesFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedIncomingMessagesKey = key;

        return this.incomingMessagesApiCaller.getPageableIncomingMessages(filter.value).pipe(
          map((_) => loadPageableIncomingMessagesSuccess({ data: _, key })),
          catchError((error) => of(loadPageableIncomingMessagesFailure({ error })))
        );
      })
    )
  );

  getIncomingMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIncomingMessage),
      concatMap(({id}) => {
        return this.incomingMessagesApiCaller.getIncomingMessage(id).pipe(
          map((_) => getIncomingMessageSuccess({ data: _ })),
          catchError((error) => of(getIncomingMessageFailure({ error })))
        );
      })
    )
  );

  setPageableIncomingMessagesFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableIncomingMessagesFilters),
      concatMap(({ data, key }) => {
        this.latestedIncomingMessagesKey = key;
        return of(data).pipe(
          map((_) => setPageableIncomingMessagesFiltersSuccess({ data, key })),
          finalize(() => this.reloadIncomingMessages(key)),
          catchError((error) => of(setPageableIncomingMessagesFiltersFailure({ error })))
        );
      })
    )
  );

  deleteIncomingMessageDocument$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteIncomingMessageDocument),
    concatMap(({ id }) => {
      return this.documentsApiCaller.deleteDocument(id).pipe(
        map((_) => deleteIncomingMessageSuccess({ id: id })),
        tap(() => this.reloadIncomingMessages(this.latestedIncomingMessagesKey)),
        catchError((error) => of(deleteIncomingMessageFailure({ error })))
      );
    })
  )
);

  private reloadIncomingMessages(key: string) {
    this.store$.dispatch(loadPageableIncomingMessages({ key }));
  }
 
  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private documentsApiCaller: DocumentsApiCallerService,
    private incomingMessagesApiCaller: IncomingMessagesApiCallerService,
    private router: Router,
    private overlayService: OverlayWrapperService
  ) { }

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'incoming-messages', 'catalog']);
  }
}
