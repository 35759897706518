import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { ClientDto } from '../../shared/models/dictionaries/clientDTO';
import { GetPageableEmployeesQuery } from '../../shared/models/queries/get-pageable-employees.query';

export const loadClientsForSelect = createAction('[FormsData] Load Clients For Select');
export const loadClientsForSelectSuccess = createAction(
  '[FormsData] Load Clients For Select Success',
  props<{ data: SelectModel<number>[] }>()
);
export const loadClientsForSelectFailure = createAction(
  '[FormsData] Load Clients For Select Failure',
  props<{ error: any }>()
);

export const loadPageableClients = createAction('[FormsData] Load Pageable Clients', props<{ key: string }>());
export const loadPageableClientsSuccess = createAction(
  '[FormsData] Load Pageable Clients Success',
  props<{ data: PageableDto<ClientDto>; key: string }>()
);
export const loadPageableClientsFailure = createAction(
  '[FormsData] Load Pageable Clients Failure',
  props<{ error: any }>()
);

export const deleteClient = createAction('[FormsData] delete Client', props<{ id: number }>());
export const deleteClientSuccess = createAction('[FormsData] delete Client Success', props<{ id: number }>());
export const deleteClientFailure = createAction('[FormsData] delete Client Failure', props<{ error: any }>());

export const addClient = createAction('[FormsData] Add Client', props<{ data: ClientDto }>());
export const addClientSuccess = createAction('[FormsData] Add Client Success', props<{ id: number; name: string }>());
export const addClientFailure = createAction('[FormsData] Add Client Failure', props<{ error: any }>());

export const updateClient = createAction('[FormsData] Update Client', props<{ data: ClientDto }>());
export const updateClientSuccess = createAction('[FormsData] Update Client Success');
export const updateClientFailure = createAction('[FormsData] Update Client Failure', props<{ error: any }>());

export const getClient = createAction('[FormsData] Get Client', props<{ id: number }>());
export const getClientSuccess = createAction('[FormsData] Get Client Success', props<{ data: ClientDto }>());
export const getClientFailure = createAction('[FormsData] Get Client Failure', props<{ error: any }>());

export const setPageableClientsFilters = createAction(
  '[Authors] Set Pageable Clients Filters',
  props<{ data?: GetPageableEmployeesQuery; key: string }>()
);
export const setPageableClientsFiltersSuccess = createAction(
  '[Authors] Set Pageable Clients Filters Success',
  props<{ data: GetPageableEmployeesQuery; key: string }>()
);
export const setPageableClientsFiltersFailure = createAction(
  '[Authors] Set Pageable Clients Filters Failure',
  props<{ error: any }>()
);
