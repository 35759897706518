import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { loadMixtureProperties, loadMixturePropertiesSuccess, loadMixturePropertiesFailure,
        addMixtureProperties, addMixturePropertiesSuccess, addMixturePropertiesFailure,
        updateMixtureProperties, updateMixturePropertiesSuccess, updateMixturePropertiesFailure } from './mixture-properties.actions';
import { concatMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RecipeApiCallerService } from '../../shared/api-services/recipe-api-caller.service';
import { PropertiesMixtureApiCallerService } from '../../shared/api-services/properties-mixture-api-caller.service';

@Injectable()
export class MixturePropertiesEffects {

    cement$ = createEffect(() =>
    this.actions$.pipe(
        ofType(loadMixtureProperties),
        concatMap(({ recipeId }) => {
            return this.recipeApiCaller.getPropertiesMixtureForRecipe(recipeId).pipe(
                 map((_) => loadMixturePropertiesSuccess({ data: _ })),
                 catchError((error) => of(loadMixturePropertiesFailure({ error })))
            );
        })
));

addCement$ = createEffect(() =>
 this.actions$.pipe(
     ofType(addMixtureProperties),
     concatMap(({ data }) => {
         return this.mixturePropertiesApiCaller.addPropertiesMixture(data).pipe(
         map((_) => addMixturePropertiesSuccess({ recipeId: data.id })),
         catchError((error) => of(addMixturePropertiesFailure({ error })))
         );
     })
 ));

 updateCement$ = createEffect(() =>
 this.actions$.pipe(
     ofType(updateMixtureProperties),
     concatMap(({ data }) => {
         return this.mixturePropertiesApiCaller.updatePropertiesMixture(data).pipe(
         map((_) => updateMixturePropertiesSuccess({ recipeId: data.id })),
         catchError((error) => of(updateMixturePropertiesFailure({ error })))
         );
     })
 ));

    constructor(
        private actions$: Actions,
        private mixturePropertiesApiCaller: PropertiesMixtureApiCallerService,
        private recipeApiCaller: RecipeApiCallerService
    ) {}
}
