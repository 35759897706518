import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { ValidatorPatterns } from '../../../validators/validator-patterns';

export class EditorialDocumentForm extends UntypedFormGroup {
  constructor() {
    super({
      positionNumber: new UntypedFormControl(undefined, [Validators.required]),
      clientId: new UntypedFormControl(undefined, [Validators.required,]),
      clientName: new UntypedFormControl(undefined),
      receivedDate: new UntypedFormControl(undefined, [Validators.required,]),
      releaseDate: new UntypedFormControl(undefined, [Validators.required,]),
      statusId: new UntypedFormControl(undefined, [Validators.required,]),
      statusName: new UntypedFormControl(undefined),
      sourceId: new UntypedFormControl(undefined, [Validators.required,]),
      sourceName: new UntypedFormControl(undefined),
      departmentId: new UntypedFormControl(undefined, [Validators.required,]),
      departmentName: new UntypedFormControl(undefined),
      description: new UntypedFormControl(undefined, [Validators.required,]),
      employeeId: new UntypedFormControl(undefined, [Validators.required,]),
      employeeName: new UntypedFormControl(undefined),
      authorId: new UntypedFormControl(undefined, [Validators.required,]),
      authorName: new UntypedFormControl(undefined),
      title: new UntypedFormControl(undefined, [Validators.required,]),

      id: new UntypedFormControl(null, [Validators.required]),
      name: new UntypedFormControl(null, [Validators.required]),
    });
  }

  forAdd() {

    return this;
  }
}
