import { Action, createReducer, on } from '@ngrx/store';
import { PaymentTypeModel } from '../../shared/models/paymentTypeModel';
import { loadPaymentType, loadPaymentTypeFailure, loadPaymentTypeSuccess } from './paymentType.actions';

export const customerFeatureKey = 'paymentType';

export interface State {
    paymentTypes?: PaymentTypeModel[];
}

export const initialState: State = {};

const paymentTypeReducer = createReducer(
    initialState,

    on(loadPaymentType, (state) => state),
    on(loadPaymentTypeSuccess, (state, { data }) => setPaymentTypes(state, data)),
    on(loadPaymentTypeFailure, (state) => setPaymentTypes(state, null)),
)

function setPaymentTypes(state: State, data: PaymentTypeModel[]) {
    return {
      ...state,
      paymentTypes: data,
    };
  }

export function reducer(state: State | undefined, action: Action) {
    return paymentTypeReducer(state, action);
  }