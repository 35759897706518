import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as FileSaver from 'file-saver';
import { Spinkit, SpinnerVisibilityService } from 'ng-http-loader';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'app-dialog-info',
  templateUrl: './dialog-info.component.html',
  styleUrls: ['./dialog-info.component.scss'],
})
export class DialogInfoComponent implements OnInit {
  useTranslation: boolean;
  label: string;
  text: string;
  isEdit: boolean;
  
  spinnerStyle = Spinkit;
  constructor(
    private dialogRef: MatDialogRef<DialogInfoComponent>,
    public spinner: SpinnerVisibilityService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      label: string;
      text: string;
      useTranslation?: boolean;
    }
  ) {
    this.label = data.label;
    this.text = data.text;
    this.useTranslation = data.useTranslation === true;
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
