import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { EMPTY, Observable, throwError, TimeoutError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { SyncTask } from "../model/syncTask.model";

@Injectable()
export class OfflineInterceptor {

    constructor(private dbService: NgxIndexedDBService) { }

    intercept(req, next) {
        return next.handle(req)
            .pipe(catchError((err: HttpErrorResponse) => this.handleError(req, err)));
    }

    private handleError(req: any, err: HttpErrorResponse): Observable<any> {
        if (this.offlineOrBadConnection(err)) {
            // A client-side or network error occurred. Handle it accordingly.
            this.addOrUpdateSyncTask<any>(req);
            return EMPTY;
        } else {
            console.log('A backend error occurred.', err);
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            return throwError(err);
        }
    }

    private offlineOrBadConnection(err: HttpErrorResponse): boolean {
        return (
            err instanceof TimeoutError ||
            err.error instanceof ErrorEvent ||
            !window.navigator.onLine
        );
    }

    private addOrUpdateSyncTask<T>(req: any): void {
        if (req.url.includes('/api/') && 
            (req.method === 'POST' || req.method === 'PUT' || req.method === 'DELETE')) {

            this.dbService.add('SyncTasks', {
                value: JSON.stringify(SyncTask.fromHttpRequest(req))
            }).subscribe(_ => {});
        }
    }
}