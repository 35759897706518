import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, withLatestFrom } from 'rxjs/operators';
import { SettlementsApiCallerService } from '../../shared/api-services/settlements-api-caller.service';
import {
  loadPageableSettlementsHistory,
  loadPageableSettlementsHistorySuccess,
  loadPageableSettlementsHistoryFailure,
  setPageableSettlementsHistoryFilters,
  setPageableSettlementsHistoryFiltersSuccess,
  setPageableSettlementsHistoryFiltersFailure,
} from './settlements-history.actions';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import { selectAllPageableSettlementsHistoryFilter } from './settlements-history.selectors';

@Injectable()
export class SettlementsHistoryEffects {
  private latestedSettlementsKey: string;

  setPageableSettlementsHistoryFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableSettlementsHistoryFilters),
      concatMap(({ data, key }) => {
        this.latestedSettlementsKey = key;
        return of(data).pipe(
          map((_) => setPageableSettlementsHistoryFiltersSuccess({ data, key })),
          finalize(() => this.reloadSettlementsHistory(key)),
          catchError((error) => of(setPageableSettlementsHistoryFiltersFailure({ error })))
        );
      })
    )
  );

  loadSettlementsHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableSettlementsHistory),
      withLatestFrom(this.store$.select(selectAllPageableSettlementsHistoryFilter)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedSettlementsKey = key;
        return this.settlementsApiCaller.getPageableSettlementsHistory(filter?.value).pipe(
          map((_) => loadPageableSettlementsHistorySuccess({ data: _, key })),
          catchError((error) => of(loadPageableSettlementsHistoryFailure({ error })))
        );
      })
    )
  );

  private reloadSettlementsHistory(key: string) {
    this.store$.dispatch(loadPageableSettlementsHistory({ key }));
  }

  constructor(
    private actions$: Actions,
    private settlementsApiCaller: SettlementsApiCallerService,
    private store$: Store<StoreState>
  ) {}
}
