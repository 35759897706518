import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { PersonDto } from '../models/person.dto';
import { UpdateCompanyEmployeeDto } from '../models/company-employee/update-company-employee.dto';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { CompanyEmployeeDto } from '../models/company-employee/company-employee.dto';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableCompanyEmployeesQuery } from '../models/queries/get-pageable-company-employees.query';

@Injectable({
  providedIn: 'root',
})
export class CompanyEmployeeApiCallerService extends BaseApiCaller {
  protected controllerPath = 'companyEmployee';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addCompanyEmployee(dto: UpdateCompanyEmployeeDto) {
    return this.post('', dto);
  }

  public updateCompanyEmployee(dto: UpdateCompanyEmployeeDto) {
    return this.put('', dto);
  }

  public deleteCompanyEmployee(id: string) {
    return this.delete(id);
  }

  public getPageableEmployees(query: GetPageableCompanyEmployeesQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<CompanyEmployeeDto>>('pageable', { params });
  }
}
