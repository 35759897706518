import { HttpHeaders, HttpRequest } from '@angular/common/http';
import _ from 'lodash';

export class SyncTask<T> {
    constructor(
      public method: string,
      public url: string,
      public body: T,
      public headers: Array<Array<any>>) { }


      public static fromHttpRequest(req: HttpRequest<any>): SyncTask<any> {
        return new SyncTask(
          req.method, 
          req.url, 
          req.body, 
          req.headers.keys().map(key => [key, req.headers.getAll(key)])
        );
      }

      public static toHttpRequest(task: SyncTask<any>): HttpRequest<any> {
        let headers = new HttpHeaders();
        for (const h of task.headers) headers = headers.append(h[0], h[1]);

        return new HttpRequest(
          task.method, 
          task.url, 
          task.body, 
          { headers: headers }
        );
      }
      
      public static compare(task1: SyncTask<any>, task2: SyncTask<any>) : boolean {
        return task1 === task2 || (
          task1.method === task2.method &&
          task1.url === task2.url &&
          task1.body === task2.body &&
          _.isEqual(task1.headers, task2.headers)
        );
      }
  }