<footer class="footer-distributed">
  <div class="footer-left">
    <p class="footer-company-name">&copy; 2020</p>
  </div>

  <div class="footer-center"></div>

  <div class="footer-right">
    <div class="display-flex">
      <p class="footer-company-about">wersja: {{ appVersion }}</p>
    </div>
  </div>
</footer>
