import { Action, createReducer, on } from '@ngrx/store';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableSpecialAdditivesQuery } from '../../shared/models/queries/get-pageable-special-additives.query';
import { SpecialAdditivesDto } from '../../shared/models/special-additives/specialAdditives.Dto';
import { SpecialAdditivesSelectModel } from '../../shared/models/specialAdditivesSelectModel';
import {
  loadPageableSpecialAdditives,
  loadPageableSpecialAdditivesFailure,
  loadPageableSpecialAdditivesSuccess,
  loadSpecialAdditivesForSelect,
  loadSpecialAdditivesForSelectFailure,
  loadSpecialAdditivesForSelectSuccess,
  setPageableSpecialAdditivesFilters,
  setPageableSpecialAdditivesFiltersSuccess,
  getSpecialAdditives,
  getSpecialAdditivesFailure,
  getSpecialAdditivesSuccess
} from './special-additives.actions';
import { selectPageableSpecialAdditivesFilters } from './special-additives.selectors';

export const specialAdditivesFeatureKey = 'specialAdditives';

export interface State {
  pageableSpecialAdditives?: DictionaryDto<string, PageableDto<SpecialAdditivesSelectModel<any>>>;
  specialAdditivesFilters?: DictionaryDto<string, GetPageableSpecialAdditivesQuery>;
  specialAdditivesForSelect?: SpecialAdditivesSelectModel<any>[];
  specialAdditives?: SpecialAdditivesDto;
}

export const initialState: State = {};

export const specialAdditivesReducer = createReducer(
  initialState,
  on(loadSpecialAdditivesForSelect, (state) => setSpecialAdditivesForSelect(state, null)),
  on(loadSpecialAdditivesForSelectSuccess, (state, { data }) => setSpecialAdditivesForSelect(state, data)),
  on(loadSpecialAdditivesForSelectFailure, (state, action) => state),

  on(loadPageableSpecialAdditives, (state, { key }) => setPageableSpecialAdditives(state, null, key)),
  on(loadPageableSpecialAdditivesSuccess, (state, { data, key }) => setPageableSpecialAdditives(state, data, key)),
  on(loadPageableSpecialAdditivesFailure, (state, action) => state),

  on(setPageableSpecialAdditivesFilters, (state, { data, key }) => setPageableSpecialAdditivesFiltersFunc(state, null, key)),
  on(setPageableSpecialAdditivesFiltersSuccess, (state, { data, key }) => setPageableSpecialAdditivesFiltersFunc(state, data, key)),

  on(getSpecialAdditives, (state) => setSpecialAdditives(state, null)),
  on(getSpecialAdditivesSuccess, (state, { data }) => setSpecialAdditives(state, data)),
  on(getSpecialAdditivesFailure, (state, action) => state),


  );

function setSpecialAdditivesForSelect(state: State, data: SpecialAdditivesSelectModel<any>[]) {
  const specialAdditivesForSelect = data;
  return {
    ...state,
    specialAdditivesForSelect,
  };
}

function setPageableSpecialAdditives(state: State, data: PageableDto<SpecialAdditivesSelectModel<any>>, key: string) {
  const pageableSpecialAdditives = Array.isArray(state.pageableSpecialAdditives)
    ? state.pageableSpecialAdditives
    : new DictionaryDto<string, PageableDto<SpecialAdditivesSelectModel<any>>>();
  const orders = pageableSpecialAdditives.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableSpecialAdditives.push({ key, value: data });
  }

  return {
    ...state,
    pageableSpecialAdditives,
  };
}

function setPageableSpecialAdditivesFiltersFunc(state: State, data: GetPageableSpecialAdditivesQuery, key: string) {
  const specialAdditivesFilters = Array.isArray(state.specialAdditivesFilters)
    ? state.specialAdditivesFilters
    : new DictionaryDto<string, GetPageableSpecialAdditivesQuery>();
  const filter = specialAdditivesFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    specialAdditivesFilters.push({ key, value: data });
  }

  return {
    ...state,
    specialAdditivesFilters,
  };
}

function setSpecialAdditives(state: State, data: SpecialAdditivesDto) {
  var specialAdditives = data;
  return {
    ...state,
    specialAdditives,
  };
}



export function reducer(state: State | undefined, action: Action) {
  return specialAdditivesReducer(state, action);
}
