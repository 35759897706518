import { OrderDeliveryStatusesForm } from './dictionaries/order-delivery-statuses';
import { EmployeeForEditForm } from './dictionaries/employee-for-edit-form';
import { Injectable } from '@angular/core';
import { CompanyValidatorsApiCallerService } from '../../api-services/company-validators-api-caller.service';
import { ContractorForm } from './contractor/contractor.form';
//import { ClientForm } from './client/client.form';
import { OperationForm } from './operation/operation.form';
import { ContractorDataForm } from './contractor/contractor-data.form';
import { ContractForm } from './contract/contract.form';
import { UserForm } from './users/user.form';
import { IdentityUsersApiCallerService } from '../../api-services/identity-users-api-caller.service';
import { PositionForm } from './positions/position.form';
import { IncomingMessageForm } from './incoming-messages/incoming-message.form';
import { OutgoingMessageForm } from './outgoing-messages/outgoing-message.form';
import { InvoiceForm } from './invoices/invoice.form';
import { EditorialDocumentForm } from './editorial-documents/editorial-document.form';
import { DictionaryElementForm } from './dictionaries/dictionary-element.form';
import { AuthorForm } from './dictionaries/author.form';
import { ClientForm } from './dictionaries/client.form';
import { EmployeeForm } from './dictionaries/employee.form';
import { DepartmentForm } from './dictionaries/department.form';
import { CarForm } from './cars/car.form';
import { ItemForm } from './items/item.form';
import { CustomerForm } from './customers/customer.form';
import { StructuralElementForm } from './structural-elements/structural-element-form';
import { SupplyForm } from './supplies/supply.form';
import { AddressForm } from './addresses/address.form';
import { EmployeeAbsenceForm } from './dictionaries/employeeAbsenceForm';
import { EmployeePasswordForm } from './dictionaries/employeePasswordForm';
import { CustomerApiCallerService } from '../../api-services/customer/customer-api-caller.service';
import { UniqueNipValidator } from '../../validators/unique-nip.validator';
import { OrderStatusesForm } from './dictionaries/order-statuses-form';
import { IngredientForm } from './ingredients/ingredient.form';
import { ItemForCustomerForm } from './items/ItemForCustomer.form';
import { ExternalEmployeeForm } from './dictionaries/externalemployee.form';
import { ItemNameValidator } from '../../validators/item-name.validator';
import { ItemApiCallerService } from '../../api-services/item/item-api-caller.service';
import { SpecialAdditivesForm } from './special-additives/special-additives-form';
import { CustomerExistValidator } from '../../validators/customer-phone-number-exist-validator';

@Injectable({ providedIn: 'root' })
export class FormFactory {
  static getSpecialAdditivesForm() {
    throw new Error('Method not implemented.');
  }
  constructor(
    private companyValidatorsApiCaller: CompanyValidatorsApiCallerService,
    private accountApiCallerService: IdentityUsersApiCallerService,
    private customerApiCaller: CustomerApiCallerService,
    private itemsApiCaller: ItemApiCallerService
  ) {}

  public getContractorDataForm() {
    return new ContractorDataForm(this.companyValidatorsApiCaller);
  }

  public getContractorForm() {
    return new ContractorForm(this.companyValidatorsApiCaller, this.accountApiCallerService);
  }

  // public getClientForm() {
  //   return new ClientForm(this.companyValidatorsApiCaller);
  // }

  public getOperationForm() {
    return new OperationForm(this.companyValidatorsApiCaller);
  }

  public getContractForm() {
    return new ContractForm(this.companyValidatorsApiCaller);
  }

  public getUserForm() {
    return new UserForm();
  }

  public getPositionForm() {
    return new PositionForm();
  }

  public getIncomingMessageForm() {
    return new IncomingMessageForm();
  }

  public getOutgoingMessageForm() {
    return new OutgoingMessageForm();
  }

  public getInvoiceForm() {
    return new InvoiceForm();
  }

  public getEditorialDocumentForm() {
    return new EditorialDocumentForm();
  }

  public getDictionaryElementForm() {
    return new DictionaryElementForm();
  }

  public getAuthorForm() {
    return new AuthorForm();
  }

  public getClientForm() {
    return new ClientForm();
  }

  public getEmployeeForm() {
    return new EmployeeForm();
  }
  public getEmployeeForEditForm() {
    return new EmployeeForEditForm();
  }

  public getEmployeePasswordForm() {
    return new EmployeePasswordForm();
  }

  public getEmployeeAbsenceForm() {
    return new EmployeeAbsenceForm();
  }

  public getDeliveryStatusesForm() {
    return new OrderDeliveryStatusesForm();
  }
  public getOrderStatusesForm() {
    return new OrderStatusesForm();
  }

  public getDepartmentForm() {
    return new DepartmentForm();
  }

  public getCarForm() {
    return new CarForm();
  }

  public getItemForm() {
    return new ItemForm();
  }
  public getItemCustomerForm() {
    const asyncValidator = new ItemNameValidator(this.itemsApiCaller);
    return new ItemForCustomerForm(asyncValidator);
  }
  public getCustomerForm() {
    const asyncValidator = new UniqueNipValidator(this.customerApiCaller);
    const asyncValidatorNumber = new CustomerExistValidator(this.customerApiCaller);
    return new CustomerForm(asyncValidator, asyncValidatorNumber);
  }
  public getStructuralElementForm() {
    return new StructuralElementForm();
  }
  public getSpecialAdditivesForm() {
    return new SpecialAdditivesForm();
  }
  public getSupplyForm() {
    return new SupplyForm();
  }
  public getAddressForm() {
    return new AddressForm();
  }
  public getIngredientsForm() {
    return new IngredientForm();
  }

  public getExternalEmployeeForm() {
    return new ExternalEmployeeForm();
  }
}
