import {
  loadPageableVersionLogsSuccess,
  loadPageableVersionLogsFailure,
  loadPageableVersionLogs,
  loadVersionLogs,
  loadVersionLogsSuccess,
  loadVersionLogsFailure,
} from './version-log.actions';
import { PageableDto } from '../../shared/models/pageable.dto';
import { VersionLogDto } from '../../shared/models/version-log.dto';
import { Action, createReducer, on } from '@ngrx/store';

export const profileFeatureKey = 'versionLog';
export const initialState: State = {};

export interface State {
  versionLogs?: PageableDto<VersionLogDto>;
  versionLog?: VersionLogDto;
  logs?: VersionLogDto[];
}

const versionLogReducer = createReducer(
  initialState,
  on(loadPageableVersionLogs, (state) => setVersionLogs(state, null)),
  on(loadPageableVersionLogsSuccess, (state, { data }) => setVersionLogs(state, data)),
  on(loadPageableVersionLogsFailure, (state, action) => setVersionLogs(state, null)),

  on(loadVersionLogs, (state) => setLogs(state, null)),
  on(loadVersionLogsSuccess, (state, { data }) => setLogs(state, data)),
  on(loadVersionLogsFailure, (state, action) => setLogs(state, null))
);

function setVersionLogs(state: State, data: PageableDto<VersionLogDto>) {
  return {
    ...state,
    versionLogs: data,
  };
}

function setLogs(state, data) {
  return { ...state, logs: data };
}

export function reducer(state: State | undefined, action: Action) {
  return versionLogReducer(state, action);
}
