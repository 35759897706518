import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableSpecialAdditivesQuery } from '../models/queries/get-pageable-special-additives.query';
import { SpecialAdditivesDto } from '../models/special-additives/specialAdditives.Dto';
import { SpecialAdditivesSelectModel } from '../models/specialAdditivesSelectModel';
import { BaseApiCaller } from './base-api-caller';

@Injectable({
  providedIn: 'root',
})
export class SpecialAdditivesApiCallerService extends BaseApiCaller {
  protected controllerPath = 'SpecialAdditives';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getSpecialAdditivesForSelect(isForHigherClass: boolean|null, customerId: string): Observable<SpecialAdditivesSelectModel<any>[]> {
    const params = this.prepareParams({ isForHigherClass, customerId });
    return this.get<SpecialAdditivesSelectModel<any>[]>('getSpecialAdditivesForSelect', { params });
  }

  public getPageableSpecialAdditives(query: GetPageableSpecialAdditivesQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<SpecialAdditivesSelectModel<any>>>('pageable', { params });
  }

  updateSpecialAdditives(data: SpecialAdditivesDto) {
    return this.put('', data);
  }
  addSpecialAdditives(data: SpecialAdditivesDto) {
    return this.post('', data);
  }
  deleteSpecialAdditives(id: string) {
    return this.delete(id);
  }
  public getSpecialAdditives(id: string) {
    return this.get<SpecialAdditivesDto>(id.toString()).pipe(map((_) => new SpecialAdditivesDto(_)));
  }
}
