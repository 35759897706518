import { Action, createReducer, on } from '@ngrx/store';
import { BaseContractDto } from '../../shared/models/contract/base-contract.dto';
import { ContractDto } from '../../shared/models/contract/contract.dto';
import {
  loadClientContracts,
  loadClientContractsFailure,
  loadClientContractsSuccess,
  loadContract,
  loadContractFailure,
  loadContractSuccess,
} from './contract.actions';

export const contractFeatureKey = 'contract';

export interface State {
  contract?: ContractDto;
  clientContracts?: BaseContractDto[];
}

export const initialState: State = {};

const contractReducer = createReducer(
  initialState,

  on(loadContract, (state) => setContract(state, null)),
  on(loadContractSuccess, (state, { data }) => setContract(state, data)),
  on(loadContractFailure, (state, action) => setContract(state, null)),

  on(loadClientContracts, (state) => setClientContracts(state, null)),
  on(loadClientContractsSuccess, (state, { data }) => setClientContracts(state, data)),
  on(loadClientContractsFailure, (state, action) => setClientContracts(state, null))
);

function setContract(state, data) {
  return { ...state, contract: data };
}

function setClientContracts(state, clientContracts: BaseContractDto[]) {
  return { ...state, clientContracts };
}
export function reducer(state: State | undefined, action: Action) {
  return contractReducer(state, action);
}
