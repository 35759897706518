import { createAction, props } from '@ngrx/store';
import { DictionaryModel } from '../../shared/models/dictionary-model';
import { OrderDto } from '../../shared/models/order/order.dto';
import { UpdateOrderDto } from '../../shared/models/order/update-order.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableOrdersQuery } from '../../shared/models/queries/get-pageable-orders.query';
import { FileInfoDto } from '../../shared/models/file-info.dto';
import { AttachmentProgressDto } from '../../shared/models/attachment/attachment-progress.dto';
import { HistoryDto } from '../../shared/models/history.dto';
import { UpdateOrderCompanyEmployeeDto } from '../../shared/models/order/update-order-company-employee.dto';
import { OrderCompanyEmployeeDto } from '../../shared/models/order/order-company-employee.dto';
import { OrderTypeDto } from '../../shared/models/order-type.dto';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';

export const loadOperation = createAction('[Operation] Load Operation', props<{ id: string }>());

export const loadOperationSuccess = createAction('[Operation] Load Operation Success', props<{ data: OrderDto }>());

export const loadOperationFailure = createAction('[Operation] Load Operation Failure', props<{ error: any }>());

export const addOrder = createAction(
  '[Operation] Add Order',
  props<{ data: UpdateOrderDto; attachments?: File[]; isClone?: boolean }>()
);

export const addOrderSuccess = createAction('[Operation] Add Order Success', props<{ orderId: string }>());

export const addOrderFailure = createAction('[Operation] Add Order Failure', props<{ error: any }>());

export const updateOrder = createAction('[Operation] Update Order', props<{ data: UpdateOrderDto }>());

export const updateOrderSuccess = createAction('[Operation] Update Order Success', props<{ orderId: string }>());

export const updateOrderFailure = createAction('[Operation] Update Order Failure', props<{ error: any }>());

export const loadPageableOrders = createAction('[Operation] Load Pageable Orders', props<{ key: string }>());

export const loadPageableOrdersSuccess = createAction(
  '[Operation] Load Pageable Orders Success',
  props<{ data: PageableDto<OrderDto>; key: string }>()
);

export const loadPageableOrdersFailure = createAction(
  '[Operation] Load Pageable Orders Failure',
  props<{ error: any }>()
);

export const setPageableOrdersFilters = createAction(
  '[Operation] Set Pageable Orders Filters',
  props<{ data?: GetPageableOrdersQuery; key: string }>()
);

export const setPageableOrdersFiltersSuccess = createAction(
  '[Operation] Set Pageable Orders Filters Success',
  props<{ data: GetPageableOrdersQuery; key: string }>()
);

export const setPageableOrdersFiltersFailure = createAction(
  '[Operation] Set Pageable Orders Filters Failure',
  props<{ error: any }>()
);

export const setOrderStatus = createAction(
  '[Operation] Set Order Status',
  props<{ orderId: string; orderStatusId: string }>()
);

export const setOrderStatusSuccess = createAction('[Operation] Set Order Status Success', props<{ orderId: string }>());

export const setOrderStatusFailure = createAction('[Operation] Set Order Status Failure', props<{ error: any }>());

export const loadOrderAvailableStatuses = createAction(
  '[Operation] Load OrderAvailableStatuses',
  props<{ id: string }>()
);

export const loadOrderAvailableStatusesSuccess = createAction(
  '[Operation] Load OrderAvailableStatuses Success',
  props<{ data: DictionaryModel<string>[] }>()
);

export const loadOrderAvailableStatusesFailure = createAction(
  '[Operation] Load OrderAvailableStatuses Failure',
  props<{ error: any }>()
);

export const loadOrderAttachmentsInfo = createAction('[Operation] Load Order Atachments Info', props<{ id: string }>());

export const loadOrderAttachmentsInfoSuccess = createAction(
  '[Operation] Load Order Atachments Info Success',
  props<{ data: FileInfoDto[] }>()
);

export const loadOrderAttachmentsInfoFailure = createAction(
  '[Operation] Load Order Atachments Info Failure',
  props<{ error: any }>()
);

export const addOrderAtachments = createAction(
  '[Operation] Add Order Atachments',
  props<{ data: File[]; orderId: string }>()
);
export const addOrderAtachmentsScheduled = createAction('[Operation] Add Order Atachments Scheduled');

export const addOrderAtachmentsSuccess = createAction('[Operation] Add Order Atachments Success');

export const addOrderAtachmentProgress = createAction(
  '[Operation] Add Order Atachments Progress',
  props<{ info: AttachmentProgressDto; orderId: string }>()
);

export const addOrderAtachmentProgressSuccess = createAction(
  '[Operation] Add Order Atachments Progress Success',
  props<{ data: AttachmentProgressDto[]; orderId: string }>()
);

export const addOrderAtachmentsFailure = createAction(
  '[Operation] Add Order Atachments Failure',
  props<{ error: any }>()
);

export const loadOrderHistory = createAction('[Operation] Load Order History', props<{ orderId: string }>());

export const loadOrderHistorySuccess = createAction(
  '[Operation] Load Order History Success',
  props<{ data: HistoryDto }>()
);

export const loadOrderHistoryFailure = createAction('[Operation] Load Order History Failure', props<{ error: any }>());
export const updateOrderCompanyEmployee = createAction(
  '[Operation] Update Order Company Employee',
  props<{ data: UpdateOrderCompanyEmployeeDto }>()
);

export const updateOrderCompanyEmployeeSuccess = createAction(
  '[Operation] Update Order Company Employee Success',
  props<{ orderId: string }>()
);

export const updateOrderCompanyEmployeeFailure = createAction(
  '[Operation] Update Order Company Employee Failure',
  props<{ error: any }>()
);

export const loadOrderCompanyEmployee = createAction(
  '[Operation] Load Order Company Employee',
  props<{ id: string }>()
);

export const loadOrderCompanyEmployeeSuccess = createAction(
  '[Operation] Load Order Company Employee Success',
  props<{ data: OrderCompanyEmployeeDto }>()
);

export const loadOrderCompanyEmployeeFailure = createAction(
  '[Operation] Load Order Company Employee Failure',
  props<{ error: any }>()
);

export const unassignOrderCompanyEmployee = createAction(
  '[Operation] Unassign Order Company Employee',
  props<{ id: string }>()
);

export const unassignOrderCompanyEmployeeSuccess = createAction(
  '[Operation] Unassign Order Company Employee Success',
  props<{ id: string }>()
);

export const unassignOrderCompanyEmployeeFailure = createAction(
  '[Operation] Unassign Order Company Employee Failure',
  props<{ error: any }>()
);

export const loadPageableOrderTypes = createAction('[Operation] Load Pageable Order Types', props<{ filters?: any }>());

export const loadPageableOrderTypesSuccess = createAction(
  '[Operation] Load Pageable Order Types Success',
  props<{ data: PageableDto<OrderTypeDto> }>()
);

export const loadPageableOrderTypesFailure = createAction(
  '[Operation] Load Pageable Order Types Failure',
  props<{ error: any }>()
);

export const createOrderType = createAction('[OrderType] Create OrderType', props<{ data: OrderTypeDto }>());

export const createOrderTypeSuccess = createAction('[OrderType] Create OrderType Success', props<{ id: string }>());

export const createOrderTypeFailure = createAction('[OrderType] Create OrderType Failure', props<{ error: any }>());

export const deleteOrderType = createAction('[OrderType] Delete OrderType', props<{ id: string }>());

export const deleteOrderTypeSuccess = createAction('[OrderType] Delete OrderType Success', props<{ id: string }>());

export const deleteOrderTypeFailure = createAction('[OrderType] Delete OrderType Failure', props<{ error: any }>());

export const updateOrderType = createAction('[OrderType] Update OrderType', props<{ data: OrderTypeDto }>());
export const updateOrderTypeSuccess = createAction('[OrderType] Update OrderType Success', props<{ Id: string }>());

export const updateOrderTypeFailure = createAction('[OrderType] Update OrderType Failure', props<{ error: any }>());
