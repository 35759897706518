import { state } from '@angular/animations';
import { createSelector } from '@ngrx/store';
import { StoreState } from '../store-state';
import { State } from './customer.reducer';

const selectFeature = (state: StoreState) => state.customer;
const selectCustomersFeature = (state: StoreState) => state.customer;
const selectCustomerFeature = (state: StoreState) => state.customer;

// export const selectCustomers = createSelector(selectFeature, (state: State) => state.customers);

export const selectPageableCustomers = createSelector(
  selectCustomersFeature,
  (state: State, props: { key: string }) => {
    const result = state.pageableCustomers ? state.pageableCustomers.find((_) => _.key === props.key) : null;

    return result ? result.value : null;
  }
);

export const selectCustomers = createSelector(selectCustomersFeature, (state: State) => {
  const result = state.customers;
  return result ? result : null;
});

export const selectCustomer = createSelector(selectCustomerFeature, (state: State) => {
  const result = state.customer;
  return result ? result : null;
});

export const selectCustomerDataByNip = createSelector(selectFeature, (state: State) => {
  const result = state.customerDataByNip;
  return result ? result : null;
});

export const selectAllPageableCustomersFilters = createSelector(
  selectCustomersFeature,
  (state: State) => state.customersFilters
);
export const selectPageableCustomersFilters = createSelector(
  selectCustomersFeature,
  (state: State, props: { key: string }) => {
    const result = state.customersFilters ? state.customersFilters.find((_) => _.key === props.key) : null;

    return result ? result.value : null;
  }
);
