import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { Spinkit, SpinnerVisibilityService } from 'ng-http-loader';
import { TargetMessageService } from 'project/src/app/callendar/service/target-message.service';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent implements OnInit {

  spinnerStyle = Spinkit;
  disabled = false;

  attachmentForm = new UntypedFormControl(null);

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSave = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { attachmentForm: UntypedFormControl},
    private dialogRef: MatDialogRef<UploadFileDialogComponent>,
    private messageService: TargetMessageService,
    private spinner: SpinnerVisibilityService,
  ) {
  }

  ngOnInit(): void {
    this.messageService.getMessage().subscribe(body =>{
      if (body.topic == 'upload-files-close') {
        this.disabled = false;
        this.spinner.hide();
        this.close(true);
      } 
    });
  }

  onConfirmAction() {
     this.disabled = true;
     this.spinner.show();
     this.messageService.sendMessage('upload-files-confirm', this.attachmentForm.value);
  }

  close(mode) {
    this.dialogRef.close(mode);
  }
}