//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadEmployeesForSelect,
  loadEmployeesForSelectSuccess,
  loadEmployeesForSelectFailure,
  loadPageableEmployees,
  loadPageableEmployeesSuccess,
  loadPageableEmployeesFailure,
  deleteEmployee,
  deleteEmployeeSuccess,
  deleteEmployeeFailure,
  addEmployee,
  addEmployeeSuccess,
  addEmployeeFailure,
  getEmployee,
  getEmployeeSuccess,
  getEmployeeFailure,
  updateEmployeeSuccess,
  updateEmployeeFailure,
  updateEmployee,
  setPageableEmployeesFilters,
  setPageableEmployeesFiltersSuccess,
  setPageableEmployeesFiltersFailure,
  loadRolesForSelect,
  loadRolesForSelectSuccess,
  loadRolesForSelectFailure
} from './employees.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
//import { EmployeesApiCallerService } from '../../shared/api-services/employees-api-caller.service';
import { selectAllPageableEmployeesFilters } from './employees.selector';
import { IdentityUsersApiCallerService } from '../../shared/api-services/identity-users-api-caller.service';
import { IdentityRolesApiCallerService } from '../../shared/api-services/identity-roles-api-caller.service';

@Injectable()
export class EmployeesEffects {

  private latestedEmployeesKey: string;
 
  addEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addEmployee),
      concatMap(({ data }) => {
        return this.identityApiCaller.createOrUpdateUser(data).pipe(
          map((_) => addEmployeeSuccess()),
          tap(() => this.reloadEmployees(this.latestedEmployeesKey)),
          catchError((error) => of(addEmployeeFailure({ error })))
        );
      })
    )
  );

  // updateEmployee$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(updateEmployee),
  //     concatMap(({ data }) => {
  //       return this.employeesApiCaller.updateEmployee(data).pipe(
  //         map((_) => updateEmployeeSuccess()),
  //         catchError((error) => of(updateEmployeeFailure({ error })))
  //       );
  //     })
  //   )
  // );

  // loadEmployeesForSelect$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(loadEmployeesForSelect),
  //     concatMap(() => {
  //       return this.employeesApiCaller.getEmployeesForSelect().pipe(
  //         map((_) => loadEmployeesForSelectSuccess({ data: _ })),
  //         catchError((error) => of(loadEmployeesForSelectFailure({ error })))
  //       );
  //     })
  //   )
  // );

  loadPageableEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableEmployees),
      withLatestFrom(this.store$.select(selectAllPageableEmployeesFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedEmployeesKey = key;
        return this.identityApiCaller.getPageableEmployees(filter.value).pipe(
          map((_) => loadPageableEmployeesSuccess({ data: _, key })),
          catchError((error) => of(loadPageableEmployeesFailure({ error })))
        );
      })
    )
  );

  getEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEmployee),
      concatMap(({ id }) => {
        return this.identityApiCaller.getUser(id).pipe(
          map((_) => getEmployeeSuccess({ data: _ })),
          catchError((error) => of(getEmployeeFailure({ error })))
        );
      })
    )
  );

  deleteEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEmployee),
      concatMap(({ id }) => {
        return this.identityApiCaller.deleteUser(id).pipe(
          map((_) => deleteEmployeeSuccess({ id: id })),
          tap(() => this.reloadEmployees(this.latestedEmployeesKey)),
          catchError((error) => of(deleteEmployeeFailure({ error })))
        );
      })
    )
  );

  setPageableEmployeesFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableEmployeesFilters),
      concatMap(({ data, key }) => {
        this.latestedEmployeesKey = key;
        return of(data).pipe(
          map((_) => setPageableEmployeesFiltersSuccess({ data, key })),
          finalize(() => this.reloadEmployees(key)),
          catchError((error) => of(setPageableEmployeesFiltersFailure({ error })))
        );
      })
    )
  );

  rolesForSelect$ = createEffect(() =>
  this.actions$.pipe(
    ofType(loadRolesForSelect),
    concatMap(() => {
      return this.identityRolesApiCaller.getSystemRoles().pipe(
        map((_) => loadRolesForSelectSuccess({ data: _ })),
        catchError((error) => of(loadRolesForSelectFailure({ error })))
      );
    })
  )
);

  private reloadEmployees(key: string) {
    this.store$.dispatch(loadEmployeesForSelect());
    this.store$.dispatch(loadPageableEmployees({key}));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    //private employeesApiCaller: EmployeesApiCallerService,
    private identityRolesApiCaller: IdentityRolesApiCallerService,
    private identityApiCaller: IdentityUsersApiCallerService,
    private router: Router,
  ) { }

}
