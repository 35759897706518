import { Routes } from '@angular/router';
import { AuthorisedLayoutComponent } from './areas/authorised-layout/authorised-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { UnauthorizedComponent } from './areas/unauthorized/unauthorized.component';
import { AutoLoginComponent } from './areas/auto-login/auto-login.component';
import { AccessDeniedComponent } from './areas/access_denied/access_denied.component';
import { DefaultRedirectGuard } from './shared/guards/default_redirect.guard';

export const AppRoutes: Routes = [
  {
    //canActivate: [AuthGuard],
    //canLoad: [AuthGuard],
    path: 'authorised',
    component: AuthorisedLayoutComponent,
    children: [
      {
        canActivate: [AuthGuard],
        path: 'incoming-messages',
        loadChildren: () =>
          import('./incoming-messages/incoming-messages.module').then((m) => m.IncomingMessagesModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'outgoing-messages',
        loadChildren: () =>
          import('./outgoing-messages/outgoing-messages.module').then((m) => m.OutgoingMessagesModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'invoice-register',
        loadChildren: () => import('./invoice-register/invoice-register.module').then((m) => m.InvoiceRegisterModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'editorial-documents',
        loadChildren: () =>
          import('./editorial-documents/editorial-documents.module').then((m) => m.EditorialDocumentsModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'other-documents',
        loadChildren: () => import('./other-documents/other-documents.module').then((m) => m.OtherDocumentsModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'dictionaries',
        loadChildren: () => import('./dictionaries/dictionaries.module').then((m) => m.DictionariesModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'forbidden',
        loadChildren: () => import('./forbidden/forbidden.module').then((m) => m.ForbiddenModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToOrdersCalendar'] },
        path: 'calendar',
        loadChildren: () => import('./callendar/callendar.module').then((m) => m.CallendarModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToRecipes'] },
        path: 'recipe',
        loadChildren: () => import('./recipe/recipe.module').then((m) => m.RecipeModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToRecipesCostsCatalog'] },
        path: 'recipeCosts',
        loadChildren: () => import('./recipe-costs/recipe-costs.module').then((m) => m.RecipeCostsModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToProducts'] },
        path: 'items',
        loadChildren: () => import('./items/items.module').then((m) => m.ItemsModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToCars'] },
        path: 'cars',
        loadChildren: () => import('./cars/cars.module').then((m) => m.CarsModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToEmployees'] },
        path: 'employees',
        loadChildren: () => import('./employees/employees.module').then((m) => m.EmployeesModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToDrivers'] },
        path: 'drivers',
        loadChildren: () => import('./drivers/drivers.module').then((m) => m.DriversModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToExternalUsers'] },
        path: 'external-users',
        loadChildren: () => import('./external-users/external-users.module').then((m) => m.ExternalUsersModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToCustomers'] },
        path: 'customers',
        loadChildren: () => import('./customers/customers.module').then((m) => m.CustomersModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToSpecialAdditives'] },
        path: 'special-additives',
        loadChildren: () =>
          import('./special-additives/special-additives.module').then((m) => m.SpecialAdditivesModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToStructuralElements'] },
        path: 'structural-elements',
        loadChildren: () =>
          import('./structural-elements/structural-elements.module').then((m) => m.StructuralElementsModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToDeliveryCalendar'] },
        path: 'delivery-calendar',
        loadChildren: () =>
          import('./delivery-calendar/delivery-calendar.module').then((m) => m.DeliveryCalendarModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToPumpLoadCalendar'] },
        path: 'pump-scheduler',
        loadChildren: () =>
          import('./pump-occupancy-scheduler/pump-scheduler.module').then((m) => m.PumpSchedulerModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToPumpLoadCalendar'] },
        path: 'nodes-scheduler',
        loadChildren: () => import('./nodes-scheduler/nodes-scheduler.module').then((m) => m.NodesSchedulerModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToOrderFulfillmentCalendar'] },
        path: 'orders-fullfilment',
        loadChildren: () =>
          import('./order-fulfillment-calendar/order-fulfillment-calendar.module').then(
            (m) => m.OrderFulfillmentCalendarModule
          ),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToOrderAndDeliveryStatuses'] },
        path: 'delivery-statuses',
        loadChildren: () =>
          import('./delivery-statuses/delivery-statuses.module').then((m) => m.DeliveryStatusesModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToOrdersCalendar'] },
        path: 'order-statuses',
        loadChildren: () => import('./order-statuses/order-statuses.module').then((m) => m.OrderStatusesModule),
      },
      {
        canActivate: [AuthGuard],
        //data: { requiredClaims: ['AccessToOrdersCalendar'] },
        path: 'orders',
        loadChildren: () => import('./callendar/callendar.module').then((m) => m.CallendarModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToOffers'] },
        path: 'offers',
        loadChildren: () => import('./offers/offers.module').then((m) => m.OffersModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToRecipesCosts'] },
        path: 'ingredients',
        loadChildren: () => import('./ingredients/ingredients.module').then((m) => m.IngredientsModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToRecipesCosts'] },
        path: 'transactions',
        loadChildren: () => import('./transactions/transactions.module').then((m) => m.TransactionsModule),
      },
      {
        canActivate: [AuthGuard],
        data: { requiredClaims: ['AccessToWZDocuments'] },
        path: 'wz-documents',
        loadChildren: () => import('./wz-documents/wz-documents.module').then((m) => m.WzDocumentsModule),
      },
      {
        canActivate: [AuthGuard],
        path: 'access-denied',
        component: AccessDeniedComponent,
      },

      { path: '', pathMatch: 'full', canActivate: [DefaultRedirectGuard], children: [] },
    ],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'autologin', component: AutoLoginComponent },
  { path: '**', redirectTo: 'authorised' },
];
