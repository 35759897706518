import { PageableDto } from '../../shared/models/pageable.dto';
import { Action, createReducer, on } from '@ngrx/store';
import {
  loadPageableSettlementsHistory,
  loadPageableSettlementsHistorySuccess,
  loadPageableSettlementsHistoryFailure,
  setPageableSettlementsHistoryFilters,
  setPageableSettlementsHistoryFiltersSuccess,
} from './settlements-history.actions';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { SettlementsHistoryDto } from '../../shared/models/orders/settlementsHistoryDto';
import { GetPageableSettlementsHistory } from '../../shared/models/queries/get-pageable-settlements-history.query';

export interface State {
  pageableHistory?: DictionaryDto<string, PageableDto<SettlementsHistoryDto>>;
  historyFilters?: DictionaryDto<string, GetPageableSettlementsHistory>;
}
export const initialState: State = {
  historyFilters: new DictionaryDto<string, GetPageableSettlementsHistory>(),
};

const historyReducer = createReducer(
  initialState,

  on(loadPageableSettlementsHistory, (state, { key }) => setPageableHistory(state, null, key)),
  on(loadPageableSettlementsHistorySuccess, (state, { data, key }) => setPageableHistory(state, data, key)),
  on(loadPageableSettlementsHistoryFailure, (state, action) => state),

  on(setPageableSettlementsHistoryFilters, (state, { key }) =>
    setPageableSettlementsHistoryFiltersFunc(state, null, key)
  ),
  on(setPageableSettlementsHistoryFiltersSuccess, (state, { data, key }) =>
    setPageableSettlementsHistoryFiltersFunc(state, data, key)
  )
);
export function reducer(state: State | undefined, action: Action) {
  return historyReducer(state, action);
}

function setPageableHistory(state: State, data: PageableDto<SettlementsHistoryDto>, key: string) {
  const pageableHistory = Array.isArray(state.pageableHistory)
    ? state.pageableHistory
    : new DictionaryDto<string, PageableDto<SettlementsHistoryDto>>();
  const settlements = pageableHistory.find((_) => _.key === key);
  if (settlements != null) {
    settlements.value = data;
  } else {
    pageableHistory.push({ key, value: data });
  }
  return {
    ...state,
    pageableHistory,
  };
}
function setPageableSettlementsHistoryFiltersFunc(state: State, data: GetPageableSettlementsHistory, key: string) {
  const historyFilters = Array.isArray(state.historyFilters)
    ? state.historyFilters
    : new DictionaryDto<string, GetPageableSettlementsHistory>();
  const filter = historyFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    historyFilters.push({ key, value: data });
  }
  return {
    ...state,
    historyFilters,
  };
}
