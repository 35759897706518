import { Action, createReducer, on } from '@ngrx/store';

import {
  loadAdditivesNorms,
  loadAdditivesNormsSuccess,
  loadAdditivesNormsFailure,
  loadWaterNorms,
  loadWaterNormsSuccess,
  loadWaterNormsFailure,
  loadEnduranceClasses,
  loadEnduranceClassesSuccess,
  loadEnduranceClassesFailure,
  loadEnduranceProgresses,
  loadEnduranceProgressesSuccess,
  loadEnduranceProgressesFailure,
  loadAggregateTypes,
  loadAggregateTypesSuccess,
  loadAggregateTypesFailure,
  loadAdmixtureTypes,
  loadAdmixtureTypesSuccess,
  loadAdmixtureTypesFailure,
  loadCementClasses,
  loadCementClassSucces,
  loadCementClassFailure,
  loadCementTypes,
  loadCementTypesSucces,
  loadCementTypesFailure,
  loadCementNorms,
  loadCementNormsSucces,
  loadCementNormsFailure,
  loadCementSpecialProperties,
  loadCementSpecialPropertiesSucces,
  loadCementSpecialPropertiesFailure,
  loadVebeConsistencyClass,
  loadVebeConsistencyClassSuccess,
  loadVebeConsistencyClassFailure,
  loadConeConsistencyClass,
  loadConeConsistencyClassSuccess,
  loadConeConsistencyClassFailure,
  loadFlowConsistencyClassFailure,
  loadFlowConsistencyClassSuccess,
  loadFlowConsistencyClass,
  loadCompactibilityConsistencyClassFailure,
  loadCompactibilityConsistencyClassSuccess,
  loadCompactibilityConsistencyClass,
} from './recipe-dictionaries.actions';
import { Statement } from '@angular/compiler';
import { DictionaryModel } from '../../shared/models/dictionary-model';

export const recipeDictionariesFeatureKey = 'recipeDictionaries';

export interface State {
  admixtureTypes?: DictionaryModel<string>[];
  cementClasses?: DictionaryModel<string>[];
  cementTypes?: DictionaryModel<string>[];
  cementNorms?: DictionaryModel<string>[];
  cementSpecialProperties?: DictionaryModel<string>[];
  additivesNorms?: DictionaryModel<string>[];
  waterNorms?: DictionaryModel<string>[];
  enduranceClasses?: DictionaryModel<string>[];
  enduranceProgresses?: DictionaryModel<string>[];
  aggregateTypes?: DictionaryModel<string>[];
  vebeConsistencyClass?: DictionaryModel<string>[];
  coneConsistencyClass?: DictionaryModel<string>[];
  flowConsistencyClass?: DictionaryModel<string>[];
  compactibilityConsistencyClass?: DictionaryModel<string>[];
}

export const initialState: State = {};

export const recipeDictionariesReducer = createReducer(
  initialState,

  on(loadAdmixtureTypes, (state) => state),
  on(loadAdmixtureTypesSuccess, (state, { data }) => setAdmixtureTypes(state, data)),
  on(loadAdmixtureTypesFailure, (state) => setAdmixtureTypes(state, null)),
  // Słownik Klas Cementu
  on(loadCementClasses, (state) => state),
  on(loadCementClassSucces, (state, { data }) => setCementClass(state, data)),
  on(loadCementClassFailure, (state) => setCementClass(state, null)),
  // Słownik Typu Cementu
  on(loadCementTypes, (state) => state),
  on(loadCementTypesSucces, (state, { data }) => setCementTypes(state, data)),
  on(loadCementTypesFailure, (state) => setCementTypes(state, null)),
  // Słownik Norm Cementu
  on(loadCementNorms, (state) => state),
  on(loadCementNormsSucces, (state, { data }) => setCementNorms(state, data)),
  on(loadCementNormsFailure, (state) => setCementNorms(state, null)),
  // Słownik Właściwości Specjalnych Cementu
  on(loadCementSpecialProperties, (state) => state),
  on(loadCementSpecialPropertiesSucces, (state, { data }) => setCementSpecialProperties(state, data)),
  on(loadCementSpecialPropertiesFailure, (state) => setCementSpecialProperties(state, null)),
  // Słownik Norm Dodatków
  on(loadAdditivesNorms, (state) => state),
  on(loadAdditivesNormsSuccess, (state, { data }) => setAdditivesNorms(state, data)),
  on(loadAdditivesNormsFailure, (state) => setAdditivesNorms(state, null)),
  // Słownik Norm Wody
  on(loadWaterNorms, (state) => state),
  on(loadWaterNormsSuccess, (state, { data }) => setWaterNorms(state, data)),
  on(loadWaterNormsFailure, (state) => setWaterNorms(state, null)),
  // Słownik Rozwoju Wytrzymałościowego Właściowości Beton
  on(loadEnduranceClasses, (state) => state),
  on(loadEnduranceClassesSuccess, (state, { data }) => setEnduranceClasses(state, data)),
  on(loadEnduranceClassesFailure, (state) => setEnduranceClasses(state, null)),
  // Słownik Klasy Wytrzymałości Na Ściskanie Betonu
  on(loadEnduranceProgresses, (state) => state),
  on(loadEnduranceProgressesSuccess, (state, { data }) => setEnduranceProgresses(state, data)),
  on(loadEnduranceProgressesFailure, (state) => setEnduranceProgresses(state, null)),
  // Słownik Typu Kruszyw
  on(loadAggregateTypes, (state) => state),
  on(loadAggregateTypesSuccess, (state, { data }) => setAggregateTypes(state, data)),
  on(loadAggregateTypesFailure, (state) => setAggregateTypes(state, null)),

  on(loadVebeConsistencyClass, (state) => state),
  on(loadVebeConsistencyClassSuccess, (state, { data }) => setVebeConsistencyClass(state, data)),
  on(loadVebeConsistencyClassFailure, (state) => setVebeConsistencyClass(state, null)),

  on(loadConeConsistencyClass, (state) => state),
  on(loadConeConsistencyClassSuccess, (state, { data }) => setConeConsistencyClass(state, data)),
  on(loadConeConsistencyClassFailure, (state) => setConeConsistencyClass(state, null)),

  on(loadFlowConsistencyClass, (state) => state),
  on(loadFlowConsistencyClassSuccess, (state, { data }) => setFlowConsistencyClass(state, data)),
  on(loadFlowConsistencyClassFailure, (state) => setFlowConsistencyClass(state, null)),

  on(loadCompactibilityConsistencyClass, (state) => state),
  on(loadCompactibilityConsistencyClassSuccess, (state, { data }) => setCompactibilityConsistencyClass(state, data)),
  on(loadCompactibilityConsistencyClassFailure, (state) => setCompactibilityConsistencyClass(state, null))
);

function setAdmixtureTypes(state, admixtureTypes: DictionaryModel<string>[]) {
  return { ...state, admixtureTypes };
}

function setCementClass(state, cementClasses: DictionaryModel<string>[]) {
  return { ...state, cementClasses };
}

function setCementTypes(state, cementTypes: DictionaryModel<string>[]) {
  return { ...state, cementTypes };
}

function setCementNorms(state, cementNorms: DictionaryModel<string>[]) {
  return { ...state, cementNorms };
}

function setCementSpecialProperties(state, cementSpecialProperties: DictionaryModel<string>[]) {
  return { ...state, cementSpecialProperties };
}

function setVebeConsistencyClass(state, vebeConsistencyClass: DictionaryModel<string>[]) {
  return { ...state, vebeConsistencyClass };
}

function setConeConsistencyClass(state, coneConsistencyClass: DictionaryModel<string>[]) {
  return { ...state, coneConsistencyClass };
}

function setFlowConsistencyClass(state, flowConsistencyClass: DictionaryModel<string>[]) {
  return { ...state, flowConsistencyClass };
}
function setCompactibilityConsistencyClass(state, compactibilityConsistencyClass: DictionaryModel<string>[]) {
  return { ...state, compactibilityConsistencyClass };
}

function setAdditivesNorms(state, additivesNorms: DictionaryModel<string>[]) {
  return { ...state, additivesNorms };
}

function setWaterNorms(state, waterNorms: DictionaryModel<string>[]) {
  return { ...state, waterNorms };
}

function setEnduranceClasses(state, enduranceClasses: DictionaryModel<string>[]) {
  return { ...state, enduranceClasses };
}

function setEnduranceProgresses(state, enduranceProgresses: DictionaryModel<string>[]) {
  return { ...state, enduranceProgresses };
}

function setAggregateTypes(state, aggregateTypes: DictionaryModel<string>[]) {
  return { ...state, aggregateTypes };
}

export function reducer(state: State | undefined, action: Action) {
  return recipeDictionariesReducer(state, action);
}
