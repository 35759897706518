import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export class SelectModelForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(null, Validators.required),
      name: new UntypedFormControl(null)
    });
  }
}
