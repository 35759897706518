import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableDto } from '../models/pageable.dto';
import { map } from 'rxjs/operators';
import { GetPageableStructuralElementsQuery } from '../models/queries/get-pageable-structural-elements.query';
import { StructuralElementDto } from '../models/structural-element/structuralElement.Dto';
import { BaseApiCaller } from './base-api-caller';

@Injectable({
  providedIn: 'root',
})
export class StructuralElementApiCallerService extends BaseApiCaller {
  protected controllerPath = 'StructuralElements';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getStructuralElements() {
    return this.get<StructuralElementDto[]>('');
  }

  public addStructuralElement(dto: StructuralElementDto) {
    return this.post('', dto);
  }

  public updateStructuralElement(dto: StructuralElementDto) {
    return this.put('', dto);
  }

  public deleteStructuralElement(id: string) {
    return this.delete(id.toString());
  }

  public getStructuralElement(id: string) {
    return this.get<StructuralElementDto>(id.toString()).pipe(map((_) => new StructuralElementDto(_)));
  }

  public getPageableStructuralElements(query: GetPageableStructuralElementsQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<StructuralElementDto>>('pageable', { params });
  }
}
