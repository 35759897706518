import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { NotificationDto } from '../../shared/models/notification.dto';
import { Store, select } from '@ngrx/store';
import { StoreState } from '../../root-store/store-state';
import { loadNotifications } from '../../root-store/notifications/notification.action';
import { selectNotifications } from '../../root-store/notifications/notifications-selectors';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  notifications: NotificationDto[];
  constructor(private store$: Store<StoreState>) {}

  ngOnInit(): void {
    this.store$.dispatch(loadNotifications());
    this.store$
      .pipe(
        select(selectNotifications),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        this.notifications = data;
      });

    var element = document.getElementById('notificationPanel');
    element.scrollTop = element.scrollHeight;
    //element.scrollIntoView(false);
  }
}
