import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentTypeModel } from '../../models/paymentTypeModel';
import { BaseApiCaller } from '../base-api-caller';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeApiCallerService extends BaseApiCaller {
  protected controllerPath = 'paymentType';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getItemAll() {
    return this.get<PaymentTypeModel[]>('');
  }
}
