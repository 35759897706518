import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './documents.reducer';

const selectDocumentsFeature = (state: StoreState) => state.documents;

export const selectPageableDocuments = createSelector(selectDocumentsFeature, (state: State, props: { key: string }) => {
  const result = state.pageableDocuments ? state.pageableDocuments.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectDocument = createSelector(selectDocumentsFeature, (state: State) => {
  const result = state.invoice;
  return result ? result : null;
});

export const selectAllPageableDocumentsFilters = createSelector(selectDocumentsFeature, (state: State) => state.documentsFilters);
export const selectPageableDocumentsFilters = createSelector(selectDocumentsFeature, (state: State, props: { key: string }) => {
  const result = state.documentsFilters ? state.documentsFilters.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});