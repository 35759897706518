import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ItemByCustomerId } from '../../models/items/itemByCustomerModel';
import { ItemByNode } from '../../models/items/itemByNodeModel';
import { ItemDto } from '../../models/items/itemDTO';
import { ItemsModel } from '../../models/items/ItemsModel';
import { SelectModel } from '../../models/select-model';
import { BaseApiCaller } from '../base-api-caller';

@Injectable({
  providedIn: 'root',
})
export class ItemApiCallerService extends BaseApiCaller {
  protected controllerPath = 'items';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getItemAll() {
    return this.get<ItemsModel[]>('');
  }

  public getItemsProductAll() {
    return this.get<ItemsModel[]>('getItemsProduct');
  }

  public getItemsServiceAll(customerId: string) {
    const params = this.prepareParams({
      customerId: customerId
    });
    return this.get<ItemsModel[]>('getItemsService', {
      params: params
    });
  }

  public getItemByNode(nodeId: string) {
    return this.get<ItemByNode[]>('getProductsByNode/' + nodeId);
  }

  public getItemByCustomer(customerId: string, nodeId: string) {
    const params = this.prepareParams({
      customerId: customerId,
      nodeId: nodeId
    });

    return this.get<ItemByCustomerId[]>('getProductsByCustomer', {
      params: params
    });
  }

  public getItemNameExist(name: string, nodeId: string, addressId: string, customerId: string) {
    const params = this.prepareParams({
      name: name,
      nodeId: nodeId,
      addressId: addressId,
      customerId: customerId,
    });
    return this.get<boolean>('getItemNameExists', {params});
  }

  public getItemsForSelect(nodeId: string, isProduct: boolean|null, isService: boolean|null): Observable<SelectModel<string>[]> {
    const params = this.prepareParams({
      nodeId,
      isProduct,
      isService
    });

    return this.get<SelectModel<string>[]>('getForSelect', {
      params: params,
    });
  }
}
