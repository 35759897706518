<div class="row">
  <div class="col-lg-9">
    <ng-content></ng-content>
  </div>
  <div class="col-lg-3 text-right">
    <button mat-button class="ml-40" (click)="toggleDisabled()" [disabled]="!editAllowed || loading">
      <mat-icon>create</mat-icon>
    </button>
    <button mat-button (click)="clear()" [disabled]="loading"><mat-icon>refresh</mat-icon></button>
    <button mat-button (click)="save()" [disabled]="loading || formStatus !== 'VALID'">
      <mat-icon [ngStyle]="{ color: formStatus === 'INVALID' ? 'red' : undefined }">save</mat-icon>
    </button>
  </div>
</div>
