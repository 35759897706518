import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree,
  CanLoad,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { StoreState } from '../../root-store/store-state';
import { selectUserData } from '../../root-store/user-data/user-data-selectors';
import { UserDataModel } from '../models/userDataModel';
import { SecurityService } from '../services/security.service';

@Injectable({ providedIn: 'root' })
export class DefaultRedirectGuard implements CanActivate {
  userData: UserDataModel;

  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private store$: Store<StoreState>,
    private securityService: SecurityService
  ) {
    this.store$
      .pipe(
        select(selectUserData),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        //dispatch for that is executed in authorized-layout component
        this.userData = data;
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.userData.role == undefined) {
      this.router.navigate(['authorised/', 'calendar']);
    }
    if (this.userData.role == 'Sprzedawca') {
      this.router.navigate(['authorised/', 'orders', 'catalog']);
    }
    if (this.userData.role == 'SuperSprzedawca') {
      this.router.navigate(['authorised/', 'orders', 'catalog']);
    }
    if (this.userData.role == 'Kierowca') {
      this.router.navigate(['authorised/', 'access-denied']);
    }
    if (this.userData.role == 'Logistyk') {
      this.router.navigate(['authorised/', 'delivery-calendar']);
    }
    if (this.userData.role == 'Węzłowy') {
      this.router.navigate(['authorised/', 'delivery-statuses', 'catalog']);
    }

    return of(true);
  }
}
