import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { map } from 'rxjs/operators';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableItemsQuery } from '../models/queries/get-pageable-items.query';
import { ItemDto } from '../models/items/itemDTO';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { SelectModel } from '../models/select-model';

@Injectable({
  providedIn: 'root',
})
export class ItemsApiCallerService extends BaseApiCaller {
  protected controllerPath = 'Items';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addItem(dto: ItemDto) {
    return this.post('', dto);
  }

  public updateItem(dto: ItemDto) {
    return this.put('', dto);
  }

  public deleteItem(id: string) {
    return this.delete(id.toString());
  }

  public getItem(id: string) {
    return this.get<ItemDto>(id.toString()).pipe(map((_) => new ItemDto(_)));
  }

  public getPageableItems(query: GetPageableItemsQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<ItemDto>>('pageable', { params });
  }

  public getItemTypesForSelect(): Observable<SelectModel<string>[]> {
    return this.get<SelectModel<string>[]>('getItemTypesForSelect');
  }

}
