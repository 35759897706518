import { DatePipe } from '@angular/common';
import { HttpClient, HttpRequest, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'project/src/environments/environment';
import { map } from 'rxjs/operators';
import { DictionaryModel } from '../models/dictionary-model';
import { FileInfoDto } from '../models/file-info.dto';
import { OrderScheduleDto } from '../models/order/order-schedule.dto';
import { OrderDto } from '../models/order/order.dto';
import { UpdateOrderDto } from '../models/order/update-order.dto';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableOrdersQuery } from '../models/queries/get-pageable-orders.query';
import { BaseApiCaller } from './base-api-caller';
import { OrderCompanyEmployeeDto } from '../models/order/order-company-employee.dto';
import { UpdateOrderCompanyEmployeeDto } from '../models/order/update-order-company-employee.dto';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root',
})
export class OrderApiCallerService extends BaseApiCaller {
  protected controllerPath = 'order';

  constructor(httpClient: HttpClient, private datePipe: DatePipe) {
    super(httpClient);
  }

  public addOrder(order: UpdateOrderDto) {
    return this.post('', order);
  }

  public updateOrder(order: UpdateOrderDto) {
    return this.put('', order);
  }

  public getPageableOrders(filters: GetPageableOrdersQuery) {
    let dateFrom: string = null;
    let dateTo: string = null;
    if (filters.dateFrom) {
      dateFrom = moment(filters.dateFrom).format(environment.apiDateFormat);
    }
    if (filters.dateTo) {
      dateTo = moment(filters.dateTo).format(environment.apiDateFormat);
    }
    const transform = {
      ...filters,
      dateFrom,
      dateTo,
    };
    const tmpParams = this.prepareParams(transform);
    const params = this.modifyFormat(tmpParams, filters.orderTypeId, filters.orderStatusId);

    const headers = { 'accept-language': 'pl-PL' };

    return this.get<PageableDto<OrderDto>>('', { params, headers }).pipe(
      map((p) => {
        p.result.forEach((item) => {
          item.schedule = new OrderScheduleDto(item.schedule);
        });

        return p;
      })
    );
  }

  private modifyFormat(params: HttpParams, typeIds: string[], statusIds): HttpParams {
    params = params.delete('orderTypeId');
    typeIds.forEach((id) => {
      params = params.append('orderTypeId', id);
    });

    params = params.delete('orderStatusId');
    statusIds.forEach((id) => {
      params = params.append('orderStatusId', id);
    });

    return params;
  }

  public getOrder(id: string) {
    return this.get<OrderDto>(id).pipe(
      map((o) => ({
        ...o,
        schedule: new OrderScheduleDto(o.schedule),
      }))
    );
  }

  public setStatus(orderId: string, statusId: string) {
    return this.put(`${orderId}/order-status/${statusId}`, undefined);
  }

  public getAvailableStatuses(orderId: string) {
    return this.get<DictionaryModel<string>[]>(`${orderId}/available-statuses`);
  }

  public getAttachmentsInfo(orderId: string) {
    return this.get<FileInfoDto[]>(orderId + '/attachments').pipe(
      map((data) => {
        return data.map((_) => {
          var result = new FileInfoDto(_);

          return result;
        });
      })
    );
  }

  public getOrderCompanyEmployee(orderId: string) {
    return this.get<OrderCompanyEmployeeDto>(orderId + '/company-employee');
  }

  public updateOrderCompanyEmployee(dto: UpdateOrderCompanyEmployeeDto) {
    return this.put('company-employee', dto);
  }

  public unassignOrderCompanyEmployee(orderId: string) {
    return this.delete('company-employee/' + orderId);
  }
}
