export class CustomerDto {
  constructor(data: CustomerDto) {
    if (data != null) {
      this.id = data.id;
      this.name = data.name;
      this.lastName = data.lastName;
      this.firstName = data.firstName;
      this.companyFullName = data.companyFullName;
      this.companyShortName = data.companyShortName;
      this.phoneNumber = data.phoneNumber;
      this.address = data.address;
      this.city = data.city;
      this.postalCode = data.postalCode;
      this.nip = data.nip;
      this.isBlackListed = data.isBlackListed;
      this.email = data.email;
      this.isCompany = data.isCompany;
      this.isIndividual = data.isIndividual;
      this.merchantLimit = data.merchantLimit;
      this.bricklayer = data.bricklayer;
      this.uniqueAbbreviation = data.uniqueAbbreviation;
      this.pesel = data.pesel;
    }
  }

  id: string;
  name: string;
  lastName: string;
  firstName: string;
  companyShortName: string;
  companyFullName: string;

  phoneNumber: string;
  address: string;

  email: string;
  city: string;
  postalCode: string;
  nip: number;

  isBlackListed: boolean;
  isCompany: boolean;
  isIndividual: boolean;
  bricklayer: boolean;
  merchantLimit: number;
  uniqueAbbreviation: string;
  pesel:number;
}
