<div class="my-modal">
  <mat-card-header>
    <mat-card-title>{{ 'IncomingMessages.NewSender' | translate }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'Dictionaries.ClientData' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-radio-group (change)="radioChange($event)" formControlName="clientTypeValue">
            <mat-radio-button value="2">{{ 'Common.Person' | translate }}</mat-radio-button>
            <mat-radio-button value="1">{{ 'Common.Company' | translate }}</mat-radio-button>
          </mat-radio-group>
          <div *ngIf="type == '2'">
            <div class="row">
              <div class="col-sm-12 display-grid">
                <mat-form-field class="example-full-width">
                  <mat-label>{{ 'Common.LastName' | translate }}</mat-label>
                  <input matInput formControlName="lastName" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 display-grid">
                <mat-form-field class="example-full-width">
                  <mat-label>{{ 'Common.FirstName' | translate }}</mat-label>
                  <input matInput formControlName="firstName" />
                </mat-form-field>
              </div>
            </div>

            <!-- 
            <div class="row">
              <div class="col-sm-12 display-grid">
                <mat-form-field class="example-full-width">
                  <mat-label>{{ 'Common.Pesel' | translate }}</mat-label>
                  <input matInput formControlName="pesel" />
                </mat-form-field>
              </div>
            </div> -->
          </div>
          <div *ngIf="type == '1'">
            <div class="row">
              <div class="col-sm-12 display-grid">
                <mat-form-field class="example-full-width">
                  <mat-label>{{ 'Common.Name' | translate }}</mat-label>
                  <input matInput formControlName="name" />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 display-grid">
                <mat-form-field class="example-full-width">
                  <mat-label>{{ 'Common.NIP' | translate }}</mat-label>
                  <input matInput formControlName="nip" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.PhoneNumber' | translate }}</mat-label>
                <input matInput formControlName="phoneNumber">
              </mat-form-field>
            </div>
          </div> -->

          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.Email' | translate }}</mat-label>
                <input matInput formControlName="email" />
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-accordion>
          <mat-expansion-panel [expanded]="true" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'Common.PhoneNumber' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-sm-12 display-grid">
                <app-phones-manager
                  inputLabel="{{ 'Common.AddPhoneNumber' | translate }}"
                  formControlName="phoneNumber"
                ></app-phones-manager>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-expansion-panel formGroupName="inhabitantAddress" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'Common.InhabitantAddress' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.Country' | translate }}</mat-label>
                <input matInput formControlName="country" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.City' | translate }}</mat-label>
                <input matInput formControlName="city" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.Street' | translate }}</mat-label>
                <input matInput formControlName="street" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.PostCode' | translate }}</mat-label>
                <input matInput formControlName="postCode" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.HouseNo' | translate }}</mat-label>
                <input matInput formControlName="houseNumber" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.ApartmentNo' | translate }}</mat-label>
                <input matInput formControlName="apartmentNumber" />
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel formGroupName="correspondenceAddress" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'Common.CorrespondenceAddress' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.Country' | translate }}</mat-label>
                <input matInput formControlName="country" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.City' | translate }}</mat-label>
                <input matInput formControlName="city" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.Street' | translate }}</mat-label>
                <input matInput formControlName="street" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.PostCode' | translate }}</mat-label>
                <input matInput formControlName="postCode" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.HouseNo' | translate }}</mat-label>
                <input matInput formControlName="houseNumber" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.ApartmentNo' | translate }}</mat-label>
                <input matInput formControlName="apartmentNumber" />
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-card-actions>
        <div class="d-flex width-100p ml-20">
          <button type="submit" color="primary" mat-raised-button (click)="confirm()">
            {{ 'Common.Save' | translate }}
          </button>
          <button color="warn" mat-raised-button (click)="cancel()">
            {{ 'Common.Cancel' | translate }}
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card-content>
</div>
