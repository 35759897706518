import { Action, createReducer, on } from '@ngrx/store';
import { loadDepartmentsForSelect, loadDepartmentsForSelectSuccess, loadDepartmentsForSelectFailure, loadPageableDepartments, loadPageableDepartmentsSuccess, loadPageableDepartmentsFailure, getDepartment, getDepartmentSuccess, getDepartmentFailure, setPageableDepartmentsFilters, setPageableDepartmentsFiltersSuccess, addDepartmentSuccess } from './departments.actions';
import { SelectModel } from '../../shared/models/select-model';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { DepartmentDto } from '../../shared/models/dictionaries/departmentDTO';
import { GetPageableDepartmentsQuery } from '../../shared/models/queries/get-pageable-departments.query';

export const departmentsFeatureKey = 'departments';

export interface State {
  departmentsForSelect?: SelectModel<number>[];
  pageableDepartments?: DictionaryDto<string,PageableDto<DepartmentDto>>;
  department?: DepartmentDto;
  departmentsFilters?: DictionaryDto<string, GetPageableDepartmentsQuery>;
  departmentAdded?: { id: number; name: string };
}

export const initialState: State = {
  departmentsFilters: new DictionaryDto<string, GetPageableDepartmentsQuery>(),
};

const usersReducer = createReducer(
  initialState,
  
  on(loadDepartmentsForSelect, (state) => setDepartmentsForSelect(state, null)),
  on(loadDepartmentsForSelectSuccess, (state, { data }) => setDepartmentsForSelect(state, data)),
  on(loadDepartmentsForSelectFailure, (state, action) => state),

  on(loadPageableDepartments, (state, {key}) => setPageableDepartments(state, null, key)),
  on(loadPageableDepartmentsSuccess, (state, { data, key }) => setPageableDepartments(state, data, key)),
  on(loadPageableDepartmentsFailure, (state, action) => state),

  on(getDepartment, (state) => setDepartment(state, null)),
  on(getDepartmentSuccess, (state, { data}) => setDepartment(state, data)),
  on(getDepartmentFailure, (state, action) => state),

  on(setPageableDepartmentsFilters, (state, { data, key }) => setPageableDepartmentsFiltersFunc(state, null, key)),
  on(setPageableDepartmentsFiltersSuccess, (state, { data, key }) => setPageableDepartmentsFiltersFunc(state, data, key)),

  on(addDepartmentSuccess, (state, data) => setAddDepartment(state, data))
);

function setDepartmentsForSelect(state: State, data: SelectModel<number>[]) {
  const departmentsForSelect = data;
  return {
    ...state,
    departmentsForSelect,
  };
}

function setAddDepartment(state: State, departmentAdded) {
  return {
    ...state,
    departmentAdded,
  };
}

function setPageableDepartments(state: State, data: PageableDto<DepartmentDto>, key : string) {
  const pageableDepartments = Array.isArray(state.pageableDepartments)
    ? state.pageableDepartments
    : new DictionaryDto<string, PageableDto<DepartmentDto>>();
  const orders = pageableDepartments.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableDepartments.push({ key, value: data });
  }

  return {
    ...state,
    pageableDepartments,
  };
}

function setDepartment(state: State, data: DepartmentDto) {
  var department = data;
  return {
    ...state,
    department,
  };
}

function setPageableDepartmentsFiltersFunc(state: State, data: GetPageableDepartmentsQuery, key: string) {
  const departmentsFilters = Array.isArray(state.departmentsFilters)
    ? state.departmentsFilters
    : new DictionaryDto<string, GetPageableDepartmentsQuery>();
  const filter = departmentsFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    departmentsFilters.push({ key, value: data });
  }

  return {
    ...state,
    departmentsFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
