import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { DictionaryModel } from '../../../models/dictionary-model';
import { OrderDto } from '../../../models/order/order.dto';
import { ClientForm } from '../client/client.form';
import { ContractorForm } from '../contractor/contractor.form';
import { OperationNotesForm } from './operation-notes.form';
import { OperationSchedulerForm } from './operation-scheduler.form';
import { CompanyValidatorsApiCallerService } from '../../../api-services/company-validators-api-caller.service';

export class OperationForm extends UntypedFormGroup {
  constructor(companyValidatorsApiCaller: CompanyValidatorsApiCallerService) {
    super({
      id: new UntypedFormControl(Guid.create(), [Validators.required]),
      orderStatusId: new UntypedFormControl(null),
      type: new UntypedFormControl(null, [Validators.required]),
      client: new ClientForm(companyValidatorsApiCaller),
      contractor: new ContractorForm(companyValidatorsApiCaller),
      notes: new OperationNotesForm(),
      schedule: new OperationSchedulerForm(),
      attachments: new UntypedFormControl(null),
      parentId: new UntypedFormControl(),
    });
  }

  public forAddOperation() {
    (this.get('client') as ClientForm).forAddOperation();
    (this.get('contractor') as ContractorForm).forAddOperation();
    (this.get('schedule') as OperationSchedulerForm).forAddOperation();

    return this;
  }

  public forEditOperation() {
    (this.get('client') as ClientForm).forAddOperation();
    (this.get('contractor') as ContractorForm).forAddOperation();
    (this.get('schedule') as OperationSchedulerForm).forEditOperation();

    return this;
  }

  patchFormOrderDto(order: OrderDto) {
    this.patchValue({
      ...order,
      type: order.orderTypeId,
      client: {
        id: order.clientId,
        name: order.clientName,
        contract: [order.contractId],
        businessObjects: order.businessObjects.map((_) => _.id),
      },
      contractor: { id: order.contractorId, name: order.contractorName } as DictionaryModel<string>,
    });
  }
}
