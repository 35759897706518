import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectModel } from '../../models/select-model';
import { FormFactory } from '../forms/form-factory';
import { AddressDto } from '../../models/dictionaries/addressDTO';
import { Observable, of } from 'rxjs';
import { StoreState } from 'project/src/app/root-store/store-state';
import { Store, select } from '@ngrx/store';
import { loadDepartmentsForSelect } from 'project/src/app/root-store/departments/departments.actions';
import { selectDepartmentsForSelect } from 'project/src/app/root-store/departments/departments.selector';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EmployeeDto } from '../../models/employees/employeeDTO';

@UntilDestroy()
@Component({
  selector: 'app-add-employee-dialog',
  templateUrl: './add-employee-dialog.component.html',
  styleUrls: ['./add-employee-dialog.component.scss'],
})
export class AddEmployeeDialogComponent implements OnInit {
  useTranslation: boolean;
  name: string;

  form: UntypedFormGroup;

  departments: SelectModel<number>[];
  filteredDepartments: Observable<SelectModel<number>[]>;

  constructor(
    private dialogRef: MatDialogRef<AddEmployeeDialogComponent>,
    formFactory: FormFactory,
    private store$: Store<StoreState>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; useTranslation?: boolean }
  ) {
    this.name = data.name;
    this.useTranslation = data.useTranslation === true;
    this.form = formFactory.getEmployeeForm().forAdd();
  }

  ngOnInit() {
    this.loadDepartments();
  }

  filterDepartments: (search: string) => void = (search: string) => {
    if (search == null || search.trim() === '') {
      this.filteredDepartments = of(this.departments);
      return;
    }
    const filterValue = search.toLowerCase();
    this.filteredDepartments = of(
      this.departments.filter((option) => option.label.toLowerCase().includes(filterValue))
    );
  };

  protected loadDepartments() {
    this.store$.dispatch(loadDepartmentsForSelect());
    this.store$.pipe(select(selectDepartmentsForSelect), untilDestroyed(this)).subscribe((data) => {
      this.departments = data;
      this.filterDepartments('');
    });
  }

  save() {
    if (!this.form.valid) {
      return;
    }
    var formValues = this.form.getRawValue();
    var model = new EmployeeDto(formValues);
    var result = model;
    this.dialogRef.close(result);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
