import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State } from './authors.reducer';

const selectAuthorsFeature = (state: StoreState) => state.authors;

export const selectAuthorsForSelect = createSelector(selectAuthorsFeature, (state: State, props: { key: string }) => {
  const result = state.authorsForSelect;
  return result ? result : null;
});

export const selectPageableAuthors = createSelector(selectAuthorsFeature, (state: State, props: { key: string }) => {
  const result = state.pageableAuthors ? state.pageableAuthors.find((_) => _.key === props.key) : null;
  return result ? result.value : null;
});

export const selectAuthor = createSelector(selectAuthorsFeature, (state: State) => {
  const result = state.author;
  return result ? result : null;
});

export const selectAllPageableAuthorsFilters = createSelector(
  selectAuthorsFeature,
  (state: State) => state.authorsFilters
);
export const selectPageableAuthorsFilters = createSelector(
  selectAuthorsFeature,
  (state: State, props: { key: string }) => {
    const result = state.authorsFilters ? state.authorsFilters.find((_) => _.key === props.key) : null;
    return result ? result.value : null;
  }
);

export const selectAddedAuthor = createSelector(selectAuthorsFeature, (state: State) => state.authorAdded);
