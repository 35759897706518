import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '../../../validators/custom.validator';
import { CustomerExistValidator } from '../../../validators/customer-phone-number-exist-validator';
import { UniqueNipValidator } from '../../../validators/unique-nip.validator';
import { ValidatorPatterns } from '../../../validators/validator-patterns';

export class CustomerForm extends UntypedFormGroup {
  constructor(private uniqueNipValidator: UniqueNipValidator, private customerExistValidator: CustomerExistValidator) {
    
    super({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      lastName: new UntypedFormControl(null, [
        Validators.pattern(ValidatorPatterns.lastname)
      ]),
      firstName: new UntypedFormControl(null, [
        Validators.pattern(ValidatorPatterns.lastname)
      ]),
      companyFullName: new UntypedFormControl(null),
      companyShortName: new UntypedFormControl(null),
      phoneNumber: new UntypedFormControl(null, [Validators.required,Validators.minLength(9),Validators.maxLength(9),Validators.pattern(ValidatorPatterns.phone)]),
      address: new UntypedFormControl(null),
      city: new UntypedFormControl(null, [Validators.pattern(ValidatorPatterns.onlyLetters)]),
      postalCode: new UntypedFormControl(null, [Validators.pattern(ValidatorPatterns.postalCode)]),
      nip: new UntypedFormControl(
        null,
        [Validators.pattern(ValidatorPatterns.nip)],
        uniqueNipValidator.createValidator()
      ),
      isBlackListed: new UntypedFormControl(false),
      email: new UntypedFormControl(null, [Validators.pattern(ValidatorPatterns.email)]),
      // purchaserContactEmail: [null, [Validators.required, Validators.pattern(ValidatorPatterns.email)]],
      isCompany: new UntypedFormControl(true),
      isIndividual: new UntypedFormControl(false),
      merchantLimit: new UntypedFormControl(null, [Validators.pattern(ValidatorPatterns.merchantLimit)]),
      customerType: new UntypedFormControl('Firma'),
      bricklayer: new UntypedFormControl(false),
      uniqueAbbreviation: new UntypedFormControl(null, [
        Validators.minLength(2),
        Validators.maxLength(55),
      ]),
      pesel: new UntypedFormControl(null),
    });
  }

  // Validators.pattern('^[a-zA-Z0-9_\\.+-]+$'),
  forAdd() {
    this.controls["phoneNumber"].setAsyncValidators(this.customerExistValidator.createValidator());
    return this;
  }

  forEdit() {
    return this;
  }
}
