import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { CompanyApiCallerService } from '../../shared/api-services/company-api-caller.service';
import { CompanyType } from '../../shared/models/enums/company-type.enum';
import { contractorWasSaved } from '../../shared/services/snack-bar-messaged';
import { SnackBarWrapperService } from '../../shared/services/snack-bar-wrapper.service';
import { StoreState } from '../store-state';
import {
  addContractor,
  addContractorFailure,
  addContractorSuccess,
  loadContractor,
  loadContractorFailure,
  loadContractorsSearchModel,
  loadContractorsSearchModelFailure,
  loadContractorsSearchModelSuccess,
  loadContractorSuccess,
  loadPageableContractors,
  loadPageableContractorsFailure,
  loadPageableContractorsSuccess,
  updateContractor,
  updateContractorFailure,
  updateContractorSuccess,
} from './contractor.actions';

@Injectable()
export class ContractorEffects {
  contractor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContractor),
      concatMap(({ id }) => {
        return this.companyApiCaller.getCompany(id).pipe(
          map((_) => loadContractorSuccess({ data: _ })),
          catchError((error) => of(loadContractorFailure({ error })))
        );
      })
    )
  );

  ContractorsSearchModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContractorsSearchModel),
      concatMap(({ data }) => {
        return this.companyApiCaller.getContractorSearchModel(data).pipe(
          map((_) => loadContractorsSearchModelSuccess({ data: _ })),
          catchError((error) => of(loadContractorsSearchModelFailure({ error })))
        );
      })
    )
  );

  addConctractor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addContractor),
      concatMap(({ data }) => {
        return this.companyApiCaller.addCompany(data.clone(CompanyType.Contractor)).pipe(
          map((_) => addContractorSuccess({ id: data.id })),
          tap((_) => this.router.navigate(['/', 'authorised', 'contractors', 'contractor', data.id])),
          catchError((error) => of(addContractorFailure({ error })))
        );
      })
    )
  );

  updateConctractor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateContractor),
      concatMap(({ data }) => {
        return this.companyApiCaller.updateCompany(data.clone(CompanyType.Contractor)).pipe(
          tap((_) => this.store$.dispatch(loadContractor({ id: data.id }))),
          map((_) => updateContractorSuccess({ id: data.id })),
          tap((_) => this.matSnackBar.openMessage(contractorWasSaved, 'success')),
          catchError((error) => of(updateContractorFailure({ error })))
        );
      })
    )
  );

  pageableContractors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableContractors),
      concatMap(({ filter }) => {
        return this.companyApiCaller.getPageableCompanies(filter).pipe(
          map((_) => loadPageableContractorsSuccess({ data: _ })),
          catchError((error) => of(loadPageableContractorsFailure({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private companyApiCaller: CompanyApiCallerService,
    private router: Router,
    private store$: Store<StoreState>,
    private matSnackBar: SnackBarWrapperService
  ) {}
}
