import { createSelector } from '@ngrx/store';
import { StoreState } from '../store-state';
import { State } from './ingredients.reducer';

const selectIngredientFeature = (state: StoreState) => state.ingredients;

export const selectPageableIngredients = createSelector(selectIngredientFeature, (state: State, props: { key: string }) => {
    const result = state.pageableIngredients ? state.pageableIngredients.find((_) => _.key === props.key) : null;
  
    return result ? result.value : null;
  });
  
  export const selectIngredient = createSelector(selectIngredientFeature, (state: State) => {
    const result = state.Ingredient;
    return result ? result : null;
  });
  
  export const selectAllPageableIngredientsFilters = createSelector(selectIngredientFeature, (state: State) => state.IngredientsFilters);
  export const selectPageableIngredientsFilters = createSelector(selectIngredientFeature, (state: State, props: { key: string }) => {
    const result = state.IngredientsFilters ? state.IngredientsFilters.find((_) => _.key === props.key) : null;
  
    return result ? result.value : null;
  });