
import { createAction, props } from '@ngrx/store';
import { AggregateDto } from '../../shared/models/aggregate/update-aggregate-dto';

export const loadAggregates = createAction('[Aggregate] Load Aggregates', props<{ recipeId: string }>());

export const loadAggregatesSuccess = createAction(
  '[Aggregate] Load Aggregates Success',
  props<{ data: AggregateDto[] }>()
);

export const loadAggregatesFailure = createAction('[Aggregate] Load Aggregates Failure', props<{ error: any }>());

export const addAggregate = createAction('[Aggregate] Add Aggregate', props<{ data: AggregateDto }>());

export const addAggregateSuccess = createAction('[Aggregate] Add Aggregate Success', props<{ id: string }>());

export const addAggregateFailure = createAction('[Aggregate] Add Aggregate Failure', props<{ error: any }>());

export const updateAggregate = createAction('[Aggregate] Update Aggregate', props<{ data: AggregateDto }>());

export const updateAggregateSuccess = createAction('[Aggregate] Update Aggregate Success', props<{ id: string }>());

export const updateAggregateFailure = createAction('[Aggregate] Update Aggregate Failure', props<{ error: any }>());

export const deleteAggregate = createAction('[Aggregate] Delete Aggregate', props<{ id: string }>());

export const deleteAggregateSuccess = createAction('[Aggregate] Delete Aggregate Success', props<{ id: string }>());

export const deleteAggregateFailure = createAction('[Aggregate] Delete Aggregate Failure', props<{ error: any }>());
