import { createAction, props } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableOtherDocumentsQuery } from '../../shared/models/queries/get-pageable-other-documents.query';
import { OtherDocumentDto } from '../../shared/models/other-documents/otherDocumentDTO';

export const addOtherDocument = createAction('[OtherDocuments] Add OtherDocument', props<{ data: OtherDocumentDto }>());
export const addOtherDocumentSuccess = createAction('[OtherDocuments] Add OtherDocument Success');
export const addOtherDocumentFailure = createAction('[OtherDocuments] Add OtherDocument Failure', props<{ error: any }>());

export const updateOtherDocument = createAction('[OtherDocuments] Add OtherDocuments', props<{ data: OtherDocumentDto }>());
export const updateOtherDocumentSuccess = createAction('[OtherDocuments] Add OtherDocuments Success');
export const updateOtherDocumentFailure = createAction('[OtherDocuments] Add OtherDocuments Failure', props<{ error: any }>());

export const deleteOtherDocument = createAction('[OtherDocuments] delete OtherDocument', props<{ id: number }>());
export const deleteOtherDocumentSuccess = createAction('[OtherDocuments] delete OtherDocument Success', props<{ id: number }>());
export const deleteOtherDocumentFailure = createAction('[OtherDocuments] delete OtherDocument Failure', props<{ error: any }>());

export const getOtherDocument = createAction('[OtherDocuments] get OtherDocument', props<{ id: number }>());
export const getOtherDocumentSuccess = createAction('[OtherDocuments] get OtherDocument Success', props<{ data: OtherDocumentDto }>());
export const getOtherDocumentFailure = createAction('[OtherDocuments] get OtherDocument Failure', props<{ error: any }>());

export const loadPageableOtherDocuments = createAction('[OtherDocuments] Load Pageable OtherDocuments', props<{ key: string }>());
export const loadPageableOtherDocumentsSuccess = createAction('[OtherDocuments] Load Pageable OtherDocuments Success', props<{ data: PageableDto<OtherDocumentDto>; key: string }>());
export const loadPageableOtherDocumentsFailure = createAction('[OtherDocuments] Load Pageable OtherDocuments Failure', props<{ error: any }>());

export const setPageableOtherDocumentsFilters = createAction('[OtherDocuments] Set Pageable OtherDocuments Filters', props<{ data?: GetPageableOtherDocumentsQuery; key: string }>());
export const setPageableOtherDocumentsFiltersSuccess = createAction('[OtherDocuments] Set Pageable OtherDocuments Filters Success', props<{ data: GetPageableOtherDocumentsQuery; key: string }>());
export const setPageableOtherDocumentsFiltersFailure = createAction('[OtherDocuments] Set Pageable OtherDocuments Filters Failure', props<{ error: any }>());
