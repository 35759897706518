import { createAction, props } from '@ngrx/store';
import { Guid } from 'guid-typescript';

export const storeUserData = createAction('[UserData] Store UserData', props<{ data: any }>());

export const storeUserDataSuccess = createAction('[UserData] Store UserData Success', props<{ data: any }>());

export const storeUserDataFailure = createAction('[UserData] Store UserData Failure', props<{ error: any }>());

export const loadAllClaims = createAction('[UserData] Load All Claims');

export const loadAllClaimsSuccess = createAction('[UserData] Load All Claims Success', props<{ data: any }>());

export const loadAllClaimsFailure = createAction('[UserData] Load All Claims Failure', props<{ error: any }>());

