//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadStatusesForSelect,
  loadStatusesForSelectSuccess,
  loadStatusesForSelectFailure,
  loadPageableStatusesSuccess,
  loadPageableStatusesFailure,
  loadPageableStatuses,
  deleteStatus,
  deleteStatusSuccess,
  deleteStatusFailure,
  addStatus,
  addStatusSuccess,
  addStatusFailure,
  getStatus,
  getStatusSuccess,
  getStatusFailure,
  updateStatus,
  updateStatusFailure,
  updateStatusSuccess,
} from './statuses.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { StatusesApiCallerService } from '../../shared/api-services/statuses-api-caller.service';

@Injectable()
export class StatusesEffects {

  addStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addStatus),
      concatMap(({ data }) => {
        return this.statusesApiCaller.addStatus(data).pipe(
          map((_) => addStatusSuccess()),
          tap(() => this.reloadStatuses()),
          catchError((error) => of(addStatusFailure({ error })))
        );
      })
    )
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStatus),
      concatMap(({ data }) => {
        return this.statusesApiCaller.updateStatus(data).pipe(
          map((_) => updateStatusSuccess()),
          catchError((error) => of(updateStatusFailure({ error })))
        );
      })
    )
  );

  loadStatusesForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStatusesForSelect),
      concatMap(() => {
        return this.statusesApiCaller.getStatuses().pipe(
          map((_) => loadStatusesForSelectSuccess({ data: _ })),
          catchError((error) => of(loadStatusesForSelectFailure({ error })))
        );
      })
    )
  );

  loadPageableStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableStatuses),
      concatMap((data) => {
        return this.statusesApiCaller.getPageableStatuses().pipe(
          map((_) => loadPageableStatusesSuccess({ data: _, })),
          catchError((error) => of(loadPageableStatusesFailure({ error })))
        );
      })
    )
  );

  getStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStatus),
      concatMap(({ id }) => {
        return this.statusesApiCaller.getStatus(id).pipe(
          map((_) => getStatusSuccess({ data: _ })),
          catchError((error) => of(getStatusFailure({ error })))
        );
      })
    )
  );

  deleteStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteStatus),
      concatMap(({ id }) => {
        return this.statusesApiCaller.deleteStatus(id).pipe(
          map((_) => deleteStatusSuccess({ id: id })),
          tap(() => this.reloadStatuses()),
          catchError((error) => of(deleteStatusFailure({ error })))
        );
      })
    )
  );

  private reloadStatuses() {
    this.store$.dispatch(loadStatusesForSelect());
    this.store$.dispatch(loadPageableStatuses());
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private statusesApiCaller: StatusesApiCallerService,
    private router: Router,
  ) { }

}
