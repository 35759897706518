import { Injectable } from '@angular/core';
import { BaseApiCaller } from '../base-api-caller';
import { HttpClient } from '@angular/common/http';
import { DictionaryModel } from '../../models/dictionary-model';

@Injectable({
  providedIn: 'root'
})
export class AdmixturesTypeApiCallerService extends BaseApiCaller {
  protected controllerPath = 'AdmixtureType';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAll() {
    return this.get<DictionaryModel<string>[]>('');
  }
}
