import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteDialogType } from './delete-dialog-type.enum';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent implements OnInit {
  name: string;
  surname: string;
  dialogType: DeleteDialogType;

  get isCorrespondence() {
    return this.dialogType != null && this.dialogType == DeleteDialogType.Correspondence;
  }

  constructor(
    private dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; surname?: string; dialogType?: DeleteDialogType }
  ) {
    this.name = data.name;
    this.surname = data.surname;

    this.dialogType = data.dialogType;
  }

  ngOnInit() {}

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
