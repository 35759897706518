import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { fileDuplicateFound } from '../../services/snack-bar-messaged';
import { SnackBarWrapperService } from '../../services/snack-bar-wrapper.service';
import { Store, select } from '@ngrx/store';
import { StoreState } from 'project/src/app/root-store/store-state';
import { loadCTypesForSelect } from 'project/src/app/root-store/c-types/c-types.actions';
import { selectCTypesForSelect } from 'project/src/app/root-store/c-types/c-types.selector';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { SelectModel } from '../../models/select-model';
import { Observable, of } from 'rxjs';
import { FileUploadDto } from '../../models/correspondence/FileUploadDto';
import { FileForm } from './file-form';

@UntilDestroy()
@Component({
  selector: 'app-custom-files-upload',
  templateUrl: './custom-files-upload.component.html',
  styleUrls: ['./custom-files-upload.component.scss'],
})
export class CustomFilesUploadComponent implements OnInit {
  @Input() form: UntypedFormArray = new UntypedFormArray([]);
  dragOverStatus: 'none' | 'drag-over' | 'drop' = 'none';

  cTypes: SelectModel<number>[];
  filteredCTypes: Observable<SelectModel<number>[]>;

  get formControls() {
    return this.form.controls as FileForm[];
  }

  constructor(private _matSnack: SnackBarWrapperService, private store$: Store<StoreState>) {}

  ngOnInit() {
    this.loadCorrespondenceType();
  }

  public log() {
    console.log(this.form);
  }

  displayName(option: SelectModel<number>): string {
    return option && option.label ? option.label : '';
  }

  protected loadCorrespondenceType() {
    this.store$.dispatch(loadCTypesForSelect());
    this.store$.pipe(select(selectCTypesForSelect), untilDestroyed(this)).subscribe((data) => {
      this.cTypes = data;
      this.filterCTypes('');
    });
  }

  filterCTypes: (search: string) => void = (search: string) => {
    if (search == null || search.trim() === '') {
      this.filteredCTypes = of(this.cTypes);
      return;
    }
    const filterValue = search.toLowerCase();
    this.filteredCTypes = of(this.cTypes.filter((option) => option.label.toLowerCase().includes(filterValue)));
  };

  filterEventHandler(event: any) {
    var inputValue = event.originalTarget.value;
    this.filterCTypes(inputValue);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.addFiles(event.dataTransfer.files);

    this.dragOverStatus = 'drop';
  }

  addFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);

      if (
        this.form.value.find(
          (_) => _.file.lastModified === file.lastModified && _.file.name === file.name && _.file.size === file.size
        ) == null
      ) {
        const control = new FileForm();
        control.patchValue({ file, type: null, typeName: null });
        this.form.push(control);
      } else {
        this._matSnack.openMessage(fileDuplicateFound, 'error');
      }
    }

    this.filterCTypes('');
  }

  onTypeChange(item: FileUploadDto, value: SelectModel<number>) {}

  removeFile(index: number) {
    this.form.removeAt(index);
  }

  onDragOver(event) {
    this.dragOverStatus = 'drag-over';
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave(event) {
    this.dragOverStatus = 'none';
  }
}
