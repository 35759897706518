import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiCaller } from './base-api-caller';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { SelectModel } from '../models/select-model';

@Injectable({
  providedIn: 'root',
})
export class CTypesApiCallerService extends BaseApiCaller {
  protected controllerPath = 'documentTypes';

  cTypes: SelectModel<number>[] = [
    { id: 1, label: "Type 1" },
    { id: 2, label: "Type 2" },
    { id: 3, label: "Type 3" }
  ];

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addCType(message: SelectModel<number>) {
    this.cTypes.push(message);
    return of("ok");
  }

  public updateCType(message: SelectModel<number>): Observable<string>{
    this.deleteCType(message.id);
    this.addCType(message);
    return of("ok");
  }

  public deleteCType(id: number) {
    var filtered = this.cTypes.filter(function(value, index, arr){
      return value.id != id;
    });
    this.cTypes = filtered;
    return of("ok");
  }

  public getCType(id: number): Observable<SelectModel<number>>{
    var filtered = this.cTypes.filter(function(value, index, arr){
      return value.id == id;
    });
    return of(filtered[0]);
  }

  public getPageableCTypes() {
    var result = new PageableDto<SelectModel<number>>();
    result.total = this.cTypes.length;
    result.result = this.cTypes;
    return of(result);
  }

  public getCTypesForSelect(): Observable<SelectModel<number>[]> {
    return this.get<SelectModel<number>[]>('getForSelect');
  }
}
