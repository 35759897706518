<mat-list>
  <ng-container *ngFor="let item of formControls; let i = index">
    <mat-list-item>
      <span class="filename">{{ item?.value.file?.name }}</span>
      <div class="file-actions">
        <div style="display: inline-block">
          <app-autocomplete
            [label]="'IncomingMessages.CorrespondenceType' | translate"
            [searchLabel]="'IncomingMessages.CorrespondenceType' | translate"
            [placeholder]="'Order.EnterClient' | translate"
            [form]="item"
            [data]="filteredCTypes | async"
            [idField]="'type'"
            [labelField]="'typeName'"
            [filterFunc]="filterCTypes"
          >
          </app-autocomplete>
        </div>
        <button
          style="margin-left: 20px !important"
          class="action-button"
          mat-raised-button
          color="warn"
          (click)="removeFile(i)"
        >
          <mat-icon>delete</mat-icon>{{ 'Common.Delete' | translate }}
        </button>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <br />
  </ng-container>
</mat-list>

<div class="d-flex p-40" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
  <div class="relative mb-6">
    <button
      type="button"
      class="action-button relative-bottom"
      mat-raised-button
      color="primary"
      (click)="fileInput.click()"
    >
      <span>{{ 'Common.AddDocument' | translate }}</span>
      <input
        #fileInput
        type="file"
        multiple
        (change)="addFiles(fileInput.files); fileInput.value = null"
        style="display: none"
      />
    </button>
  </div>
</div>
