export class ValidatorPatterns {
  static email = '^[a-zA-Z0-9_\\.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+$';
  static phone = '^[\\d\\s\\+]+$';
  static postalCode = '^[0-9]{2}(-)[0-9]{3}$';
  static positiveNumber = '^\\d{1,15}(\\.\\d{1,2})?$';
  static positiveIntegerNumber = '^\\d+$';
  static firstname = '^[A-Z][a-zA-ZżŻźŹąĄĘęŃńóÓłŁćĆśŚs\\040]+$';
  static lastname = '^[a-zA-ZżŻźŹąĄĘęŃńóÓłŁćĆśŚs\\040-]+$';
  static onlyLetters = '^[a-zA-ZżŻźŹąĄĘęŃńóÓłŁćĆśŚ\\s-.]+$';
  static onlyLettersAndDigits = '^[0-9a-zA-ZżŻźŹąĄĘęŃńóÓłŁćĆśŚ.\\s]+$';
  static legalEntityName = '^[-0-9a-zA-ZżŻźŹąĄĘęŃńóÓłŁćĆśŚ&!#+%.\\s]+$';
  static contractNamePattern = new RegExp(`^[-0-9a-zA-ZżŻźŹąĄĘęŃńóÓłŁćĆśŚ.\\s\\/]+$`);
  static tenDots = '^[0-9]*$';
  static address = new RegExp(`^[0-9a-zA-ZżŻźŹąĄĘęŃńóÓłŁćĆśŚ\\s\\/]+$`);
  static personalIdentityNumber = new RegExp('[0-9]{4}[0-3]{1}[0-9]{6}');
  static nationalCourtRegister = new RegExp('[0-9]{10}');
  static merchantLimit = '^(-)?\\d{1,15}(\\.\\d{1,2})?$';

  static nip = '\\d{10}';
}
