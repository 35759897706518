
import { createAction, props } from '@ngrx/store';
import { ConsistencyDto } from '../../shared/models/consistency/update-consistency-dto';

export const loadConsistency = createAction('[Consistency] Load Consistency', props<{ recipeId: string }>());

export const loadConsistencySuccess = createAction(
  '[Consistency] Load Consistency Success',
  props<{ data: ConsistencyDto }>()
);

export const loadConsistencyFailure = createAction('[Consistency] Load Consistency Failure', props<{ error: any }>());

export const addConsistency = createAction('[Consistency] Add Consistency', props<{ data: ConsistencyDto }>());

export const addConsistencySuccess = createAction('[Consistency] Add Consistency Success', props<{ id: string }>());

export const addConsistencyFailure = createAction('[Consistency] Add Consistency Failure', props<{ error: any }>());

export const updateConsistency = createAction('[Consistency] Update Consistency', props<{ data: ConsistencyDto }>());

export const updateConsistencySuccess = createAction(
  '[Consistency] Update Consistency Success',
  props<{ id: string }>()
);

export const updateConsistencyFailure = createAction(
  '[Consistency] Update Consistency Failure',
  props<{ error: any }>()
);
