import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
// vendor dependencies
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// environment
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthorisedLayoutComponent } from './areas/authorised-layout/authorised-layout.component';
import { GuestLayoutComponent } from './areas/guest-layout/guest-layout.component';
// app
import { Config } from './common/index';
import { FooterComponent } from './footer/footer.component';
// Components
import { MainMenuComponent } from './main-menu/main-menu.component';
import { RootStoreModule } from './root-store/root-store.module';
import { JwtInterceptor } from './shared/http-interceptors/jwt.interceptor';
import { SharedModule } from './shared/shared.module';
import { LoadingComponent } from './shared/spinner.component';
import { HeaderComponent } from './areas/header/header.component';
import { ErrorInterceptor } from './shared/http-interceptors/error.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlPlService } from './shared/services/mat-paginator-intl-pl.service';
import { LayoutModule } from '@angular/cdk/layout';
import { NotificationComponent } from './areas/notification/notification.component';
import { OidcConfigService, AuthModule } from 'angular-auth-oidc-client';
import { UnauthorizedComponent } from './areas/unauthorized/unauthorized.component';
import { AutoLoginComponent } from './areas/auto-login/auto-login.component';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CommentsComponent } from './comments/comments.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChangeStatusComponent } from './change-status/change-status.component';
import { SignalrService } from './shared/services/signalr.service';
import { OfflineInterceptor } from './pwa/service/offline.interceptor';
import { OfflineService } from './pwa/service/offline.service';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

Config.PLATFORM_TARGET = Config.PLATFORMS.WEB;
registerLocaleData(localePl, 'pl');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http as any, './assets/i18n/', '.json');
}
export function getBaseUrl() {
  return environment.apiRoot;
}

export function loadConfig(oidcConfigService: OidcConfigService) {
  return () => oidcConfigService.withConfig(environment.identityConfig);
}

const dbConfig: DBConfig = {
  name: 'AppDb',
  version: 2,
  objectStoresMeta: [
  {
    store: 'SyncTasks',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'value', keypath: 'value', options: { unique: false } }
    ]
  },
  {
    store: 'Customers',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'value', keypath: 'value', options: { unique: false } }
    ]
  }]
};


@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    FooterComponent,
    AuthorisedLayoutComponent,
    GuestLayoutComponent,
    MainMenuComponent,
    HeaderComponent,
    NotificationComponent,
    UnauthorizedComponent,
    AutoLoginComponent,
    CommentsComponent,
    ChangeStatusComponent
  ],
  imports: [
    AppRoutingModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    OverlayModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    EffectsModule.forRoot([]),
    RootStoreModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    LayoutModule,
    AuthModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    NgxIndexedDBModule.forRoot(dbConfig)
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pl' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlPlService },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } },
    DatePipe,
    OfflineService,
    {
      provide: APP_INITIALIZER,
      useFactory: (offlineService: OfflineService) => () => offlineService.setup(),
      deps: [OfflineService],
      multi: true
    },
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [OidcConfigService],
      multi: true,
    },
    SignalrService,
    {
      provide: APP_INITIALIZER,
      useFactory: (signalrService: SignalrService) => () => signalrService.startConnection(),
      deps: [SignalrService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
