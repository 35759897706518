import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiCaller } from './base-api-caller';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { SelectModel } from '../models/select-model';

@Injectable({
  providedIn: 'root',
})
export class StatusesApiCallerService extends BaseApiCaller {
  protected controllerPath = 'users';

  statuses: SelectModel<number>[] = [
    { id: 1, label: "Nowy" },
    { id: 2, label: "W przetwarzaniu" },
  ];

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addStatus(message: SelectModel<number>) {
    this.statuses.push(message);
    return of("ok");

  }

  public updateStatus(message: SelectModel<number>): Observable<string>{
    this.deleteStatus(message.id);
    this.addStatus(message);
    return of("ok");
  }

  public deleteStatus(id: number) {
    var filtered = this.statuses.filter(function(value, index, arr){
      return value.id != id;
    });
    this.statuses = filtered;
    return of("ok");
  }

  public getStatus(id: number): Observable<SelectModel<number>>{
    var filtered = this.statuses.filter(function(value, index, arr){
      return value.id == id;
    });
    return of(filtered[0]);
  }

  public getPageableStatuses() {
    var result = new PageableDto<SelectModel<number>>();
    result.total = this.statuses.length;
    result.result = this.statuses;
    return of(result);
  }

  public getStatuses(): Observable<SelectModel<number>[]> {
    return of(this.statuses);
  }
}
