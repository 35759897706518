import { createAction, props } from '@ngrx/store';
import { UpdatePropertiesMixtureDto } from '../../shared/models/propertiesMixture/update-properties-mixture-dto';


export const loadMixtureProperties = createAction('[MixtureProperties] Load MixtureProperties', props<{ recipeId: string }>());
// tslint:disable-next-line:max-line-length
export const addMixtureProperties = createAction('[MixtureProperties] Add MixtureProperties', props<{ data: UpdatePropertiesMixtureDto }>());
// tslint:disable-next-line:max-line-length
export const updateMixtureProperties = createAction('[MixtureProperties] Update MixtureProperties', props<{ data: UpdatePropertiesMixtureDto }>());

// tslint:disable-next-line:max-line-length
export const loadMixturePropertiesSuccess = createAction('[MixtureProperties] Load MixtureProperties Success', props<{ data: UpdatePropertiesMixtureDto }>());
export const loadMixturePropertiesFailure = createAction('[MixtureProperties] Load MixtureProperties Failure', props<{ error: any }>());

export const addMixturePropertiesSuccess = createAction('[MixtureProperties] Add MixtureProperties Success', props<{ recipeId: string }>());
export const addMixturePropertiesFailure = createAction('[MixtureProperties] Add MixtureProperties Failure', props<{ error: any }>());

// tslint:disable-next-line:max-line-length
export const updateMixturePropertiesSuccess = createAction('[MixtureProperties] Update MixtureProperties Success', props<{ recipeId: string }>());
export const updateMixturePropertiesFailure = createAction('[MixtureProperties] Update MixtureProperties Failure', props<{ error: any }>());
