import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { loadCement, loadCementSuccess, loadCementFailure,
         addCementSuccess, addCementFailure, addCement,
         updateCement, updateCementSuccess, updateCementFailure } from './cement.actions';
import { concatMap, catchError, map } from 'rxjs/operators';

import { of } from 'rxjs';
import { CementApiCallerService } from '../../shared/api-services/cement-api-caller.service';
import { RecipeApiCallerService } from '../../shared/api-services/recipe-api-caller.service';

@Injectable()
export class CementEffects {

    cement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCement),
            concatMap(({ recipeId }) => {
                return this.recipeApiCaller.getCementForRecipe(recipeId).pipe(
                     map((_) => loadCementSuccess({ data: _ })),
                     catchError((error) => of(loadCementFailure({ error })))
                );
            })
    ));

    addCement$ = createEffect(() =>
     this.actions$.pipe(
         ofType(addCement),
         concatMap(({ data }) => {
             return this.cementApiCaller.addCement(data).pipe(
             map((_) => addCementSuccess({ recipeId: data.id })),
             catchError((error) => of(addCementFailure({ error })))
             );
         })
     ));

     updateCement$ = createEffect(() =>
     this.actions$.pipe(
         ofType(updateCement),
         concatMap(({ data }) => {
             return this.cementApiCaller.updateCement(data).pipe(
             map((_) => updateCementSuccess({ recipeId: data.id })),
             catchError((error) => of(updateCementFailure({ error })))
             );
         })
     ));

    constructor(
        private actions$: Actions,
        private cementApiCaller: CementApiCallerService,
        private recipeApiCaller: RecipeApiCallerService
    ) {}
}
