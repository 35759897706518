import { createAction, props } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableCarsQuery } from '../../shared/models/queries/get-pageable-cars.query';
import { CarDto } from '../../shared/models/cars/carDTO';
import { Guid } from 'guid-typescript';
import { SelectModel } from '../../shared/models/select-model';
import { GetCarsQuery } from '../../shared/models/cars/get-cars.query';
import { CarSelectModel } from '../../shared/models/carSelectModel';


export const addCar = createAction('[Cars] Add Car', props<{ data: CarDto }>());
export const addCarSuccess = createAction('[Cars] Add Car Success', props<{ data: any }>());
export const addCarFailure = createAction('[Cars] Add Car Failure', props<{ error: any }>());

export const updateCar = createAction('[Cars] Update Car', props<{ data: CarDto }>());
export const updateCarSuccess = createAction('[Cars] Update Car Success');
export const updateCarFailure = createAction('[Cars] Update Car Failure', props<{ error: any }>());

export const deleteCar = createAction('[Cars] delete Car', props<{ id: string }>());
export const deleteCarSuccess = createAction('[Cars] delete Car Success', props<{ id: string }>());
export const deleteCarFailure = createAction('[Cars] delete Car Failure', props<{ error: any }>());

export const getCar = createAction('[Cars] get Car', props<{ id: string }>());
export const getCarSuccess = createAction('[Cars] get Car Success', props<{ data: CarDto }>());
export const getCarFailure = createAction('[Cars] get Car Failure', props<{ error: any }>());

export const loadPageableCars = createAction('[Cars] Load Pageable Cars', props<{ key: string }>());
export const loadPageableCarsSuccess = createAction('[Cars] Load Pageable Cars Success', props<{ data: PageableDto<CarDto>; key: string }>());
export const loadPageableCarsFailure = createAction('[Cars] Load Pageable Cars Failure', props<{ error: any }>());

export const setPageableCarsFilters = createAction('[Cars] Set Pageable Cars Filters', props<{ data?: GetPageableCarsQuery; key: string }>());
export const setPageableCarsFiltersSuccess = createAction('[Cars] Set Pageable Cars Filters Success', props<{ data: GetPageableCarsQuery; key: string }>());
export const setPageableCarsFiltersFailure = createAction('[Cars] Set Pageable Cars Filters Failure', props<{ error: any }>());

export const loadCarTypesForSelect = createAction('[Cars] Load CarTypes For Select');
export const loadCarTypesForSelectSuccess = createAction('[Cars] Load CarTypes For Select Success', props<{ data: SelectModel<string>[] }>());
export const loadCarTypesForSelectFailure = createAction('[Cars] Load CarTypes For Select Failure', props<{ error: any }>());

export const loadCars = createAction('[Cars] Load Cars');
export const loadCarsSuccess = createAction('[Cars] Load Cars Success', props<{ data: CarDto[] }>());
export const loadCarsFailure = createAction('[Cars] Load Cars Failure', props<{ error: any }>());

export const loadCarTypesPump = createAction('[Cars] Load CarTypesPump For Select', props<{ typeIds: Array<string> }>());
export const loadCarTypesPumpSuccess = createAction('[Cars] Load CarTypes For Select Success', props<{ data: CarSelectModel<string>[] }>());
export const loadCarTypesPumpFailure = createAction('[Cars] Load CarTypes For Select Failure', props<{ error: any }>());
export const loadFilteredCars = createAction('[Cars] Load Cars', props<{data: GetCarsQuery}>());
export const loadFilteredCarsSuccess = createAction('[Cars] Load Cars Success', props<{ data: CarDto[] }>());
export const loadFilteredCarsFailure = createAction('[Cars] Load Cars Failure', props<{ error: any }>());

export const loadCarTypesMixer = createAction('[Cars] Load CarTypesMixer For Select', props<{ typeId: string }>());
export const loadCarTypesMixerSuccess = createAction('[Cars] Load CarTypesMixer For Select Success', props<{ data: CarSelectModel<string>[] }>());
export const loadCarTypesMixerFailure = createAction('[Cars] Load CarTypesMixer For Select Failure', props<{ error: any }>());


