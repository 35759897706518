import { GetPageableCustomersQuery } from './../../shared/models/queries/get-pageable-customers-query';
import { CustomerDto } from './../../shared/models/customers/customer-dto';
import { Action, createReducer, on } from '@ngrx/store';
import { CustomerModel } from '../../shared/models/calendar/customer.model';

import {
  addCustomer,
  addCustomerFailure,
  addCustomerSuccess,
  getCustomer,
  getCustomerDataByNip,
  getCustomerDataByNipFailure,
  getCustomerDataByNipSuccess,
  getCustomerFailure,
  getCustomerSuccess,
  loadCustomer,
  loadCustomerFailure,
  loadCustomerSuccess,
  loadPageableCustomers,
  loadPageableCustomersFailure,
  loadPageableCustomersSuccess,
  setPageableCustomersFilters,
  setPageableCustomersFiltersSuccess,
} from './customer.actions';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GusCustomerDto } from '../../shared/models/customers/gus-customer-dto';

export const customerFeatureKey = 'customer';

export interface State {
  customers?: CustomerModel[];

  customer?: CustomerDto;
  customerDataByNip?: GusCustomerDto;
  pageableCustomers?: DictionaryDto<string, PageableDto<CustomerDto>>;
  customersFilters?: DictionaryDto<string, GetPageableCustomersQuery>;
}

export const initialState: State = {};

const customerReducer = createReducer(
  initialState,

  on(addCustomer, (state) => setAddCustomer(state, null)),
  on(addCustomerSuccess, (state) => setAddCustomer(state, null)),
  on(addCustomerFailure, (state) => setAddCustomer(state, null)),

  on(loadCustomer, (state) => state),
  on(loadCustomerSuccess, (state, { data }) => setCustomers(state, data)),
  on(loadCustomerFailure, (state) => setCustomers(state, null)),

  on(loadPageableCustomers, (state, { key }) => setPageableCustomers(state, null, key)),
  on(loadPageableCustomersSuccess, (state, { data, key }) => setPageableCustomers(state, data, key)),
  on(loadPageableCustomersFailure, (state, action) => state),

  on(getCustomer, (state) => setCustomer(state, null)),
  on(getCustomerSuccess, (state, { data }) => setCustomer(state, data)),
  on(getCustomerFailure, (state, action) => state),

  on(getCustomerDataByNip, (state) => setCustomerDataByNip(state, null)),
  on(getCustomerDataByNipSuccess, (state, { data }) => setCustomerDataByNip(state, data)),
  on(getCustomerDataByNipFailure, (state, action) => state),

  on(setPageableCustomersFilters, (state, { data, key }) => setPageableCustomersFiltersFunc(state, null, key)),
  on(setPageableCustomersFiltersSuccess, (state, { data, key }) => setPageableCustomersFiltersFunc(state, data, key))
);

function setCustomers(state: State, data: CustomerModel[]) {
  return {
    ...state,
    customers: data,
  };
}

function setCustomer(state: State, data: CustomerDto) {
  var customer = data;
  return {
    ...state,
    customer,
  };
}

function setCustomerDataByNip(state: State, data: GusCustomerDto) {
  var customerDataByNip = data;
  return {
    ...state,
    customerDataByNip,
  };
}

function setAddCustomer(state: State, data: CustomerDto) {
  return {
    ...state,
    data,
  };
}

function setPageableCustomersFiltersFunc(state: State, data: GetPageableCustomersQuery, key: string) {
  const customersFilters = Array.isArray(state.customersFilters)
    ? state.customersFilters
    : new DictionaryDto<string, GetPageableCustomersQuery>();
  const filter = customersFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    customersFilters.push({ key, value: data });
  }

  return {
    ...state,
    customersFilters,
  };
}

function setPageableCustomers(state: State, data: PageableDto<CustomerDto>, key: string) {
  const pageableCustomers = Array.isArray(state.pageableCustomers)
    ? state.pageableCustomers
    : new DictionaryDto<string, PageableDto<CustomerDto>>();
  const orders = pageableCustomers.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableCustomers.push({ key, value: data });
  }

  return {
    ...state,
    pageableCustomers,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return customerReducer(state, action);
}
