import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, withLatestFrom, catchError, map, finalize } from 'rxjs/operators';
import { WZApiCallerService } from '../../shared/api-services/wzapi-caller.service';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { StoreState } from '../store-state';
import { loadPageableWZDocuments,
         loadPageableWZDocumentsFailure,
         loadPageableWZDocumentsSuccess,
         setPageableWZDocumentsFilters,
         setPageableWZDocumentsFiltersFailure,
         setPageableWZDocumentsFiltersSuccess } from './wz-documents.actions';
import { selectAllPageableWZDocumentsFilters } from './wz-documents.selectors';

@Injectable()
export class WZDocumentsEffects {

    private latestedWZDocumentsKey: string;

    loadPageableWZDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableWZDocuments),
      withLatestFrom(this.store$.select(selectAllPageableWZDocumentsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedWZDocumentsKey = key;

        return this.WZApiCaller.getPageableWZDocuments(filter.value).pipe(
          map((_) => loadPageableWZDocumentsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableWZDocumentsFailure({ error })))
        );
      })
    )
  );

  setPageableWZDocumentsFilters$ = createEffect(() =>
  this.actions$.pipe(
    ofType(setPageableWZDocumentsFilters),
    concatMap(({ data, key }) => {
      this.latestedWZDocumentsKey = key;
      return of(data).pipe(
        map((_) => setPageableWZDocumentsFiltersSuccess({ data, key })),
        finalize(() => this.reloadCars(key)),
        catchError((error) => of(setPageableWZDocumentsFiltersFailure({ error })))
      );
    })
  )
);

  private reloadCars(key: string) {
    this.store$.dispatch(loadPageableWZDocuments({ key }));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private WZApiCaller: WZApiCallerService,
  ) { }
}