import { NotificationDto } from '../../shared/models/notification.dto';
import { loadNotificationsSuccess, loadNotificationsFailure, loadNotifications } from './notification.action';
import { createReducer, Action, on } from '@ngrx/store';

export const notificationsFeatureKey = 'notifications';

export interface State {
  notifications?: NotificationDto[];
}
export const initialState: State = {};

const notificationsReducer = createReducer(
  initialState,

  on(loadNotifications, (state) => state),
  on(loadNotificationsSuccess, (state, { data }) => setNotifications(state, data)),
  on(loadNotificationsFailure, (state) => setNotifications(state, null))
);

function setNotifications(state, notifications: NotificationDto[]) {
  return { ...state, notifications };
}

export function reducer(state: State | undefined, action: Action) {
  return notificationsReducer(state, action);
}
