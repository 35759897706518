import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiCaller } from './base-api-caller';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { SelectModel } from '../models/select-model';
import { NodeDto } from '../models/dictionaries/nodeDTO';
import { GetPageableAuthorsQuery } from '../models/queries/get-pageable-authors.query';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NodesApiCallerService extends BaseApiCaller {
  protected controllerPath = 'nodes';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addNode(dto: NodeDto) {
    return this.postWithReponseSimpleType<number>('', dto)
  }

  public updateNode(dto: NodeDto){
    return this.put('', dto);
  }

  public deleteNode(id: number) {
    return this.delete(id.toString());
  }

  public getNode(id: number): Observable<NodeDto>{
    return this.get<NodeDto>(id.toString()).pipe(map((_) => new NodeDto(_)));
  }

  public getPageableNodes(query: GetPageableAuthorsQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<NodeDto>>('', { params });
  }

  public getNodesForSelect(): Observable<SelectModel<string>[]> {
    return this.get<SelectModel<string>[]>('getForSelect');
  }

  public getNodes() {
    return this.get<NodeDto[]>('');
  }
}
