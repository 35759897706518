import { createAction, props } from '@ngrx/store';
import { BaseContractDto } from '../../shared/models/contract/base-contract.dto';
import { ContractDto } from '../../shared/models/contract/contract.dto';

export const loadContract = createAction('[Contract] Load Contract', props<{ id: string }>());

export const loadContractSuccess = createAction('[Contract] Load Contract Success', props<{ data: ContractDto }>());

export const loadContractFailure = createAction('[Contract] Load Contract Failure', props<{ error: any }>());

export const loadClientContracts = createAction('[Contract] Load Client Contracts', props<{ id: string }>());

export const loadClientContractsSuccess = createAction(
  '[Contract] Load Client Contracts Success',
  props<{ data: BaseContractDto[] }>()
);

export const loadClientContractsFailure = createAction(
  '[Contract] Load Client Contracts Failure',
  props<{ error: any }>()
);
