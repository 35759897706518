//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadSourcesForSelect,
  loadSourcesForSelectSuccess,
  loadSourcesForSelectFailure,
  loadPageableSources,
  loadPageableSourcesSuccess,
  loadPageableSourcesFailure,
  deleteSourceFailure,
  deleteSourceSuccess,
  deleteSource,
  addSource,
  addSourceSuccess,
  addSourceFailure,
  getSource,
  getSourceSuccess,
  getSourceFailure,
  updateSource,
  updateSourceSuccess,
  updateSourceFailure,
} from './sources.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { SnackBarWrapperService } from '../../shared/services/snack-bar-wrapper.service';
import { DepartmentsApiCallerService } from '../../shared/api-services/departments-api-caller.service';
import { AuthorsApiCallerService } from '../../shared/api-services/authors-api-caller.service';
import { SourcesApiCallerService } from '../../shared/api-services/sources-api-caller.service';
import { StatusesApiCallerService } from '../../shared/api-services/statuses-api-caller.service';
import { ClientsApiCallerService } from '../../shared/api-services/clients-api-caller.service';
import { CTypesApiCallerService } from '../../shared/api-services/c-types-api-caller.service';

@Injectable()
export class SourcesEffects {

  addSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSource),
      concatMap(({ data }) => {
        return this.sourcesApiCaller.addSource(data).pipe(
          map((_) => addSourceSuccess()),
          tap(() => this.reloadSources()),
          catchError((error) => of(addSourceFailure({ error })))
        );
      })
    )
  );

  updateSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSource),
      concatMap(({ data }) => {
        return this.sourcesApiCaller.updateSource(data).pipe(
          map((_) => updateSourceSuccess()),
          catchError((error) => of(updateSourceFailure({ error })))
        );
      })
    )
  );

  loadSourcesForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSourcesForSelect),
      concatMap(() => {
        return this.sourcesApiCaller.getSourcesForSelect().pipe(
          map((_) => loadSourcesForSelectSuccess({ data: _ })),
          catchError((error) => of(loadSourcesForSelectFailure({ error })))
        );
      })
    )
  );

  loadPageableSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableSources),
      concatMap((data) => {
        return this.sourcesApiCaller.getPageableSources().pipe(
          map((_) => loadPageableSourcesSuccess({ data: _, })),
          catchError((error) => of(loadPageableSourcesFailure({ error })))
        );
      })
    )
  );

  getSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSource),
      concatMap(({ id }) => {
        return this.sourcesApiCaller.getSource(id).pipe(
          map((_) => getSourceSuccess({ data: _ })),
          catchError((error) => of(getSourceFailure({ error })))
        );
      })
    )
  );

  deleteSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSource),
      concatMap(({ id }) => {
        return this.sourcesApiCaller.deleteSource(id).pipe(
          map((_) => deleteSourceSuccess({ id: id })),
          tap(() => this.reloadSources()),
          catchError((error) => of(deleteSourceFailure({ error })))
        );
      })
    )
  );

  private reloadSources() {
    this.store$.dispatch(loadSourcesForSelect());
    this.store$.dispatch(loadPageableSources());
  }


  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private sourcesApiCaller: SourcesApiCallerService,
    private router: Router,
  ) { }

}
