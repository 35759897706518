import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { PipesModule } from './pipes/pipes.module';
import { WidgetsModule } from './widgets/widgets.module';
import { DirectivesModule } from './directives/directives.module';
import { HasClaimDirective } from './directives/has-claim.directive';
import { ApiService, API_BASE_URL } from './api-services/api-services';
import { environment } from 'project/src/environments/environment';

@NgModule({
    imports: [CommonModule, NgSelectModule, MaterialModule, DirectivesModule],
    declarations: [],
    exports: [
        CdkTreeModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
        WidgetsModule,
        TranslateModule,
        MaterialModule,
        PipesModule,
        HasClaimDirective,
    ],
    providers: [
        { provide: API_BASE_URL, useValue: environment.apiRoot },
        ApiService
    ]
})
export class SharedModule {}
