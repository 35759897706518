import { createAction, props } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableWZDocumentsQuery } from '../../shared/models/queries/get-pageable-wzdocuments';
import { WZDocumentModel } from '../../shared/models/wzDocuments/wzDocumentModel';

export const loadPageableWZDocuments = createAction(
  '[WZDocuments] Load Pageable WZDocuments',
  props<{ key: string }>()
);
export const loadPageableWZDocumentsSuccess = createAction(
  '[WZDocuments] Load Pageable WZDocuments Success',
  props<{ data: PageableDto<WZDocumentModel>; key: string }>()
);
export const loadPageableWZDocumentsFailure = createAction(
  '[WZDocuments] Load Pageable WZDocuments Failure',
  props<{ error: any }>()
);

export const setPageableWZDocumentsFilters = createAction(
  '[WZDocuments] Set Pageable WZDocuments Filters',
  props<{ data?: GetPageableWZDocumentsQuery; key: string }>()
);
export const setPageableWZDocumentsFiltersSuccess = createAction(
  '[WZDocuments] Set Pageable WZDocuments Filters Success',
  props<{ data: GetPageableWZDocumentsQuery; key: string }>()
);
export const setPageableWZDocumentsFiltersFailure = createAction(
  '[WZDocuments] Set Pageable WZDocuments Filters Failure',
  props<{ error: any }>()
);


