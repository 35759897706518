import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State as State } from './nodes.reducer';

const selectNodesFeature = (state: StoreState) => state.nodes;

export const selectNodesForSelect = createSelector(selectNodesFeature, (state: State, props: { key: string }) => {
  const result = state.nodesForSelect;
  return result ? result : null;
});

export const selectPageableNodes = createSelector(selectNodesFeature, (state: State, props: {key: string}) => {
  const result = state.pageableNodes ? state.pageableNodes.find((_) => _.key === props.key) : null;
  return result ? result.value : null;
});

export const selectNode = createSelector(selectNodesFeature, (state: State) => {
  const result = state.node;
  return result ? result : null;
});

export const selectAllPageableNodesFilters = createSelector(selectNodesFeature, (state: State) => state.nodesFilters);
export const selectPageableNodesFilters = createSelector(selectNodesFeature, (state: State, props: { key: string }) => {
  const result = state.nodesFilters ? state.nodesFilters.find((_) => _.key === props.key) : null;
  return result ? result.value : null;
});

export const selectAddedNode = createSelector(selectNodesFeature, (state: State) => state.nodeAdded);