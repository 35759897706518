import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { PageableQuery } from '../../models/contracts/pageable-query';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss']
})
export class MatTableComponent implements OnInit {
  @Input() total: number;
  @Input() set heightOffset(value) {
    this._heightOffset = value;
    this.onResize();
  }
  @Input() filters: PageableQuery;
  @Input() loading = true;
  @Output() reload = new EventEmitter();
  height: number;
  private _heightOffset = 350;

  constructor() {}

  ngOnInit() {
    this.onResize();
  }

  @HostListener('window:resize')
  onResize() {
    this.height = window.innerHeight - this._heightOffset;
  }

  doReload() {
    this.reload.emit();
  }

  pageChange(event: PageEvent) {
    this.filters.pageNumber = event.pageIndex;
    this.filters.pageSize = event.pageSize;
    this.doReload();
  }
}
