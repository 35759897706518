//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadSupplyStatusesForSelect,
  loadSupplyStatusesForSelectSuccess,
  loadSupplyStatusesForSelectFailure
} from './supply-statuses.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { SupplyStatusesApiCallerService } from '../../shared/api-services/supply-statuses-api-caller.service';


@Injectable()
export class SupplyStatusesEffects {

  loadSupplyStatusesForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSupplyStatusesForSelect),
      concatMap(() => {
        return this.SupplyStatusesApiCaller.getSupplyStatusesForSelect().pipe(
          map((_) => loadSupplyStatusesForSelectSuccess({ data: _ })),
          catchError((error) => of(loadSupplyStatusesForSelectFailure({ error })))
        );
      })
    )
  );

  private reloadSupplyStatuses(key: string) {
    this.store$.dispatch(loadSupplyStatusesForSelect());
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private SupplyStatusesApiCaller: SupplyStatusesApiCallerService,
    private router: Router
  ) {}
}
