import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { map } from 'rxjs/operators';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableIncomingMessagesQuery } from '../models/queries/get-pageable-incoming-messages.query';
import { CorrespondenceDto } from '../models/correspondence/correspondenceDTO';

@Injectable({
  providedIn: 'root',
})
export class IncomingMessagesApiCallerService extends BaseApiCaller {
  protected controllerPath = 'Correspondence';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addIncomingMessage(dto: CorrespondenceDto) {
    return this.post('', dto);
  }

  public updateIncomingMessage(dto: CorrespondenceDto) {
    return this.put('', dto);
  }

  public deleteIncomingMessage(id: number) {
    return this.delete(id.toString());
  }

  public getIncomingMessage(id: number) {
    return this.get<CorrespondenceDto>(id.toString()).pipe(map((_) => new CorrespondenceDto(_)));
  }

  public getPageableIncomingMessages(query: GetPageableIncomingMessagesQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<CorrespondenceDto>>('', { params });
  }

}
