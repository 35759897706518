import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';

export class DictionaryElementForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(null, [Validators.required]),
      value: new UntypedFormControl(null, [Validators.required]),
    });
  }

  forAdd() {

    return this;
  }
}
