import { Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, tap, withLatestFrom } from 'rxjs/operators';
import { OrderApiCallerService } from '../../shared/api-services/orders/order-api-caller.service';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';
import { AlertifyService } from '../../shared/services/alertify.service';
import {
  addCommentOfDay,
  addCommentOfDayFailure,
  addCommentOfDaySuccess,
  addCopyOrder,
  addCopyOrderFailure,
  addCopyOrderSuccess,
  addOrder,
  addOrderFailure,
  addOrderSuccess,
  loadAllCommentsOfDay,
  loadAllCommentsOfDayFailure,
  loadAllCommentsOfDaySuccess,
  loadAllOrderByDate,
  loadAllOrderByDateFailure,
  loadAllOrderByDateSuccess,
  loadAllOrderByHourRange,
  loadAllOrderByHourRangeFailure,
  loadAllOrderByHourRangeSuccess,
  loadCustomerOrderHistory,
  loadCustomerOrderHistoryFailure,
  loadCustomerOrderHistorySuccess,
  loadInProgrgessOrderByDate,
  loadInProgrgessOrderByDateFailure,
  loadInProgrgessOrderByDateSuccess,
  loadOrder,
  loadOrderByDate,
  loadOrderByDateFailure,
  loadOrderByDateSuccess,
  loadOrderFailure,
  loadOrders,
  loadOrdersCatalog,
  loadOrdersCatalogFailure,
  loadOrdersCatalogSuccess,
  loadOrdersFailure,
  loadOrdersSuccess,
  loadOrderSuccess,
  loadPageableOrdersCatalog,
  loadPageableOrdersCatalogFailure,
  loadPageableOrdersCatalogSuccess,
  setPageableOrdersCatalogFilters,
  setPageableOrdersCatalogFiltersFailure,
  setPageableOrdersCatalogFiltersSuccess,
  updateOrder,
  updateOrderDate,
  updateOrderDateFailure,
  updateOrderDateSuccess,
  updateOrderFailure,
  updateOrderSettlement,
  updateOrderSettlementFailure,
  updateOrderSettlementSuccess,
  updateOrderState,
  updateOrderStateFailure,
  updateOrderStateSuccess,
  updateOrderSuccess,
  setPageableOrderEditHistoryFilters,
  setPageableOrderEditHistoryFiltersSuccess,
  setPageableOrderEditHistoryFiltersFailure,
  loadPageableOrderEditHistory,
  loadPageableOrderEditHistorySuccess,
  loadPageableOrderEditHistoryFailure
} from './order.actions';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import { selectAllPageableOrderEditHistoryFilters, selectAllPageableOrdersCatalogFilters } from './order.selectors';

declare let alertify: any;

@Injectable()
export class OrdersEffects {
  @ViewChild('spinner', { static: true }) spinner;
  private latestedOrdersCatalogKey: string;
  orders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrders),
      concatMap(() => {
        return this.orderApiCaller.getOrder().pipe(
          map((_) => loadOrdersSuccess({ data: _ })),
          catchError((error) => of(loadOrdersFailure({ error })))
        );
      })
    )
  );

  addOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOrder),
      concatMap(({ data }) => {
        let lastValue;
        return this.orderApiCaller.postOrder(data).pipe(
          map((_) => addOrderSuccess({ data: _ })),
          tap((_) => {
            lastValue = _;
          }),
          finalize(() => {
            this.overlayService.closeOverlay('add-incoming-message');
            this.reloadOrdersCatalog(this.latestedOrdersCatalogKey);
          }),
          catchError((error) => of(addOrderFailure({ error })))
        );
      })
    )
  );

  addCopyOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCopyOrder),
      concatMap(({ data }) => {
        let lastValue;
        return this.orderApiCaller.addCopyOrder(data).pipe(
          map((_) => addCopyOrderSuccess({ data: _ })),
          tap((_) => {
            lastValue = _;
          }),
          finalize(() => {
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(addCopyOrderFailure({ error })))
        );
      })
    )
  );

  ordersByDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderByDate),
      concatMap((date) => {
        return this.orderApiCaller.getOrderByDate(date).pipe(
          map((_) => loadOrderByDateSuccess({ data: _ })),
          catchError((error) => of(loadOrderByDateFailure({ error })))
        );
      })
    )
  );

  getOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrder),
      concatMap(({ orderId }) => {
        return this.orderApiCaller.getOrderById(orderId).pipe(
          map((_) => loadOrderSuccess({ data: _ })),
          catchError((error) => of(loadOrderFailure({ error })))
        );
      })
    )
  );

  updateOrderDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrderDate),
      concatMap(({ data }) => {
        let lastValue;
        return this.orderApiCaller.updateOrderDate(data).pipe(
          map((_) => updateOrderDateSuccess({ data: _ })),
          tap((_) => {
            lastValue = _;
          }),
          finalize(() => {
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(updateOrderDateFailure({ error })))
        );
      })
    )
  );

  updateOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrder),
      concatMap(({ data }) => {
        let lastValue;
        return this.orderApiCaller.updateOrder(data).pipe(
          map((_) => updateOrderSuccess({ data: _ })),
          tap((_) => {
            lastValue = _;
          }),
          finalize(() => {
            this.overlayService.closeOverlay('add-incoming-message');
            this.reloadOrdersCatalog(this.latestedOrdersCatalogKey);
          }),
          catchError((error) => of(updateOrderFailure({ error })))
        );
      })
    )
  );

  updateOrderState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrderState),
      concatMap(({ data }) => {
        let lastValue;
        return this.orderApiCaller.updateOrderState(data).pipe(
          map((_) => updateOrderStateSuccess({ data: _ })),
          tap((_) => {
            lastValue = _;
          }),
          finalize(() => {
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(updateOrderStateFailure({ error })))
        );
      })
    )
  );

  updateOrderSettlement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrderSettlement),
      concatMap(({ data }) => {
        let lastValue;
        return this.orderApiCaller.updateOrderSettlement(data).pipe(
          map((_) => {
            this.alertify.success('Rozliczenie pomyślne');
            return updateOrderSettlementSuccess({ data: _ });
          }),
          tap((_) => {
            lastValue = _;
          }),
          finalize(() => {
            this.overlayService.closeOverlay('add-incoming-message');
            this.reloadOrdersCatalog(this.latestedOrdersCatalogKey);
          }),
          catchError((error) => {
            this.alertify.error('Booo!!');
            return of(updateOrderSettlementFailure({ error }))
          })
        );
      })
    )
  );

  inProggressOrdersByDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInProgrgessOrderByDate),
      concatMap((date) => {
        return this.orderApiCaller.getInProggressOrderByDate(date).pipe(
          map((_) => loadInProgrgessOrderByDateSuccess({ data: _ })),
          catchError((error) => of(loadInProgrgessOrderByDateFailure({ error })))
        );
      })
    )
  );

  allOrdersByDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllOrderByDate),
      concatMap((date) => {
        return this.orderApiCaller.getAllOrderByDate(date).pipe(
          map((_) => loadAllOrderByDateSuccess({ data: _ })),
          catchError((error) => of(loadAllOrderByDateFailure({ error })))
        );
      })
    )
  );

  allOrdersByHourRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllOrderByHourRange),
      concatMap((date) => {
        return this.orderApiCaller.getAllOrderFourHourRange(date).pipe(
          map((_) => loadAllOrderByHourRangeSuccess({ data: _ })),
          catchError((error) => of(loadAllOrderByHourRangeFailure({ error })))
        );
      })
    )
  );

  allCommentfOfDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllCommentsOfDay),
      concatMap((date) => {
        return this.orderApiCaller.getAllCommentsOfDay(date).pipe(
          map((_) => loadAllCommentsOfDaySuccess({ data: _ })),
          catchError((error) => of(loadAllCommentsOfDayFailure({ error })))
        );
      })
    )
  );

  addCommentOfDayt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCommentOfDay),
      concatMap(({ data }) => {
        return this.orderApiCaller.addCommentOfDay(data).pipe(
          map((_) => addCommentOfDaySuccess({ data })),
          catchError((error) => of(addCommentOfDayFailure({ error })))
        );
      })
    )
  );

  cusotmerOrderHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerOrderHistory),
      concatMap(({ customerId }) => {
        return this.orderApiCaller.getCustomerOrderHistory(customerId).pipe(
          map((_) => loadCustomerOrderHistorySuccess({ data: _ })),
          catchError((error) => of(loadCustomerOrderHistoryFailure({ error })))
        );
      })
    )
  );

  loadPageableOrdersCatalog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableOrdersCatalog),
      withLatestFrom(this.store$.select(selectAllPageableOrdersCatalogFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedOrdersCatalogKey = key;

        return this.orderApiCaller.getPageableOrdersCatalog(filter?.value).pipe(
          map((_) => loadPageableOrdersCatalogSuccess({ data: _, key })),
          catchError((error) => of(loadPageableOrdersCatalogFailure({ error })))
        );
      })
    )
  );


  // reloadPageableOrdersCatalog(){
  //   this.store$.dispatch(loadPageableOrdersCatalog({}));
  // }

  setPageableOrdersCatalogFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableOrdersCatalogFilters),
      concatMap(({ data, key }) => {
        this.latestedOrdersCatalogKey = key;
        return of(data).pipe(
          map((_) => setPageableOrdersCatalogFiltersSuccess({ data, key })),
          finalize(() => this.reloadOrdersCatalog(key)),
          catchError((error) => of(setPageableOrdersCatalogFiltersFailure({ error })))
        );
      })
    )
  );

  OrdersCatalog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrdersCatalog),
      concatMap(() => {
        return this.orderApiCaller.getOrdersCatalog().pipe(
          map((_) => loadOrdersCatalogSuccess({ data: _ })),
          catchError((error) => of(loadOrdersCatalogFailure({ error })))
        );
      })
    )
  );

  setPageableOrderEditHistoryFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableOrderEditHistoryFilters),
      concatMap(({ data, key }) => {
        return of(data).pipe(
          map((_) => setPageableOrderEditHistoryFiltersSuccess({ data, key })),
          finalize(() => this.reloadOrderEditHistory(key)),
          catchError((error) => of(setPageableOrderEditHistoryFiltersFailure({ error })))
        );
      })
    )
  );

  loadPageableOrderEditHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableOrderEditHistory),
      withLatestFrom(this.store$.select(selectAllPageableOrderEditHistoryFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);

        return this.orderApiCaller.getOrderEditHistoryPageable(filter.value).pipe(
          map((_) => loadPageableOrderEditHistorySuccess({ data: _, key })),
          catchError((error) => of(loadPageableOrderEditHistoryFailure({ error })))
        );
      })
    )
  );

  private reloadOrdersCatalog(key: string) {
    this.store$.dispatch(loadPageableOrdersCatalog({ key }));
  }

  private reloadOrderEditHistory(key: string) {
    this.store$.dispatch(loadPageableOrderEditHistory({ key }));
  }


  constructor(
    private actions$: Actions,
    private orderApiCaller: OrderApiCallerService,
    private overlayService: OverlayWrapperService,
    private router: Router,
    // tslint:disable-next-line:no-shadowed-variable
    private alertify: AlertifyService,
    private store$: Store<StoreState>
  ) {}

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'calendar']);
  }
}
