<mat-form-field [ngStyle]="{ 'width.px': width }">
  <mat-label>{{ label }}</mat-label>
  <input
    [type]="inputType"
    [step]="step"
    [placeholder]="placeholder"
    matInput
    [formControl]="form"
    [autocomplete]="autocomplete"
  />
  <mat-error *ngIf="form?.hasError('required')">
    {{ 'ValidationErrors.FieldRequired' | translate }}
  </mat-error>
  <mat-error *ngIf="form?.hasError('isNotUnique')">
    {{ 'ValidationErrors.IsNotUnique' | translate }}
  </mat-error>
  <mat-error *ngIf="form?.hasError('pattern')">
    {{ 'ValidationErrors.Pattern' | translate }}
  </mat-error>
</mat-form-field>
