import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableWZDocumentsQuery } from '../models/queries/get-pageable-wzdocuments';
import { WZDocumentModel } from '../models/wzDocuments/wzDocumentModel';
import { BaseApiCaller } from './base-api-caller';

@Injectable({
  providedIn: 'root',
})
export class WZApiCallerService extends BaseApiCaller {
  protected controllerPath = 'WZ';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public GetWZ(supplyId: string): Observable<any> {
    return this.getDocumentWZ(supplyId);
  }

  public getPageableWZDocuments(query: GetPageableWZDocumentsQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<WZDocumentModel>>('pageable', { params });
  }

  public getWZShowPDF(id: string): Observable<any> {
    return this.getDocumentPDF(id + '/download');
  }

  public getWZPrint(id: string): Observable<any> {
    return this.getDocumentPDF(id + '/print');
  }

  public getWzDocumentsByCustomer(customerId: string) {
    return this.get<WZDocumentModel[]>('getWzDocumentsByCustomer/' + customerId);
  }
}
