import { Action, ActionReducer } from '@ngrx/store';
import { merge, pick } from 'lodash-es';

function setSavedState(state: any, localStorageKey: string) {
  const json = JSON.stringify(state);
  localStorage.setItem(localStorageKey, json);
}
function getSavedState(localStorageKey: string): any {
  return JSON.parse(localStorage.getItem(localStorageKey));
}
export const storeKey = '__app_storage__';
const stateKeys = ['pageSize', 'operations.ordersFilters', 'userData'];

export function storageMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
  let onInit = true;
  return function (state: S, action: A): S {
    const nextState = reducer(state, action);
    if (onInit) {
      onInit = false;
      const savedState = getSavedState(storeKey);
      return merge(nextState, savedState);
    }
    const stateToSave = pick(nextState, stateKeys);
    setSavedState(stateToSave, storeKey);
    return nextState;
  };
}
