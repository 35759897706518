
import { createReducer, on, Action } from '@ngrx/store';
import { UpdateAdditiveDto } from '../../shared/models/additive/update-additive-dto';
import { loadAdditives, loadAdditivesSuccess, loadAdditivesFailure } from './additives.actions';


export const additivesFeatureKey = 'additive';

export interface State {
    additives?: UpdateAdditiveDto[];
}

export const initialState: State = {};


const additiveReducer = createReducer(
    initialState,
    on(loadAdditives, (state) => setAdditive(state, null)),
    on(loadAdditivesSuccess, (state, { data }) => setAdditive(state, data)),
    on(loadAdditivesFailure, (state) => setAdditive(state, null))
);

function setAdditive(state: State, data: UpdateAdditiveDto[]) {
    return {
        ...state,
        additives: data,
    };
}

export function reducer(state: State | undefined, action: Action) {
    return additiveReducer(state, action);
}
