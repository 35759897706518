import { Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, tap, withLatestFrom } from 'rxjs/operators';
import { RecipeCostsApiCallerService } from '../../shared/api-services/recipe-costs-api-caller.service';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';
import { StoreState } from '../store-state';
import { selectAllPageableRecipeCostsFilters } from './recipe-costs.selector';
import { addRecipeCost, addRecipeCostFailure, addRecipeCostSuccess, deleteRecipeCost, deleteRecipeCostFailure, deleteRecipeCostSuccess, getRecipeCost, getRecipeCostFailure, getRecipeCostSuccess, loadPageableRecipeCosts, loadPageableRecipeCostsFailure, loadPageableRecipeCostsSuccess, setPageableRecipeCostsFilters, setPageableRecipeCostsFiltersFailure, setPageableRecipeCostsFiltersSuccess, updateRecipeCost, updateRecipeCostFailure, updateRecipeCostSuccess } from './recipe-costs.actions';

@Injectable()
export class RecipeCostsEffects {
  @ViewChild('spinner', {static:true}) spinner;

  private latestedRecipeCostsKey: string;


  deleteRecipeCosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRecipeCost),
      concatMap(({ id }) => {
        return this.RecipeCostsApiCaller.deleteRecipeCost(id).pipe(
          map((_) => deleteRecipeCostSuccess({ id })),
          tap(() => this.reloadRecipeCosts(this.latestedRecipeCostsKey)),
          catchError((error) => of(deleteRecipeCostFailure({ error })))
        );
      })
    )
  );

  addRecipeCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addRecipeCost),
      concatMap(({ data }) => {
        let lastValue;
        return this.RecipeCostsApiCaller.addRecipeCost(data).pipe(
          map((_) => addRecipeCostSuccess({data: _})),
          catchError((error) => of(addRecipeCostFailure({ error })))
        );
      })
    )
  );

  updateRecipeCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRecipeCost),
      concatMap(({ data }) => {
        let lastValue;
        return this.RecipeCostsApiCaller.updateRecipeCost(data).pipe(
          map((_) => updateRecipeCostSuccess()),
          tap((_) => lastValue = _),
          catchError((error) => of(updateRecipeCostFailure({ error })))
        );
      })
    )
  );

  loadPageableRecipeCosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableRecipeCosts),
      withLatestFrom(this.store$.select(selectAllPageableRecipeCostsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedRecipeCostsKey = key;

        return this.RecipeCostsApiCaller.getPageableItems(filter.value).pipe(
          map((_) => loadPageableRecipeCostsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableRecipeCostsFailure({ error })))
        );
      })
    )
  );

  getRecipeCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecipeCost),
      concatMap(({id}) => {
        return this.RecipeCostsApiCaller.getRecipeCost(id).pipe(
          map((_) => getRecipeCostSuccess({ data: _ })),
          catchError((error) => of(getRecipeCostFailure({ error })))
        );
      })
    )
  );

  setPageableRecipeCostsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableRecipeCostsFilters),
      concatMap(({ data, key }) => {
        this.latestedRecipeCostsKey = key;
        return of(data).pipe(
          map((_) => setPageableRecipeCostsFiltersSuccess({ data, key })),
          finalize(() => this.reloadRecipeCosts(key)),
          catchError((error) => of(setPageableRecipeCostsFiltersFailure({ error })))
        );
      })
    )
  );

  private reloadRecipeCosts(key: string) {
    this.store$.dispatch(loadPageableRecipeCosts({ key }));
  }
 
  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private RecipeCostsApiCaller: RecipeCostsApiCallerService,
    private router: Router,
    private overlayService: OverlayWrapperService
  ) { }

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'RecipeCosts', 'catalog']);
  }
}
