import { createAction, props } from "@ngrx/store";
import { OfferModel } from "../../shared/api-services/api-services";
import { PageableDto } from "../../shared/models/pageable.dto";
import { GetPageableOffersQuery } from "../../shared/models/queries/get-pageable-offers.query";

export const loadPageableOffers = createAction('[Items] Load Pageable Offers', props<{ key: string }>());
export const loadPageableOffersSuccess = createAction('[Items] Load Pageable Offers Success', props<{ data: PageableDto<OfferModel>; key: string }>());
export const loadPageableOffersFailure = createAction('[Items] Load Pageable Offers Failure', props<{ error: any }>());

export const setPageableOffersFilters = createAction('[Items] Set Pageable Offers Filters', props<{ data?: GetPageableOffersQuery; key: string }>());
export const setPageableOffersFiltersSuccess = createAction('[Items] Set Pageable Offers Filters Success', props<{ data: GetPageableOffersQuery; key: string }>());
export const setPageableOffersFiltersFailure = createAction('[Items] Set Pageable Offers Filters Failure', props<{ error: any }>());
