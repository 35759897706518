import { Action, createReducer, on } from '@ngrx/store';
import { OperationDto } from '../../shared/models/operation.dto';
import { storeUserData, loadAllClaims, loadAllClaimsSuccess, loadAllClaimsFailure, storeUserDataSuccess, storeUserDataFailure } from './user-data.actions';
import { PermissionDictionaryElement } from '../../shared/models/permissionDictionaryElement';
import { UserDataModel } from '../../shared/models/userDataModel';


export const operationFeatureKey = 'operation';

export interface State {
  userData?: UserDataModel;
  claimsDictionary?: PermissionDictionaryElement[];
}

export const initialState: State = {};

const userDataReducer = createReducer(
  initialState,

  on(storeUserData, state => setUserData(state, null)),
  on(storeUserDataSuccess, (state, { data }) => setUserData(state, data)),
  on(storeUserDataFailure, (state, action) => setUserData(state, null)),
  on(loadAllClaims, state => setClaimsDictionary(state, null)),
  on(loadAllClaimsSuccess, (state, { data }) => setClaimsDictionary(state, data)),
  on(loadAllClaimsFailure, (state, action) => setClaimsDictionary(state, null))
);

function setUserData(state: State, data: UserDataModel) {
  return {
    ...state,
    userData: data
  };
}

function setClaimsDictionary(state: State, data: any) {
  if(data != null){
    var jsonClaims = JSON.stringify(data);
    localStorage.setItem('claimsDictionary', jsonClaims);
  }
  return {
    ...state,
    claimsDictionary: data
  };
}

export function reducer(state: State | undefined, action: Action) {
  return userDataReducer(state, action);
}
