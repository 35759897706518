import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';

import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SpecialAdditivesApiCallerService } from '../../shared/api-services/special-additives-api-caller.service';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';

import {
  loadPageableSpecialAdditives,
  loadPageableSpecialAdditivesFailure,
  loadPageableSpecialAdditivesSuccess,
  loadSpecialAdditivesForSelect,
  loadSpecialAdditivesForSelectFailure,
  loadSpecialAdditivesForSelectSuccess,
  setPageableSpecialAdditivesFilters,
  setPageableSpecialAdditivesFiltersFailure,
  setPageableSpecialAdditivesFiltersSuccess,
  getSpecialAdditives,
  getSpecialAdditivesFailure,
  getSpecialAdditivesSuccess,
} from './special-additives.actions';
import {
  selectAllPageableSpecialAdditivesFilters,
  selectPageableSpecialAdditivesFilters,
} from './special-additives.selectors';

@Injectable()
export class SpecialAdditivesEffects {
  private latestedAddresssKey: string;
  private isForHigherClass: boolean;

  private latestedSpecialAdditivesKey: string;

  setPageableSpecialAdditivesFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableSpecialAdditivesFilters),
      concatMap(({ data, key }) => {
        this.latestedSpecialAdditivesKey = key;
        return of(data).pipe(
          map((_) => setPageableSpecialAdditivesFiltersSuccess({ data, key })),
          finalize(() => this.reloadSpecialAdditives(key)),
          catchError((error) => of(setPageableSpecialAdditivesFiltersFailure({ error })))
        );
      })
    )
  );

  loadSpecialAdditivesForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSpecialAdditivesForSelect),
      concatMap(({ isForHigherClass, customerId }) => {
        this.isForHigherClass = isForHigherClass;
        return this.specialAdditivesApiCaller.getSpecialAdditivesForSelect(isForHigherClass, customerId).pipe(
          map((_) => loadSpecialAdditivesForSelectSuccess({ data: _ })),
          catchError((error) => of(loadSpecialAdditivesForSelectFailure({ error })))
        );
      })
    )
  );

  loadPageableSpecialAdditives$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableSpecialAdditives),
      withLatestFrom(this.store$.select(selectAllPageableSpecialAdditivesFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedSpecialAdditivesKey = key;

        return this.specialAdditivesApiCaller.getPageableSpecialAdditives(filter.value).pipe(
          map((_) => loadPageableSpecialAdditivesSuccess({ data: _, key })),
          catchError((error) => of(loadPageableSpecialAdditivesFailure({ error })))
        );
      })
    )
  );

  getSpecialAdditives$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSpecialAdditives),
      concatMap(({ id }) => {
        return this.specialAdditivesApiCaller.getSpecialAdditives(id).pipe(
          map((_) => getSpecialAdditivesSuccess({ data: _ })),
          catchError((error) => of(getSpecialAdditivesFailure({ error })))
        );
      })
    )
  );

  //   private reloadAddresses(key: string) {
  //     this.store$.dispatch(loadAddressesForSelect({customerId: this.latestCustomerKey}));
  //   }

  private reloadSpecialAdditives(key: string) {
    this.store$.dispatch(loadPageableSpecialAdditives({ key }));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private specialAdditivesApiCaller: SpecialAdditivesApiCallerService,
    private router: Router
  ) {}
}
