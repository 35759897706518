import { UntypedFormGroup, Validators, UntypedFormControl, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { ValidatorPatterns } from '../../../validators/validator-patterns';
import { PersonDto } from '../../../models/person.dto';
import { Guid } from 'guid-typescript';
import { AccountApiCallerService } from '../../../api-services/account-api-caller.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdentityUsersApiCallerService } from '../../../api-services/identity-users-api-caller.service';

export class PersonListForm extends UntypedFormGroup {
  private accountApiCallerService: IdentityUsersApiCallerService;

  constructor(data?: PersonDto) {
    super({
      id: new UntypedFormControl(undefined),
      firstName: new UntypedFormControl(undefined, [Validators.required, Validators.pattern(ValidatorPatterns.firstname)]),
      lastName: new UntypedFormControl(undefined, [Validators.required, Validators.pattern(ValidatorPatterns.lastname)]),
      post: new UntypedFormControl(undefined, [Validators.pattern(ValidatorPatterns.onlyLetters)]),
      isSuperEmployee: new UntypedFormControl(false, [Validators.required, Validators.pattern(ValidatorPatterns.onlyLetters)]),
      email: new UntypedFormControl(undefined, [Validators.required, Validators.email]),
    });

    if (data != null) {
      this.patchValue({ ...data, id: data.id == null ? Guid.EMPTY : data.id });
    }
  }

  forAdd(accountApiCallerService: IdentityUsersApiCallerService) {
    this.accountApiCallerService = accountApiCallerService;

    this.get('email').setAsyncValidators(this.createEmailUniqueValidator);

    return this;
  }

  private createEmailUniqueValidator: AsyncValidatorFn = (control: AbstractControl) => {
    if (control.value == null || control.value.length === 0) {
      return of(null);
    }
    return this.accountApiCallerService.emailUnique(control.value).pipe(
      map((_) => {
        const result = _ ? null : { isNotUnique: true };
        return result;
      })
    );
  };
}
