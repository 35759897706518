import {
  loadRecipe,
  loadRecipeSuccess,
  loadRecipeFailure,
  loadPageableRecipesSuccess,
  loadPageableRecipes,
  loadPageableRecipesFailure,
  setPageableRecipesFilters,
  setPageableRecipesFiltersSuccess,
} from './recipe.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { RecipeDto } from '../../shared/models/recipe-dto';
import { DictionaryDto } from 'project/src/app/shared/models/dictionary.dto';
import { PageableDto } from 'project/src/app/shared/models/pageable.dto';
import { GetPageableRecipesQuery } from 'project/src/app/shared/models/queries/get-pageable-recipes-query';

export const recipeFeatureKey = 'recipe';

export interface State {
  recipe?: RecipeDto;

  pageableRecipes?: DictionaryDto<string, PageableDto<RecipeDto>>;
  recipesFilters?: DictionaryDto<string, GetPageableRecipesQuery>;
}

export const initialState: State = {
  recipesFilters: new DictionaryDto<string, GetPageableRecipesQuery>(),
};

const recipeReducer = createReducer(
  initialState,
  on(loadRecipe, (state) => setRecipe(state, null)),
  on(loadRecipeSuccess, (state, { data }) => setRecipe(state, data)),
  on(loadRecipeFailure, (state, action) => setRecipe(state, null)),

  on(loadPageableRecipes, (state, { key }) => setPageableRecipes(state, null, key)),
  on(loadPageableRecipesSuccess, (state, { data, key }) => setPageableRecipes(state, data, key)),
  on(loadPageableRecipesFailure, (state, action) => state),

  on(setPageableRecipesFilters, (state, { data, key }) => setPageableRecipesFiltersFunc(state, null, key)),
  on(setPageableRecipesFiltersSuccess, (state, { data, key }) => setPageableRecipesFiltersFunc(state, data, key))
);

function setRecipe(state: State, data: RecipeDto) {
  return {
    ...state,
    recipe: data,
  };
}

function setPageableRecipes(state: State, data: PageableDto<RecipeDto>, key: string) {
  const pageableRecipes = Array.isArray(state.pageableRecipes)
    ? state.pageableRecipes
    : new DictionaryDto<string, PageableDto<RecipeDto>>();
  const orders = pageableRecipes.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableRecipes.push({ key, value: data });
  }

  return {
    ...state,
    pageableRecipes,
  };
}

function setPageableRecipesFiltersFunc(state: State, data: GetPageableRecipesQuery, key: string) {
  const recipesFilters = Array.isArray(state.recipesFilters)
    ? state.recipesFilters
    : new DictionaryDto<string, GetPageableRecipesQuery>();
  const filter = recipesFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    recipesFilters.push({ key, value: data });
  }

  return {
    ...state,
    recipesFilters,
  };
}
export function reducer(state: State | undefined, action: Action) {
  return recipeReducer(state, action);
}
