import { createAction, props } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableRecipeCostsQuery } from '../../shared/models/queries/get-pageable-recipe-costs-query';
import { RecipeCostDto } from '../../shared/models/recipe-costs/recipe-cost-dto';


export const addRecipeCost = createAction('[RecipeCosts] Add RecipeCost', props<{ data: RecipeCostDto }>());
export const addRecipeCostSuccess = createAction('[RecipeCosts] Add RecipeCost Success', props<{ data: any }>());
export const addRecipeCostFailure = createAction('[RecipeCosts] Add RecipeCost Failure', props<{ error: any }>());

export const updateRecipeCost = createAction('[RecipeCosts] Update RecipeCost', props<{ data: RecipeCostDto }>());
export const updateRecipeCostSuccess = createAction('[RecipeCosts] Update RecipeCost Success');
export const updateRecipeCostFailure = createAction('[RecipeCosts] Update RecipeCost Failure', props<{ error: any }>());

export const deleteRecipeCost = createAction('[RecipeCosts] delete RecipeCost', props<{ id: string }>());
export const deleteRecipeCostSuccess = createAction('[RecipeCosts] delete RecipeCost Success', props<{ id: string }>());
export const deleteRecipeCostFailure = createAction('[RecipeCosts] delete RecipeCost Failure', props<{ error: any }>());

export const getRecipeCost = createAction('[RecipeCosts] get RecipeCost', props<{ id: string }>());
export const getRecipeCostSuccess = createAction('[RecipeCosts] get RecipeCost Success', props<{ data: RecipeCostDto }>());
export const getRecipeCostFailure = createAction('[RecipeCosts] get RecipeCost Failure', props<{ error: any }>());

export const loadPageableRecipeCosts = createAction('[RecipeCosts] Load Pageable RecipeCosts', props<{ key: string }>());
export const loadPageableRecipeCostsSuccess = createAction('[RecipeCosts] Load Pageable RecipeCosts Success', props<{ data: PageableDto<RecipeCostDto>; key: string }>());
export const loadPageableRecipeCostsFailure = createAction('[RecipeCosts] Load Pageable RecipeCosts Failure', props<{ error: any }>());

export const setPageableRecipeCostsFilters = createAction('[RecipeCosts] Set Pageable RecipeCosts Filters', props<{ data?: GetPageableRecipeCostsQuery; key: string }>());
export const setPageableRecipeCostsFiltersSuccess = createAction('[RecipeCosts] Set Pageable RecipeCosts Filters Success', props<{ data: GetPageableRecipeCostsQuery; key: string }>());
export const setPageableRecipeCostsFiltersFailure = createAction('[RecipeCosts] Set Pageable RecipeCosts Filters Failure', props<{ error: any }>());