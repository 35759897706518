import { Action, createReducer, on } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { TransactionDto } from '../../shared/models/transactions/transaction.dto';
import {
  loadPageableTransactions,
  loadPageableTransactionsFailure,
  loadPageableTransactionsSuccess,
  loadUnhandledTransactionsForAttachment,
  loadUnhandledTransactionsForAttachmentFailure,
  loadUnhandledTransactionsForAttachmentSuccess,
  loadUnhandledTransactionsForClient,
  loadUnhandledTransactionsForClientFailure,
  loadUnhandledTransactionsForClientSuccess,
} from './transactions.actions';

export const contractFeatureKey = 'transaction';

export interface State {
  unhandledTransactionsForAttachment?: TransactionDto[];
  pageableTransactions?: PageableDto<TransactionDto>;
  unhandledTransactionsForClient?: TransactionDto[];
}

export const initialState: State = {};

const transactionReducer = createReducer(
  initialState,

  on(loadPageableTransactions, (state) => setPageableTransactions(state, null)),
  on(loadPageableTransactionsSuccess, (state, { data }) => setPageableTransactions(state, data)),
  on(loadPageableTransactionsFailure, (state, action) => setPageableTransactions(state, null)),

  on(loadUnhandledTransactionsForAttachment, (state) => setUnhandledTransactionsForAttachment(state, null)),
  on(loadUnhandledTransactionsForAttachmentSuccess, (state, { data }) =>
    setUnhandledTransactionsForAttachment(state, data)
  ),
  on(loadUnhandledTransactionsForAttachmentFailure, (state, action) =>
    setUnhandledTransactionsForAttachment(state, null)
  ),

  on(loadUnhandledTransactionsForClient, (state) => setUnhandledTransactionsForClient(state, null)),
  on(loadUnhandledTransactionsForClientSuccess, (state, { data }) => setUnhandledTransactionsForClient(state, data)),
  on(loadUnhandledTransactionsForClientFailure, (state, action) => setUnhandledTransactionsForClient(state, null))
);

function setUnhandledTransactionsForAttachment(state, data) {
  return { ...state, unhandledTransactionsForAttachment: data };
}

function setUnhandledTransactionsForClient(state, data) {
  return { ...state, unhandledTransactionsForClient: data };
}

function setPageableTransactions(state: State, pageableTransactions: PageableDto<TransactionDto>): State {
  return {
    ...state,
    pageableTransactions,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return transactionReducer(state, action);
}
