import { Action, createReducer, on } from '@ngrx/store';
import { loadDeliveryItemsForDate, loadDeliveryItemsForDateFailure, loadDeliveryItemsForDateSuccess } from './delivery-calendar.actions';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { IdentityPageableDto } from '../../shared/api-services/identity-users-api-caller.service';
import { DeliveryItemDto } from '../../shared/models/deliveryCalendar/deliveryItemDTO';

export const deliveryCalendarFeatureKey = 'deliveryCalendar';

export interface State {
  deliveryItemsForDate?: DeliveryItemDto[];
  // pageableDeliveryItems?: DictionaryDto<string,PageableDto<DeliveryItemDto>>;
  // employee?: UpdateDeliveryItemDto;
  // employeesFilters?: DictionaryDto<string, GetPageableDeliveryItemsQuery>;
  // employeeAdded?: { id: number; name: string };
}

export const initialState: State = {
  //employeesFilters: new DictionaryDto<string, GetPageableDeliveryItemsQuery>(),
};

const deliveryCalendarReducer = createReducer(
  initialState,

  on(loadDeliveryItemsForDate, (state) => setDeliveryItemsForSelect(state, null)),
  on(loadDeliveryItemsForDateSuccess, (state, { data }) => setDeliveryItemsForSelect(state, data)),
  on(loadDeliveryItemsForDateFailure, (state, action) => state),
  
  // on(loadPageableDeliveryItems, (state, {key} ) => setPageableDeliveryItems(state, null, key)),
  // on(loadPageableDeliveryItemsSuccess, (state, { data, key }) => setPageableDeliveryItems(state, data, key)),
  // on(loadPageableDeliveryItemsFailure, (state, action) => state),

  // on(getDeliveryItem, (state) => setDeliveryItem(state, null)),
  // on(getDeliveryItemSuccess, (state, { data}) => setDeliveryItem(state, data)),
  // on(getDeliveryItemFailure, (state, action) => state),

  // on(setPageableDeliveryItemsFilters, (state, { data, key }) => setPageableDeliveryItemsFiltersFunc(state, null, key)),
  // on(setPageableDeliveryItemsFiltersSuccess, (state, { data, key }) => setPageableDeliveryItemsFiltersFunc(state, data, key)),

  // on(addDeliveryItemSuccess, (state, data) => setAddDeliveryItem(state, data))
);

function setDeliveryItemsForSelect(state: State, data: DeliveryItemDto[]) {
  const deliveryItemsForDate = data;
  return {
    ...state,
    deliveryItemsForDate,
  };
}

// function setAddDeliveryItem(state: State, employeeAdded) {
//   return {
//     ...state,
//     employeeAdded,
//   };
// }

// function setPageableDeliveryItems(state: State, data: IdentityPageableDto<DeliveryItemDto>, key: string) {
//   var pageable = {} as PageableDto<DeliveryItemDto>;
//   if(data != null){
//     pageable.result = data.data;
//     pageable.total = data.totalPages;
//   }
//   const pageableDeliveryItems = Array.isArray(state.pageableDeliveryItems)
//     ? state.pageableDeliveryItems
//     : new DictionaryDto<string, PageableDto<DeliveryItemDto>>();
//   const orders = pageableDeliveryItems.find((_) => _.key === key);
//   if (orders != null) {
//     orders.value = pageable;
//   } else {
//     pageableDeliveryItems.push({ key, value: pageable });
//   }

//   return {
//     ...state,
//     pageableDeliveryItems,
//   };
// }

// function setDeliveryItem(state: State, data: UpdateDeliveryItemDto) {
//   var employee = data;
//   return {
//     ...state,
//     employee,
//   };
// }

// function setPageableDeliveryItemsFiltersFunc(state: State, data: GetPageableDeliveryItemsQuery, key: string) {
//   const employeesFilters = Array.isArray(state.employeesFilters)
//     ? state.employeesFilters
//     : new DictionaryDto<string, GetPageableDeliveryItemsQuery>();
//   const filter = employeesFilters.find((_) => _.key === key);
//   if (filter != null) {
//     filter.value = data;
//   } else {
//     employeesFilters.push({ key, value: data });
//   }

//   return {
//     ...state,
//     employeesFilters,
//   };
// }

export function reducer(state: State | undefined, action: Action) {
  return deliveryCalendarReducer(state, action);
}
