import { formatDate } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl, ValidatorFn } from '@angular/forms';
import { CustomValidator } from '../../../validators/custom.validator';

export class EmployeeAbsenceForm extends UntypedFormGroup {
  constructor() {
    super(
      {
        userId: new UntypedFormControl(null),
        absenceFrom: new UntypedFormControl(null, [Validators.required]),
        absenceTo: new UntypedFormControl(null, [Validators.required]),
      },
      CustomeDateValidators.fromToDate
    );
  }

  forAdd() {
    return this;
  }
}

export class CustomeDateValidators {
  public static fromToDate(f: UntypedFormGroup, errorName: string = 'fromToDate') {
    const fromDate = formatDate(new Date(f.get('absenceFrom').value), 'yyyy-MM-dd', 'pl');
    const toDate = formatDate(new Date(f.get('absenceTo').value), 'yyyy-MM-dd', 'pl');
    if (fromDate !== null && toDate !== null && fromDate > toDate) {
      return { [errorName]: true };
    }
    return null;
  }
}
