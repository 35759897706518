import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiCaller } from './base-api-caller';
import { map } from 'rxjs/operators';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { OtherDocumentDto } from '../models/other-documents/otherDocumentDTO';
import { GetPageableIncomingMessagesQuery } from '../models/queries/get-pageable-incoming-messages.query';

@Injectable({
  providedIn: 'root',
})
export class OtherDocumentsApiCallerService extends BaseApiCaller {
  protected controllerPath = 'users';

  editorialDocuments: OtherDocumentDto[] = [
    {id:1, documentNumber:1, documentName: "Document", documentType: "Faktura",correspondenceType: "Typ", positionNumber:1, title: "aaa", receivedDate: new Date(), releaseDate: new Date(), clientName:"Client 1", clientId: 1, authorName: "Author 1", authorId: 1, sourceId:1, sourceName: "Source 1", statusName: "Nowy" ,statusId: 1, departmentName:"Department1", departmentId: 1, employeeName:"Jan Kowalski", employeeId: 1, description: "opis tej korespondencji jest taki.."},
    {id:2, documentNumber:1, documentName: "Document", documentType: "Faktura",correspondenceType: "Typ", positionNumber:2, title: "aaa", receivedDate: new Date(), releaseDate: new Date(), clientName:"Client 2", clientId: 2, authorName: "Author 2", authorId: 2, sourceId:1, sourceName: "Source 1", statusName: "Nowy" ,statusId: 1, departmentName:"Department1", departmentId: 1, employeeName:"Jan Kowalski", employeeId: 1, description: "opis tej korespondencji jest taki.."},
    {id:3, documentNumber:1, documentName: "Document", documentType: "Faktura",correspondenceType: "Typ", positionNumber:3, title: "aaa", receivedDate: new Date(), releaseDate: new Date(), clientName:"Client 3", clientId: 3, authorName: "Author 3", authorId: 3, sourceId:1, sourceName: "Source 1", statusName: "Nowy" ,statusId: 1, departmentName:"Department1", departmentId: 1, employeeName:"Jan Kowalski", employeeId: 1, description: "opis tej korespondencji jest taki.."},
    {id:4, documentNumber:1, documentName: "Document", documentType: "Faktura",correspondenceType: "Typ", positionNumber:4, title: "aaa", receivedDate: new Date(), releaseDate: new Date(), clientName:"Client 4", clientId: 4, authorName: "Author 4", authorId: 4, sourceId:1, sourceName: "Source 1", statusName: "Nowy" ,statusId: 1, departmentName:"Department1", departmentId: 1, employeeName:"Jan Kowalski", employeeId: 1, description: "opis tej korespondencji jest taki.."},
    {id:5, documentNumber:1, documentName: "Document", documentType: "Faktura",correspondenceType: "Typ", positionNumber:5, title: "aaa", receivedDate: new Date(), releaseDate: new Date(), clientName:"Client 5", clientId: 5, authorName: "Author 5", authorId: 5, sourceId:1, sourceName: "Source 1", statusName: "Nowy" ,statusId: 1, departmentName:"Department1", departmentId: 1, employeeName:"Jan Kowalski", employeeId: 1, description: "opis tej korespondencji jest taki.."},
    {id:6, documentNumber:1, documentName: "Document", documentType: "Faktura",correspondenceType: "Typ", positionNumber:6, title: "aaa", receivedDate: new Date(), releaseDate: new Date(), clientName:"Client 6", clientId: 6, authorName: "Author 6", authorId: 6, sourceId:1, sourceName: "Source 1", statusName: "Nowy" ,statusId: 1, departmentName:"Department1", departmentId: 1, employeeName:"Jan Kowalski", employeeId: 1, description: "opis tej korespondencji jest taki.."},
    {id:7, documentNumber:1, documentName: "Document", documentType: "Faktura",correspondenceType: "Typ", positionNumber:7, title: "aaa", receivedDate: new Date(), releaseDate: new Date(), clientName:"Client 7", clientId: 7, authorName: "Author 7", authorId: 7, sourceId:1, sourceName: "Source 1", statusName: "Nowy" ,statusId: 1, departmentName:"Department1", departmentId: 1, employeeName:"Jan Kowalski", employeeId: 1, description: "opis tej korespondencji jest taki.."},
  ];

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addOtherDocument(message: OtherDocumentDto) {
    //return this.post('', dto);
    this.editorialDocuments.push(message);
    return of("ok");

  }

  public updateOtherDocument(message: OtherDocumentDto): Observable<string>{
    this.deleteOtherDocument(message.id);
    this.addOtherDocument(message);
    return of("ok");
  }

  public deleteOtherDocument(id: number) {
    //return this.delete(id);
    var filtered = this.editorialDocuments.filter(function(value, index, arr){
      return value.id != id;
    });
    this.editorialDocuments = filtered;
    return of("ok");
  }

  public getOtherDocument(id: number): Observable<OtherDocumentDto>{
    var filtered = this.editorialDocuments.filter(function(value, index, arr){
      return value.id == id;
    });
    return of(filtered[0]);
  }

  public getPageableOtherDocuments(filters: GetPageableIncomingMessagesQuery) {
    var result = new PageableDto<OtherDocumentDto>();
    result.total = this.editorialDocuments.length;
    result.result = this.editorialDocuments;
    return of(result);
  }

  public getPageable(query: GetPageableQuery) {
    //const params = this.prepareParams(query);
    //return this.get<PageableDto<IncomingMessageDto>>('pageable', { params });

  }
}
