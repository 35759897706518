
import { createAction, props } from '@ngrx/store';
import { AdmixtureDto } from '../../shared/models/admixture/update-admixture-dto';

export const loadAdmixtures = createAction('[Admixture] Load Admixtures', props<{ recipeId: string }>());

export const loadAdmixturesSuccess = createAction(
  '[Admixture] Load Admixtures Success',
  props<{ data: AdmixtureDto[] }>()
);

export const loadAdmixturesFailure = createAction('[Admixture] Load Admixtures Failure', props<{ error: any }>());

export const addAdmixture = createAction('[Admixture] Add Admixtures', props<{ data: AdmixtureDto }>());

export const addAdmixtureSuccess = createAction('[Admixture] Add Admixtures Success', props<{ id: string }>());

export const addAdmixtureFailure = createAction('[Admixture] Add Admixtures Failure', props<{ error: any }>());

export const updateAdmixture = createAction('[Admixture] Update Admixtures', props<{ data: AdmixtureDto }>());

export const updateAdmixtureSuccess = createAction('[Admixture] Update Admixtures Success', props<{ id: string }>());

export const updateAdmixtureFailure = createAction('[Admixture] Update Admixtures Failure', props<{ error: any }>());

export const deleteAdmixture = createAction('[Admixture] Delete Admixtures', props<{ id: string }>());

export const deleteAdmixtureSuccess = createAction('[Admixture] Delete Admixtures Success', props<{ id: string }>());

export const deleteAdmixtureFailure = createAction('[Admixture] Delete Admixtures Failure', props<{ error: any }>());
