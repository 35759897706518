import { Action, createReducer, on } from '@ngrx/store';
import { DictionaryModel } from '../../shared/models/dictionary-model';
import {
  loadOrderTypes,
  loadOrderTypesSuccess,
  loadOrderTypesFailure,
  loadOrderStatuses,
  loadOrderStatusesSuccess,
  loadOrderStatusesFailure,
} from './dictionaries.actions';

export const dictionariesFeatureKey = 'dictionaries';

export interface State {
  orderStatuses?: DictionaryModel<string>[];
  orderTypes?: DictionaryModel<string>[];
}

export const initialState: State = {};

const dictionariesReducer = createReducer(
  initialState,

  on(loadOrderTypes, (state) => state),
  on(loadOrderTypesSuccess, (state, { data }) => setOrderTypes(state, data)),
  on(loadOrderTypesFailure, (state) => setOrderTypes(state, null)),

  on(loadOrderStatuses, (state) => state),
  on(loadOrderStatusesSuccess, (state, { data }) => setOrderStatuses(state, data)),
  on(loadOrderStatusesFailure, (state) => setOrderStatuses(state, null))
);

function setOrderTypes(state, orderTypes: DictionaryModel<string>[]) {
  return { ...state, orderTypes };
}

function setOrderStatuses(state, orderStatuses: DictionaryModel<string>[]) {
  return { ...state, orderStatuses };
}

export function reducer(state: State | undefined, action: Action) {
  return dictionariesReducer(state, action);
}
