//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadAuthorsForSelect,
  loadAuthorsForSelectSuccess,
  loadAuthorsForSelectFailure,
  addAuthorSuccess,
  addAuthorFailure,
  loadPageableAuthors,
  loadPageableAuthorsSuccess,
  loadPageableAuthorsFailure,
  deleteAuthor,
  deleteAuthorSuccess,
  deleteAuthorFailure,
  getAuthor,
  getAuthorSuccess,
  getAuthorFailure,
  updateAuthor,
  updateAuthorSuccess,
  updateAuthorFailure,
  addAuthor,
  setPageableAuthorsFilters,
  setPageableAuthorsFiltersSuccess,
  setPageableAuthorsFiltersFailure,
} from './authors.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { AuthorsApiCallerService } from '../../shared/api-services/authors-api-caller.service';
import { selectAllPageableAuthorsFilters } from './authors.selector';

@Injectable()
export class AuthorsEffects {
  private latestedAuthorsKey: string;

  addAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAuthor),
      concatMap(({ data }) => {
        return this.authorsApiCaller.addAuthor(data).pipe(
          map((_) => addAuthorSuccess({ id: _, name: data.firstName + ' ' + data.lastName })),
          tap(() => this.reloadAuthors(this.latestedAuthorsKey)),
          catchError((error) => of(addAuthorFailure({ error })))
        );
      })
    )
  );

  updateAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAuthor),
      concatMap(({ data }) => {
        return this.authorsApiCaller.updateAuthor(data).pipe(
          map((_) => updateAuthorSuccess()),
          catchError((error) => of(updateAuthorFailure({ error })))
        );
      })
    )
  );

  loadAuthorsForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuthorsForSelect),
      concatMap(() => {
        return this.authorsApiCaller.getAuthorsForSelect().pipe(
          map((_) => loadAuthorsForSelectSuccess({ data: _ })),
          catchError((error) => of(loadAuthorsForSelectFailure({ error })))
        );
      })
    )
  );

  loadPageableAuthors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableAuthors),
      withLatestFrom(this.store$.select(selectAllPageableAuthorsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedAuthorsKey = key;
        return this.authorsApiCaller.getPageableAuthors(filter.value).pipe(
          map((_) => loadPageableAuthorsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableAuthorsFailure({ error })))
        );
      })
    )
  );

  getAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAuthor),
      concatMap(({ id }) => {
        return this.authorsApiCaller.getAuthor(id).pipe(
          map((_) => getAuthorSuccess({ data: _ })),
          catchError((error) => of(getAuthorFailure({ error })))
        );
      })
    )
  );

  deleteAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAuthor),
      concatMap(({ id }) => {
        return this.authorsApiCaller.deleteAuthor(id).pipe(
          map((_) => deleteAuthorSuccess({ id: id })),
          tap(() => this.reloadAuthors(this.latestedAuthorsKey)),
          catchError((error) => of(deleteAuthorFailure({ error })))
        );
      })
    )
  );

  setPageableAuthorsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableAuthorsFilters),
      concatMap(({ data, key }) => {
        this.latestedAuthorsKey = key;
        return of(data).pipe(
          map((_) => setPageableAuthorsFiltersSuccess({ data, key })),
          finalize(() => this.reloadAuthors(key)),
          catchError((error) => of(setPageableAuthorsFiltersFailure({ error })))
        );
      })
    )
  );

  private reloadAuthors(key: string) {
    this.store$.dispatch(loadAuthorsForSelect());
    this.store$.dispatch(loadPageableAuthors({ key }));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private authorsApiCaller: AuthorsApiCallerService,
    private router: Router
  ) {}
}
