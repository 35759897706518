import { CustomerDto } from './../../models/customers/customer-dto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerModel } from '../../models/calendar/customer.model';
import { PageableDto } from '../../models/pageable.dto';
import { GetPageableCustomersQuery } from '../../models/queries/get-pageable-customers-query';
import { BaseApiCaller } from '../base-api-caller';
import { map } from 'rxjs/operators';
import { GusCustomerDto } from '../../models/customers/gus-customer-dto';
import { AliasDto } from '../../models/customers/alias.dto';
import { SearchModel } from '../../models/search-model';
import { SelectModel } from '../../models/select-model';
import { MarchanLimit } from '../../models/customers/merchantLimit.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerApiCallerService extends BaseApiCaller {
  protected controllerPath = 'customer';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addCustomer(dto: CustomerDto) {
    return this.post('', dto);
  }

  public updateCustomer(dto: CustomerDto) {
    return this.put('', dto);
  }

  public getCustomerAll() {
    return this.get<CustomerModel[]>('');
  }

  public getCustomerDataByNip(nip: string) {
    return this.get<GusCustomerDto>(nip + '/from-gus');
  }

  public doesCustomerWithNipExist(excludeId: string, nip: string) {
    const params = this.prepareParams({
      excludeId: excludeId,
      nip: nip,
    });
    return this.get<boolean>('DoesCustomerWithNipExist', { params });
  }

  public getPageableCustomers(query: GetPageableCustomersQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<CustomerDto>>('pageable', { params });
  }

  public getCustomer(id: string) {
    return this.get<CustomerDto>(id.toString()).pipe(map((_) => new CustomerDto(_)));
  }

  addCustomerAlias(data: AliasDto) {
    return this.post('alias', data);
  }

  getCustomersSearchModel(filter: SearchModel) {
    const params = this.prepareParams(filter);
    return this.get<SelectModel<string>[]>('search-model', { params });
  }

  getAllCustomersSearchModel(filter: SearchModel) {
    const params = this.prepareParams(filter);
    return this.get<SelectModel<string>[]>('search-customer-model', { params });
  }

  public checkCustomerWithUniqueAbbreviation(uniqueAbbreviation: string) {
    const params = this.prepareParams({
      uniqueAbbreviation,
    });
    return this.get<boolean>('CheckCustomerWithUniqueAbbreviation', { params });
  }

  public updateMerchantLimit(dto: MarchanLimit) {
    return this.put('update_merchant_limit', dto);
  }

  getPhoneNumberExist(phoneNumber: number) {
    const params = this.prepareParams({ phoneNumber: phoneNumber });
    return this.get<boolean>('isCustomerWithPhoneNumberExist', { params });
  }

  public getCustomerById(id: string) {
    return this.get<any>(id);
  }
}
