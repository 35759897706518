import { Overlay, OverlayRef, PositionStrategy, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { select, Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'project/src/environments/environment';
import { filter } from 'rxjs/operators';
import { loadNotifications } from '../../root-store/notifications/notification.action';
import { selectNotifications } from '../../root-store/notifications/notifications-selectors';
import { StoreState } from '../../root-store/store-state';
import { loadAllClaims, storeUserData } from '../../root-store/user-data/user-data.actions';
import { NotificationDto } from '../../shared/models/notification.dto';
import { NotificationComponent } from '../notification/notification.component';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() snav: MatSidenav;
  isAuthenticated: boolean;
  userData: any;
  user: string;
  isDebug = false;
  roles: string;
  notificationsNumber: number;
  overlayRef: OverlayRef;
  overlayPosition: PositionStrategy;
  notificationComponentPortal: ComponentPortal<NotificationComponent>;
  scrollStrategy: ScrollStrategy;
  open: boolean;
  notifications: NotificationDto[];
  @ViewChild('notificationsButton') buttonRef: ElementRef;

  constructor(
    private overlayWrapperService: OverlayWrapperService,
    private store$: Store<StoreState>,
    private overlay: Overlay,
    private readonly sso: ScrollStrategyOptions,
    public oidcSecurityService: OidcSecurityService
  ) {
    this.isDebug = environment.production !== true;
    this.scrollStrategy = this.sso.block();
    this.open = true;
  }

  ngOnInit() {
    this.store$.dispatch(loadNotifications());
    this.store$
      .pipe(
        select(selectNotifications),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        this.notificationsNumber = data.length;
      });

    this.oidcSecurityService.userData$.subscribe((auth) => {
      this.isAuthenticated = auth;
    });

    this.oidcSecurityService.userData$.subscribe((userData) => {
      if (userData != null) {
        this.userData = userData;
        this.user = userData.email;
        //given_name[0] + " " + userData.family_name[0];
        this.store$.dispatch(storeUserData({ data: this.userData }));
        this.store$.dispatch(loadAllClaims());
      }
    });
  }

  public showNotifications() {}

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  ngAfterViewInit() {
    this.notificationComponentPortal = new ComponentPortal(NotificationComponent);
  }


  // //GetScreenSize

  // scrWidth:any;
  // @HostListener('window:resize', ['$event'])
  // getScreenSize() {
  // this.scrWidth = window.innerWidth;
  // }

  // mobileDevices(){
  //   if(this.scrWidth <= 900) {
  //     this.snav.toggle();
  //   }
  // }

}
