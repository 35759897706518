import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';


export const loadStatusesForSelect = createAction('[FormsData] Load Statuses For Select');
export const loadStatusesForSelectSuccess = createAction('[FormsData] Load Statuses For Select Success', props<{ data: SelectModel<number>[] }>());
export const loadStatusesForSelectFailure = createAction('[FormsData] Load Statuses For Select Failure', props<{ error: any }>());

export const loadPageableStatuses = createAction('[FormsData] Load Pageable Statuses');
export const loadPageableStatusesSuccess = createAction('[FormsData] Load Pageable Statuses Success', props<{ data: PageableDto<SelectModel<number>> }>());
export const loadPageableStatusesFailure = createAction('[FormsData] Load Pageable Statuses Failure', props<{ error: any }>());

export const deleteStatus = createAction('[FormsData] delete Status', props<{ id: number }>());
export const deleteStatusSuccess = createAction('[FormsData] delete Status Success', props<{ id: number }>());
export const deleteStatusFailure = createAction('[FormsData] delete Status Failure', props<{ error: any }>());

export const addStatus = createAction('[FormsData] Add Status', props<{ data: SelectModel<number> }>());
export const addStatusSuccess = createAction('[FormsData] Add Status Success');
export const addStatusFailure = createAction('[FormsData] Add Status Failure', props<{ error: any }>());

export const updateStatus = createAction('[FormsData] Update Status', props<{ data: SelectModel<number> }>());
export const updateStatusSuccess = createAction('[FormsData] Update Status Success');
export const updateStatusFailure = createAction('[FormsData] Update Status Failure', props<{ error: any }>());

export const getStatus = createAction('[FormsData] Get Status', props<{ id: number }>());
export const getStatusSuccess = createAction('[FormsData] Get Status Success', props<{ data: SelectModel<number> }>());
export const getStatusFailure = createAction('[FormsData] Get Status Failure', props<{ error: any }>());
