import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { Observable } from 'rxjs';
import { SelectModel } from '../models/select-model';
import { SupplyStatusDto } from '../models/SupplyStatuses/SupplyStatusDto';

@Injectable({
  providedIn: 'root',
})
export class SupplyStatusesApiCallerService extends BaseApiCaller {
  protected controllerPath = 'SupplyStatuses';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getSupplyStatusesForSelect(): Observable<SelectModel<string>[]> {
    return this.get<SelectModel<string>[]>('getSupplyStatusesForSelect');
  }

  public getAllSupplyStatuses(): Observable<SupplyStatusDto[]> {
    return this.get<SupplyStatusDto[]>('');
  }

}
