import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { ValidationResultDto } from '../models/validation/validation-result.dto';

@Injectable({
  providedIn: 'root',
})
export class CompanyValidatorsApiCallerService extends BaseApiCaller {
  protected controllerPath = 'CompanyValidation';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public validateNameUnique(value: string, propertyName: string, id?: string) {
    const params = this.prepareParams({ value, propertyName, id });

    return this.httpClient.get<ValidationResultDto>(this.getFullPath('property-unique'), { params });
  }
}
