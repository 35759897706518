import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CompanyValidatorsApiCallerService } from '../../../api-services/company-validators-api-caller.service';
import { IPatchValue } from '../../../interfaces/i-patch-value';
import { CompanyDto } from '../../../models/company/company.dto';
import { IndividualType } from '../../../models/enums/individual-type.enum';
import { EnumValidator } from '../../../validators/enum.validator';

function init(companyValidatorsApiCaller: CompanyValidatorsApiCallerService) {
  return {
    individualType: new UntypedFormControl(IndividualType.LegalEntity, [
      Validators.required,
      EnumValidator.createIsInEnumValidator(IndividualType),
    ]),
  };
}

export class ContractorDataForm extends UntypedFormGroup {
  constructor(companyValidatorsApiCaller: CompanyValidatorsApiCallerService) {
    super(init(companyValidatorsApiCaller));

    this.get('individualType').valueChanges.subscribe((_) => {
      const legalEntityData = this.get('name');

      this.updateValueAndValidity({ emitEvent: false });
    });
  }

  destroy() {}

  //override
  public patchValue(
    value: IPatchValue | CompanyDto,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    if (value instanceof CompanyDto) {
      super.patchValue(this.mapCompanyDto(value), options);
    } else {
      super.patchValue(value, options);
    }
  }

  private mapCompanyDto(company: CompanyDto) {
    return {
      id: company.id,
      individualType: company.individualType,
      name: company.name,
    };
  }
}
