import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import { DeliveryItemDto } from '../../shared/models/deliveryCalendar/deliveryItemDTO';

export const loadDeliveryItemsForDate = createAction('[DeliveryCalendar] Load DeliveryItems For Date',props<{date: Date, nodes: string[]}>());
export const loadDeliveryItemsForDateSuccess = createAction('[DeliveryCalendar] Load DeliveryItems For Date Success', props<{ data: DeliveryItemDto[] }>());
export const loadDeliveryItemsForDateFailure = createAction('[DeliveryCalendar] Load DeliveryItems For Date Failure', props<{ error: any }>());

export const deleteDeliveryItem = createAction('[DeliveryCalendar] delete DeliveryItem', props<{ id: string }>());
export const deleteDeliveryItemSuccess = createAction('[DeliveryCalendar] delete DeliveryItem Success', props<{ id: string }>());
export const deleteDeliveryItemFailure = createAction('[DeliveryCalendar] delete DeliveryItem Failure', props<{ error: any }>());

export const addDeliveryItem = createAction('[DeliveryCalendar] Add DeliveryItem', props<{ data: DeliveryItemDto }>());
export const addDeliveryItemSuccess = createAction('[DeliveryCalendar] Add DeliveryItem Success');
export const addDeliveryItemFailure = createAction('[DeliveryCalendar] Add DeliveryItem Failure', props<{ error: any }>());

export const updateDeliveryItem = createAction('[DeliveryCalendar] Update DeliveryItem', props<{ data: DeliveryItemDto }>());
export const updateDeliveryItemSuccess = createAction('[DeliveryCalendar] Update Emplouyee Success');
export const updateDeliveryItemFailure = createAction('[DeliveryCalendar] Update Emplouyee Failure', props<{ error: any }>());

export const getDeliveryItem = createAction('[DeliveryCalendar] Get DeliveryItem', props<{ id: string }>());
export const getDeliveryItemSuccess = createAction('[DeliveryCalendar] Get DeliveryItem Success', props<{ data: DeliveryItemDto }>());
export const getDeliveryItemFailure = createAction('[DeliveryCalendar] Get DeliveryItem Failure', props<{ error: any }>());