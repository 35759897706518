import { AddressDto } from './addressDTO';
import { ClientType } from '../enums/client-type.enum';

export class ClientDto {
  constructor(data: ClientDto) {
    if (data != null) {
      this.id = data.id;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.name = data.name;
      this.clientType = data.clientType;
      this.nip = data.nip;
      this.pesel = data.pesel;
      this.phoneNumber = data.phoneNumber;
      this.email = data.email;
      this.addresses = data.addresses;
    }
  }

  id: number;
  firstName?: string;
  lastName?: string;
  name?: string;
  clientType: ClientType;
  nip?: string;
  pesel?: string;
  phoneNumber: string;
  email: string;
  addresses: AddressDto[];
}
