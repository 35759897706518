import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { CorrespondenceType } from '../../../models/enums/correspondence-type.enum';

export class OutgoingMessageForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(undefined),
      positionNumber: new UntypedFormControl(undefined),
      clientId: new UntypedFormControl(undefined, [Validators.required]),
      clientName: new UntypedFormControl(undefined),
      receivedDate: new UntypedFormControl(new Date(), [Validators.required]),
      releaseDate: new UntypedFormControl(new Date(), [Validators.required]),
      statusId: new UntypedFormControl(undefined),
      statusName: new UntypedFormControl(undefined),
      sourceId: new UntypedFormControl(undefined, [Validators.required]),
      sourceName: new UntypedFormControl(undefined),
      departmentId: new UntypedFormControl(undefined, [Validators.required]),
      departmentName: new UntypedFormControl(undefined),
      description: new UntypedFormControl(undefined),
      employeeId: new UntypedFormControl(undefined, [Validators.required]),
      employeeName: new UntypedFormControl(undefined),
      authorId: new UntypedFormControl(undefined),
      authorName: new UntypedFormControl(undefined),
      title: new UntypedFormControl(undefined),
      type: new UntypedFormControl(CorrespondenceType.Out),
    });
  }

  forAdd() {
    return this;
  }
}
