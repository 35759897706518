import { AddressDto } from './addressDTO';

export class AuthorDto {
  constructor(data: AuthorDto) {
    if (data != null) {
      this.id = data.id;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.addresses = data.addresses;
    }
  }

  id: number;
  firstName: string;
  lastName: string;
  addresses: AddressDto[];
}
