import { Component, OnInit, ViewChild, Input, OnDestroy, forwardRef } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-phones-manager',
  templateUrl: './phones-manager.component.html',
  styleUrls: ['./phones-manager.component.scss'],
  providers: [{       
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => PhonesManagerComponent),
    multi: true     
}  ]
})
export class PhonesManagerComponent implements OnInit, ControlValueAccessor {
  
  @Input() label: string = "Choose values";
  @Input() inputLabel: string = "Add new value";
  @Input() placeholder: string;
  @Input() formControlName;
  items: string[] = [];
  value: string[];


  inputValue: string = "";

  constructor() {
    
  }

  writeValue(obj: any): void {
    this.value = obj;
    if(obj != null){
      console.log("obj received")
      this.items = [...obj];
    }
  }
  registerOnChange(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error("Method not implemented.");
  }

  ngOnInit() {
    this.validateInputs();
    //this.searchForm.setValidators(this.validateForm());
  }

  addElement() {
    if(this.inputValue != null){
      this.items.push(this.inputValue);
      this.value.push(this.inputValue);
      this.inputValue = null
    }
  }

  deleteElement(number: string) {

    this.items = this.items.filter(existingNumber => {
      if(existingNumber == number) return false;
      else return true;
    })
    
    this.value.splice(0,this.value.length)
    this.items.forEach(item => {
      this.value.push(item);
    });

  }

  private validateInputs() {
    
  }
}
