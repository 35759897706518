import { Action, createReducer, on } from '@ngrx/store';
import { loadSourcesForSelect, loadSourcesForSelectSuccess, loadSourcesForSelectFailure, loadPageableSources, loadPageableSourcesSuccess, loadPageableSourcesFailure, getSource, getSourceSuccess, getSourceFailure, } from './sources.actions';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';

export const sourcesFeatureKey = 'sources';

export interface State {
  sourcesForSelect?: SelectModel<number>[];
  pageableSources?: PageableDto<SelectModel<number>>;
  source?: SelectModel<number>; 
}

export const initialState: State = {
  
};

const usersReducer = createReducer(
  initialState,
  
  on(loadSourcesForSelect, (state) => setSourcesForSelect(state, null)),
  on(loadSourcesForSelectSuccess, (state, { data }) => setSourcesForSelect(state, data)),
  on(loadSourcesForSelectFailure, (state, action) => state),

  on(loadPageableSources, (state) => setPageableSources(state, null)),
  on(loadPageableSourcesSuccess, (state, { data }) => setPageableSources(state, data)),
  on(loadPageableSourcesFailure, (state, action) => state),

  on(getSource, (state) => setSource(state, null)),
  on(getSourceSuccess, (state, { data}) => setSource(state, data)),
  on(getSourceFailure, (state, action) => state),
);

function setSourcesForSelect(state: State, data: SelectModel<number>[]) {
  const sourcesForSelect = data;
  return {
    ...state,
    sourcesForSelect,
  };
}

function setPageableSources(state: State, data: PageableDto<SelectModel<number>>) {
  const pageableSources = data;
  return {
    ...state,
    pageableSources,
  };
}

function setSource(state: State, data: SelectModel<number>) {
  var source = data;
  return {
    ...state,
    source,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
