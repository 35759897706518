<mat-card>
  <p>
    Zmiana statusu zamówienia nr: <strong>{{ orderNumber }}</strong>
  </p>
  <div class="main">
    <button *ngFor="let option of statusesData" (click)="onChangeStatus(option)">
      <div class="item-badge" [ngStyle]="{ backgroundColor: option.color }"></div>

      <div class="item-text">{{ option.name }}</div>
    </button>
  </div>
</mat-card>
