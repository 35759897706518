import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadPageableVersionLogs,
  loadPageableVersionLogsSuccess,
  loadPageableVersionLogsFailure,
  createVersionLog,
  createVersionLogSuccess,
  createVersionLogFailure,
  loadVersionLogsSuccess,
  loadVersionLogsFailure,
  loadVersionLogs,
} from './version-log.actions';
import { Injectable } from '@angular/core';
import { concatMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { VersionApiCallerService } from '../../shared/api-services/version-api-caller.service';

@Injectable()
export class VersionLogEffects {
  createVersionLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createVersionLog),
      concatMap(({ versionLog }) => {
        return this.versionApiCaller.addVersionLog(versionLog).pipe(
          map((_) => createVersionLogSuccess({ id: versionLog.id })),
          tap(() => this.reloadPageable()),
          catchError((error) => {
            this.reloadPageable();
            return of(createVersionLogFailure({ error }));
          })
        );
      })
    )
  );

  logs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVersionLogs),
      concatMap(({ id }) => {
        return this.versionApiCaller.getVersionLogs(id).pipe(
          map((_) => loadVersionLogsSuccess({ data: _ })),
          catchError((error) => of(loadVersionLogsFailure({ error })))
        );
      })
    )
  );

  private filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private versionApiCaller: VersionApiCallerService
  ) {}

  private reloadPageable() {
    this.store$.dispatch(loadPageableVersionLogs({ filters: this.filters }));
  }
}
