import { Injectable } from '@angular/core';
import { loadNotifications, loadNotificationsSuccess, loadNotificationsFailure } from './notification.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationDto } from '../../shared/models/notification.dto';
import { of } from 'rxjs';
import { concatMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class NotificationsEffects {
  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNotifications),
      concatMap(() => {
        return of(this.notifications).pipe(
          map((_) => loadNotificationsSuccess({ data: _ })),
          catchError((error) => of(loadNotificationsFailure({ error })))
        );
      })
    )
  );

  notifications: NotificationDto[];
  constructor(private actions$: Actions) {
    this.notifications = [
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },

      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'czwarta', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'druga', redirectURL: '/page/next', type: 'warn' },
      { message: 'trzecia', redirectURL: '/page/next', type: 'warn' },
      { message: 'ostatnie', redirectURL: '/page/next', type: 'warn' },
      { message: 'pierwsze powiadomienie', redirectURL: '/page/next', type: 'warn' },
      { message: 'powiad', redirectURL: '/page/next', type: 'warn' },
      { message: 'om', redirectURL: '/page/next', type: 'warn' },
      { message: 'ienia', redirectURL: '/page/next', type: 'warn' },
    ];
  }
}
