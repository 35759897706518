import { PageableDto } from '../../shared/models/pageable.dto';
import { Action, createReducer, on } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import {
  loadPageableSettlements,
  loadPageableSettlementsSuccess,
  loadPageableSettlementsFailure,
  setPageableSettlementsFilters,
  setPageableSettlementsFiltersSuccess,
  setPageableSettlementsFiltersFailure,
} from './settlements.actions';
import { SettlementsDto } from '../../shared/models/orders/settlementsDto';
import { GetPageableSettlements } from '../../shared/models/queries/get-pageable-settlements.query';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { SettlementsHistoryDto } from '../../shared/models/orders/settlementsHistoryDto';
import { GetPageableSettlementsHistory } from '../../shared/models/queries/get-pageable-settlements-history.query';

export interface State {
  settlementsSearchModel?: SelectModel<string>[];
  pageableSettlements?: DictionaryDto<string, PageableDto<SettlementsDto>>;
  settlementsFilters?: DictionaryDto<string, GetPageableSettlements>;

  settlements?: SettlementsHistoryDto;
  pageableHistory?: DictionaryDto<string, PageableDto<SettlementsHistoryDto>>;
  historyFilters?: DictionaryDto<string, GetPageableSettlementsHistory>;
}

export const initialState: State = {
  settlementsFilters: new DictionaryDto<string, GetPageableSettlements>(),
  historyFilters: new DictionaryDto<string, GetPageableSettlementsHistory>(),
};

const settlementsReducer = createReducer(
  initialState,

  on(loadPageableSettlements, (state, { key }) => setPageableSettlements(state, null, key)),
  on(loadPageableSettlementsSuccess, (state, { data, key }) => setPageableSettlements(state, data, key)),
  on(loadPageableSettlementsFailure, (state, action) => state),

  on(setPageableSettlementsFilters, (state, { data, key }) => setPageableSettlementsFiltersFunc(state, null, key)),
  on(setPageableSettlementsFiltersSuccess, (state, { data, key }) =>
    setPageableSettlementsFiltersFunc(state, data, key)
  )
);

export function reducer(state: State | undefined, action: Action) {
  return settlementsReducer(state, action);
}

function setPageableSettlements(state: State, data: PageableDto<SettlementsDto>, key: string) {
  const pageableSettlements = Array.isArray(state.pageableSettlements)
    ? state.pageableSettlements
    : new DictionaryDto<string, PageableDto<SettlementsDto>>();
  const settlements = pageableSettlements.find((_) => _.key === key);
  if (settlements != null) {
    settlements.value = data;
  } else {
    pageableSettlements.push({ key, value: data });
  }

  return {
    ...state,
    pageableSettlements,
  };
}

function setPageableSettlementsFiltersFunc(state: State, data: GetPageableSettlements, key: string) {
  const settlementFilters = Array.isArray(state.settlementsFilters)
    ? state.settlementsFilters
    : new DictionaryDto<string, GetPageableSettlements>();
  const filter = settlementFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    settlementFilters.push({ key, value: data });
  }

  return {
    ...state,
    settlementFilters,
  };
}
