import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiCaller } from './base-api-caller';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { SelectModel } from '../models/select-model';
import { map } from 'rxjs/operators';
import { AddressDto } from '../models/addresses/addressDTO';
import { AddressSelectModel } from '../models/addressSelectModel';

@Injectable({
  providedIn: 'root',
})
export class AddressesApiCallerService extends BaseApiCaller {
  protected controllerPath = 'Addresses';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addAddress(dto: AddressDto) {
    return this.postWithReponseSimpleType<number>('', dto);
  }

  public updateAddress(dto: AddressDto) {
    return this.put('', dto);
  }

  public deleteAddress(id: string) {
    return this.delete(id.toString());
  }

  public getAddress(id: string): Observable<AddressDto> {
    return this.get<AddressDto>(id.toString()).pipe(map((_) => new AddressDto(_)));
  }

  public getAddressesForSelect(customerId?: string): Observable<AddressSelectModel<any>[]> {
    const params = this.prepareParams({customerId});
    return this.get<AddressSelectModel<any>[]>('getAddressesForSelect', { params});
  }
}
