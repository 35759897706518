<mat-dialog-content class="horizontalScrollbar">
  <mat-card-header>
    <mat-card-title>{{ 'IncomingMessages.NewAuthor' | translate }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="save()">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'Dictionaries.AuthorData' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="col-sm-12 display-grid">
            <app-input [form]="form.get('lastName')" [label]="'Common.LastName' | translate"> </app-input>
          </div>
          <br />
          <div class="col-sm-12 display-grid">
            <app-input [form]="form.get('firstName')" [label]="'Common.FirstName' | translate"> </app-input>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <mat-accordion>
        <mat-expansion-panel formGroupName="inhabitantAddress" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'Common.InhabitantAddress' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.Country' | translate }}</mat-label>
                <input matInput formControlName="country" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.City' | translate }}</mat-label>
                <input matInput formControlName="city" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.Street' | translate }}</mat-label>
                <input matInput formControlName="street" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.PostCode' | translate }}</mat-label>
                <input matInput formControlName="postCode" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.HouseNo' | translate }}</mat-label>
                <input matInput formControlName="houseNumber" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.ApartmentNo' | translate }}</mat-label>
                <input matInput formControlName="apartmentNumber" />
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel formGroupName="correspondenceAddress" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'Common.CorrespondenceAddress' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.Country' | translate }}</mat-label>
                <input matInput formControlName="country" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.City' | translate }}</mat-label>
                <input matInput formControlName="city" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.Street' | translate }}</mat-label>
                <input matInput formControlName="street" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.PostCode' | translate }}</mat-label>
                <input matInput formControlName="postCode" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.HouseNo' | translate }}</mat-label>
                <input matInput formControlName="houseNumber" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 display-grid">
              <mat-form-field class="example-full-width">
                <mat-label>{{ 'Common.ApartmentNo' | translate }}</mat-label>
                <input matInput formControlName="apartmentNumber" />
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion> -->
      <mat-card-actions>
        <div class="d-flex width-100p ml-20">
          <button type="submit" color="primary" mat-raised-button>
            {{ 'Common.Save' | translate }}
          </button>
          <button color="warn" mat-raised-button (click)="cancel()">
            {{ 'Common.Cancel' | translate }}
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-dialog-content>
