import { loadAggregates, loadAggregatesSuccess, loadAggregatesFailure } from './aggregate.actions';
import { loadAdmixtures, loadAdmixturesSuccess } from './../admixture/admixture.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { AggregateDto } from '../../shared/models/aggregate/update-aggregate-dto';

export const aggregateFeatureKey = 'aggregate';

export interface State {
  aggregates?: AggregateDto[];
}

export const initialState: State = {};

const aggregateReducer = createReducer(
  initialState,
  on(loadAggregates, (state) => setAggregates(state, null)),
  on(loadAggregatesSuccess, (state, { data }) => setAggregates(state, data)),
  on(loadAggregatesFailure, (state, action) => setAggregates(state, null))
);

function setAggregates(state: State, data: AggregateDto[]) {
  return {
    ...state,
    aggregates: data,
  };
}
export function reducer(state: State | undefined, action: Action) {
  return aggregateReducer(state, action);
}
