import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '../../../validators/custom.validator';
import { ItemNameValidator } from '../../../validators/item-name.validator';
export class ItemForCustomerForm extends UntypedFormGroup {
  constructor(private itemNameValidator: ItemNameValidator) {
    super({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required]),
      unit: new UntypedFormControl(null, [Validators.required]),
      recipeNumber: new UntypedFormControl(null),
      recipeCostId: new UntypedFormControl(null),
      itemTypeId: new UntypedFormControl(null, [Validators.required]),
      itemTypeName: new UntypedFormControl(null),
      nodeId: new UntypedFormControl(null),
      nodeName: new UntypedFormControl(null),
      unitPrice: new UntypedFormControl(null, [Validators.required, CustomValidator.isCommaSeparatedNumber]),
      isProduct: new UntypedFormControl(true),
      isService: new UntypedFormControl(false),
      recipeCost: new UntypedFormControl(null),
      isWet: new UntypedFormControl(true, [CustomValidator.isRequiredForConcrete]),
      radioButton: new UntypedFormControl('isProduct'),
      concreteTypeRadioButton: new UntypedFormControl('isWet'),
      clientId: new UntypedFormControl(null),
      productId: new UntypedFormControl(null),
      productName: new UntypedFormControl(null),
      addressId: new UntypedFormControl(null),
      addressName: new UntypedFormControl(null),
    });
  }

  forAdd() {
    this.controls["name"].setAsyncValidators(this.itemNameValidator.createValidator());
    return this;
  }

  forEdit() {
    return this;
  }
}
