export class NodeDto {
  constructor(data: NodeDto) {
    if (data != null) {
      this.id = data.id;
      this.name = data.name;
      this.nodeType = data.nodeType;
    }
  }

  id: string;
  name: string;
  nodeType: number;
}
