import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export interface SnackBarMessageData {
  message: string;
}

@Component({
  template: ` <div class="display-flex">
    <span>{{ errorMessage | translate }}</span>
    <button style="margin-left:auto;" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>`,
})
export class SnackBarMessagesComponent {
  errorMessage: string;
  constructor(
    private snackBarRef: MatSnackBarRef<SnackBarMessagesComponent>,
    @Inject(MAT_SNACK_BAR_DATA) data: SnackBarMessageData
  ) {
    if (data != null) {
      this.errorMessage = data.message;
    }
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
