<mat-card style="max-height: vh;" *ngIf="!loading; else spinner">
  <mat-card-header>
    <button class="close" mat-button (click)="onCancelAction()"><mat-icon>clear</mat-icon></button>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="content">
      <div *ngIf="form; else formless">
        <form [formGroup]="form" *ngIf="form">
          <ng-container *ngTemplateOutlet="formless"></ng-container>
        </form>
      </div>

      <ng-template #formless>
        <ng-content></ng-content>
      </ng-template>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="d-flex width-100p ml-20">
      <div class="flex-spacer"></div>

      <button
        *ngIf="showSaveButton"
        color="primary"
        mat-raised-button
        [disabled]="!form.valid"
        (click)="onConfirmAction()"
      >
        {{ 'Common.Save' | translate }}
      </button>
      <button mat-raised-button (click)="onCancelAction()">
        {{ 'Common.Cancel' | translate }}
      </button>
    </div>
  </mat-card-actions>
</mat-card>

<ng-template #spinner>
  <div class="center">
    <mat-spinner [diameter]="240"> </mat-spinner>
  </div>
</ng-template>
