import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from 'project/src/app/shared/api-services/base-api-caller';
import { map } from 'rxjs/operators';
import { IngredientDto } from '../models/ingredients/ingredient-dto';
import { UpdateIngredientDto } from '../models/ingredients/update-ingredient-dto';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableIngredientsQuery } from '../models/queries/get-pageable-ingredients.query';

@Injectable({
    providedIn: 'root',
  })
export class IngredientApiCallerService extends BaseApiCaller {
    protected controllerPath = 'ingredients';
  
    constructor(httpClient: HttpClient) {
      super(httpClient);
    }
    
  
    public addIngredient(ingredient: UpdateIngredientDto) {
        console.log(ingredient);
      return this.post('', ingredient);
    }
  
    public updateIngredient(ingredient: UpdateIngredientDto) {
      return this.put('', ingredient);
    }
    public deleteIngredient(id: string) {
        return this.delete(id);
    }
    public getIngredient(id: string) {
        return this.get<IngredientDto>(id.toString()).pipe(map((_) => new IngredientDto(_)));
    }
  
    public getPageableItems(query: GetPageableIngredientsQuery) {
        const params = this.prepareParams(query);
        return this.get<PageableDto<IngredientDto>>('pageable', { params });
    }
}