import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { ValidatorPatterns } from '../../../validators/validator-patterns';

export class AddressForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(null),
      address: new UntypedFormControl(null, [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      postalCode: new UntypedFormControl(null, [Validators.pattern(ValidatorPatterns.postalCode)]),
      phoneNumber: new UntypedFormControl(null, [Validators.pattern(ValidatorPatterns.phone)]),
      isMainAddress: new UntypedFormControl(false, [Validators.required]),
      managerName: new UntypedFormControl(null),
      customerId: new UntypedFormControl(null, [Validators.required])
    });

  }

  forAdd() {

    return this;
  }
}
