import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, withLatestFrom } from 'rxjs/operators';
import { SettlementsApiCallerService } from '../../shared/api-services/settlements-api-caller.service';
import {
  loadPageableSettlements,
  setPageableSettlementsFilters,
  setPageableSettlementsFiltersSuccess,
  setPageableSettlementsFiltersFailure,
  loadPageableSettlementsSuccess,
  loadPageableSettlementsFailure,
} from './settlements.actions';
import { selectAllPageableSettlementsFilters } from './settlements.selectors';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';

@Injectable()
export class SettlementsEffects {
  private latestedSettlementsKey: string;

  setPageableSettlementsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableSettlementsFilters),
      concatMap(({ data, key }) => {
        this.latestedSettlementsKey = key;
        return of(data).pipe(
          map((_) => setPageableSettlementsFiltersSuccess({ data, key })),
          finalize(() => this.reloadSettlementsCatalog(key)),
          catchError((error) => of(setPageableSettlementsFiltersFailure({ error })))
        );
      })
    )
  );

  loadPageableSettlements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableSettlements),
      withLatestFrom(this.store$.select(selectAllPageableSettlementsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedSettlementsKey = key;
        return this.settlementsApiCaller.getPageableSettlements(filter?.value).pipe(
          map((_) => loadPageableSettlementsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableSettlementsFailure({ error })))
        );
      })
    )
  );

  private reloadSettlementsCatalog(key: string) {
    this.store$.dispatch(loadPageableSettlements({ key }));
  }

  constructor(
    private actions$: Actions,
    private settlementsApiCaller: SettlementsApiCallerService,
    private store$: Store<StoreState>
  ) {}
}
