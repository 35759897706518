import { GetPageableOrdersCatalog } from './../../shared/models/queries/get-pageable-orders-catalog.query';
import { Action, createReducer, on } from '@ngrx/store';
import { CustomerOrderHistory } from '../../shared/models/customerOrderHistoryModel';
import { CommentsOfDayDto } from '../../shared/models/orders/commentsOfDayDto';
import { OrderDto } from '../../shared/models/orders/order.dto';
import { OrderForDeliveryStatusesDto } from '../../shared/models/orders/orderForDeliveryStatuses.Dto';
import { OrderGroupedByHourHour } from '../../shared/models/orders/ordersGroupedByHour';

import {
  addCommentOfDay,
  addCommentOfDayFailure,
  addCommentOfDaySuccess,
  addCopyOrder,
  addCopyOrderFailure,
  addCopyOrderSuccess,
  addOrder,
  addOrderFailure,
  addOrderSuccess,
  loadAllCommentsOfDay,
  loadAllCommentsOfDayFailure,
  loadAllCommentsOfDaySuccess,
  loadAllOrderByDate,
  loadAllOrderByDateFailure,
  loadAllOrderByDateSuccess,
  loadAllOrderByHourRange,
  loadAllOrderByHourRangeFailure,
  loadAllOrderByHourRangeSuccess,
  loadCustomerOrderHistory,
  loadCustomerOrderHistoryFailure,
  loadCustomerOrderHistorySuccess,
  loadInProgrgessOrderByDate,
  loadInProgrgessOrderByDateFailure,
  loadInProgrgessOrderByDateSuccess,
  loadOrder,
  loadOrderByDate,
  loadOrderByDateFailure,
  loadOrderByDateSuccess,
  loadOrderFailure,
  loadOrders,
  loadOrdersCatalog,
  loadOrdersCatalogFailure,
  loadOrdersCatalogSuccess,
  loadOrdersFailure,
  loadOrdersSuccess,
  loadOrderSuccess,
  loadPageableOrderEditHistory,
  loadPageableOrderEditHistorySuccess,
  loadPageableOrderEditHistoryFailure,
  loadPageableOrdersCatalog,
  loadPageableOrdersCatalogFailure,
  loadPageableOrdersCatalogSuccess,
  setPageableOrdersCatalogFilters,
  setPageableOrdersCatalogFiltersSuccess,
  updateOrder,
  updateOrderDate,
  updateOrderDateFailure,
  updateOrderDateSuccess,
  updateOrderFailure,
  updateOrderSingalr,
  updateOrderState,
  updateOrderStateFailure,
  updateOrderStateSuccess,
  updateOrderSuccess,
  setPageableOrderEditHistoryFilters,
  setPageableOrderEditHistoryFiltersSuccess
} from './order.actions';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { OrderStatusesForHour } from '../../shared/models/orders/ordersForHourRange';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableSettlements } from '../../shared/models/queries/get-pageable-settlements.query';
import { GetPageableOrderEditHistoryQuery } from '../../shared/models/queries/get-pageable-order-edit-history.query';
import { OrderEditHistoryModel } from '../../shared/api-services/api-services';

export const customerFeatureKey = 'orders';

export interface State {
  orders?: OrderDto[];
  order?: OrderDto;
  ordersByDate?: OrderDto[];
  Getorder?: OrderDto;
  OrderDate?: OrderDto;
  inProggrssOrdersByDate?: OrderDto[];

  allOrdersByDate?: OrderForDeliveryStatusesDto[];

  allOrdersByHourRange?: OrderGroupedByHourHour[];

  allComentsOfDay?: CommentsOfDayDto[];

  addCommentOfDay?: CommentsOfDayDto;
  customerOrderHistory?: CustomerOrderHistory[];

  pageableOrdersCatalog?: DictionaryDto<string, PageableDto<OrderStatusesForHour>>;
  ordersCatalogFilters?: DictionaryDto<string, GetPageableOrdersCatalog>;
  settlementsFilters?: DictionaryDto<string, GetPageableSettlements>;

  ordersCatalog?: OrderStatusesForHour[];
  pageableEditHistory?: PageableDto<OrderEditHistoryModel>;
  pageableEditHistoryFilters?: DictionaryDto<string, GetPageableOrderEditHistoryQuery>;
}

export const initialState: State = {
  ordersCatalogFilters: new DictionaryDto<string, GetPageableOrdersCatalog>(),
};

const ordersReducer = createReducer(
  initialState,

  on(loadOrders, (state) => state),
  on(loadOrdersSuccess, (state, { data }) => setOrders(state, data)),
  on(loadOrdersFailure, (state) => setOrders(state, null)),

  on(addOrder, (state) => setOrder(state, null)),
  on(addOrderSuccess, (state) => setOrder(state, null)),
  on(addOrderFailure, (state) => setOrder(state, null)),

  on(loadOrderByDate, (state) => state),
  on(loadOrderByDateSuccess, (state, { data }) => setOrderByDate(state, data)),
  on(loadOrderByDateFailure, (state) => setOrderByDate(state, null)),

  on(loadOrder, (state) => state),
  on(loadOrderSuccess, (state, { data }) => setOrder(state, data)),
  on(loadOrderFailure, (state) => setOrder(state, null)),

  on(updateOrderDate, (state) => setOrderDate(state, null)),
  on(updateOrderDateSuccess, (state) => setOrderDate(state, null)),
  on(updateOrderDateFailure, (state) => setOrderDate(state, null)),

  on(updateOrder, (state) => setOrder(state, null)),
  on(updateOrderSuccess, (state) => setOrder(state, null)),
  on(updateOrderFailure, (state) => setOrder(state, null)),

  on(addCopyOrder, (state) => setOrder(state, null)),
  on(addCopyOrderSuccess, (state) => setOrder(state, null)),
  on(addCopyOrderFailure, (state) => setOrder(state, null)),

  on(updateOrderState, (state) => setOrder(state, null)),
  on(updateOrderStateSuccess, (state) => setOrder(state, null)),
  on(updateOrderStateFailure, (state) => setOrder(state, null)),

  on(loadInProgrgessOrderByDate, (state) => state),
  on(loadInProgrgessOrderByDateSuccess, (state, { data }) => setInProggressOrderByDate(state, data)),
  on(loadInProgrgessOrderByDateFailure, (state) => setInProggressOrderByDate(state, null)),

  on(loadAllOrderByDate, (state) => state),
  on(loadAllOrderByDateSuccess, (state, { data }) => setAllOrderByDate(state, data)),
  on(loadAllOrderByDateFailure, (state) => setAllOrderByDate(state, null)),

  on(loadAllOrderByHourRange, (state) => state),
  on(loadAllOrderByHourRangeSuccess, (state, { data }) => setAllOrderByHourRange(state, data)),
  on(loadAllOrderByHourRangeFailure, (state) => setAllOrderByHourRange(state, null)),

  on(loadAllCommentsOfDay, (state) => state),
  on(loadAllCommentsOfDaySuccess, (state, { data }) => setAllCommentsOfDay(state, data)),
  on(loadAllCommentsOfDayFailure, (state) => setAllCommentsOfDay(state, null)),

  on(addCommentOfDay, (state) => state),
  on(addCommentOfDaySuccess, (state, { data }) => setAddCommentOfDay(state, data)),
  on(addCommentOfDayFailure, (state) => setAddCommentOfDay(state, null)),

  on(loadCustomerOrderHistory, (state) => state),
  on(loadCustomerOrderHistorySuccess, (state, { data }) => setCustomerOrderHistoery(state, data)),
  on(loadCustomerOrderHistoryFailure, (state) => setCustomerOrderHistoery(state, null)),

  on(loadPageableOrdersCatalog, (state, { key }) => setPageableOrdersCatalog(state, null, key)),
  on(loadPageableOrdersCatalogSuccess, (state, { data, key }) => setPageableOrdersCatalog(state, data, key)),
  on(loadPageableOrdersCatalogFailure, (state, action) => state),

  on(setPageableOrdersCatalogFilters, (state, { data, key }) => setPageableOrderdCatalogFiltersFunc(state, null, key)),
  on(setPageableOrdersCatalogFiltersSuccess, (state, { data, key }) =>
    setPageableOrderdCatalogFiltersFunc(state, data, key)
  ),

  on(loadOrdersCatalog, (state) => state),
  on(loadOrdersCatalogSuccess, (state, { data }) => setOrdersCatalog(state, data)),
  on(loadOrdersCatalogFailure, (state) => setOrdersCatalog(state, null)),

  on(updateOrderSingalr, (state, { data }) => updateOrderSignalr(state, data)),


  on(loadPageableOrderEditHistory, (state) => setPageableOrderEditHistory(state, null)),
  on(loadPageableOrderEditHistorySuccess, (state, { data }) => setPageableOrderEditHistory(state, data)),
  on(loadPageableOrderEditHistoryFailure, (state, _) => setPageableOrderEditHistory(state, null)),

  on(setPageableOrderEditHistoryFilters, (state, { data, key }) => setPageableOrderEditHistoryFunc(state, null, key)),
  on(setPageableOrderEditHistoryFiltersSuccess, (state, { data, key }) => setPageableOrderEditHistoryFunc(state, data, key)),

);

function setOrders(state: State, data: OrderDto[]) {
  return {
    ...state,
    orders: data,
  };
}

function setOrder(state: State, data: OrderDto) {
  return {
    ...state,
    Getorder: data,
  };
}

function setOrderByDate(state: State, data: OrderDto[]) {
  return {
    ...state,
    ordersByDate: data,
  };
}

function setOrderDate(state: State, data: OrderDto) {
  return {
    ...state,
    OrderDate: data,
  };
}

function setInProggressOrderByDate(state: State, data: OrderDto[]) {
  return {
    ...state,
    inProggrssOrdersByDate: data,
  };
}

function setAllOrderByDate(state: State, data: OrderForDeliveryStatusesDto[]) {
  return {
    ...state,
    allOrdersByDate: data,
  };
}

function setAllOrderByHourRange(state: State, data: OrderGroupedByHourHour[]) {
  return {
    ...state,
    allOrdersByHourRange: data,
  };
}

function setAllCommentsOfDay(state: State, data: CommentsOfDayDto[]) {
  return {
    ...state,
    allComentsOfDay: data,
  };
}

function setAddCommentOfDay(state: State, data: CommentsOfDayDto) {
  return {
    ...state,
    addCommentOfDay: data,
  };
}

function setCustomerOrderHistoery(state: State, data: CustomerOrderHistory[]) {
  return {
    ...state,
    customerOrderHistory: data,
  };
}

function setPageableOrdersCatalog(state: State, data: PageableDto<OrderStatusesForHour>, key: string) {
  const pageableOrdersCatalog = Array.isArray(state.pageableOrdersCatalog)
    ? state.pageableOrdersCatalog
    : new DictionaryDto<string, PageableDto<OrderStatusesForHour>>();
  const orders = pageableOrdersCatalog.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableOrdersCatalog.push({ key, value: data });
  }

  return {
    ...state,
    pageableOrdersCatalog,
  };
}

function setPageableOrderdCatalogFiltersFunc(state: State, data: GetPageableOrdersCatalog, key: string) {
  const ordersCatalogFilters = Array.isArray(state.ordersCatalogFilters)
    ? state.ordersCatalogFilters
    : new DictionaryDto<string, GetPageableOrdersCatalog>();
  const filter = ordersCatalogFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    ordersCatalogFilters.push({ key, value: data });
  }

  return {
    ...state,
    ordersCatalogFilters,
  };
}

function setOrdersCatalog(state: State, data: OrderStatusesForHour[]) {
  return {
    ...state,
    ordersCatalog: data,
  };
}

function updateOrderSignalr(state: State, data: OrderDto) {
  const newOrdersByDate = state.ordersByDate.map((order) => {
    if (order.id === data.id) {
      return { ...order, ...data };
    } else {
      return order;
    }
  });
  return { ...state, ordersByDate: newOrdersByDate };
}

function setPageableOrderEditHistory(state, pageableEditHistory: PageableDto<OrderEditHistoryModel>): State {
  return { ...state, pageableEditHistory };
}

function setPageableOrderEditHistoryFunc(state: State, data: GetPageableOrderEditHistoryQuery, key: string) {
  const pageableEditHistoryFilters = Array.isArray(state.pageableEditHistoryFilters)
    ? state.pageableEditHistoryFilters
    : new DictionaryDto<string, GetPageableOrderEditHistoryQuery>();
  const filter = pageableEditHistoryFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    pageableEditHistoryFilters.push({ key, value: data });
  }

  return {
    ...state,
    pageableEditHistoryFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return ordersReducer(state, action);
}
