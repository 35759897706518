import { Action, createReducer, on } from '@ngrx/store';
import { CompanyDto } from '../../shared/models/company/company.dto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { SelectModel } from '../../shared/models/select-model';
import {
  loadContractor,
  loadContractorFailure,
  loadContractorsSearchModel,
  loadContractorsSearchModelFailure,
  loadContractorsSearchModelSuccess,
  loadContractorSuccess,
  loadPageableContractors,
  loadPageableContractorsFailure,
  loadPageableContractorsSuccess,
} from './contractor.actions';

export const contractorFeatureKey = 'contractor';

export interface State {
  contractor?: CompanyDto;
  contractorsSearchModel?: SelectModel<string>[];
  pageableContractors?: PageableDto<CompanyDto>;
}

export const initialState: State = {};

const contractorReducer = createReducer(
  initialState,

  on(loadContractor, (state) => setContractor(state, null)),
  on(loadContractorSuccess, (state, { data }) => setContractor(state, data)),
  on(loadContractorFailure, (state, action) => setContractor(state, null)),

  on(loadPageableContractors, (state) => setPageableContractors(state, null)),
  on(loadPageableContractorsSuccess, (state, { data }) => setPageableContractors(state, data)),
  on(loadPageableContractorsFailure, (state, action) => setPageableContractors(state, null)),

  on(loadContractorsSearchModel, (state) => setContractorsSearchModel(state, null)),
  on(loadContractorsSearchModelSuccess, (state, { data }) => setContractorsSearchModel(state, data)),
  on(loadContractorsSearchModelFailure, (state, action) => setContractorsSearchModel(state, null))
);

function setContractor(state: State, contractor: CompanyDto): State {
  return {
    ...state,
    contractor,
  };
}

function setPageableContractors(state: State, pageableContractors: PageableDto<CompanyDto>): State {
  return {
    ...state,
    pageableContractors,
  };
}

function setContractorsSearchModel(state: State, contractorsSearchModel: SelectModel<string>[]): State {
  return {
    ...state,
    contractorsSearchModel,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return contractorReducer(state, action);
}
