import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { TransactionDto } from '../models/transactions/transaction.dto';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';

@Injectable({
  providedIn: 'root',
})
export class TransactionApiCallerService extends BaseApiCaller {
  protected controllerPath = 'transaction';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  loadUnhandledTransactionsForAttachment(attachmentId: string) {
    return this.get<TransactionDto[]>(attachmentId);
  }

  handleTransaction(data) {
    return this.post('handleTransaction', data);
  }

  getPageableTransactions(filter: GetPageableQuery) {
    const params = this.prepareParams(filter);

    return this.get<PageableDto<TransactionDto>>('', { params });
  }

  loadUnhandledTransactionsForClient(clientName: string) {
    return this.get<TransactionDto[]>(`unhandledForClient/${clientName}`);
  }

  sendCsvFile(data: FormData) {
    return this.post('sendCsvFile', data);
  }
}
