import { Component, OnInit } from '@angular/core';
import { AppRole } from '../shared/models/enums/app-role.enum';
import { NavItem } from '../shared/widgets/menu-list-item/nav-item';
import { MainMenuItens } from './main-menu-items';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
})
export class MainMenuComponent implements OnInit {
  appRole = AppRole;
  showNewOrder = false;
  items$: Observable<NavItem[]>;

  constructor(menuItems: MainMenuItens) {
    this.items$ = menuItems.getNavItems();
  }

  ngOnInit(): void {}

  // checkIsInRole(...val: AppRole[]): boolean {
  //   return this.roleGuard.isInRoleActivate(val);
  // }

  // checkIsInRoleStrict(...val: AppRole[]): boolean {
  //   return this.roleGuard.isInRoleActivate(val, { strict: true });
  // }
}
