import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { Guid } from 'guid-typescript';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { loadOrderAttachmentsInfo } from 'project/src/app/root-store/operations/operation.actions';
import { selectOrderAttachmentsInfo } from 'project/src/app/root-store/operations/operations-selectors';
import { StoreState } from 'project/src/app/root-store/store-state';
import { throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { HttpResponseHelper } from '../../helpers/http-response.helper';
import { FileInfoDto } from '../../models/file-info.dto';
import { fileDuplicateFound } from '../../services/snack-bar-messaged';
import { SnackBarWrapperService } from '../../services/snack-bar-wrapper.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FileStatus } from './file-status';
import { IFilesManagerActions } from './files-manager.actions';

@UntilDestroy()
@Component({
  selector: 'app-files-manager',
  templateUrl: './files-manager.component.html',
  styleUrls: ['./../files-upload/files-upload.component.scss'],
})
export class FilesManagerComponent implements OnInit, OnDestroy {
  @Input() actions: IFilesManagerActions;
  dragOverStatus: 'none' | 'drag-over' | 'drop' = 'none';

  constructor(
    private _matSnack: SnackBarWrapperService,
    private store$: Store<StoreState>,
    private dialog: MatDialog
  ) {}

  files: FileStatus<any>[] = [];

  serverFiles: FileInfoDto[] = [];

  ngOnInit() {
    this.actions.files
      .pipe(
        untilDestroyed(this),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        this.serverFiles = data.map((_) => new FileInfoDto(_));
      });
  }

  ngOnDestroy(): void {}

  download(id: string) {
    this.actions.download(id).subscribe((response: HttpResponse<Blob>) => {
      const fileName = HttpResponseHelper.getFileName(response);
      saveAs(response.body, fileName);
    });
  }

  save(index: number) {
    const fileStatus = this.files[index];

    this.actions
      .upload(fileStatus)
      .pipe(
        catchError((err, caught) => {
          fileStatus.error = true;

          return throwError(err);
        })
      )
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          fileStatus.progress = Math.round((100 * event.loaded) / event.total);
        } else if (event.type === HttpEventType.Response) {
          if (event.ok) {
            fileStatus.finished = true;
          } else {
            fileStatus.error = true;
          }
        }
      });
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.addFiles(event.dataTransfer.files);

    this.dragOverStatus = 'drop';
  }

  addFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);

      if (
        this.files.find(
          (_) => _.file.lastModified === file.lastModified && _.file.name === file.name && _.file.size === file.size
        ) == null
      ) {
        const index =
          this.files.push(new FileStatus(file, this.actions.generateKey ? this.actions.generateKey() : undefined)) - 1;
        this.save(index);
      } else {
        this._matSnack.openMessage(fileDuplicateFound, 'error');
      }
    }
  }

  removeFile(id: string) {
    const fileStatus = this.files.find((_) => _.id === id);
    const name = fileStatus.file != null ? fileStatus.file.name : 'Common.File';

    this.openDeleteDialog(name, () => {
      this.removeLocal(id);

      this.actions.delete(fileStatus.id).subscribe(() => {
        this.removeLocal(id);
      });
    });

    // const dialogRef = this.dialog.open(DeleteDialogComponent, {
    //   minWidth: '350px',
    //   disableClose: false,
    //   closeOnNavigation: true,
    //   data: { name, useTranslation: fileStatus.file != null },
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result === true) {
    //     this.removeLocal(id);

    //     this.actions.delete(fileStatus.id).subscribe(() => {
    //       this.removeLocal(id);
    //     });
    //   }
    // });
  }

  removeServer(id: string) {
    const toDelete = this.serverFiles.find((_) => _.id === id);
    const name = toDelete != null ? toDelete.fileName : 'Common.File';

    this.openDeleteDialog(name, () => {
      toDelete.isDeleting = true;

      this.actions.delete(toDelete.id).subscribe(() => {
        this.serverFiles = this.serverFiles.filter((_) => _.id != id);
      });
    });
  }

  removeLocal(id: string) {
    this.files = this.files.filter((_) => _.id !== id);
  }

  onDragOver(event) {
    this.dragOverStatus = 'drag-over';
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave(event) {
    this.dragOverStatus = 'none';
  }

  private openDeleteDialog(name: string, callback: () => void) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      minWidth: '350px',
      disableClose: false,
      closeOnNavigation: true,
      data: { name },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        callback();
      }
    });
  }
}
