import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BaseApiCaller } from './base-api-caller';
import { FileUploadDto } from '../models/correspondence/FileUploadDto';
import { GetPageableDocumentsQuery } from '../models/queries/get-pageable-documents.query';
import { DocumentDto } from '../models/documents/documentDTO';
import { PageableDto } from '../models/pageable.dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentsApiCallerService extends BaseApiCaller {
  protected controllerPath = 'documents';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getDocument(id: number) {
    return this.httpClient.get(this.getFullPath(id.toString()), { responseType: 'blob', observe: 'response' });
  }

  public addDocument(incomingMessageId: number, file: FileUploadDto) {
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('documentTypeId', file.type.toString());
    formData.append('CorrespondenceId', incomingMessageId.toString());
    //formData.append('id', id);

    const uploadReq = new HttpRequest('POST', this.getFullPath(''), formData, {
      reportProgress: true,
    });

    return this.httpClient.request(uploadReq);
  }

  public getPageableDocuments(query: GetPageableDocumentsQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<DocumentDto>>('', { params });
  }

  public deleteDocument(id) {
    return super.delete(id);
  }
}
