import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { OfferModel, SendOfferPdfByMailCommand } from './api-services';

@Injectable({
  providedIn: 'root',
})
export class OffersApiCallerService extends BaseApiCaller {
  protected controllerPath = 'offers';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public sendPdfByMail(command: SendOfferPdfByMailCommand) {
    return this.post('sendpdf', command);
  }

  public getPageable(query: GetPageableQuery) {
    const params = this.prepareParams(query);

    return this.get<PageableDto<OfferModel>>('pageable', { params });
  }
}
