<mat-dialog-content class="horizontalScrollbar">
  <mat-card-header>
    <mat-card-title>{{ 'IncomingMessages.NewDepartment' | translate }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="save()">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'Dictionaries.DepartmentData' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="col-sm-12 display-grid">
            <app-input [form]="form.get('name')" [label]="'Common.Name' | translate"> </app-input>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    
      <mat-card-actions>
        <div class="d-flex width-100p ml-20">
          <button type="submit" color="primary" mat-raised-button>
            {{ 'Common.Save' | translate }}
          </button>
          <button color="warn" mat-raised-button (click)="cancel()">
            {{ 'Common.Cancel' | translate }}
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-dialog-content>
