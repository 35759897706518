export class DepartmentDto {
  constructor(data: DepartmentDto) {
    if (data != null) {
      this.id = data.id;
      this.name = data.name;
    }
  }

  id: number;
  name: string;
}
