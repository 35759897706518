import { Injectable, ViewChild } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  addCustomer,
  addCustomerAlias,
  addCustomerAliasFailure,
  addCustomerAliasSuccess,
  addCustomerFailure,
  addCustomerSuccess,
  getCustomer,
  getCustomerDataByNip,
  getCustomerDataByNipFailure,
  getCustomerDataByNipSuccess,
  getCustomerFailure,
  getCustomerSuccess,
  loadCustomer,
  loadCustomerFailure,
  loadCustomerSuccess,
  loadPageableCustomers,
  loadPageableCustomersFailure,
  loadPageableCustomersSuccess,
  setPageableCustomersFilters,
  setPageableCustomersFiltersFailure,
  setPageableCustomersFiltersSuccess,
  updateCustomer,
  updateCustomerFailure,
  updateCustomerSuccess,
} from './customer.actions';

import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { SnackBarWrapperService } from '../../shared/services/snack-bar-wrapper.service';

import { DocumentsApiCallerService } from '../../shared/api-services/documents-api-caller.service';
import { AttachmentProgressDto } from '../../shared/models/attachment/attachment-progress.dto';
import { HttpEventType } from '@angular/common/http';
import { UploadStatus } from '../../shared/models/enums/upload-status.enum';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';
import { CustomerApiCallerService } from '../../shared/api-services/customer/customer-api-caller.service';
import { selectAllPageableCustomersFilters } from './customer.selectors';
import { loadPageableTransactions } from '../transactions/transactions.actions';
import { PageableQuery } from '../../shared/models/contracts/pageable-query';

@Injectable()
export class CustomerEffects {
  private latestedCustomersKey: string;
  customer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomer),
      concatMap(() => {
        return this.customerApiCaller.getCustomerAll().pipe(
          map((_) => loadCustomerSuccess({ data: _ })),
          catchError((error) => of(loadCustomerFailure({ error })))
        );
      })
    )
  );

  setPageableCustomersFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableCustomersFilters),
      concatMap(({ data, key }) => {
        this.latestedCustomersKey = key;
        return of(data).pipe(
          map((_) => setPageableCustomersFiltersSuccess({ data, key })),
          finalize(() => this.reloadCustomers(key)),
          catchError((error) => of(setPageableCustomersFiltersFailure({ error })))
        );
      })
    )
  );

  loadPageableCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableCustomers),
      withLatestFrom(this.store$.select(selectAllPageableCustomersFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedCustomersKey = key;

        return this.customerApiCaller.getPageableCustomers(filter.value).pipe(
          map((_) => loadPageableCustomersSuccess({ data: _, key })),
          catchError((error) => of(loadPageableCustomersFailure({ error })))
        );
      })
    )
  );

  getCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCustomer),
      concatMap(({ id }) => {
        return this.customerApiCaller.getCustomer(id).pipe(
          map((_) => getCustomerSuccess({ data: _ })),
          catchError((error) => of(getCustomerFailure({ error })))
        );
      })
    )
  );

  getCustomerDataByNip$ = createEffect(() =>
  this.actions$.pipe(
    ofType(getCustomerDataByNip),
    concatMap(({ nip }) => {
      return this.customerApiCaller.getCustomerDataByNip(nip).pipe(
        map((_) => getCustomerDataByNipSuccess({ data: _ })),
        catchError((error) => of(getCustomerDataByNipFailure({ error })))
      );
    })
  )
);

  addCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCustomer),
      concatMap(({ data }) => {
        let lastValue;
        return this.customerApiCaller.addCustomer(data).pipe(
          map((_) => addCustomerSuccess({ data: _ })),
          tap((_) => {
            lastValue = _;
          }),
          finalize(() => {
            let customerId = lastValue.data;
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(addCustomerFailure({ error })))
        );
      })
    )
  );

  updateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomer),
      concatMap(({ data }) => {
        let lastValue;
        return this.customerApiCaller.updateCustomer(data).pipe(
          map((_) => updateCustomerSuccess()),
          tap((_) => (lastValue = _)),
          finalize(() => {
            let customerId = data.id;
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(updateCustomerFailure({ error })))
        );
      })
    )
  );

  createCustomerAlias$ = createEffect(() =>
  this.actions$.pipe(
    ofType(addCustomerAlias),
    concatMap(({ data }) => {
      return this.customerApiCaller.addCustomerAlias(data).pipe(
        map((_) => addCustomerAliasSuccess({ id: data.id })),
        tap(() => {
          this.store$.dispatch(
            loadPageableTransactions({filters: new GetPageableQuery(new PageableQuery())})
          );
        }),
        catchError((error) => {
          return of(addCustomerAliasFailure({ error }));
        }),
      );
    })
  )
);

  private reloadCustomers(key: string) {
    this.store$.dispatch(loadPageableCustomers({ key }));
  }

  constructor(
    private actions$: Actions,
    private customerApiCaller: CustomerApiCallerService,
    private store$: Store<StoreState>,

    private overlayService: OverlayWrapperService,
    private router: Router
  ) {}

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'customers', 'catalog']);
  }
}
