export const customerFeatureKey = 'Ingredients';
export const IngredientsFeatureKey = 'Ingredients';



export interface State {
    Ingredients?: IngredientDto[];
}

import { PageableDto } from '../../shared/models/pageable.dto';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { SelectModel } from '../../shared/models/select-model';
import { Action, createReducer, on } from '@ngrx/store';
import { IngredientDto } from '../../shared/models/ingredients/ingredient-dto';
import { addIngredient, addIngredientSuccess, addIngredientFailure, loadPageableIngredients, loadPageableIngredientsSuccess, loadPageableIngredientsFailure, getIngredient, getIngredientSuccess, getIngredientFailure, setPageableIngredientsFilters, setPageableIngredientsFiltersSuccess } from './ingredietns.actions';
import { GetPageableIngredientsQuery } from '../../shared/models/queries/get-pageable-ingredients.query';



export interface State {
  pageableIngredients?: DictionaryDto<string, PageableDto<IngredientDto>>;
  IngredientsFilters?: DictionaryDto<string, GetPageableIngredientsQuery>;
  Ingredient?: IngredientDto;
  carTypesForSelect?: SelectModel<string>[];
}

export const initialState: State = {
  IngredientsFilters: new DictionaryDto<string, GetPageableIngredientsQuery>(),
};

const ingredientsReducer = createReducer(
  initialState,

  on(addIngredient, (state) => setAddIngredient (state, null)),
  on(addIngredientSuccess, (state) => setAddIngredient(state, null)),
  on(addIngredientFailure, (state) => setAddIngredient(state, null)),

  on(loadPageableIngredients, (state, { key }) => setPageableIngredients(state, null, key)),
  on(loadPageableIngredientsSuccess, (state, { data, key }) => setPageableIngredients(state, data, key)),
  on(loadPageableIngredientsFailure, (state, action) => state),

  on(getIngredient, (state) => setIngredient(state, null)),
  on(getIngredientSuccess, (state, { data}) => setIngredient(state, data)),
  on(getIngredientFailure, (state, action) => state),

  on(setPageableIngredientsFilters, (state, { data, key }) => setPageableIngredientsFiltersFunc(state, null, key)),
  on(setPageableIngredientsFiltersSuccess, (state, { data, key }) => setPageableIngredientsFiltersFunc(state, data, key)),

);

function setPageableIngredients(state: State, data: PageableDto<IngredientDto>, key: string) {
  const pageableIngredients = Array.isArray(state.pageableIngredients)
    ? state.pageableIngredients
    : new DictionaryDto<string, PageableDto<IngredientDto>>();
  const orders = pageableIngredients.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableIngredients.push({ key, value: data });
  }

  return {
    ...state,
    pageableIngredients,
  };
}

function setIngredient(state: State, data: IngredientDto) {
  var Ingredient = data;
  return {
    ...state,
    Ingredient,
  };
}

function setAddIngredient(state: State, attachmentsProgress: IngredientDto[]) {
  return {
    ...state,
    attachmentsProgress,
  };
}

function setPageableIngredientsFiltersFunc(state: State, data: GetPageableIngredientsQuery, key: string) {
  const carsFilters = Array.isArray(state.IngredientsFilters)
    ? state.IngredientsFilters
    : new DictionaryDto<string, GetPageableIngredientsQuery>();
  const filter = carsFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    carsFilters.push({ key, value: data });
  }

  return {
    ...state,
    carsFilters,
  };
}


export function reducer(state: State | undefined, action: Action) {
  return ingredientsReducer(state, action);
}
