import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IngredientTypeDto } from '../models/ingredient-types/ingredient-type-dto';
import { BaseApiCaller } from './base-api-caller';

@Injectable({
    providedIn: 'root',
  })
export class IngredientTypeApiCallerService extends BaseApiCaller {
    protected controllerPath = 'ingredientTypes';
  
    constructor(httpClient: HttpClient) {
      super(httpClient);
    }
    
  
    // public addIngredientType(ingredient: UpdateIngredientDto) {
    //     console.log(ingredient);
    //   return this.post('', ingredient);
    // }
  
    // public updateIngredientType(ingredient: UpdateIngredientDto) {
    //   return this.put('', ingredient);
    // }
    // public deleteIngredientType(id: string) {
    //     return this.delete(id);
    // }
    public getIngredientTypes() {
      return this.get<IngredientTypeDto[]>('');
    }

    public getIngredientTypesForNode(nodeId: string) {
        let params = new HttpParams();
        params = params.set("NodeId", nodeId);
        return this.get<IngredientTypeDto[]>('for-node', { params });
    }
}