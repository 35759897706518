import { Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, tap, withLatestFrom } from 'rxjs/operators';
import { IngredientApiCallerService } from '../../shared/api-services/ingredients-api-caller.service';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';
import { StoreState } from '../store-state';
import { selectAllPageableIngredientsFilters } from './ingredients.selector';
import { addIngredient, addIngredientFailure, addIngredientSuccess, deleteIngredient, deleteIngredientFailure, deleteIngredientSuccess, getIngredient, getIngredientFailure, getIngredientSuccess, loadPageableIngredients, loadPageableIngredientsFailure, loadPageableIngredientsSuccess, setPageableIngredientsFilters, setPageableIngredientsFiltersFailure, setPageableIngredientsFiltersSuccess, updateIngredient, updateIngredientFailure, updateIngredientSuccess } from './ingredietns.actions';

@Injectable()
export class IngredientsEffects {
  @ViewChild('spinner', {static:true}) spinner;

  private latestedIngredientsKey: string;


  deleteIngredients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteIngredient),
      concatMap(({ id }) => {
        return this.ingredientsApiCaller.deleteIngredient(id).pipe(
          map((_) => deleteIngredientSuccess({ id })),
          tap(() => this.reloadIngredients(this.latestedIngredientsKey)),
          catchError((error) => of(deleteIngredientFailure({ error })))
        );
      })
    )
  );

  addIngredient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addIngredient),
      concatMap(({ data }) => {
        let lastValue;
        return this.ingredientsApiCaller.addIngredient(data).pipe(
          map((_) => addIngredientSuccess({data: _})),
          tap((_) => lastValue = _),
          finalize(() => {
            let carId = lastValue.data;
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(addIngredientFailure({ error })))
        );
      })
    )
  );

  updateIngredient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateIngredient),
      concatMap(({ data }) => {
        let lastValue;
        return this.ingredientsApiCaller.updateIngredient(data).pipe(
          map((_) => updateIngredientSuccess()),
          tap((_) => lastValue = _),
          finalize(() => {
            let carId = lastValue.data;
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(updateIngredientFailure({ error })))
        );
      })
    )
  );

  loadPageableIngredients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableIngredients),
      withLatestFrom(this.store$.select(selectAllPageableIngredientsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedIngredientsKey = key;

        return this.ingredientsApiCaller.getPageableItems(filter.value).pipe(
          map((_) => loadPageableIngredientsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableIngredientsFailure({ error })))
        );
      })
    )
  );

  getIngredient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIngredient),
      concatMap(({id}) => {
        return this.ingredientsApiCaller.getIngredient(id).pipe(
          map((_) => getIngredientSuccess({ data: _ })),
          catchError((error) => of(getIngredientFailure({ error })))
        );
      })
    )
  );

  setPageableIngredientsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableIngredientsFilters),
      concatMap(({ data, key }) => {
        this.latestedIngredientsKey = key;
        return of(data).pipe(
          map((_) => setPageableIngredientsFiltersSuccess({ data, key })),
          finalize(() => this.reloadIngredients(key)),
          catchError((error) => of(setPageableIngredientsFiltersFailure({ error })))
        );
      })
    )
  );

  private reloadIngredients(key: string) {
    this.store$.dispatch(loadPageableIngredients({ key }));
  }
 
  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private ingredientsApiCaller: IngredientApiCallerService,
    private router: Router,
    private overlayService: OverlayWrapperService
  ) { }

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'ingredients', 'catalog']);
  }
}
