import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CompanyEmployeeApiCallerService } from '../../shared/api-services/company-employee-api-caller.service';
import { map, catchError, concatMap, tap } from 'rxjs/operators';
import {
  createCompanyEmployeeSuccess,
  createCompanyEmployeeFailure,
  createCompanyEmployee,
  updateCompanyEmployee,
  updateCompanyEmployeeSuccess,
  updateCompanyEmployeeFailure,
  deleteCompanyEmployee,
  deleteCompanyEmployeeSuccess,
  deleteCompanyEmployeeFailure,
  getPageableCompanyEmployee,
  getPageableCompanyEmployeeSuccess,
  getPageableCompanyEmployeeFailure,
} from './company-employee.actions';
import { of } from 'rxjs';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { StoreState } from '../store-state';
import { Store } from '@ngrx/store';

@Injectable()
export class CompanyEmployeeEffects {
  createCompanyEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCompanyEmployee),
      concatMap(({ data }) => {
        return this.companyEmployeeApiCaller.addCompanyEmployee(data).pipe(
          map((_) => createCompanyEmployeeSuccess({ id: data.id })),
          tap(() => this.reloadPageable()),
          catchError((error) => {
            this.reloadPageable();
            return of(deleteCompanyEmployeeFailure({ error }));
          })
        );
      })
    )
  );

  updateCompanyEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCompanyEmployee),
      concatMap(({ data }) => {
        return this.companyEmployeeApiCaller.updateCompanyEmployee(data).pipe(
          map((_) => updateCompanyEmployeeSuccess({ id: data.id })),
          catchError((error) => of(updateCompanyEmployeeFailure({ error })))
        );
      })
    )
  );

  getPageableCompanyEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPageableCompanyEmployee),
      concatMap(({ filters }) => {
        if (filters != null) {
          this.filters = filters;
        }
        return this.companyEmployeeApiCaller.getPageableEmployees(this.filters).pipe(
          map((_) => getPageableCompanyEmployeeSuccess({ data: _ })),
          catchError((error) => of(getPageableCompanyEmployeeFailure({ error })))
        );
      })
    )
  );

  deleteCompanyEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCompanyEmployee),
      concatMap(({ id }) => {
        return this.companyEmployeeApiCaller.deleteCompanyEmployee(id).pipe(
          catchError((error) => {
            this.reloadPageable();
            return of(deleteCompanyEmployeeFailure({ error }));
          }),
          map((_) => deleteCompanyEmployeeSuccess({ id: id })),
          tap(() => this.reloadPageable())
        );
      })
    )
  );

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private companyEmployeeApiCaller: CompanyEmployeeApiCallerService
  ) {}

  private reloadPageable() {
    this.store$.dispatch(getPageableCompanyEmployee({}));
  }
}
