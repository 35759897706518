import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppRole } from '../../models/enums/app-role.enum';
import { AuthGuard } from '../../guards/auth.guard';
import { AppPermission } from '../../models/enums/app-permission.enum';

@Component({
  selector: 'app-edit-actions',
  templateUrl: './edit-actions.component.html',
  styleUrls: ['./edit-actions.component.scss'],
})
export class EditActionsComponent implements OnInit, OnDestroy, DoCheck {
  @Input() form: UntypedFormGroup;
  @Input() loading: boolean;
  @Input() allowedRoles: AppPermission[];
  @Output() onClear = new EventEmitter();
  @Output() onSave = new EventEmitter();
  formStatus = 'DISABLED';
  editAllowed = true;

  constructor(private authGuard: AuthGuard) {}

  ngOnDestroy(): void {}

  ngOnInit() {
    if (this.allowedRoles != null) {
      // this.editAllowed = this.roleGuard.isInRoleActivate(this.allowedRoles);
      this.editAllowed = this.authGuard.hasClaims(this.allowedRoles);
    }
  }

  ngDoCheck() {
    this.formStatus = this.form.status;
  }

  toggleDisabled() {
    console.log(this.form);
    if (!this.editAllowed) {
      return;
    }
    if (this.form.disabled) {
      this.form.enable({ emitEvent: false });
    } else {
      this.form.disable({ emitEvent: false });
    }
  }

  clear() {
    this.onClear.emit();
  }

  save() {
    if (!this.editAllowed) {
      return;
    }
    if (this.form.valid) {
      this.onSave.emit();
    }
  }
}
