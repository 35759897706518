import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';
import { SettlementsDto } from '../models/orders/settlementsDto';
import { UpdateSettlementCommand } from './api-services';
import { SettlementsHistoryDto } from '../models/orders/settlementsHistoryDto';

@Injectable({
  providedIn: 'root',
})
export class SettlementsApiCallerService extends BaseApiCaller {
  protected controllerPath = 'settlements';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public createSettlementsFromFile(form: FormData) {
    return this.post('create-from-files', form);
  }

  public updateSettlements(dto: UpdateSettlementCommand) {
    return this.put('', dto);
  }

  public getPageableSettlements(filter: GetPageableQuery) {
    const params = this.prepareParams(filter);
    return this.get<PageableDto<SettlementsDto>>('pageable', { params });
  }

  public getPageableSettlementsHistory(filter: GetPageableQuery) {
    const params = this.prepareParams(filter);
    return this.get<PageableDto<SettlementsHistoryDto>>('pageable-history', { params });
  }
}
