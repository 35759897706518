export class IngredientDto {  
    
    constructor(data: IngredientDto){
        console.log(data);
        if(data != null){
            this.id= data.id;
            this.name = data.name;
            this.value = data.value;
            this.ingredientTypeId = data.ingredientTypeId;
            this.ingredientType = data.ingredientType;
            this.ingredientTypeName = data.ingredientTypeName;
            this.nodeName = data.nodeName;
            this.nodeId = data.nodeId;
        }

    }  

    id: string;
    name: string;
    value: number;
    ingredientTypeId: string;
    ingredientType: any;
    ingredientTypeName: string;
    nodeId: string;
    nodeName: string;
}