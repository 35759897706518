import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { AddressDto } from '../../shared/models/addresses/addressDTO';
import { AddressSelectModel } from '../../shared/models/addressSelectModel';

export const loadAddressesForSelect = createAction('[Addresses] Load Addresses For Select', props<{customerId: string}>());
export const loadAddressesForSelectSuccess = createAction(
  '[Addresses] Load Addresses For Select Success',
  props<{ data: AddressSelectModel<any>[] }>()
);
export const loadAddressesForSelectFailure = createAction(
  '[Addresses] Load Addresses For Select Failure',
  props<{ error: any }>()
);

export const deleteAddress = createAction('[Addresses] delete Address', props<{ id: string }>());
export const deleteAddressSuccess = createAction('[Addresses] delete Address Success', props<{ id: string }>());
export const deleteAddressFailure = createAction('[Addresses] delete Address Failure', props<{ error: any }>());

export const addAddress = createAction('[Addresses] Add Client', props<{ data: AddressDto }>());
export const addAddressSuccess = createAction('[Addresses] Add Address Success');
export const addAddressFailure = createAction('[Addresses] Add Address Failure', props<{ error: any }>());

export const updateAddress = createAction('[Addresses] Update Address', props<{ data: AddressDto }>());
export const updateAddressSuccess = createAction('[Addresses] Update Address Success');
export const updateAddressFailure = createAction('[Addresses] Update Address Failure', props<{ error: any }>());

export const getAddress = createAction('[Addresses] Get Address', props<{ id: string }>());
export const getAddressSuccess = createAction('[Addresses] Get Address Success', props<{ data: AddressDto }>());
export const getAddressFailure = createAction('[Addresses] Get Address Failure', props<{ error: any }>());

