import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';
import { HttpClient } from '@angular/common/http';
import { SelectModel } from '../models/select-model';
import { DictionaryModel } from '../models/dictionary-model';
import { OrderTypeDto } from '../models/order-type.dto';
import { PageableDto } from '../models/pageable.dto';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { ValidationResultDto } from '../models/validation/validation-result.dto';

@Injectable({
  providedIn: 'root',
})
export class OrderTypeApiCallerService extends BaseApiCaller {
  protected controllerPath = 'orderType';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getOrderTypes() {
    return this.get<DictionaryModel<string>[]>('');
  }

  public getPageableOrderTypes(filter: GetPageableQuery) {
    const params = this.prepareParams(filter);

    return this.get<PageableDto<OrderTypeDto>>('pageable', { params });
  }

  public addOrderType(data: OrderTypeDto) {
    return this.post('', data);
  }

  public typeNameUnique(typeName: string) {
    return this.httpClient.get<ValidationResultDto>(this.getFullPath('order-type-unique/' + typeName));
  }

  public deleteOrderType(id: string) {
    return this.delete(id);
  }

  public updateOrderType(dto: OrderTypeDto) {
    return this.put('', dto);
  }
}
