import { createAction, props } from '@ngrx/store';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { DepartmentDto } from '../../shared/models/dictionaries/departmentDTO';
import { GetPageableDepartmentsQuery } from '../../shared/models/queries/get-pageable-departments.query';

export const loadDepartmentsForSelect = createAction('[FormsData] Load Departments For Select');
export const loadDepartmentsForSelectSuccess = createAction('[FormsData] Load Departments For Select Success', props<{ data: SelectModel<number>[] }>());
export const loadDepartmentsForSelectFailure = createAction('[FormsData] Load Departments For Select Failure', props<{ error: any }>());

export const loadPageableDepartments = createAction('[FormsData] Load Pageable Departments', props<{ key: string }>());
export const loadPageableDepartmentsSuccess = createAction('[FormsData] Load Pageable Departments Success', props<{ data: PageableDto<DepartmentDto>; key: string }>());
export const loadPageableDepartmentsFailure = createAction('[FormsData] Load Pageable Departments Failure', props<{ error: any }>());

export const deleteDepartment = createAction('[FormsData] delete Department', props<{ id: number }>());
export const deleteDepartmentSuccess = createAction('[FormsData] delete Department Success', props<{ id: number }>());
export const deleteDepartmentFailure = createAction('[FormsData] delete Department Failure', props<{ error: any }>());

export const addDepartment = createAction('[FormsData] Add Department', props<{ data: DepartmentDto }>());
export const addDepartmentSuccess = createAction('[FormsData] Add Department Success', props<{ id: number; name: string }>());
export const addDepartmentFailure = createAction('[FormsData] Add Department Failure', props<{ error: any }>());

export const updateDepartment = createAction('[FormsData] Update Department', props<{ data: DepartmentDto }>());
export const updateDepartmentSuccess = createAction('[FormsData] Update Department Success');
export const updateDepartmentFailure = createAction('[FormsData] Update Department Failure', props<{ error: any }>());

export const getDepartment = createAction('[FormsData] Get Department', props<{ id: number }>());
export const getDepartmentSuccess = createAction('[FormsData] Get Department Success', props<{ data: DepartmentDto }>());
export const getDepartmentFailure = createAction('[FormsData] Get Department Failure', props<{ error: any }>());

export const setPageableDepartmentsFilters = createAction('[Authors] Set Pageable Departments Filters', props<{ data?: GetPageableDepartmentsQuery; key: string }>());
export const setPageableDepartmentsFiltersSuccess = createAction('[Authors] Set Pageable Departments Filters Success', props<{ data: GetPageableDepartmentsQuery; key: string }>());
export const setPageableDepartmentsFiltersFailure = createAction('[Authors] Set Pageable Departments Filters Failure', props<{ error: any }>());