import { createSelector } from '@ngrx/store';
import { StoreState } from '../store-state';
import { State } from './recipe-costs.reducer';

const selectRecipeCostFeature = (state: StoreState) => state.recipeCosts;

export const selectPageableRecipeCosts = createSelector(selectRecipeCostFeature, (state: State, props: { key: string }) => {
    const result = state.pageableRecipeCosts ? state.pageableRecipeCosts.find((_) => _.key === props.key) : null;
  
    return result ? result.value : null;
  });
  
  export const selectRecipeCost = createSelector(selectRecipeCostFeature, (state: State,  props: { id: string }) =>{
    const result = state.RecipeCost ? state.RecipeCost.id == props.id ? state.RecipeCost : null : null;
    return result ? result : null;
  });
  
  export const selectAllPageableRecipeCostsFilters = createSelector(selectRecipeCostFeature, (state: State) => state.RecipeCostsFilters);
  export const selectPageableRecipeCostsFilters = createSelector(selectRecipeCostFeature, (state: State, props: { key: string }) => {
    const result = state.RecipeCostsFilters ? state.RecipeCostsFilters.find((_) => _.key === props.key) : null;
  
    return result ? result.value : null;
  });