import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DictionaryModel } from '../../models/dictionary-model';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-options-select',
  templateUrl: './options-select.component.html',
  styleUrls: ['./options-select.component.scss'],
})
export class OptionsSelectComponent implements OnInit {
  @ViewChild('matSelect') matSelect: MatSelect;
  @Input() form: UntypedFormControl;
  @Input() options: DictionaryModel<string>[];
  @Input() label: string;
  get selectionStatus(): 'all' | 'none' | 'intermediate' {
    if (!Array.isArray(this.form.value) || !Array.isArray(this.options) || this.form.value.length === 0) {
      return 'none';
    }
    if (this.form.value.length === this.options.length) {
      return 'all';
    }

    return 'intermediate';
  }

  constructor() {}

  ngOnInit(): void {}

  public selectAllRows() {
    const selection = this.selectionStatus === 'all' ? [] : this.options.map((_) => _.id);

    this.form.setValue(selection);
  }
}
