//import { IncomingMessagesApiCallerService } from '../../shared/api-services/IncomingMessages-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';
import {
  loadClientsForSelect,
  loadClientsForSelectSuccess,
  loadClientsForSelectFailure,
  addClient,
  addClientSuccess,
  addClientFailure,
  loadPageableClientsSuccess,
  loadPageableClientsFailure,
  loadPageableClients,
  deleteClient,
  deleteClientSuccess,
  deleteClientFailure,
  updateClient,
  updateClientSuccess,
  updateClientFailure,
  getClient,
  getClientSuccess,
  getClientFailure,
  setPageableClientsFilters,
  setPageableClientsFiltersSuccess,
  setPageableClientsFiltersFailure,
} from './clients.actions';
import { concatMap, tap, catchError, map, finalize, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { SnackBarWrapperService } from '../../shared/services/snack-bar-wrapper.service';
import { ClientsApiCallerService } from '../../shared/api-services/clients-api-caller.service';
import { selectAllPageableClientsFilters } from './clients.selector';

@Injectable()
export class ClientsEffects {
  private latestedClientsKey: string;

  addClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addClient),
      concatMap(({ data }) => {
        return this.clientsApiCaller.addClient(data).pipe(
          map((_) =>
            addClientSuccess({ id: _, name: data.clientType == 1 ? data.firstName + ' ' + data.lastName : data.name })
          ),
          tap(() => this.reloadClients(this.latestedClientsKey)),
          catchError((error) => of(addClientFailure({ error })))
        );
      })
    )
  );

  updateClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateClient),
      concatMap(({ data }) => {
        return this.clientsApiCaller.updateClient(data).pipe(
          map((_) => updateClientSuccess()),
          catchError((error) => of(updateClientFailure({ error })))
        );
      })
    )
  );

  loadClientsForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadClientsForSelect),
      concatMap(() => {
        return this.clientsApiCaller.getClientsForSelect().pipe(
          map((_) => loadClientsForSelectSuccess({ data: _ })),
          catchError((error) => of(loadClientsForSelectFailure({ error })))
        );
      })
    )
  );

  loadPageableClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableClients),
      withLatestFrom(this.store$.select(selectAllPageableClientsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedClientsKey = key;
        return this.clientsApiCaller.getPageableClients(filter.value).pipe(
          map((_) => loadPageableClientsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableClientsFailure({ error })))
        );
      })
    )
  );

  getClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClient),
      concatMap(({ id }) => {
        return this.clientsApiCaller.getClient(id).pipe(
          map((_) => getClientSuccess({ data: _ })),
          catchError((error) => of(getClientFailure({ error })))
        );
      })
    )
  );

  deleteClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteClient),
      concatMap(({ id }) => {
        return this.clientsApiCaller.deleteClient(id).pipe(
          map((_) => deleteClientSuccess({ id: id })),
          tap(() => this.reloadClients(this.latestedClientsKey)),
          catchError((error) => of(deleteClientFailure({ error })))
        );
      })
    )
  );

  setPageableClientsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableClientsFilters),
      concatMap(({ data, key }) => {
        this.latestedClientsKey = key;
        return of(data).pipe(
          map((_) => setPageableClientsFiltersSuccess({ data, key })),
          finalize(() => this.reloadClients(key)),
          catchError((error) => of(setPageableClientsFiltersFailure({ error })))
        );
      })
    )
  );

  private reloadClients(key: string) {
    this.store$.dispatch(loadClientsForSelect());
    this.store$.dispatch(loadPageableClients({ key }));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private clientsApiCaller: ClientsApiCallerService,
    private router: Router,
    private matSnackBar: SnackBarWrapperService
  ) {}
}
