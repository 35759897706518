import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export class StructuralElementForm extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, Validators.required),
    });
  }

  forAdd() {
    return this;
  }
}
