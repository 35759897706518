import { Action, createReducer, on } from '@ngrx/store';
import { loadEmployeesForSelect, loadEmployeesForSelectSuccess, loadEmployeesForSelectFailure, loadPageableEmployees, loadPageableEmployeesSuccess, loadPageableEmployeesFailure, getEmployee, getEmployeeSuccess, getEmployeeFailure, setPageableEmployeesFilters, setPageableEmployeesFiltersSuccess, addEmployeeSuccess} from './employees.actions';
import { SelectModel } from '../../shared/models/select-model';
import { PageableDto } from '../../shared/models/pageable.dto';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { GetPageableEmployeesQuery } from '../../shared/models/queries/get-pageable-employees.query';
import { IdentityPageableDto } from '../../shared/api-services/identity-users-api-caller.service';
import { EmployeesModule } from '../../employees/employees.module';
import { EmployeeDto } from '../../shared/models/employees/employeeDTO';
import { UpdateEmployeeDto } from '../../shared/models/employees/updateEmployeeDTO';

export const employeesFeatureKey = 'employees';

export interface State {
  employeesForSelect?: SelectModel<number>[];
  pageableEmployees?: DictionaryDto<string,PageableDto<EmployeeDto>>;
  employee?: UpdateEmployeeDto;
  employeesFilters?: DictionaryDto<string, GetPageableEmployeesQuery>;
  employeeAdded?: { id: number; name: string };
}

export const initialState: State = {
  employeesFilters: new DictionaryDto<string, GetPageableEmployeesQuery>(),
};

const usersReducer = createReducer(
  initialState,

  on(loadEmployeesForSelect, (state) => setEmployeesForSelect(state, null)),
  on(loadEmployeesForSelectSuccess, (state, { data }) => setEmployeesForSelect(state, data)),
  on(loadEmployeesForSelectFailure, (state, action) => state),
  
  on(loadPageableEmployees, (state, {key} ) => setPageableEmployees(state, null, key)),
  on(loadPageableEmployeesSuccess, (state, { data, key }) => setPageableEmployees(state, data, key)),
  on(loadPageableEmployeesFailure, (state, action) => state),

  on(getEmployee, (state) => setEmployee(state, null)),
  on(getEmployeeSuccess, (state, { data}) => setEmployee(state, data)),
  on(getEmployeeFailure, (state, action) => state),

  on(setPageableEmployeesFilters, (state, { data, key }) => setPageableEmployeesFiltersFunc(state, null, key)),
  on(setPageableEmployeesFiltersSuccess, (state, { data, key }) => setPageableEmployeesFiltersFunc(state, data, key)),

  on(addEmployeeSuccess, (state, data) => setAddEmployee(state, data))
);

function setEmployeesForSelect(state: State, data: SelectModel<number>[]) {
  const employeesForSelect = data;
  return {
    ...state,
    employeesForSelect,
  };
}

function setAddEmployee(state: State, employeeAdded) {
  return {
    ...state,
    employeeAdded,
  };
}

function setPageableEmployees(state: State, data: IdentityPageableDto<EmployeeDto>, key: string) {
  var pageable = {} as PageableDto<EmployeeDto>;
  if(data != null){
    pageable.result = data.data;
    pageable.total = data.totalPages;
  }
  const pageableEmployees = Array.isArray(state.pageableEmployees)
    ? state.pageableEmployees
    : new DictionaryDto<string, PageableDto<EmployeeDto>>();
  const orders = pageableEmployees.find((_) => _.key === key);
  if (orders != null) {
    orders.value = pageable;
  } else {
    pageableEmployees.push({ key, value: pageable });
  }

  return {
    ...state,
    pageableEmployees,
  };
}

function setEmployee(state: State, data: UpdateEmployeeDto) {
  var employee = data;
  return {
    ...state,
    employee,
  };
}

function setPageableEmployeesFiltersFunc(state: State, data: GetPageableEmployeesQuery, key: string) {
  const employeesFilters = Array.isArray(state.employeesFilters)
    ? state.employeesFilters
    : new DictionaryDto<string, GetPageableEmployeesQuery>();
  const filter = employeesFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    employeesFilters.push({ key, value: data });
  }

  return {
    ...state,
    employeesFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
