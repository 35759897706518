import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { UpdateCementDto } from '../models/cement/update-cement-dto';
import { BaseApiCaller } from './base-api-caller';

@Injectable({
  providedIn: 'root',
})
export class CementApiCallerService extends BaseApiCaller {
  protected controllerPath = 'cement';

  // constructor(httpClient: HttpClient, private datePipe: DatePipe) {
  //   super(httpClient);
  // }

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  

  public addCement(cement: UpdateCementDto) {
    return this.post('', cement);
  }

  public updateCement(cement: UpdateCementDto) {
    return this.put('', cement);
  }

}
