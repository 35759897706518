import { createAction, props } from '@ngrx/store';
import { SettlementsDto } from '../../shared/models/orders/settlementsDto';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableSettlements } from '../../shared/models/queries/get-pageable-settlements.query';

export const loadPageableSettlements = createAction('[Operation] Load Pageable Settlements', props<{ key: string }>());
export const loadPageableSettlementsSuccess = createAction(
  '[Operation] Load Pageable Settlements Success',
  props<{ data: PageableDto<SettlementsDto>; key: string }>()
);
export const loadPageableSettlementsFailure = createAction(
  '[Operation] Load Pageable Settlements Failure',
  props<{ error: any }>()
);

export const setPageableSettlementsFilters = createAction(
  '[Operation] Set Pageable Settlements Filters',
  props<{ data?: GetPageableSettlements; key: string }>()
);
export const setPageableSettlementsFiltersSuccess = createAction(
  '[Operation] Set Pageable Settlements Filters Success',
  props<{ data: GetPageableSettlements; key: string }>()
);
export const setPageableSettlementsFiltersFailure = createAction(
  '[Operation] Set Pageable Settlements Filters Failure',
  props<{ error: any }>()
);
