import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiCaller } from './base-api-caller';
import { UpdatePropertiesMixtureDto } from '../models/propertiesMixture/update-properties-mixture-dto';

@Injectable({
  providedIn: 'root'
})
export class PropertiesMixtureApiCallerService extends BaseApiCaller {
  protected controllerPath = 'propertiesMixture';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addPropertiesMixture(propertiesMixture: UpdatePropertiesMixtureDto) {
    return this.post('', propertiesMixture);
  }

  public updatePropertiesMixture(propertiesMixture: UpdatePropertiesMixtureDto) {
    return this.put('', propertiesMixture);
  }
}
