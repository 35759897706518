import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { PaymentTypeApiCallerService } from '../../shared/api-services/paymentType/payment-type-api-caller.service';
import { loadPaymentType, loadPaymentTypeFailure, loadPaymentTypeSuccess } from './paymentType.actions';

@Injectable()
export class PaymentTypeEffects {
   paymentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPaymentType),
      concatMap(() => {
        return this.paymentTypeApiService.getItemAll().pipe(
          map((_) => loadPaymentTypeSuccess({ data: _ })),
          catchError((error) => of(loadPaymentTypeFailure({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private paymentTypeApiService: PaymentTypeApiCallerService,
  ) {}

}