import { UntypedFormControl } from '@angular/forms';

export class EnumValidator {
  public static createIsInEnumValidator(T) {
    return (control: UntypedFormControl) => {
      if (control.value != null && Object.values(T).indexOf(control.value) < 0) {
        return { isNotEnum: true };
      }

      return null;
    };
  }
}
