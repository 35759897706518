import { createReducer, Action, on } from '@ngrx/store';
import {
  setPageSizeFailure,
  setPageSizeSuccess,
  setOrderBySuccess,
  setOrderByFailure,
  setSortTypeSuccess,
  setSortTypeFailure,
} from './tables.actions';
import { PageSizeMapDto } from '../../shared/models/page-size-map.dto';
import { OrderByMapDto } from '../../shared/models/order-by-map.dto';
import { SortTypeMapDto } from '../../shared/models/sort-type-map.dto';

export interface State {
  pageSize?: PageSizeMapDto[];
  sort?: OrderByMapDto[];
  sortType?: SortTypeMapDto[];
}

export const initialState: State = {};

const tablesReducer = createReducer(
  initialState,

  on(setPageSizeSuccess, (state, { data }) => setPageSizes(state, data)),
  on(setPageSizeFailure, (state) => setPageSizes(state, null)),

  on(setOrderBySuccess, (state, { data }) => setOrderBy(state, data)),
  on(setOrderByFailure, (state) => setOrderBy(state, null)),

  on(setSortTypeSuccess, (state, { data }) => setSortType(state, data)),
  on(setSortTypeFailure, (state) => setSortType(state, null))
);

function setPageSizes(state: State, pageSize) {
  return {
    ...state,
    pageSize,
  };
}

function setOrderBy(state: State, sort) {
  return {
    ...state,
    sort,
  };
}

function setSortType(state: State, sortType) {
  return {
    ...state,
    sortType,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return tablesReducer(state, action);
}
