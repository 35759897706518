import { StoreState } from '../store-state';
import { createSelector } from '@ngrx/store';
import { State } from './operation.reducer';
import { PageableDto } from '../../shared/models/pageable.dto';
import { OrderDto } from '../../shared/models/order/order.dto';

const selectFeature = (state: StoreState) => state.operations;

export const selectOperation = createSelector(selectFeature, (state: State) => state.operation);

export const selectPageableOrders = createSelector(selectFeature, (state: State, props: { key: string }) => {
  const result = state.pageableOrders ? state.pageableOrders.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectAllPageableOrderFilters = createSelector(selectFeature, (state: State) => state.ordersFilters);
export const selectPageableOrderFilters = createSelector(selectFeature, (state: State, props: { key: string }) => {
  const result = state.ordersFilters ? state.ordersFilters.find((_) => _.key === props.key) : null;

  return result ? result.value : null;
});

export const selectAvailableStatuses = createSelector(selectFeature, (state: State) => state.orderAvailableStatuses);

export const selectOrderAttachmentsInfo = createSelector(selectFeature, (state: State) => state.attachmentsInfo);

export const selectOrderHistory = createSelector(selectFeature, (state: State) => state.history);

export const selectPageableOrderTypes = createSelector(selectFeature, (state: State) => state.orderTypes);

export const selectOrderAttachmentsProgress = createSelector(
  selectFeature,
  (state: State) => state.attachmentsProgress
);

export const selectOrderCompanyEmployee = createSelector(selectFeature, (state: State) => state.orderCompanyEmployee);
