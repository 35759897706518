import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CredentialsDto } from '../models/Account/credentials-dto';
import { AuthenticationResponseDto } from '../models/Account/authentication-response-dto';
import { BaseApiCaller } from './base-api-caller';
import { map } from 'rxjs/operators';
import { GetPageableQuery } from '../models/queries/get-pageable.query';
import { PageableDto } from '../models/pageable.dto';

import { GetPageableIncomingMessagesQuery } from '../models/queries/get-pageable-incoming-messages.query';
import { CorrespondenceDto } from '../models/correspondence/correspondenceDTO';

@Injectable({
  providedIn: 'root',
})
export class OutgoingMessagesApiCallerService extends BaseApiCaller {
  protected controllerPath = 'correspondence';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public addOutgoingMessage(dto: CorrespondenceDto) {
    return this.post('', dto);
  }

  public updateOutgoingMessage(dto: CorrespondenceDto) {
    return this.put('', dto);
  }

  public deleteOutgoingMessage(id: number) {
    return this.delete(id.toString());
  }

  public getOutgoingMessage(id: number): Observable<CorrespondenceDto> {
    return this.get<CorrespondenceDto>(id.toString()).pipe(map((_) => new CorrespondenceDto(_)));
  }

  public getPageableOutgoingMessages(query: GetPageableIncomingMessagesQuery) {
    const params = this.prepareParams(query);
    return this.get<PageableDto<CorrespondenceDto>>('', { params });
  }

  public sendOutgoingMessage(id: number) {
    return this.put(`sendOutgoingMessage/${id}`, null);
  }
}
