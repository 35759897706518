import { Action, createReducer, on } from '@ngrx/store';
import { loadCTypesForSelect, loadCTypesForSelectSuccess, loadCTypesForSelectFailure, loadPageableCTypes, loadPageableCTypesSuccess, loadPageableCTypesFailure,  getCType, getCTypeSuccess, getCTypeFailure } from './c-types.actions';
import { SelectModel } from '../../shared/models/select-model';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { PageableDto } from '../../shared/models/pageable.dto';

export const formsDataFeatureKey = 'formsData';

export interface State {
  cTypesForSelect?: SelectModel<number>[];
  pageableCTypes?: PageableDto<SelectModel<number>>;
  cType?: SelectModel<number>;
  
}

export const initialState: State = {
  
};

const usersReducer = createReducer(
  initialState,
  
  on(loadCTypesForSelect, (state) => setCTypesForSelect(state, null)),
  on(loadCTypesForSelectSuccess, (state, { data }) => setCTypesForSelect(state, data)),
  on(loadCTypesForSelectFailure, (state, action) => state),

  on(loadPageableCTypes, (state) => setPageableCTypes(state, null)),
  on(loadPageableCTypesSuccess, (state, { data }) => setPageableCTypes(state, data)),
  on(loadPageableCTypesFailure, (state, action) => state),

  on(getCType, (state) => setCType(state, null)),
  on(getCTypeSuccess, (state, { data}) => setCType(state, data)),
  on(getCTypeFailure, (state, action) => state),
);


function setCTypesForSelect(state: State, data: SelectModel<number>[]) {
  const cTypesForSelect = data;
  return {
    ...state,
    cTypesForSelect,
  };
}

function setPageableCTypes(state: State, data: PageableDto<SelectModel<number>>) {
  const pageableCTypes = data;
  return {
    ...state,
    pageableCTypes,
  };
}

function setCType(state: State, data: SelectModel<number>) {
  var cType = data;
  return {
    ...state,
    cType,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
