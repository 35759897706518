import { OrderGroupedByHourHour } from './../../shared/models/orders/ordersGroupedByHour';
import { createAction, props } from '@ngrx/store';
import { CommentsOfDayDto } from '../../shared/models/orders/commentsOfDayDto';
import { OrderDto } from '../../shared/models/orders/order.dto';
import { OrderDateModel } from '../../shared/models/orders/orderDate.model';
import { OrderForDeliveryStatusesDto } from '../../shared/models/orders/orderForDeliveryStatuses.Dto';

import { OrderStatus } from '../../shared/models/orders/orderState.model';
import { CustomerOrderHistory } from '../../shared/models/customerOrderHistoryModel';
import { OrderStatusesForHour } from '../../shared/models/orders/ordersForHourRange';
import { PageableDto } from '../../shared/models/pageable.dto';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { GetPageableOrdersCatalog } from '../../shared/models/queries/get-pageable-orders-catalog.query';
import { SettlementsDto } from '../../shared/models/orders/settlementsDto';

import { GetPageableOrderEditHistoryQuery } from '../../shared/models/queries/get-pageable-order-edit-history.query';
import { OrderEditHistoryModel } from '../../shared/api-services/api-services';


export const loadOrders = createAction('[Orders] Load Orders');
export const loadOrdersSuccess = createAction('[Orders] Load Orders Success', props<{ data: OrderDto[] }>());
export const loadOrdersFailure = createAction('[Orders] Load Orders Failure', props<{ error: any }>());

export const addOrder = createAction('[Orders] Add Order', props<{ data: OrderDto }>());
export const addOrderSuccess = createAction('[Orders] Add Order Success', props<{ data: any }>());
export const addOrderFailure = createAction('[Orders] Add Order Failure', props<{ error: any }>());

// tslint:disable-next-line:max-line-length
export const loadOrderByDate = createAction(
  '[Orders] Load OrderByDate',
  props<{ payload: { dateFrom: any; dateTo: any; nodeId: string } }>()
);
export const loadOrderByDateSuccess = createAction('[Orders] Load OrderByDate Success', props<{ data: OrderDto[] }>());
export const loadOrderByDateFailure = createAction('[Orders] Load OrderByDate Failure', props<{ error: any }>());

export const loadOrder = createAction('[Orders] get Order', props<{ orderId: string }>());
export const loadOrderSuccess = createAction('[Orders] get Order Success', props<{ data: OrderDto }>());
export const loadOrderFailure = createAction('[Orders] get Order Failure', props<{ error: any }>());

export const updateOrderDate = createAction('[Orders] Update OrderDate', props<{ data: OrderDateModel }>());
export const updateOrderDateSuccess = createAction('[Orders] Update OrderDate Success', props<{ data: any }>());
export const updateOrderDateFailure = createAction('[Orders] Update OrderDate Failure', props<{ error: any }>());

export const updateOrder = createAction('[Orders] Update Order', props<{ data: OrderDto }>());
export const updateOrderSuccess = createAction('[Orders] Update Order Success', props<{ data: any }>());
export const updateOrderFailure = createAction('[Orders] Update Order Failure', props<{ error: any }>());

export const addCopyOrder = createAction('[Orders] Add CopyOrder', props<{ data: OrderDto }>());
export const addCopyOrderSuccess = createAction('[Orders] Add CopyOrder Success', props<{ data: any }>());
export const addCopyOrderFailure = createAction('[Orders] Add CopyOrder Failure', props<{ error: any }>());

export const updateOrderState = createAction('[Orders] Update OrderDate', props<{ data: OrderStatus }>());
export const updateOrderStateSuccess = createAction('[Orders] Update OrderDate Success', props<{ data: any }>());
export const updateOrderStateFailure = createAction('[Orders] Update OrderDate Failure', props<{ error: any }>());

export const updateOrderSettlement = createAction('[Orders] Update OrderSettlement', props<{ data: SettlementsDto }>());
export const updateOrderSettlementSuccess = createAction('[Orders] Update OrderSettlement Success', props<{ data: any }>());
export const updateOrderSettlementFailure = createAction('[Orders] Update OrderSettlement Failure', props<{ error: any }>());

// tslint:disable-next-line:max-line-length
export const loadInProgrgessOrderByDate = createAction(
  '[Orders] Load InProgrgessOrderByDate',
  props<{ payload: { dateFrom: any; dateTo: any; nodeId: string } }>()
);
// tslint:disable-next-line:max-line-length
export const loadInProgrgessOrderByDateSuccess = createAction(
  '[Orders] Load InProgrgessOrderByDate Success',
  props<{ data: OrderDto[] }>()
);
export const loadInProgrgessOrderByDateFailure = createAction(
  '[Orders] Load InProgrgessOrderByDate Failure',
  props<{ error: any }>()
);

export const loadAllOrderByDate = createAction(
  '[Orders] Load loadAllOrderByDate',
  props<{ payload: { dateFrom: any; dateTo: any; nodeId: string } }>()
);
// tslint:disable-next-line:max-line-length
export const loadAllOrderByDateSuccess = createAction(
  '[Orders] Load loadAllOrderByDate Success',
  props<{ data: OrderForDeliveryStatusesDto[] }>()
);
export const loadAllOrderByDateFailure = createAction(
  '[Orders] Load loadAllOrderByDate Failure',
  props<{ error: any }>()
);

export const loadAllOrderByHourRange = createAction(
  '[Orders] Load loadAllOrderByHourRange',
  props<{ payload: { dateFrom: any; dateTo: any; nodeId: string } }>()
);
// tslint:disable-next-line:max-line-length
export const loadAllOrderByHourRangeSuccess = createAction(
  '[Orders] Load loadAllOrderByHourRange Success',
  props<{ data: OrderGroupedByHourHour[] }>()
);
export const loadAllOrderByHourRangeFailure = createAction(
  '[Orders] Load loadAllOrderByHourRange Failure',
  props<{ error: any }>()
);

export const loadAllCommentsOfDay = createAction(
  '[Orders] Load loadAllCommentsOfDay',
  props<{ payload: { dateOfComments: any } }>()
);
// tslint:disable-next-line:max-line-length
export const loadAllCommentsOfDaySuccess = createAction(
  '[Orders] Load loadAllCommentsOfDay Success',
  props<{ data: CommentsOfDayDto[] }>()
);
export const loadAllCommentsOfDayFailure = createAction(
  '[Orders] Load loadAllCommentsOfDay Failure',
  props<{ error: any }>()
);

export const addCommentOfDay = createAction('[Orders] Add Cement', props<{ data: CommentsOfDayDto }>());
export const addCommentOfDaySuccess = createAction('[Cement] Add Cement Success', props<{ data: CommentsOfDayDto }>());
export const addCommentOfDayFailure = createAction('[Cement] Add Cement Failure', props<{ error: any }>());

export const loadCustomerOrderHistory = createAction(
  '[Orders] Load CustomerOrderHistory',
  props<{ customerId: string }>()
);
// tslint:disable-next-line:max-line-length
export const loadCustomerOrderHistorySuccess = createAction(
  '[Orders] Load CustomerOrderHistory Success',
  props<{ data: CustomerOrderHistory[] }>()
);
export const loadCustomerOrderHistoryFailure = createAction(
  '[Orders] Load CustomerOrderHistory Failure',
  props<{ error: any }>()
);

export const loadPageableOrdersCatalog = createAction('[Orders] Load Pageable OrdersCatalog', props<{ key: string }>());
export const loadPageableOrdersCatalogSuccess = createAction(
  '[Orders] Load Pageable OrdersCatalog Success',
  props<{ data: PageableDto<OrderStatusesForHour>; key: string }>()
);
export const loadPageableOrdersCatalogFailure = createAction(
  '[Orders] Load Pageable OrdersCatalog Failure',
  props<{ error: any }>()
);

export const setPageableOrdersCatalogFilters = createAction(
  '[Orders] Set Pageable OrdersCatalog Filters',
  props<{ data?: GetPageableQuery; key: string }>()
);
export const setPageableOrdersCatalogFiltersSuccess = createAction(
  '[Orders] Set Pageable OrdersCatalog Filters Success',
  props<{ data: GetPageableOrdersCatalog; key: string }>()
);
export const setPageableOrdersCatalogFiltersFailure = createAction(
  '[Orders] Set Pageable OrdersCatalog Filters Failure',
  props<{ error: any }>()
);


export const loadOrdersCatalog = createAction('[Orders] Load OrdersCatalog');
export const loadOrdersCatalogSuccess = createAction(
  '[Orders] Load OrdersCatalog Success',
  props<{ data: OrderStatusesForHour[] }>()
);
export const loadOrdersCatalogFailure = createAction('[Orders] Load OrdersCatalog Failure', props<{ error: any }>());

export const updateOrderSingalr = createAction('[Orders] Update Order Signalr', props<{ data: OrderDto }>());


export const loadPageableOrderEditHistory = createAction('[Items] Load Pageable Order Edit History', props<{ key: string }>());
export const loadPageableOrderEditHistorySuccess = createAction('[Items] Load Pageable Order Edit History Success', props<{ data: PageableDto<OrderEditHistoryModel>; key: string }>());
export const loadPageableOrderEditHistoryFailure = createAction('[Items] Load Pageable Order Edit History Failure', props<{ error: any }>());


export const setPageableOrderEditHistoryFilters = createAction('[Items] Set Pageable Order Edit History Filters', props<{ data?: GetPageableOrderEditHistoryQuery; key: string }>());
export const setPageableOrderEditHistoryFiltersSuccess = createAction('[Items] Set Pageable Order Edit History Filters Success', props<{ data: GetPageableOrderEditHistoryQuery; key: string }>());
export const setPageableOrderEditHistoryFiltersFailure = createAction('[Items] Set Pageable Order Edit History Filters Failure', props<{ error: any }>());
