<div class="my-modal">
  <mat-card-header>
    <mat-card-title style="text-align: center;">{{  'Dialogs.Delete.Title' | translate }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <span>{{ (isCorrespondence ? 'Dialogs.Delete.DeleteCorrespondence' : 'Dialogs.Delete.Message' ) | translate: { value: name } }}</span>
  </mat-card-content>
  <mat-card-actions>
    <div class="d-flex width-100p ml-20">
      <div class="flex-spacer"></div>
      <button color="primary" class="modal-accept-button" mat-raised-button (click)="confirm()">
        {{ 'Boolean.true' | translate }}
      </button>
      <button color="warn" class="right-button" mat-raised-button (click)="cancel()">
        {{ 'Boolean.false' | translate }}
      </button>
    </div>
  </mat-card-actions>
</div>
