import { Injectable, ViewChild } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, tap, withLatestFrom } from 'rxjs/operators';
import { ItemApiCallerService } from '../../shared/api-services/item/item-api-caller.service';
import { Router } from '@angular/router';
import { GetPageableQuery } from '../../shared/models/queries/get-pageable.query';
import { SnackBarWrapperService } from '../../shared/services/snack-bar-wrapper.service';
import { selectAllPageableItemsFilters } from './items.selector';
import { ItemsApiCallerService } from '../../shared/api-services/items-api-caller.service';
import { DocumentsApiCallerService } from '../../shared/api-services/documents-api-caller.service';
import { AttachmentProgressDto } from '../../shared/models/attachment/attachment-progress.dto';
import { HttpEventType } from '@angular/common/http';
import { UploadStatus } from '../../shared/models/enums/upload-status.enum';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';
import {
  loadItems,
  loadItemsFailure,
  loadItemsProduct,
  loadItemsProductFailure,
  loadItemsProductSuccess,
  loadItemsService,
  loadItemsServiceFailure,
  loadItemsServiceSuccess,
  loadItemsSuccess,
  addItem,
  addItemSuccess,
  addItemFailure,
  deleteItem,
  deleteItemSuccess,
  deleteItemFailure,
  setPageableItemsFilters,
  setPageableItemsFiltersSuccess,
  setPageableItemsFiltersFailure,
  loadPageableItems,
  loadPageableItemsSuccess,
  loadPageableItemsFailure,
  getItem,
  getItemSuccess,
  getItemFailure,
  updateItem,
  updateItemSuccess,
  updateItemFailure,
  loadItemTypesForSelect,
  loadItemTypesForSelectFailure,
  loadItemTypesForSelectSuccess,
  getItemsByNodeId,
  getItemsByNodeIdSuccess,
  getItemsByNodeIdFailure,
  addCustomerItem,
  addCustomerItemSuccess,
  addCustomerItemFailure,
  updateCustomerItem,
  updateCustomerItemSuccess,
  updateCustomerItemFailure,
  getItemsByCustomerId,
  getItemsByCustomerIdSuccess,
  getItemsByCustomerIdFailure,
  loadItemsForSelect,
  loadItemsForSelectSuccess,
  loadItemsForSelectFailure,
} from './items.actions';
import { Store } from '@ngrx/store';
import { StoreState } from '../store-state';

@Injectable()
export class ItemsEffects {
  @ViewChild('spinner', { static: true }) spinner;

  private latestedItemsKey: string;

  items$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadItems),
      concatMap(() => {
        return this.itemsApiCaller.getItemAll().pipe(
          map((_) => loadItemsSuccess({ data: _ })),
          catchError((error) => of(loadItemsFailure({ error })))
        );
      })
    )
  );

  itemsProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadItemsProduct),
      concatMap(() => {
        return this.itemsApiCaller.getItemsProductAll().pipe(
          map((_) => loadItemsProductSuccess({ data: _ })),
          catchError((error) => of(loadItemsProductFailure({ error })))
        );
      })
    )
  );

  itemsService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadItemsService),
      concatMap(({ customerId }) => {
        return this.itemsApiCaller.getItemsServiceAll(customerId).pipe(
          map((_) => loadItemsServiceSuccess({ data: _ })),
          catchError((error) => of(loadItemsServiceFailure({ error })))
        );
      })
    )
  );

  deleteItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteItem),
      concatMap(({ id }) => {
        return this.carsApiCaller.deleteItem(id).pipe(
          map((_) => deleteItemSuccess({ id })),
          tap(() => this.reloadItems(this.latestedItemsKey)),
          catchError((error) => of(deleteItemFailure({ error })))
        );
      })
    )
  );

  addItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addItem),
      concatMap(({ data }) => {
        let lastValue;
        return this.carsApiCaller.addItem(data).pipe(
          map((_) => addItemSuccess({ data: _ })),
          tap((_) => {
            lastValue = _;
          }),
          finalize(() => {
            let carId = lastValue.data;
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => {
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
            return of(addItemFailure({ error }));
          })
        );
      })
    )
  );

  addCustomerItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCustomerItem),
      concatMap(({ data }) => {
        let lastValue;
        return this.carsApiCaller.addItem(data).pipe(
          map((_) => addCustomerItemSuccess({ data: _ })),
          tap((_) => {
            lastValue = _;
          }),
          finalize(() => {
            let carId = lastValue.data;
            //this.navigateToCustomerCatalog();
            // this.router.navigate(['/', 'authorised', 'customers', 'catalog']);
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(addCustomerItemFailure({ error })))
        );
      })
    )
  );

  updateItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateItem),
      concatMap(({ data }) => {
        let lastValue;
        return this.carsApiCaller.updateItem(data).pipe(
          map((_) => updateItemSuccess()),
          tap((_) => (lastValue = _)),
          finalize(() => {
            let ItemId = data.id;
            this.navigateToCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(updateItemFailure({ error })))
        );
      })
    )
  );

  updateCustomerItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomerItem),
      concatMap(({ data }) => {
        let lastValue;
        return this.carsApiCaller.updateItem(data).pipe(
          map((_) => updateCustomerItemSuccess()),
          tap((_) => (lastValue = _)),
          finalize(() => {
            //this.navigateToCustomerCatalog();
            this.overlayService.closeOverlay('add-incoming-message');
          }),
          catchError((error) => of(updateCustomerItemFailure({ error })))
        );
      })
    )
  );

  loadPageableItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPageableItems),
      withLatestFrom(this.store$.select(selectAllPageableItemsFilters)),
      concatMap((data) => {
        const key = data[0].key;
        const filters = data[1];
        const filter = filters.find((_) => _.key === key);
        this.latestedItemsKey = key;

        return this.carsApiCaller.getPageableItems(filter.value).pipe(
          map((_) => loadPageableItemsSuccess({ data: _, key })),
          catchError((error) => of(loadPageableItemsFailure({ error })))
        );
      })
    )
  );

  getItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getItem),
      concatMap(({ id }) => {
        return this.carsApiCaller.getItem(id).pipe(
          map((_) => getItemSuccess({ data: _ })),
          catchError((error) => of(getItemFailure({ error })))
        );
      })
    )
  );

  setPageableItemsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPageableItemsFilters),
      concatMap(({ data, key }) => {
        this.latestedItemsKey = key;
        return of(data).pipe(
          map((_) => setPageableItemsFiltersSuccess({ data, key })),
          finalize(() => this.reloadItems(key)),
          catchError((error) => of(setPageableItemsFiltersFailure({ error })))
        );
      })
    )
  );

  loadItemTypesForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadItemTypesForSelect),
      concatMap(() => {
        return this.carsApiCaller.getItemTypesForSelect().pipe(
          map((_) => loadItemTypesForSelectSuccess({ data: _ })),
          catchError((error) => of(loadItemTypesForSelectFailure({ error })))
        );
      })
    )
  );

  getItemsByNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getItemsByNodeId),
      concatMap(({ nodeId }) => {
        return this.itemsApiCaller.getItemByNode(nodeId).pipe(
          map((_) => getItemsByNodeIdSuccess({ data: _ })),
          catchError((error) => of(getItemsByNodeIdFailure({ error })))
        );
      })
    )
  );

  getItemsByCustomerId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getItemsByCustomerId),
      concatMap(({ customerId, nodeId }) => {
        return this.itemsApiCaller.getItemByCustomer(customerId, nodeId).pipe(
          map((_) => getItemsByCustomerIdSuccess({ data: _ })),
          catchError((error) => of(getItemsByCustomerIdFailure({ error })))
        );
      })
    )
  );

  loadNodesForSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadItemsForSelect),
      concatMap(({ nodeId, isProduct, isService }) => {
        return this.itemsApiCaller.getItemsForSelect(nodeId, isProduct, isService).pipe(
          map((_) => loadItemsForSelectSuccess({ data: _ })),
          catchError((error) => of(loadItemsForSelectFailure({ error })))
        );
      })
    )
  );

  private reloadItems(key: string) {
    this.store$.dispatch(loadPageableItems({ key }));
  }

  filters: GetPageableQuery;

  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private carsApiCaller: ItemsApiCallerService,
    private itemsApiCaller: ItemApiCallerService,
    private router: Router,
    private overlayService: OverlayWrapperService
  ) {}

  private navigateToCatalog() {
    this.router.navigate(['/', 'authorised', 'items', 'catalog']);
  }

  // private navigateToCustomerCatalog() {
  //   this.router.navigate(['/', 'authorised', 'customers', 'catalog']);
  // }

}
