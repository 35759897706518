import { Action, createReducer, on } from '@ngrx/store';
import { PageableDto } from '../../shared/models/pageable.dto';
import { EditorialDocumentDto } from '../../shared/models/editorial-documents/editorialDocumentDTO';
import { addEditorialDocument, addEditorialDocumentSuccess, addEditorialDocumentFailure, loadPageableEditorialDocuments, loadPageableEditorialDocumentsFailure, loadPageableEditorialDocumentsSuccess, setPageableEditorialDocumentsFilters, setPageableEditorialDocumentsFiltersSuccess, getEditorialDocument, getEditorialDocumentSuccess, getEditorialDocumentFailure } from './editorial-documents.actions';
import { DictionaryDto } from '../../shared/models/dictionary.dto';
import { GetPageableEditorialDocumentsQuery } from '../../shared/models/queries/get-pageable-editorial-documents.query';

export const editorialDocumentsFeatureKey = 'editorialDocuments';

export interface State {
  pageableEditorialDocuments?: DictionaryDto<string, PageableDto<EditorialDocumentDto>>;
  employeesFilters?: DictionaryDto<string, GetPageableEditorialDocumentsQuery>;
  editorialDocument?: EditorialDocumentDto;
}

export const initialState: State = {
  employeesFilters: new DictionaryDto<string, GetPageableEditorialDocumentsQuery>(),
};

const usersReducer = createReducer(
  initialState,

  on(addEditorialDocument, (state) => setAddEditorialDocument(state, null)),
  on(addEditorialDocumentSuccess, (state) => setAddEditorialDocument(state, null)),
  on(addEditorialDocumentFailure, (state) => setAddEditorialDocument(state, null)),

  on(loadPageableEditorialDocuments, (state, { key }) => setPageableEditorialDocuments(state, null, key)),
  on(loadPageableEditorialDocumentsSuccess, (state, { data, key }) => setPageableEditorialDocuments(state, data, key)),
  on(loadPageableEditorialDocumentsFailure, (state, action) => state),

  on(getEditorialDocument, (state) => setEditorialDocument(state, null)),
  on(getEditorialDocumentSuccess, (state, { data}) => setEditorialDocument(state, data)),
  on(getEditorialDocumentFailure, (state, action) => state),

  on(setPageableEditorialDocumentsFilters, (state, { data, key }) => setPageableEditorialDocumentsFiltersFunc(state, null, key)),
  on(setPageableEditorialDocumentsFiltersSuccess, (state, { data, key }) => setPageableEditorialDocumentsFiltersFunc(state, data, key)),
);

function setPageableEditorialDocuments(state: State, data: PageableDto<EditorialDocumentDto>, key: string) {
  const pageableEditorialDocuments = Array.isArray(state.pageableEditorialDocuments)
    ? state.pageableEditorialDocuments
    : new DictionaryDto<string, PageableDto<EditorialDocumentDto>>();
  const orders = pageableEditorialDocuments.find((_) => _.key === key);
  if (orders != null) {
    orders.value = data;
  } else {
    pageableEditorialDocuments.push({ key, value: data });
  }

  return {
    ...state,
    pageableEditorialDocuments,
  };
}

function setEditorialDocument(state: State, data: EditorialDocumentDto) {
  var editorialDocument = data;
  return {
    ...state,
    editorialDocument,
  };
}

function setAddEditorialDocument(state: State, attachmentsProgress: EditorialDocumentDto[]) {
  return {
    ...state,
    attachmentsProgress,
  };
}

function setPageableEditorialDocumentsFiltersFunc(state: State, data: GetPageableEditorialDocumentsQuery, key: string) {
  const employeesFilters = Array.isArray(state.employeesFilters)
    ? state.employeesFilters
    : new DictionaryDto<string, GetPageableEditorialDocumentsQuery>();
  const filter = employeesFilters.find((_) => _.key === key);
  if (filter != null) {
    filter.value = data;
  } else {
    employeesFilters.push({ key, value: data });
  }

  return {
    ...state,
    employeesFilters,
  };
}

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
