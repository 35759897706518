import { createFeatureSelector, createSelector } from '@ngrx/store';

import { StoreState } from '../store-state';
import { State } from './special-additives.reducer';

const selectspecialAdditivesFeature = (state: StoreState) => state.specialAdditives;

export const selectPageableSpecialAdditivesFilters = createSelector(
  selectspecialAdditivesFeature,
  (state: State, props: { key: string }) => {
    const result = state.specialAdditivesFilters
      ? state.specialAdditivesFilters.find((_) => _.key === props.key)
      : null;

    return result ? result.value : null;
  }
);

export const selectAllPageableSpecialAdditivesFilters = createSelector(
  selectspecialAdditivesFeature,
  (state: State) => state.specialAdditivesFilters
);

export const selectPageableSpecialAdditives = createSelector(
  selectspecialAdditivesFeature,
  (state: State, props: { key: string }) => {
    const result = state.pageableSpecialAdditives
      ? state.pageableSpecialAdditives.find((_) => _.key === props.key)
      : null;

    return result ? result.value : null;
  }
);

export const selectSpecialAdditivesForSelect = createSelector(
  selectspecialAdditivesFeature,
  (state: State, props: { key: string }) => {
    const result = state.specialAdditivesForSelect;
    return result ? result : null;
  }
);

export const selectSpecialAdditives = createSelector(selectspecialAdditivesFeature, (state: State) => {
  const result = state.specialAdditives;
  return result ? result : null;
});
