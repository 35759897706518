import { AdmixtureApiCallerService } from './../../shared/api-services/admixture-api-caller.service';
import { RecipeApiCallerService } from './../../shared/api-services/recipe-api-caller.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadAdmixtures,
  loadAdmixturesSuccess,
  loadAdmixturesFailure,
  addAdmixture,
  addAdmixtureSuccess,
  addAdmixtureFailure,
  updateAdmixture,
  updateAdmixtureSuccess,
  updateAdmixtureFailure,
  deleteAdmixture,
  deleteAdmixtureSuccess,
  deleteAdmixtureFailure,
} from './admixture.actions';
import { concatMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AdmixtureEffects {
  admixtures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAdmixtures),
      concatMap(({ recipeId }) => {
        return this.recipeApiCaller.getRecipeAdmixtures(recipeId).pipe(
          map((_) => loadAdmixturesSuccess({ data: _ })),
          catchError((error) => of(loadAdmixturesFailure({ error })))
        );
      })
    )
  );

  addAdmixture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAdmixture),
      concatMap(({ data }) => {
        return this.admixtureApiCaller.addAdmixture(data).pipe(
          map((_) => addAdmixtureSuccess({ id: data.id })),

          catchError((error) => of(addAdmixtureFailure({ error })))
        );
      })
    )
  );

  updateAdmixture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAdmixture),
      concatMap(({ data }) => {
        return this.admixtureApiCaller.updateAdmixture(data).pipe(
          map((_) => updateAdmixtureSuccess({ id: data.id })),

          catchError((error) => of(updateAdmixtureFailure({ error })))
        );
      })
    )
  );

  deleteAdmixture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAdmixture),
      concatMap(({ id }) => {
        return this.admixtureApiCaller.deleteAdmixture(id).pipe(
          map((_) => deleteAdmixtureSuccess({ id })),

          catchError((error) => of(deleteAdmixtureFailure({ error })))
        );
      })
    )
  );
  constructor(
    private actions$: Actions,
    private admixtureApiCaller: AdmixtureApiCallerService,
    private recipeApiCaller: RecipeApiCallerService
  ) {}
}
